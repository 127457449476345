import axios from "axios";
import {
  API_PATHS,
} from "../utils/constants/api.constants";

class EmployerPlanService {

  static getEmployershortPlans() {
    let api = "";
    api = API_PATHS.getemployerShortplan;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getEmployerLongPlans() {
    let api = "";
    api = API_PATHS.getemployerLongplan;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  
//initiate payments service for employer
static employerIntiatePayment(item) {
  let api = "";
  api = API_PATHS.employercheckoutinitiate;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item, { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
}

static employerPlanIntiatePayment(item) {
  let api = "";
  api = API_PATHS.employerplanpurchase;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item, { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
}

//purchage assistance 
static purchageAssistance(item) {
  let api = "";
  api = API_PATHS.purchageassistance;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item, { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
}

  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default EmployerPlanService;
