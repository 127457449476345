import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import client from "../../Assets/Images/client.png";
import { StarRating1 } from "./EmployerRatingModal";
import { FaTimes } from "react-icons/fa";

function ReviewModal({ showModal, data, setShowModal, role }) {
  const [showAllRatings, setShowAllRatings] = useState(false);

  const handleCloseModal = () => {
    setShowAllRatings(false);
    setShowModal(false);
  };
  const handleViewMore = () => setShowAllRatings(true);

  return (
    <>
      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <div className="d-flex justify-content-end">
          <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
            <FaTimes />
          </div>
        </div>
        <Modal.Body
          align="left"
          style={{ maxHeight: "50vh", overflowY: "auto" }}
        >
          <h5>Reviews</h5>

          {data?.ratingsList
            ?.slice(0, showAllRatings ? data?.ratingsList?.length : 4)
            .map((rating, index) => (
              <div>
                {rating?.comment && (
                  <Col key={index} sm={6} lg={12} md={12} className="">
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <div className=" p-1">
                        {role === "cook" ? (
                          <>
                            <div className="overviewcard">
                              <div>
                                <img
                                  alt=""
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "15px",
                                  }}
                                  src={rating?.cookId?.dp || client}
                                />
                              </div>

                              <div className="ms-4">
                                <b>{rating?.cookId?.fullName}</b>
                                <p>{rating?.comment}</p>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div>
                                <div>
                                  Behaviour :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.behaviour)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  Salary :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.salary)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="ms-5">
                                <div>
                                  Facilities :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.facilities)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  Workculture :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.workculture)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        ) : (
                          <>
                            <div className="overviewcard">
                              <div>
                                <img
                                  alt=""
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "15px",
                                  }}
                                  src={rating?.employerId?.dp || client}
                                />
                              </div>
                              <div className="ms-4">
                                <b>{rating?.employerId?.fullName}</b>
                                <p>{rating?.comment}</p>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div>
                                <div>
                                  Behaviour :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.behaviour)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  Punctuality :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.punctuality)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="ms-5">
                                <div>
                                  Hygiene :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.hygiene)}
                                    />
                                  </div>
                                </div>
                                <div>
                                  Taste :<br />
                                  <div>
                                    <StarRating1
                                      value={Math.ceil(rating?.taste)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              </div>
            ))}

          <div className="d-flex justify-content-center">
            {data?.ratingsList?.length > 3 && !showAllRatings && (
              <Link
                style={{
                  textDecoration: "none",
                  borderBottom: "1px solid grey",
                }}
                onClick={handleViewMore}
              >
                View More
              </Link>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReviewModal;
