//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../nav_bar/Footer";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import banner from "../../Assets/Images/customer-service.png";
import cell from "../../Assets/Images/cell.gif";
import mail from "../../Assets/Images/mail.gif";
import Modal2 from "./Modal2";
import { contactUs } from "../../store/slices/config";
import { IoMdCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import SuccessModal from "./SuccessModal";
import { Helmet } from "react-helmet";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileVal, setMobileVal] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [successModal, setsuccessModal] = useState(false);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, checked } = e.target;
    let value = e.target.value;
    switch (name) {
      case "name":
        setName(value);
        validationErrors[name] =
          value.trim() === "" || !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (value.trim() !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "email":
        setEmail(value);
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "Please enter a valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;
      case "subject":
        setSubject(value);
        validationErrors[name] =
          value.trim() === "" || !isValidSubject(value)
            ? "Please enter a valid subject"
            : "";
        if (value.trim() !== "" && isValidSubject(value)) {
          validationErrors[name] = "";
        }
        break;
      case "message":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setMessage(value);
        validationErrors[name] =
          value.trim() === "" || !isValidMessage(value)
            ? "Please enter a valid message"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;
      case "mobileVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobileVal(value);
        validationErrors[name] =
          value.trim() === "" || !isValidmobile(value)
            ? "Please enter a valid mobile number"
            : "";
        if (value.trim().length === "") {
          validationErrors[name] = "";
        }
        break;

      default:
        break;
    }

    setErrors(validationErrors);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9_ ']{2,}$/.test(name);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const isValidSubject = (subject) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\s)[\s\S]{3,}$/.test(subject);
  };
  const isValidMessage = (message) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\s)[\s\S]{3,}$/.test(message);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  async function contact(e) {
    e.preventDefault();
    const validationErrors = {};
    if (!name.trim()) {
      validationErrors.name = "Please enter your name";
    } else if (!isValidName(name)) {
      validationErrors.name = "Name must be atleast three characters";
    }
    if (!email.trim()) {
      validationErrors.email = "Please enter your email";
    } else if (!isValidEmail(email)) {
      validationErrors.email = "Please enter a valid email";
    }
    if (!mobileVal.trim()) {
      validationErrors.mobileVal = "Please enter your mobile number";
    } else if (!isValidmobile(mobileVal)) {
      validationErrors.mobileVal = "Please enter a valid mobile number";
    }
    if (!subject.trim()) {
      validationErrors.subject = "Please enter subject";
    } else if (!isValidSubject(subject)) {
      validationErrors.subject = "Please enter a valid subject";
    }

    if (!message.trim()) {
      validationErrors.message = "Please enter your message";
    } else if (!isValidMessage(message)) {
      validationErrors.message = "Please enter a valid message";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let mobile = parseInt(mobileVal);

    let item = {
      name,
      email,
      mobile,
      message,
      subject,
    };

    dispatch(contactUs(item))
      .unwrap()
      .then(() => {
        setModalMessage("Query submitted successfully!");
        setsuccessModal(true);
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }

  return (
    <>
      <Helmet>
        <title>Contact | Cook and Chef | Customer Care | support</title>
        <meta
          name="description"
          content="Contact us for any help. Get in touch with CookandChef by Email, Whatsapp or Customer Care"
        />
        <meta
          name="keywords"
          content="Cook and Chef Contact Number, CookandChef customer care, support CookandChef"
        />
      </Helmet>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <AuthNavMenu />

      <div className="breadcrumb-container pt-3 pb-3">
        <Row>
          <Col>
            <h2 className="text-center">Contact Us</h2>
            <p className="text-center">
              Don't hesitate to ask, we're here to solve your problems.
            </p>
          </Col>
        </Row>
      </div>
      <Container>
        <Row
          className="mt-5"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            className="p-3 justify-content-center align-items-center"
            lg={10}
            xs={12}
            md={12}
            xl={10}
          >
            <Row>
              <Col lg={5} sm={11} md={11} className="m-3">
                <Row>
                  <Col lg={12} md={12} sm={12} className="">
                    <div
                      className="overviewcardContact p-5"
                      style={{
                        height: "100%",
                        background: "white",
                        borderRadius: "10px",
                        boxShadow: "0 5px 15px rgba(145, 92, 182,0.2)",
                      }}
                    >
                      <div className="overviewcircleContact">
                        <img src={cell} alt="" style={{ height: "30px" }} />
                        &nbsp; &nbsp;
                        <a
                          href="tel:+919178828999"
                          style={{ color: "#000", fontSize: "20px" }}
                        >
                          +91- 9178828999
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} className="">
                    <div
                      className="overviewcardContact mt-3 p-5"
                      style={{
                        height: "100%",
                        background: "white",
                        borderRadius: "10px",
                        boxShadow: "0 5px 15px rgba(145, 92, 182,0.2)",
                      }}
                    >
                      <div className="overviewcircleContact">
                        <img src={mail} alt="" style={{ height: "30px" }} />
                        &nbsp; &nbsp;
                        <a
                          href="mailto:support@cookandchef.in"
                          style={{ color: "#000", fontSize: "20px" }}
                        >
                          support@cookandchef.in
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={5}
                sm={11}
                md={11}
                className="m-3"
                style={{
                  height: "100%",
                  background: "white",
                  borderRadius: "10px",
                  boxShadow: "0 5px 15px rgba(145, 92, 182,0.2)",
                }}
              >
                <Form
                  noValidate
                  autoComplete="off"
                  onSubmit={contact}
                  className="text-center p-4"
                >
                  <Row>
                    <Col lg={12} sm={12} md={12}>
                      <b>For any query, drop a message</b>
                      <Form.Group className="mb-4 mt-3">
                        <Form.Control
                          className="input1"
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          placeholder="Enter your name *"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={12} sm={12} md={12}>
                      <Form.Group className="mb-4 position-relative">
                        <Form.Control
                          name="mobileVal"
                          onChange={handleChange}
                          value={mobileVal}
                          isInvalid={!!errors.mobileVal}
                          className="input1"
                          placeholder="Enter your mobile number *"
                        />

                        <Form.Control.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {errors.mobileVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={11} md={12}>
                      <Form.Group className="mb-4">
                        <Form.Control
                          className="input1"
                          type="text"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          placeholder="Enter your email *"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={12} sm={11} md={12}>
                      <Form.Group className="mb-4 position-relative">
                        <Form.Control
                          name="subject"
                          onChange={handleChange}
                          value={subject}
                          isInvalid={!!errors.subject}
                          className="input1"
                          placeholder="Enter subject *"
                        />

                        <Form.Control.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={11} md={12}>
                      <Form.Group className="mb-4">
                        <Form.Control
                          className="input1"
                          as="textarea"
                          style={{ resize: "none" }}
                          row={1}
                          name="message"
                          value={message}
                          onChange={handleChange}
                          isInvalid={!!errors.message}
                          placeholder="Enter your message *"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    className="mb-2 mt-2  saveBtn"
                    style={{ width: "200px" }}
                    type="submit"
                  >
                    Send Message
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default ContactUs;
