import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsCardText } from "react-icons/bs";
import { AiFillWarning } from "react-icons/ai";
import cookimg from "../../../../Assets/Images/cook.png";
import { Link } from "react-router-dom";
import empimg from "../../../../Assets/Images/restaurant.png";
import {
  getcookProfile,
  getcookDashboardData,
  findJobs,
  findHouseJobs,
  FindEventcook,
  UpdateLogin,
  GetAdvertisements,
} from "../../../../store/slices/cookprofile";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Modal2 from "../../auth_screens/Modal2";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import ShowtopcontentSkeleton from "../../auth_screens/topcontentskeleton";
import ShowoverviewSkeleton from "../../auth_screens/overviewskeleton";
import InitApp from "../../../../utils/helpers/CreateWeavy";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import Carousel from "react-bootstrap/Carousel";

export const ImageSlider = ({ images }) => {
  return (
    <Carousel>
      {images?.length &&
        images?.map((image, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

function CookDashHome() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [data, setData] = useState([]);
  const [dashData, setdashData] = useState([]);
  const [jobData, setjobData] = useState([]);
  const [loginData, setloginData] = useState();
  const [housejobdata, sethousejobData] = useState([]);
  const [eventdata, seteventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [jobIsNew, setjobIsNew] = useState(false);
  const [housejobIsNew, sethousejobIsNew] = useState(false);
  const [eventIsNew, seteventIsNew] = useState(false);
  const [Ads, setAds] = useState();
  const [images, setImages] = useState();

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);

        if (!data.user.data.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.user?.data?.id,
              name: data?.user?.data?.fullName,
            }),
          };
          InitApp(requestOptions, data.user.data, "cook");
        } else {
          localStorage.setItem("weavyId", data.user.data.weavyId);
        }
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Dashboard";
  }, []);

  React.useEffect(() => {
    dispatch(UpdateLogin())
      .unwrap()
      .then((data) => {
        setloginData(data.loginData.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getcookDashboardData())
      .unwrap()
      .then((data) => {
        setdashData(data.user.data);
        setIsLoading(false);
      })
      .catch(({ message }) => {
        // setShowModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [dispatch]);

  const getDetails = () => {
    const item = {
      limit: 4,
      page: 1,
    };
    dispatch(findJobs(item))
      .unwrap()
      .then((data) => {
        setjobData(data?.jobsdata?.data);
        setjobIsNew(data?.jobsdata?.isNew);
        setIsLoading(false);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    gethouseDetails();
  }, [dispatch]);

  const gethouseDetails = () => {
    const item = {
      limit: 4,
      page: 1,
    };
    dispatch(findHouseJobs(item))
      .unwrap()
      .then((data) => {
        sethousejobData(data?.housejobsdata?.data);
        sethousejobIsNew(data?.housejobsdata?.isNew);
        setIsLoading(false);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    geteventDetails();
  }, [dispatch]);

  const geteventDetails = () => {
    const item = {
      limit: 4,
      page: 1,
    };
    dispatch(FindEventcook(item))
      .unwrap()
      .then((data) => {
        seteventData(data?.eventsData?.data);
        seteventIsNew(data?.eventsData?.isNew);
        setIsLoading(false);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    let usertype;

    if (data?.cookType === 2 && data?.partyCook === 1) {
      usertype = "chef";
    } else if (data?.cookType === 1 && data?.partyCook === 1) {
      usertype = "housecook";
    } else if (data?.cookType === 1) {
      usertype = "housecook";
    } else if (data?.cookType === 2) {
      usertype = "chef";
    } else if (data?.memberType === 2) {
      usertype = "catering";
    } else if (data?.partyCook === 1) {
      usertype = "partycook";
    }
    if (usertype) {
      getAds(usertype);
    }
  }, [data]);

  const getAds = (usertype) => {
    const item = {
      limit: 100,
      page: 1,
      usertype: usertype,
    };
    dispatch(GetAdvertisements(item))
      .then((data) => {
        setAds(data?.payload?.ads?.data);
        const images = data?.payload?.ads?.data?.map((el) => el?.image);
        if (images && images?.length > 0) {
          setImages(images);
        }
      })
      .catch(({ message }) => {});
  };

  const startAngle = 0;
  const customStyles = buildStyles({
    rotation: startAngle / 360, // Convert startAngle to a fraction
    strokeLinecap: "butt", // Round or butt
    textSize: "16px",
    pathTransitionDuration: 0.5, // Animation duration
    pathColor: "#ff5c09", // Progress color
    textColor: "#ff5c09", // Text color
    trailColor: "#f4f4f4", // Trail color
    backgroundColor: "#3e98c7", // Background color
  });
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <div className="dash_card my-4 justify-content-center align-items-center">
        {isLoading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row>
            <Col lg={3} sm={5} xs={5} md={3} xl={2}>
              <div className="cprogress">
                <CircularProgressbarWithChildren
                  value={data?.profilePercent || 0}
                  strokeWidth={3}
                  styles={customStyles}
                  className="circular-progress"
                >
                  {data?.dp ? (
                    <img src={data?.dp} alt="user profile" className="userdp" />
                  ) : (
                    <img src={cookimg} alt="user profile" className="userdp" />
                  )}
                </CircularProgressbarWithChildren>
              </div>
            </Col>
            <Col
              lg={4}
              sm={6}
              xs={6}
              xl={5}
              md={4}
              align="left"
              className="ms-4"
            >
              <div className="h4 text-muted mt-1">
                नमस्ते, {data?.fullName}!
              </div>
              {data?.memberType === 1 && (
                <Button
                  className="mt-2 editprofileBtn"
                  onClick={() => navigate("/hin/cook/edit-cook-profile")}
                >
                  प्रोफ़ाइल संपादित करें
                </Button>
              )}
              {data?.memberType === 2 && (
                <Button
                  className="mt-2 editprofileBtn"
                  onClick={() => navigate("/hin/cook/editcateringprofile")}
                >
                  प्रोफ़ाइल संपादित करें
                </Button>
              )}

              {data?.basicProfileStatus === 0 && (
                <p className="d-flex justify-content-left align-items-left mt-2">
                  अपूर्ण प्रोफ़ाइल
                  <AiFillWarning className="m-1" />
                </p>
              )}
            </Col>

            <Col lg={4} sm={12} md={4} xl={4} className="mt-2">
              <Row>
                <Col>
                  <div
                    style={{
                      backgroundColor: "#fffbde",
                      borderRadius: "15px",
                      color: "white",
                      padding: "14px",
                    }}
                  >
                    <h6
                      style={{ color: "#404040" }}
                      className="justify-content-left align-items-left mb-3"
                    >
                      प्रोफ़ाइल पूर्णता
                    </h6>
                    {data?.cookType === 2 && (
                      <p style={{ color: "#404040", lineHeight: "15px" }}>
                        शेफ प्रोफ़ाइल:&nbsp;
                        {data?.chefProfileStatus === 1 && <b>Yes</b>}
                        {data?.chefProfileStatus === 0 && <b>No</b>}
                      </p>
                    )}
                    {data?.cookType === 1 && (
                      <p style={{ color: "#404040", lineHeight: "15px" }}>
                        House Profile:&nbsp;
                        {data?.houseCookProfileStatus === 1 && <b>Yes</b>}
                        {data?.houseCookProfileStatus === 0 && <b>No</b>}
                      </p>
                    )}
                    {data?.memberType === 2 && (
                      <p style={{ color: "#404040", lineHeight: "15px" }}>
                        कैटरिंग प्रोफ़ाइल:&nbsp;
                        {data?.cateringProfileStatus === 1 && <b>Yes</b>}
                        {data?.cateringProfileStatus === 0 && <b>No</b>}
                      </p>
                    )}
                    {data?.partyCook === 1 && (
                      <p style={{ color: "#404040", lineHeight: "15px" }}>
                        पार्टी प्रोफ़ाइल:&nbsp;
                        {data?.partyCookProfileStatus === 1 && <b>Yes</b>}
                        {data?.partyCookProfileStatus === 0 && <b>No</b>}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>

      <div className="mt-5">
        {isLoading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row>
            {data?.cookType === 2 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/jobs" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#dfffe7" }}>
                    Find Jobs
                    {jobIsNew === true && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "10px",
                          width: "10px",
                          marginTop: "-20px",
                          marginLeft: "10px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </Col>
            )}
            {data?.cookType === 2 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/applied-jobs" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#fbe7f8" }}>
                    Applied Jobs
                  </div>
                </Link>
              </Col>
            )}
            {data?.cookType === 1 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/housejobs" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#fcfacf" }}>
                    Find House Jobs
                    {housejobIsNew === true && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "10px",
                          width: "10px",
                          marginTop: "-5px",
                          marginLeft: "10px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </Col>
            )}
            {data?.cookType === 1 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link
                  to="/hin/cook/applied-house-jobs"
                  style={{ color: "#000" }}
                >
                  <div className="homeCard" style={{ background: "#e2ebf8" }}>
                    Applied House Jobs
                  </div>
                </Link>
              </Col>
            )}
            {data?.partyCook === 1 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/find-events" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#fcfacf" }}>
                    Find Events
                    {eventIsNew === true && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "10px",
                          width: "10px",
                          marginTop: "-20px",
                          marginLeft: "10px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </Col>
            )}
            {data?.partyCook === 1 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/applied-events" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#e2ebf8" }}>
                    Interests Sent
                  </div>
                </Link>
              </Col>
            )}

            {data?.memberType === 2 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/find-events" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#fcfacf" }}>
                    Find Events
                    {eventIsNew === true && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "10px",
                          width: "10px",
                          marginTop: "-5px",
                          marginLeft: "10px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      ></div>
                    )}
                  </div>
                </Link>
              </Col>
            )}
            {data?.memberType === 2 && (
              <Col xs={6} lg={3} md={3} xl={3} align="center">
                <Link to="/hin/cook/applied-events" style={{ color: "#000" }}>
                  <div className="homeCard" style={{ background: "#e2ebf8" }}>
                    Interests Sent
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        )}
      </div>
      {images && (
        <div className=" mb-5 mt-5">
          <ImageSlider images={images} className="img-fluid" />
        </div>
      )}
      <Row className="mt-5">
        <Col lg="6" md="6">
          <p className="me-2 pt-3">अवलोकन</p>
          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.cookType === 2 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.chefCurrentDailyPointsBalance} /&nbsp;
                      {dashData?.chefTotalDailyPointsBalance}
                    </b>

                    <p>Daily Chef Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.cookType === 2 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.chefCurrentMonthlyPointsBalance} /&nbsp;
                      {dashData?.chefTotalMonthlyPointsBalance}
                    </b>

                    <p>Monthly Chef Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.cookType === 1 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.houseCookCurrentDailyPointsBalance} /&nbsp;
                      {dashData?.houseCookTotalDailyPointsBalance}
                    </b>

                    <p>Daily House Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.cookType === 1 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.houseCookCurrentMonthlyPointsBalance} /&nbsp;
                      {dashData?.houseCookTotalMonthlyPointsBalance}
                    </b>

                    <p>Monthly House Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.partyCook === 1 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.partyCookCurrentDailyPointsBalance} /&nbsp;
                      {dashData?.partyCookTotalDailyPointsBalance}
                    </b>

                    <p>Daily Party Points</p>
                  </div>
                </div>
              )}
            </div>
          )}
          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.partyCook === 1 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.partyCookCurrentMonthlyPointsBalance} /&nbsp;
                      {dashData?.partyCookTotalMonthlyPointsBalance}
                    </b>

                    <p>Monthly Party Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.memberType === 2 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.cateringCurrentDailyPointsBalance} /&nbsp;
                      {dashData?.cateringTotalDailyPointsBalance}
                    </b>

                    <p>Daily Catering Points</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div>
              {data?.memberType === 2 && (
                <div className="overviewcard">
                  <div className="overviewcircle">
                    <span
                      style={{
                        color: "#ff5c09",
                        fontSize: "18px",
                        marginTop: "-5px",
                      }}
                    >
                      <BsCardText />
                    </span>
                  </div>

                  <div className="ms-3">
                    <br />
                    <b>
                      {dashData?.cateringCurrentMonthlyPointsBalance} /&nbsp;
                      {dashData?.cateringTotalMonthlyPointsBalance}
                    </b>

                    <p>Monthly Catering Points</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
        {data?.cookType === 2 && data?.partyCook === 1 && (
          <Col lg="6" md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="pt-3">अनुशंसित नौकरियाँ</p>
              <Link
                to="/hin/cook/jobs"
                style={{ cursor: "pointer", color: "#fc5509" }}
              >
                सभी देखें
              </Link>
            </div>
            {isLoading ? (
              <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
            ) : (
              <div style={{ marginTop: "-10px" }}>
                {jobData?.map((item, index) => (
                  <div className="overviewcard">
                    <div>
                      {item?.employerId?.dp && (
                        <img
                          src={item?.employerId?.dp}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                      {!item?.employerId?.dp && (
                        <img
                          src={empimg}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ms-3">
                      <b>{item?.designation?.substring(0, 20)}</b>
                      <div>{item?.location}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        )}
        {data?.cookType === 1 && (
          <Col lg="6" md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="pt-3">अनुशंसित घरेलू नौकरियाँ</p>
              <Link
                to="/hin/cook/housejobs"
                style={{ cursor: "pointer", color: "#fc5509" }}
              >
                सभी देखें
              </Link>
            </div>
            {isLoading ? (
              <div>{ShowtopcontentSkeleton()}</div>
            ) : (
              <div style={{ marginTop: "-10px" }}>
                {housejobdata?.map((item, index) => (
                  <div className="overviewcard">
                    <div>
                      {item?.clientId?.dp && (
                        <img
                          src={item?.clientId?.dp}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                      {!item?.clientId?.dp && (
                        <img
                          src={empimg}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ms-3">
                      <b>{item?.clientId?.fullName?.substring(0, 20)}</b>
                      <div>{item?.clientId?.area}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        )}
        {data?.memberType === 2 && (
          <Col lg="6" md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="pt-3">अनुशंसित इवेंट्स</p>
              <Link
                to="/hin/cook/find-events"
                style={{ cursor: "pointer", color: "#fc5509" }}
              >
                सभी देखें
              </Link>
            </div>
            {isLoading ? (
              <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
            ) : (
              <div style={{ marginTop: "-10px" }}>
                {eventdata?.map((item, index) => (
                  <div className="overviewcard">
                    <div>
                      {item?.clientId?.dp && (
                        <img
                          src={item?.clientId?.dp}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                      {!item?.clientId?.dp && (
                        <img
                          src={empimg}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ms-3">
                      <b>{item?.eventType.substring(0, 20)}</b>
                      <div>{item?.city}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        )}
        {!data?.cookType && data?.partyCook === 1 && (
          <Col lg="6" md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="pt-3">अनुशंसित इवेंट्स</p>
              <Link
                to="/hin/cook/find-events"
                style={{ cursor: "pointer", color: "#fc5509" }}
              >
                सभी देखें
              </Link>
            </div>
            {isLoading ? (
              <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
            ) : (
              <div style={{ marginTop: "-10px" }}>
                {eventdata?.map((item, index) => (
                  <div className="overviewcard">
                    <div>
                      {item?.clientId?.dp && (
                        <img
                          src={item?.clientId?.dp}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                      {!item?.clientId?.dp && (
                        <img
                          src={empimg}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ms-3">
                      <b>{item?.eventType?.substring(0, 20)}</b>
                      <div>{item?.city}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        )}
        {data?.cookType === 2 && !data?.partyCook && (
          <Col lg="6" md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="pt-3">अनुशंसित नौकरियाँ</p>
              <Link
                to="/hin/cook/jobs"
                style={{ cursor: "pointer", color: "#fc5509" }}
              >
                सभी देखें
              </Link>
            </div>
            {isLoading ? (
              <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
            ) : (
              <div style={{ marginTop: "-10px" }}>
                {jobData?.map((item, index) => (
                  <div className="overviewcard">
                    <div>
                      {item?.employerId?.dp && (
                        <img
                          src={item?.employerId?.dp}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                      {!item?.employerId?.dp && (
                        <img
                          src={empimg}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ms-3">
                      <b>{item?.designation?.substring(0, 20)}</b>
                      <div>{item?.location}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
        )}
      </Row>
    </>
  );
}

export default CookDashHome;
