import React from "react";
import Skeleton from "react-loading-skeleton";

const DPSkeleton = () => {
  return (
    <>
      <div className="card">
        <Skeleton height={30} circle={true} />
      </div>
    </>
  );
};

export default DPSkeleton;
