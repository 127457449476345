import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import ConfigService from "../../services/config.service";

export const getCuisines = createAsyncThunk(
    "config/getcuisines",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getCuisines();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




export const getPaymentsKey = createAsyncThunk(
    "config/getpaymentskey",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getPaymentKey();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getQualification = createAsyncThunk(
    "config/getqualification",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getQualification();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const getLanguages = createAsyncThunk(
    "config/getlanguages",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getLanguages();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getStates = createAsyncThunk(
    "config/getstates",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getStates();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const topChefs = createAsyncThunk(
    "config/getstates",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.topChefs();
            return { topchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const topjob = createAsyncThunk(
    "config/getstates",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.topJobs();
            return { topjobs: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const testimonial = createAsyncThunk(
    "config/getstates",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.testimonial();
            return { testimonial: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getfaq = createAsyncThunk(
    "config/faq",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.faqs();
            return { faq: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getMessageNotification = createAsyncThunk(
    "config/getMessageNotification",
    async (token, thunkAPI) => {
        try {
            const data = await ConfigService.getmessageNotification(token);
           
            return { messagenotification: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const contactUs = createAsyncThunk(
    "config/contactus",
    async (item, thunkAPI) => {
      try {
        const data = await ConfigService.contactUs(item);
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    messageNotifications:"",
    isLoggedIn: false,
};

const configSlice = createSlice({
    name: "config",
    initialState,
    extraReducers: {
        [getCuisines.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getCuisines.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getCuisines.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        
        [getMessageNotification.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.messageNotifications = action.payload.messagenotification;
        },

        [getQualification.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getQualification.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getQualification.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
       

        // [logout.fulfilled]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = configSlice;
export default reducer;