import React, { useState } from "react";
import { Col, Popover, Row } from "react-bootstrap";

import logo from "../../../Assets/Images/nav_logo.png";
import empimg from "../../../Assets/Images/restaurant.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import settings from "../../../Assets/Images/settings.png";
import signout from "../../../Assets/Images/logout.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function NavBar() {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [myData, setMydata] = useState();

  const logout = () => {
    localStorage.clear();
    localStorage.setItem("logout", Date.now().toString());

    localStorage.setItem("role", "employer");

    navigate("/employer-login");
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        // Perform logout operations in this tab
        // For example, redirect to the login page
        window.location.href = "/employer-login";
      }
    };

    // Attach the event listener
    window.addEventListener("storage", handleStorageChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const data1 = useSelector((state) => state.employerData.empProfileData);

  useEffect(() => {
    setMydata(data1);
  }, [data1]);

  const popover = (
    <Popover>
      <Popover.Body>
        <Link className="d-flex justify-content-left align-items-center mt-1">
          <img src={settings} className="navIcon" alt="" />
          <Link
            style={{ fontSize: "14px", color: "#000" }}
            to="/employer/settings"
          >
            &nbsp;Settings
          </Link>
        </Link>

        <Link className="d-flex justify-content-left align-items-center mt-2">
          <img src={signout} className="navIcon" alt="" />

          <div style={{ fontSize: "14px", color: "#000" }} onClick={logout}>
            &nbsp;Logout
          </div>
        </Link>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Row
        className="d-flex justify-content-center"
        style={{ backgroundColor: "white" }}
      >
        <Col
          xs={11}
          md={10}
          lg={9}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <img
              style={{ cursor: "pointer", height: "30px", width: "80px" }}
              onClick={() => navigate("/")}
              src={logo}
              className="d-none d-md-none d-lg-block d-xl-block m-3"
              alt="app logo"
            />
          </div>
          <div className="position-relative">
            {myData?.user?.data?.dp ? (
              <img
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "150px",
                  border: "solid 1px #a020f0",
                  padding: "2px",
                }}
                src={myData?.user?.data?.dp}
                alt="user"
              />
            ) : (
              <img
                alt="user"
                src={empimg}
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "100%",
                  padding: "2px",
                  border: "solid 1px #a020f0",
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NavBar;
