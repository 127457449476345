import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { EnableAccount } from "../../store/slices/clientprofile";
import { useDispatch } from "react-redux";
import SuccessModal from "../auth_screens/SuccessModal";

function EnableProfileModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalMessage, setModalMessage] = useState();
  const [successModal, setsuccessModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handlEnableAccount = () => {
    dispatch(EnableAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
          navigate("/employer/post-job");
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };

  return (
    <>
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        style={{ left: "5%", top: "20%" }}
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>Enable account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enable your profile to post a job</p>
          <Button className="cancel_btn" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="apply_btn ms-2" onClick={handlEnableAccount}>
            Enable
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EnableProfileModal;
