import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoMdCall } from "react-icons/io";
import client from "../../../../Assets/Images/client.png";
import { FaBookmark } from "react-icons/fa";
import { BsBookmark, BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import {
  CookMobileactivityLog,
  removeshortlistjob,
  cookCancelJob,
  HouseJobdetails,
  ApplyHousecookJob,
  shortlisthousejob,
  getcookProfile,
} from "../../../../store/slices/cookprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ReportChefModal from "./ReportModal";
import { createChatRoom } from "./CreateChatRoom";
import InitApp1 from "../../../../utils/helpers/CreateOthersWeavy";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import { StarRating1 } from "../../../../utils/helpers/EmployerRatingModal";
import ReviewModal from "../../../../utils/helpers/ReviewModal";
import EmployerRatingModal from "../../../../utils/helpers/EmployerRatingModal";

function HouseJobDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    document.title = "CookandChef | House जॉब विवरण";
  }, []);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [mobileInteraction, setMobileInteraction] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [profileData, setProfileData] = useState([]);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [successModal, setsuccessModal] = useState(false);
  const [reportJob, setReportJob] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);
  const [rateModal, setRateModal] = useState(false);
  const [employerId, setEmployerId] = useState();
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  useEffect(() => {
    getDetails();
  }, [id]);

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setProfileData(data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const getDetails = () => {
    dispatch(HouseJobdetails(id))
      .unwrap()
      .then((data) => {
        setData(data.user?.data);
        setphoneNumber(data?.user?.data?.jobData?.clientId?.mobile);
        setEmployerId(data?.user?.data?.jobData?.clientId?.id);
        setwhatsappNumber(data?.user?.data?.jobData?.clientId?.whatsappNumber);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const handleCloseModal = () => {
    setShow1(false);
  };

  const ApplyJob = () => {
    const item = {
      id: id,
    };
    if (profileData?.user?.data?.houseCookProfileStatus === 0) {
      setShow1(true);
    } else {
      dispatch(ApplyHousecookJob(item))
        .then((data) => {
          if (data?.error?.message === "Rejected") {
            setModalMessage(data?.payload?.message);
            setShowModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            getDetails();
          } else {
            setModalMessage("Applied successfully!");
            setsuccessModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            getDetails();
          }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
      getDetails();
    }
  };

  const CancelApplication = () => {
    const item = {
      id: data?.applicationData?.id,
    };
    if (profileData?.user?.data?.houseCookProfileStatus === 0) {
      setShow1(true);
    } else {
      dispatch(cookCancelJob(item))
        .then((data) => {
          if (data?.user?.message) {
            setModalMessage(data?.user?.message);
            setShowModal(true);
          }
          getDetails();
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const toggleMobileView = () => {
    const item = {
      requirementId: id,
      activity: "viewedmobilenumber",
    };
    if (profileData?.user?.data?.houseCookProfileStatus === 0) {
      setShow1(true);
    } else if (data?.jobData?.clientId?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else {
      if (showFullMobile == false) {
        dispatch(CookMobileactivityLog(item))
          .unwrap()
          .then((data) => {
            setShowFullMobile(!showFullMobile);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
          });
      }
    }
  };
  const handleInteracction = async (value) => {
    const item = {
      requirementId: id,
      activity: value,
    };
    if (profileData?.user?.data?.houseCookProfileStatus === 0) {
      setShow1(true);
    } else {
      try {
        const data1 = await dispatch(CookMobileactivityLog(item)).unwrap();

        if (value === "mobileinteraction") {
          window.location.href = `tel:${phoneNumber}`;
        } else if (value === "whatsappinteraction") {
          window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
        } else if (value === "chatinteraction") {
          let member2;
          let res;
          const weavyid = parseInt(localStorage.getItem("weavyId"));
          const member1 = parseInt(user?.data?.weavyId) || weavyid;

          const chatroomName = data?.jobData?.clientId?.fullName;
          if (!data?.jobData?.clientId?.weavyId) {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer  ${API_PATHS.weavyKey}`,
              },
              body: JSON.stringify({
                uid: data?.jobData?.clientId?.id,
                name: data?.jobData?.clientId?.fullName,
              }),
            };
            const rees = await InitApp1(
              requestOptions,
              data?.jobData?.clientId,
              "employer"
            );
            member2 = parseInt(localStorage.getItem("weavyId2"));
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          } else {
            member2 = parseInt(data?.jobData?.clientId?.weavyId);

            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          }

          if (res?.id) {
            navigate("/hin/cook/message", {
              state: { res: res },
            });
          }
        }
      } catch ({ message }) {
        setModalMessage(message);
        setShowModal(true);
      }
    }
  };

  const removeshortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      requirementId: id,
    };

    dispatch(removeshortlistjob(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);
          setShowModal(true);
        }
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const shortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      id: id,
    };

    dispatch(shortlisthousejob(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);
          setShowModal(true);
        }
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const gotoEdit = () => {
    navigate("/hin/cook/edit-house-profile");
  };

  useEffect(() => {
    // Check if data exists and has ratingsList
    if (data && data?.jobData && data?.jobData?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.jobData?.ratingsList.some(
        (rating) => rating.comment
      );

      // Set reviews based on whether any rating has a comment
      setReviews(hasComment);
    }
  }, [data]);

  return (
    <>
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={id}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
        type="HouseJob"
      />
      <ReviewModal
        showModal={reviewModal}
        data={data.jobData}
        setShowModal={setShowReviewModal}
        role="cook"
      />
      <EmployerRatingModal
        rateModal={rateModal}
        id={employerId}
        setRateModal={setRateModal}
        getDetails={getDetails}
        data={data}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={show1}
        style={{ top: "20%", left: "5%" }}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <p>
            Your house profile is empty.
            <br /> <b>Please update your profile to apply</b>
          </p>

          <Button className="modalsaveBtn" onClick={gotoEdit}>
            Update
          </Button>
          <Button className="modalcancelBtn ms-2" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/cook/housejobs" className="bcrumb">
          हॉउस कुक जॉब्स खोजें
        </Link>
        &nbsp;&bull; हॉउस जॉब विवरण
      </div>
      <Row className="mt-2">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={3}>
                {data?.jobData?.clientId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={data?.jobData?.clientId?.dp}
                    alt="user profile"
                  />
                )}
                {!data?.jobData?.clientId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={client}
                    alt="user profile"
                  />
                )}
              </Col>
              <Col
                xs={3}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && <p>Saved</p>}
                </div>
              </Col>
              <Col
                xs={1}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={7} xl={7} xs={12} md={6}>
                <h6 className="mt-2" style={{ textWrap: "wrap" }}>
                  {data?.jobData?.clientId?.fullName}
                </h6>
                <p style={{ textWrap: "wrap" }}>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.jobData?.clientId?.area},
                  {data?.jobData?.clientId?.cityName}
                  <br />
                  <AiOutlineMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.jobData?.clientId?.mobile}</span>
                  ) : (
                    <span>
                      {data?.jobData?.clientId?.mobile
                        ?.toString()
                        ?.substring(0, 3)}
                      xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-25px",

                          color: "blue",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col
                lg={2}
                xl={2}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && (
                    <p style={{ marginRight: "-25px" }}>Saved</p>
                  )}
                </div>
              </Col>
              <Col
                lg={1}
                md={1}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={5} xl={7} xs={12} md={5} />
              <Col lg={7} xl={5} xs={12} md={7} className="jobCard">
                <Row>
                  {data?.applicationData ? (
                    <Col lg={5} md={5} xs={5}>
                      <Link
                        onClick={CancelApplication}
                        style={{ lineHeight: "40px" }}
                      >
                        <u>आवेदन वापस लें</u>
                      </Link>
                    </Col>
                  ) : (
                    <Col lg={5} md={5} xs={5}>
                      <button className="jobsaveBtn mt-2" onClick={ApplyJob}>
                        आवेदन करें
                      </button>
                    </Col>
                  )}
                  {data?.jobData?.clientId?.smsContact === 1 && (
                    <Col
                      lg={2}
                      xs={2}
                      md={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.jobData?.clientId?.whatsappContact === 1 &&
                    data?.jobData?.clientId?.whatsappNumber && (
                      <Col
                        lg={2}
                        xs={2}
                        md={2}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInteracction("whatsappinteraction")
                        }
                      >
                        <BsWhatsapp
                          style={{ color: "#059F14", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                  {data?.jobData?.clientId?.chatContact === 1 && (
                    <Col
                      lg={2}
                      md={2}
                      xs={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {isLoading ? (
        <Row className="card  p-3">{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-4">
          <Col lg={12} xs={12} md={12} sm={12} className="card p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              रेक्विरेमेंट विवरण
            </h6>

            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading">व्यंजन</p>
                <p className="text-details">
                  {data?.jobData?.cuisines?.join(",  ")}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">जॉब का प्रकार</p>
                {data?.jobData?.jobType === 1 && (
                  <p className="text-details">Part Time</p>
                )}
                {data?.jobData?.jobType === 2 && (
                  <p className="text-details">Full Time</p>
                )}
                {data?.jobData?.jobType === 3 && (
                  <p className="text-details">Any</p>
                )}
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">पसंदीदा लिंग</p>
                {data?.jobData?.preferredGender === 1 && (
                  <p className="text-details">पुरुष</p>
                )}
                {data?.jobData?.preferredGender === 2 && (
                  <p className="text-details">महिला</p>
                )}
                {data?.jobData?.preferredGender === 3 && (
                  <p className="text-details">Any</p>
                )}
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">कम से कम पेमेंट</p>

                <p className="text-details">{data?.jobData?.minimumPayment}</p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">भोजन का प्रकार</p>
                <Row>
                  {data?.jobData?.breakfast === 1 && (
                    <Col lg={4} xl={4} sm={4} md={4}>
                      <p
                        style={{
                          padding: "3px",
                          border: "solid 1px #ff5c09",
                          borderRadius: "10px",
                          width: "auto",
                          textAlign: "center",
                        }}
                      >
                        Breakfast
                      </p>
                    </Col>
                  )}

                  {data?.jobData?.lunch === 1 && (
                    <Col lg={4} xl={4} sm={4} md={4}>
                      <p
                        style={{
                          padding: "3px",
                          border: "solid 1px #ff5c09",
                          borderRadius: "10px",
                          width: "auto",
                          textAlign: "center",
                        }}
                      >
                        Lunch
                      </p>
                    </Col>
                  )}

                  {data?.jobData?.dinner === 1 && (
                    <Col lg={4} xl={4} sm={4} md={4}>
                      <p
                        style={{
                          padding: "3px",
                          border: "solid 1px #ff5c09",
                          borderRadius: "10px",
                          width: "auto",
                          textAlign: "center",
                        }}
                      >
                        Dinner
                      </p>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">बर्तन धोना</p>
                {data?.jobData?.vesselWash === 1 && (
                  <p className="text-details">Yes</p>
                )}
                {data?.jobData?.vesselWash === 0 && (
                  <p className="text-details">No</p>
                )}
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">प्राथमिकता चाहिए</p>

                <p className="text-details">{data?.jobData?.urgency}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {isLoading ? (
        <Row className="card  p-3">{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-4">
          <Col lg={12} xs={12} md={12} sm={12} className="card p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Client Details
            </h6>

            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading">पता पंक्ति1</p>

                <p className="text-details">
                  {data?.jobData?.clientId?.addressLine1}
                </p>
              </Col>

              <Col lg={6} xs={6}>
                <p className="text-heading">पता पंक्ति2</p>

                <p className="text-details">
                  {data?.jobData?.clientId?.addressLine2}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">लिंग</p>
                {data?.jobData?.clientId?.gender === 1 && (
                  <p className="text-details">पुरुष</p>
                )}
                {data?.jobData?.clientId?.gender === 2 && (
                  <p className="text-details">महिला</p>
                )}
                {data?.jobData?.clientId?.gender === 3 && (
                  <p className="text-details">अन्य</p>
                )}
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">पेशा</p>

                <p className="text-details">
                  {data?.jobData?.clientId?.occupation}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">ज्ञात भाषाएँ</p>
                <p className="text-details">
                  {data?.jobData?.clientId?.languages?.join(",  ")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <br />
      <br />
      <br />
      <Row>
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col
            style={{ borderRadius: "15px", padding: "14px" }}
            className="card"
          >
            {data?.jobData?.ratingsData?.behaviour?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.facilities?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.salary?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.workculture?.ratingAvg === 0 ? (
              <div>
                <div className="text-start">
                  रेटिंग देने वाले प्रथम व्यक्ति बनें
                </div>
                <Link
                  className="text-start"
                  style={{
                    color: "#ff5c09",
                    textDecoration: "underline",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setRateModal(true)}
                >
                  <u>इस क्लाइंट को रेटिंग दें</u>
                </Link>
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <div style={{ marginTop: "10px" }}>Work culture :</div>
                    <div style={{ marginTop: "10px" }}>Salary & increment:</div>
                    <div style={{ marginTop: "10px" }}>Facilities :</div>
                    <div style={{ marginTop: "10px" }}>Behaviour :</div>
                  </div>
                  <div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.workculture?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.salary?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.facilities?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.behaviour?.ratingAvg
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Link
                    className="text-start mt-3"
                    style={{
                      color: "#ff5c09",
                      textDecoration: "underline",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => setRateModal(true)}
                  >
                    <u>इस क्लाइंट को रेटिंग दें </u>
                  </Link>

                  {reviews && (
                    <Link
                      className="text-start mt-3 ml-5"
                      style={{
                        color: "#ff5c09",
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "30px",
                      }}
                      onClick={() => setShowReviewModal(true)}
                    >
                      <u>समीक्षा देखें</u>
                    </Link>
                  )}
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col style={{ borderRadius: "15px", padding: "14px" }}>
          <Link
            onClick={() => setReportJob(true)}
            className="mt-3"
            style={{
              color: "#8F8686",
              cursor: "pointer",
            }}
          >
            <u>इस क्लाइंट की रिपोर्ट करें</u>
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default HouseJobDetails;
