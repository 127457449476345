//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------

import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import React, { useState, useEffect } from "react";

import logo from "../../../Assets/Images/nav_logo.png";
import { BsBriefcaseFill } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import { LuChefHat } from "react-icons/lu";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function AuthNavMenu() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [navBackground, setNavBackground] = useState(false);

  return (
    <>
      {/* web navbar */}

      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        sticky="top"
        style={{
          transition: "1s ease",
          color: "black",
          backgroundColor: navBackground ? "white" : "white",
        }}
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              className="me-5"
              height={60}
              width={142}
              alt="app logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <BiMenuAltLeft style={{ fontSize: "32px" }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink className="me-2 nav-link-item" text-start to="/">
                Home
              </NavLink>

              <NavLink to="/contact" className="me-4 nav-link-item">
                Contact
              </NavLink>
            </Nav>

            <Nav>
              <NavLink to="/login" className="nav-link-item me-2">
                <LuChefHat /> Login
              </NavLink>
            </Nav>
            <Nav>
              <NavLink to="/employer-login" className="nav-link-item nav_login">
                <BsBriefcaseFill /> Employer Login
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavMenu;
