import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getCuisines } from "../../../../store/slices/config";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import {
  editCateringProfile,
  getcookProfile,
} from "../../../../store/slices/cookprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EditCatering() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [city, setCity] = useState("");
  const [foodType, setFoodType] = useState("");
  const [minPlates, setMinPlates] = useState("");
  const [fssai, setFssai] = useState("");
  const [website, setWebsite] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const cuisineRef = useRef(null);
  const foodtypeRef = useRef(null);
  const minplateRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "CookandChef | Edit Profile";
  }, []);

  const isValidWebsite = (value) =>
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/.test(
      value
    );
  const isValidFSSAI = (value) => /^[12].{13}$/.test(value);

  const isValidTeamSize = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 3 && intValue <= 100000 && /^[1-9]\d*$/.test(value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    const validationErrors = {};
    if (!chefCuisines?.length) {
      validationErrors.cuisines = "कृपया ज्ञात व्यंजन दर्ज करें";
    }

    if (!foodType) {
      validationErrors.foodType = "कृपया भोजन का प्रकार चुनें";
    }
    if (!minPlates) {
      validationErrors.minPlates = "कृपया प्लेटों की न्यूनतम संख्या चुनें";
    }
    if (website && !isValidWebsite(website)) {
      validationErrors.website = "कृपया एक वैध वेबसाइट दर्ज करें";
    }
    if (fssai && !isValidFSSAI(fssai)) {
      validationErrors.fssai = "कृपया एक वैध FSSAI नंबर दर्ज करें";
    }
    if (teamSize && !isValidTeamSize(teamSize)) {
      validationErrors.teamSize = "टीम का आकार न्यूनतम 3 होना चाहिए";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.cuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.foodType) {
        foodtypeRef.current.focus();
      } else if (validationErrors.minPlates) {
        minplateRef.current.focus();
      }
      return;
    } else {
      let item = {
        fssai: fssai,
        cateringFoodType: parseInt(foodType) || undefined,
        cateringMinPlates: parseInt(minPlates) || undefined,
        cateringCuisines: chefCuisines?.length ? chefCuisines : undefined,
        teamSize: parseInt(teamSize) || undefined,
        website: website,
      };
      dispatch(editCateringProfile(item))
        .unwrap()
        .then((data) => {
          setModalMessage(data.user.message);

          setsuccessModal(true);
          setTimeout(() => {
            navigate("/hin/cook/cook-profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "city":
        setCity(value);
        validationErrors[name] =
          value === "" ? "कृपया शहर का नाम दर्ज करें" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "foodType":
        setFoodType(value);
        validationErrors[name] =
          value === "" ? "कृपया भोजन का प्रकार चुनें" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "minPlates":
        setMinPlates(value);
        validationErrors[name] =
          value === "" ? "कृपया न्यूनतम प्लेटें चुनें" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "fssai":
        setFssai(value);
        validationErrors[name] =
          value !== "" && !isValidFSSAI(value)
            ? "कृपया एक वैध FSSAI नंबर दर्ज करें"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "website":
        setWebsite(value);
        validationErrors[name] =
          value !== "" && !isValidWebsite(value)
            ? "कृपया एक वैध वेबसाइट दर्ज करें"
            : "";
        if (!value === "" && isValidWebsite(value)) {
          delete validationErrors[name];
        }
        break;
      case "teamSize":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 3) {
          value = value.slice(0, 3);
        }
        setTeamSize(value);
        validationErrors[name] =
          value !== "" && !isValidTeamSize(value)
            ? "टीम का आकार न्यूनतम 3 होना चाहिए"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  var options = cuisines?.map(function (item) {
    return item["cuisineName"];
  });

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setchefCuisines(data?.user?.data?.cateringCuisines);
        setMinPlates(data?.user?.data?.cateringMinPlates);
        setFoodType(data?.user?.data?.cateringFoodType);
        setWebsite(data?.user?.data?.website);
        setFssai(data?.user?.data?.fssai);
        setTeamSize(data?.user?.data?.teamSize);
      })
      .catch(({ message }) => {
        // setModalMessage(message)
        // setShowModal(true)
      });
  }, [dispatch]);

  const handleChefCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setchefCuisines(selected);
    validationErrors.cuisines =
      selected?.length === 0 ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
    if (!selected?.length === 0) {
      delete validationErrors.cuisines;
    }

    setErrors(validationErrors);
  };

  const cancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &bull;
        <Link to="/hin/cook/cook-profile" className="bcrumb">
          कुक प्रोफ़ाइल
        </Link>
        &bull; Edit कैटरिंग प्रोफ़ाइल
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row className="mt-4 gx-3 d-flex justify-content-center ">
          <Col lg={12} xs={11} className="mb-4">
            <Row className="=d-flex justify=-content-center align-items-center">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "20px 40px",
                }}
              >
                <Col lg={12}>
                  <Row>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">ज्ञात व्यंजन *</label>
                        <div ref={cuisineRef}>
                          <Typeahead
                            clearButton
                            id="basic-typeahead-multiple"
                            labelKey="name"
                            multiple
                            className="input1"
                            onChange={handleChefCuisineChange}
                            options={options}
                            placeholder="ज्ञात व्यंजन"
                            selected={chefCuisines}
                            isInvalid={!!errors.cuisines}
                          />
                        </div>
                        <p style={{ color: "#DC3545", fontSize: "14px" }}>
                          {errors.cuisines}
                        </p>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Food Type *</label>
                        <Form.Control
                          as="select"
                          name="foodType"
                          className="input1"
                          value={foodType}
                          onChange={handleChange}
                          isInvalid={!!errors.foodType}
                          isValid={!errors.foodType && foodType?.length > 0}
                          ref={foodtypeRef}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="1">Pure Veg</option>
                          <option value="2">Veg/Non-Veg</option>
                          <option value="3">Jain Food</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.foodType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Min. Plates *</label>
                        <Form.Control
                          as="select"
                          name="minPlates"
                          className="input1"
                          value={minPlates}
                          onChange={handleChange}
                          isInvalid={!!errors.minPlates}
                          ref={minplateRef}
                          isValid={!errors.minPlates && minPlates?.length > 0}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.minPlates}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">FSSAI</label>
                        <Form.Control
                          name="fssai"
                          maxlength="14"
                          value={fssai}
                          onChange={handleChange}
                          className="input1"
                          isInvalid={!!errors.fssai}
                          isValid={!errors.fssai && fssai?.length > 0}
                          type="text"
                          placeholder="Enter FSSAI number"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fssai}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Website</label>
                        <Form.Control
                          name="website"
                          value={website}
                          onChange={handleChange}
                          isInvalid={!!errors.website}
                          isValid={!errors.website && website?.length > 0}
                          className="input1"
                          type="text"
                          placeholder="Enter your website"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.website}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Team Size</label>
                        <Form.Control
                          name="teamSize"
                          value={teamSize}
                          onChange={handleChange}
                          isInvalid={!!errors.teamSize}
                          isValid={!errors.teamSize && teamSize?.length > 0}
                          className="input1"
                          type="text"
                          placeholder="Enter your team size"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.teamSize}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Row className="d-flex justify-content-center mt-4">
                  <Col lg={4} xs={8}>
                    <div className="d-flex justify-content-around my-2 align-items-center">
                      <Button
                        type="cancel"
                        onClick={cancel}
                        className="cancelBtn"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isdisabled}
                        className="saveBtn ms-2"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EditCatering;
