import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCuisines } from "../../../store/slices/config";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { Link } from "react-router-dom";
import {
  editclientRequirement,
  getclientRequirement,
} from "../../../store/slices/clientprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EditRequirement() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Edit Requirement";
  }, []);

  const [cuisines, setCuisines] = useState([]);
  const [selectCuisines, setSelectCuisines] = useState([]);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [vesselWash, setvesselWash] = useState();
  const [isdisabled, setisDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobTypeRef = useRef(null);
  const genderRef = useRef(null);
  const cuisineRef = useRef(null);
  const minPaymentRef = useRef(null);
  const urgecyRef = useRef(null);
  const mealTypeRef = useRef(null);

  React.useEffect(() => {
    dispatch(getclientRequirement())
      .unwrap()
      .then((data) => {
        setData(data?.user?.data[0]);
        setSelectCuisines(data?.user?.data[0]?.cuisines);
        setvesselWash(data?.user?.data[0]?.vesselWash || 0);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!data?.jobType) {
      validationErrors.jobType = "Please select job type";
    }
    if (!data?.preferredGender) {
      validationErrors.preferredGender = "Please select preferred gender";
    }

    if (
      (data?.breakfast === 0 && data?.lunch === 0 && data?.dinner === 0) ||
      !data ||
      (data?.breakfast === 0 &&
        data?.lunch === undefined &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === 0 &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === undefined &&
        data?.dinner === 0) ||
      (data?.breakfast === 0 &&
        data?.lunch === undefined &&
        data?.dinner === 0) ||
      (data?.breakfast === undefined &&
        data?.lunch === 0 &&
        data?.dinner === 0) ||
      (data?.breakfast === 0 &&
        data?.lunch === 0 &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === undefined &&
        data?.dinner === undefined)
    ) {
      validationErrors.mealType = "Please select atleast one meal";
    }

    if (!data?.minimumPayment) {
      validationErrors.minimumPayment = "Please select minimum payment";
    }
    if (!data?.urgency) {
      validationErrors.urgency = "Please select urgency";
    }

    const isValid = validateCuisines(selectCuisines);
    if (!isValid) {
      validationErrors.cuisines = "Please enter atleast one cuisine";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.jobType) {
        jobTypeRef.current.focus();
      } else if (validationErrors.preferredGender) {
        genderRef.current.focus();
      } else if (validationErrors.cuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.mealType) {
        mealTypeRef.current.focus();
      } else if (validationErrors.urgency) {
        urgecyRef.current.focus();
      }
      return;
    } else {
      const item = {
        cuisines: selectCuisines,
        jobType: parseInt(data.jobType),
        minimumPayment: data.minimumPayment,
        urgency: data.urgency || undefined,
        breakfast: parseInt(data.breakfast) || 0,
        lunch: parseInt(data.lunch) || 0,
        dinner: parseInt(data.dinner) || 0,
        vesselWash: parseInt(vesselWash),
        preferredGender: parseInt(data.preferredGender) || undefined,
      };

      dispatch(editclientRequirement(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Requirement updated succesfully");
          setsuccessModal(true);
          setTimeout(() => {
            navigate("/client/requirement");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangeMeal = (e) => {
    const { value, name, checked } = e.target;
    switch (name) {
      case "lunch":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          lunch: checked ? 1 : 0,
        });
        break;
      case "dinner":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          dinner: checked ? 1 : 0,
        });
        break;
      case "breakfast":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          breakfast: checked ? 1 : 0,
        });
        break; // Add break statement here
      default:
        break;
    }
  };
  const validateCuisines = (selectedOptions) => {
    return selectedOptions?.length > 0;
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value, checked, type } = e.target;

    switch (name) {
      case "jobType":
        setData({
          ...data,
          jobType: value,
        });
        validationErrors[name] = value === "" ? "please select job type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "preferredGender":
        setData({
          ...data,
          preferredGender: value,
        });
        validationErrors[name] =
          value === "" ? "Please select preferred gender" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "minimumPayment":
        setData({
          ...data,
          minimumPayment: value,
        });
        validationErrors[name] =
          value === "" ? "Please select minimum payment" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "urgency":
        setData({
          ...data,
          urgency: value,
        });
        validationErrors[name] = value === "" ? "Please select urgency" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setSelectCuisines(selected);
    validationErrors.cuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.cuisines;
    }
    setErrors(validationErrors);
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/client/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/client/requirement" className="bcrumb">
          Requirement
        </Link>
        &nbsp;&bull; Edit Requirement
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row className="d-flex justify-content-center mt-4 card p-3">
          <Col lg={12} xs={12} md={12} className="mb-4">
            <Row className="gx-2 d-flex justify-content-center">
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Job Type *</label>
                  <Form.Control
                    as="select"
                    name="jobType"
                    className="input1"
                    value={data?.jobType}
                    onChange={handleChange}
                    isInvalid={!!errors.jobType}
                    isValid={errors.jobType}
                    ref={jobTypeRef}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="1">Full Time</option>
                    <option value="2">Part Time</option>
                    <option value="3">Any</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.jobType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Preferred Gender *</label>
                  <Form.Control
                    as="select"
                    name="preferredGender"
                    className="input1"
                    value={data?.preferredGender}
                    onChange={handleChange}
                    isInvalid={!!errors.preferredGender}
                    isValid={errors.preferredGender}
                    ref={genderRef}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.preferredGender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={12} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-1 label">Cuisines *</label>
                  <div ref={cuisineRef}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="cuisineName"
                      multiple
                      className="input1"
                      onChange={handleCuisineChange}
                      options={options}
                      placeholder="Select cuisines"
                      selected={selectCuisines}
                    />
                  </div>
                </Form.Group>
                <p style={{ color: "#DC3545", fontSize: "14px" }}>
                  {errors.cuisines}
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={12}>
            <Row className="gx-2 d-flex justify-content-center">
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Meal Type *</label>
                  <Form.Check
                    type="checkbox"
                    id="breakfast"
                    name="breakfast"
                    label="Breakfast"
                    ref={mealTypeRef}
                    checked={data?.breakfast === 1}
                    onChange={handleChangeMeal}
                  />

                  <Form.Check
                    type="checkbox"
                    id="lunch"
                    name="lunch"
                    label="Lunch"
                    checked={data?.lunch === 1}
                    onChange={handleChangeMeal}
                  />
                  <Form.Check
                    type="checkbox"
                    id="dinner"
                    name="dinner"
                    label="Dinner"
                    checked={data?.dinner === 1}
                    onChange={handleChangeMeal}
                  />
                  <div style={{ fontSize: "14px", color: "#dc3545" }}>
                    {errors.mealType}
                  </div>
                </Form.Group>
              </Col>

              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Min. Payment *</label>
                  <Form.Control
                    as="select"
                    name="minimumPayment"
                    className="input1"
                    value={data?.minimumPayment}
                    onChange={handleChange}
                    isInvalid={!!errors.minimumPayment}
                    ref={minPaymentRef}
                    isValid={errors.minimumPayment}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="1000 to 2000">1000 to 2000</option>
                    <option value="2000 to 3000">2000 to 30000</option>
                    <option value="3000 to 5000">3000 to 5000</option>
                    <option value="5000 to 10000">5000 to 10000</option>
                    <option value="10000 to 15000">10000 to 15000</option>
                    <option value="15000 to 25000">15000 to 25000</option>
                    <option value="Above 25000">Above 25000</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.minimumPayment}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Urgency *</label>
                  <Form.Control
                    as="select"
                    name="urgency"
                    className="input1"
                    value={data?.urgency}
                    onChange={handleChange}
                    isInvalid={!!errors.urgency}
                    isValid={errors.urgency}
                    ref={urgecyRef}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Immediately">Immediately</option>
                    <option value="15 Days">15 Days</option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Months</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.urgency}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className=" justify-content-start align-items-center">
                  <label className="mb-2 label">Vessel Wash</label>
                  <Row>
                    <Col lg={3} md={3} xs={6}>
                      <Form.Check
                        type="switch"
                        id="yes"
                        label="Yes"
                        name="vesselWash"
                        value={data?.vesselWash}
                        onChange={() => setvesselWash(1)}
                        checked={vesselWash === 1}
                        className="switchBtn"
                      />
                    </Col>
                    <Col lg={3} md={3} xs={6}>
                      <Form.Check
                        type="switch"
                        id="no"
                        label="No"
                        name="vesselWash"
                        value={data?.vesselWash}
                        onChange={() => setvesselWash(0)}
                        checked={vesselWash === 0}
                        className="switchBtn"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <Col lg={6} className="p-3"></Col>
            </Row>
          </Col>
          <Row className="d-flex justify-content-center mt-4">
            <Col lg={4} xs={8}>
              <div className="d-flex justify-content-around my-2 align-items-center">
                <Button onClick={() => navigate(-1)} className="cancelBtn">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isdisabled}
                  className="saveBtn ms-2"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
}

export default EditRequirement;
