import { Link } from "react-router-dom";
import page from "../../Assets/Images/404.png";
import logo from "../../Assets/Images/nav_logo.png";
import { useNavigate, useParams } from "react-router-dom";
const title = "Error 404!";
const desc = "Oops! The Page You Are Looking For Could Not Be Found";
const btnText = "Go Back";

const EmployerErrorPage = () => {
  const navigate = useNavigate();
  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="four-zero-section padding-tb section-bg mt-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-sm-12 col-12 mb-5" align="center">
            <div className="four-zero-content">
              {/* <img src={logo} alt="cookandchef" /> */}

              <h3>{title}</h3>
              <p>{desc}</p>
              <Link onClick={cancelHandler} className="lab-btn">
                <span>
                  {btnText} <i className="icofont-external-link"></i>
                </span>
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-sm-6 col-12">
            {/* <div className="foue-zero-thumb"> */}
            {/* <img src={page} alt="" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerErrorPage;
