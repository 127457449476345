import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EditemployerProfile,
  getemployerProfile,
} from "../../../store/slices/employerprofile";
import { Link } from "react-router-dom";
import { API_PATHS } from "../../../utils/constants/api.constants";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import AvatarModel from "../../../utils/helpers/ImageCropper/AvatarModal";
import DataSkeleton from "../../auth_screens/dataskeleton";

function EditProfile() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [fileName, setFileName] = useState("Upload Boundary File");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [cityName, setCityName] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [successModal, setsuccessModal] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isdisabled, setisDisabled] = useState(false);
  const [inputcityError, setcityInputError] = useState(false);
  const propertyRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const contactPersonRef = useRef(null);
  const contactPersonnumberRef = useRef(null);
  const totemployeesRef = useRef(null);
  const estdYearref = useRef(null);
  const websiteRef = useRef(null);
  const licenceRef = useRef(null);
  const addresRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    document.title = "CookandChef | EditProfile";
  }, []);

  const isValidName = (fullName) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9@&'’ -]{3,}$/.test(fullName);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z0-9\- ',&@]{3,100}$/.test(currentCityName);
  };

  const isValidAddressline1 = (addressline1) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9 .'#-,]{1,100}$/.test(addressline1);
  };

  const isValidEmployeesCount = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 1 && intValue <= 100000 && /^[1-9]\d*$/.test(value);
  };

  const isValidEstablishmentYear = (value) => {
    const currentYear = new Date().getFullYear();
    const intValue = parseInt(value, 10);
    return (
      intValue >= 1900 &&
      intValue <= currentYear &&
      /^(19[0-9]\d|20[0-2]\d)$/.test(value)
    );
  };

  const isValidWebsite = (value) =>
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/.test(
      value
    );

  const isValidcontactpersonName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9_ ']{2,}$/.test(name);
  };

  const isValidFSSAI = (value) => /^[12].{13}$/.test(value);

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setSelectedImage(data?.user?.data?.dp);

        UpdateData(data?.user?.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  const UpdateData = (data) => {
    setData({
      ...data,
      cityCoordinates: {
        type: "Point",
        coordinates: data?.cityCoordinates?.coordinates || [],
      },
    });
  };

  const cityinputRef = useRef(null);
  useEffect(() => {
    if (!isLoading && cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
        } else {
          setcityInputError(false);
          setErrors({
            ...errors,
            cityName: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setCityName(place.name);
          setcity(coordinates, place.name);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setcityInputError(true);
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, [isLoading]);

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      cityName: city,
      cityCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!data.fullName) {
      validationErrors.fullName = "Please enter property name";
    } else if (!isValidName(data.fullName)) {
      validationErrors.fullName =
        "Property name should have at least 3 characters";
    }

    if (
      (data.cityName && !isValidcurrentCity(data.cityName)) ||
      inputcityError
    ) {
      validationErrors.cityName = "Please enter valid city name";
    }
    if (!data.propertyType) {
      validationErrors.propertyType = "Please select property type";
    }
    if (
      data.establishmentYear &&
      !isValidEstablishmentYear(data.establishmentYear)
    ) {
      validationErrors.establishmentYear =
        "Please enter valid establishment year";
    }
    if (data.contactPersonMobile && !isValidmobile(data.contactPersonMobile)) {
      validationErrors.contactPersonMobile = "Please enter valid mobile number";
    }

    if (data.contactPerson && !isValidcontactpersonName(data.contactPerson)) {
      validationErrors.contactPerson = "Please enter valid contact person name";
    }

    if (data.addressLine1 && !isValidAddressline1(data.addressLine1)) {
      validationErrors.addressLine1 = "Please enter valid adress";
    }

    if (data.employeesCount && !isValidEmployeesCount(data.employeesCount)) {
      validationErrors.employeesCount =
        "Total no. of employees should not be more than 100000";
    }

    if (
      data.establishmentYear &&
      !isValidEstablishmentYear(data.establishmentYear)
    ) {
      validationErrors.establishmentYear =
        "Please enter valid establishment year";
    }

    if (data.website && !isValidWebsite(data.website)) {
      validationErrors.website = "Please enter your website";
    }

    if (data.fssai && !isValidFSSAI(data.fssai)) {
      validationErrors.fssai = "Please enter a valid FSSAI number";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.fullName) {
        propertyRef.current.focus();
      } else if (validationErrors.propertyType) {
        propertyTypeRef.current.focus();
      } else if (validationErrors.contactPerson) {
        contactPersonRef.current.focus();
      } else if (validationErrors.contactPersonMobile) {
        contactPersonnumberRef.current.focus();
      } else if (validationErrors.cityName) {
        cityinputRef.current.focus();
      } else if (validationErrors.employeesCount) {
        totemployeesRef.current.focus();
      } else if (validationErrors.establishmentYear) {
        estdYearref.current.focus();
      } else if (validationErrors.website) {
        websiteRef.current.focus();
      } else if (validationErrors.fssai) {
        licenceRef.current.focus();
      } else if (validationErrors.addressLine1) {
        addresRef.current.focus();
      }

      return;
    } else {
      const numberph = parseInt(data.contactPersonMobile);

      const countemp = parseInt(data.employeesCount);
      const propertyTypeVal = parseInt(data.propertyType);
      const item = {
        fullName: data.fullName,
        propertyType: propertyTypeVal,
        contactPerson: data.contactPerson,
        contactPersonMobile: numberph || undefined,
        fssai: data.fssai,
        dp: data.dp,
        establishmentYear: parseInt(data.establishmentYear) || undefined,
        employeesCount: countemp || undefined,
        website: data.website,
        cityName: data.cityName,
        cityCoordinates: cityName
          ? {
              type: "Point",
              coordinates: data?.cityCoordinates?.coordinates,
            }
          : undefined,
      };
      dispatch(EditemployerProfile(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Profile updated succesfully");
          setsuccessModal(true);
          setTimeout(() => {
            navigate("/employer/profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
        });
    }
  };

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFileEmployer, requestOptions);
    let data2 = await response.json();
    setData({
      ...data,
      dp: data2.data.location,
    });
  }

  //handle change for setting input values
  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "fullName":
        setData({
          ...data,
          fullName: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter valid property name"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "cityName":
        setData({
          ...data,
          cityName: value,
        });
        validationErrors[name] = value !== "" ? "Please enter valid city" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "propertyType":
        setData({
          ...data,
          propertyType: value,
        });
        validationErrors[name] =
          value === "" ? "Please select property type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "contactPerson":
        setData({
          ...data,
          contactPerson: value,
        });
        validationErrors[name] =
          value !== "" && !isValidcontactpersonName(value)
            ? "Please enter a valid name"
            : "";
        if (isValidcontactpersonName(value)) {
          delete validationErrors[name];
        }
        break;
      case "contactPersonMobile":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setData({
          ...data,
          contactPersonMobile: value,
        });
        validationErrors[name] =
          value !== "" && !isValidmobile(value)
            ? "Please enter valid mobile number"
            : "";
        if (isValidmobile(value)) {
          delete validationErrors[name];
        }
        break;
      case "addressLine1":
        setData({
          ...data,
          addressLine1: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAddressline1(value)
            ? "Please enter valid address"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "employeesCount":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setData({
          ...data,
          employeesCount: value,
        });
        validationErrors[name] =
          value !== "" && !isValidEmployeesCount(value)
            ? "Please enter a valid number of employees"
            : "";
        if (isValidEmployeesCount(value)) {
          delete validationErrors[name];
        }
        break;

      case "establishmentYear":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 4) {
          value = value.slice(0, 4);
        }
        setData({
          ...data,
          establishmentYear: value,
        });
        validationErrors[name] =
          value !== "" && !isValidEstablishmentYear(value)
            ? "Please enter a valid establishment year"
            : "";
        if (isValidEstablishmentYear(value)) {
          delete validationErrors[name];
        }
        break;

      case "website":
        setData({
          ...data,
          website: value,
        });
        validationErrors[name] =
          value !== "" && !isValidWebsite(value)
            ? "Please enter a valid website URL"
            : "";
        if (isValidWebsite(value)) {
          delete validationErrors[name];
        }
        break;

      case "fssai":
        setData({
          ...data,
          fssai: value,
        });
        validationErrors[name] =
          value !== "" && !isValidFSSAI(value)
            ? "Please enter a valid FSSAI number"
            : "";
        if (isValidFSSAI(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setData({
      ...data,
      [name]: value,
    });

    setErrors(validationErrors);
  };
  const navigate = useNavigate();

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <AvatarModel
        showModal1={avatarModalOpen}
        image={imageData}
        setShowModal1={setAvatarModalOpen}
        handleSave={handleSave}
        setData={setData}
        data={data}
      />

      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/employer/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/employer/profile" className="bcrumb">
          Profile
        </Link>
        &nbsp;&bull; Edit Profile
      </div>

      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        {isLoading ? (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            {DataSkeleton()}
          </Row>
        ) : (
          <Row className="mt-2 gx-3 d-flex justify-content-center card ">
            <Col lg={12} xl={12} md={12} xs={11}>
              <Col lg={12} className=" p-3">
                <Row>
                  <Col lg={3} md={3} sm={12} xs={12} align="center">
                    {data?.dp && (
                      <img
                        className="mt-2"
                        src={data.dp}
                        alt="user profile"
                        style={{
                          height: "120px",
                          width: "120px",
                          borderRadius: "20px",
                        }}
                      />
                    )}
                    {!data?.dp && (
                      <div
                        className="pt-5 mt-2"
                        style={{
                          height: "120px",
                          width: "120px",
                          borderRadius: "20px",
                          background: "#303030",
                        }}
                      >
                        <p style={{ color: "#fff" }}>Upload Image</p>
                      </div>
                    )}
                  </Col>
                  <Col lg={4} md={4} xs={12} className="mt-5" align="left">
                    <label for="fileInput" className="custom-button">
                      Change Image
                    </label>
                    <Form.Control
                      type="file"
                      name="profiledp"
                      id="fileInput"
                      lable={fileName}
                      onChange={(e) => handleChangeImage(e)}
                      className="custom-file-label"
                      custom
                    />
                  </Col>
                </Row>
              </Col>
              <hr />
              <Row className="d-flex justify-content-center mt-2  p-3">
                <Col lg={12} xs={12} md={12} className="mb-4">
                  <Row className="gx-2 d-flex justify-content-center">
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Property Name *</label>
                        <Form.Control
                          className="input1"
                          name="fullName"
                          value={data.fullName}
                          onChange={handleChange}
                          type="text"
                          isInvalid={!!errors.fullName}
                          ref={propertyRef}
                          placeholder="Enter property name"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fullName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Property Type *</label>
                        <Form.Control
                          as="select"
                          name="propertyType"
                          className="input1"
                          value={data.propertyType}
                          ref={propertyTypeRef}
                          onChange={handleChange}
                          isInvalid={!!errors.propertyType}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="1">Hotel</option>
                          <option value="2">Restaurant</option>
                          <option value="3">Cafe</option>
                          <option value="4">Cloud Kitchen</option>
                          <option value="5">Others</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.propertyType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          Contact Person Name
                        </label>
                        <Form.Control
                          name="contactPerson"
                          value={data.contactPerson}
                          onChange={handleChange}
                          isInvalid={!!errors.contactPerson}
                          className="input1"
                          type="text"
                          ref={contactPersonRef}
                          placeholder="Enter contact person name"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contactPerson}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          Contact Person Number
                        </label>
                        <Form.Control
                          name="contactPersonMobile"
                          value={data.contactPersonMobile}
                          onChange={handleChange}
                          isInvalid={!!errors.contactPersonMobile}
                          className="input1"
                          type="text"
                          ref={contactPersonnumberRef}
                          placeholder="Enter contact person number"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contactPersonMobile}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          No. of Total Employees
                        </label>
                        <Form.Control
                          name="employeesCount"
                          value={data.employeesCount}
                          onChange={handleChange}
                          isInvalid={!!errors.employeesCount}
                          className="input1"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          ref={totemployeesRef}
                          placeholder="Enter total No. of employees"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.employeesCount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          Year of Establishment
                        </label>
                        <Form.Control
                          name="establishmentYear"
                          value={data.establishmentYear}
                          onChange={handleChange}
                          isInvalid={!!errors.establishmentYear}
                          className="input1"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          ref={estdYearref}
                          placeholder="Enter year of establishment"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.establishmentYear}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Website</label>
                        <Form.Control
                          name="website"
                          value={data.website}
                          onChange={handleChange}
                          isInvalid={!!errors.website}
                          className="input1"
                          type="text"
                          ref={websiteRef}
                          placeholder="Enter your website"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.website}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">FSSAI</label>
                        <Form.Control
                          name="fssai"
                          maxlength="14"
                          value={data.fssai}
                          onChange={handleChange}
                          isInvalid={!!errors.fssai}
                          className="input1"
                          ref={licenceRef}
                          type="text"
                          placeholder="Enter FSSAI number"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fssai}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Address</label>
                        <Form.Control
                          name="addressLine1"
                          value={data.addressLine1}
                          onChange={handleChange}
                          isInvalid={!!errors.addressLine1}
                          className="input1"
                          ref={addresRef}
                          type="text"
                          maxLength={100}
                          placeholder="Enter address"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.addressLine1}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">City</label>

                        <Form.Control
                          name="cityName"
                          value={data.cityName}
                          onChange={handleChange}
                          className="input1"
                          isInvalid={!!errors.cityName}
                          type="text"
                          ref={cityinputRef}
                          placeholder="Enter city"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cityName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <Row className="d-flex justify-content-center mt-4">
                  <Col lg={4} xs={8}>
                    <div className="d-flex justify-content-around my-2 align-items-center">
                      <Button
                        onClick={() => navigate(-1)}
                        className="cancelBtn"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isdisabled}
                        className="saveBtn ms-2"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}

export default EditProfile;
