import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ConfigService {


    static getCuisines() {
        let api = '';
        api = API_PATHS.getCuisine;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getQualification() {
        let api = '';
        api = API_PATHS.getQualification;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static getLanguages() {
        let api = '';
        api = API_PATHS.getLanguages;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static getStates() {
        let api = '';
        api = API_PATHS.getState;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    
    static getPaymentKey() {
        let api = '';
        api = API_PATHS.gepaymentkey;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
            .get(api,{ headers: { "x-access-token": token } })
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static topChefs() {
        let api = '';
        api = API_PATHS.topChefs;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static topJobs() {
        let api = '';
        api = API_PATHS.topJobs;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static testimonial() {
        let api = '';
        api = API_PATHS.testimonial;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static faqs() {
        let api = '';
        api = API_PATHS.getFaq;
     
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getmessageNotification = async(token) =>{
      try{

     const requestoptions ={
        method:"GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
     }

        const response = await fetch(
            `https://${API_PATHS.weavyId}.weavy.io/api/notifications`,
            requestoptions
          );
          const data1 = await response.json();
      return data1

     }catch(err){
        console.log(err)
     }

    }
       
            
               
            


    static contactUs(item) {
        return axios.post(API_PATHS.contactUs, item).then((response) => {
          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
      }


    static removeuserDetails() {
        localStorage.removeItem("user");
    }

    static getuserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ConfigService;