import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import EmployerService from "../../services/employer.service";

export const getemployerProfile = createAsyncThunk(
    "employer/getprofile",
    async ( thunkAPI) => {
        try {
            const data = await EmployerService.getemployerProfile();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getemployerDashboardData = createAsyncThunk(
    "employer/getdashboraddata",
    async ( thunkAPI) => {
        try {
            const data = await EmployerService.getemployerDashbaordData();
            return { empDashData: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getemployerPlanHistory = createAsyncThunk(
    "employer/getplanhistory",
    async ( thunkAPI) => {
        try {
            const data = await EmployerService.getplanHistory();
            return { empPlanHistory: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getemployerApplications = createAsyncThunk(
    "employer/getallapplication",
    async ( item,thunkAPI) => {
        try {
            const data = await EmployerService.getAllApplications(item);
            return { singleapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getallemployerApplications = createAsyncThunk(
    "employer/getallapplication",
    async ( thunkAPI) => {
        try {
            const data = await EmployerService.getAllApplicationsdata();
            return { allapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getemployerjobApplications = createAsyncThunk(
    "employer/getallapplication",
    async ( item,thunkAPI) => {
        try {
            const data = await EmployerService.getJobApplications(item);
            return { jobapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const getEventRequests = createAsyncThunk(
    "employer/geteventrequests",
    async ( item,thunkAPI) => {
        try {
            const data = await EmployerService.getEventRequests(item);
            return { alleventrequest: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getRequirementRequests = createAsyncThunk(
    "employer/geteventrequests",
    async ( item,thunkAPI) => {
        try {
            const data = await EmployerService.getRequirementRequests(item);
            return { allrequirementrequest: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
//edit employer profile
export const EditemployerProfile = createAsyncThunk(
    "employer/editprofile",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.editemployerProfile(item);
            return { editempprofile: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const UpdateLogin = createAsyncThunk(
    "employer/updatelogin",
    async (thunkAPI) => {
        try {
            const data = await EmployerService.UpdateLogin();
            return { logindata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//postjob slice
export const PostjobEmployer = createAsyncThunk(
    "employer/postjob",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.PostJob(item);
            return { postjob: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//edit job 
export const EditjobEmployer = createAsyncThunk(
    "employer/editjob",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.editJob(item);
            return { editjob: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//get job
export const GetjobEmployer = createAsyncThunk(
    "employer/getjob",
    async (id, thunkAPI) => {
        try {
            const data = await EmployerService.getJob(id);
            return { getalljobemployer: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const GetChefDetails = createAsyncThunk(
    "employer/getchefdetails",
    async (id, thunkAPI) => {
        try {
            const data = await EmployerService.getChefDetails(id);
            return { chefdetailsEmployer: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const GetApplicationDetails = createAsyncThunk(
    "employer/getapplicationdetails",
    async (id, thunkAPI) => {
        try {
            const data = await EmployerService.getApplicationDetails(id);
            return { applicationdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const GetAlljobEmployer = createAsyncThunk(
    "employer/getjob",
    async ( thunkAPI) => {
        try {
            const data = await EmployerService.getAllJob();
            return { getalljobemployer: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const findChef = createAsyncThunk(
    "employer/findchef",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.findchef(item);
            return { findchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const topChef = createAsyncThunk(
    "employer/findchef",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.topchef(item);
            return { findchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//get job details from employer
export const GetEmployerJobDetails = createAsyncThunk(
    "cook/getjobdetails",
    async (id, thunkAPI) => {
        try {
            const data = await EmployerService.getEmployerJobDetails(id);
            return { jobdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// for setting emplyer application status
export const EmployerApplicationStatus = createAsyncThunk(
    "employer/applicationstatus",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.employerAplicationSatus(item);
            return { applicationstatus: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const clienteventStatus = createAsyncThunk(
    "employer/applicationstatus",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.clientEventSatus(item);
            return { eventstatus: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


// for employer mobile activity log
export const EmployerMobileactivityLog = createAsyncThunk(
    "employer/mobileactivity",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.employerMobileactivityLog(item);
            return { employermobileactivity: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




export const viewshortlistedchefs = createAsyncThunk(
    "employer/viewshortlistchef",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.viewshortlistingchefs(item);
            return { shortlistedchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)





export const viewemployershortlistedchefs = createAsyncThunk(
    "employer/viewemployershortlistchef",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.viewEmployershortlistingchefs(item);
            return { shortlistedchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)
//for shortlisting jobs from employer
export const shortlistchef = createAsyncThunk(
    "employer/shortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.shortlistchefs(item);
            return { shortlistchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//employer update weavy
export const updateWeavyEmployer = createAsyncThunk(
    "employer/updateweavyid",
    async (item,thunkAPI) => {
        try {
            const data = await EmployerService.updateEmployerWeavy(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
//employer report chef 

export const EmployerReoprtChef = createAsyncThunk(
    "employer/reportChef",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.employerReportchefService(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const StoreCookRating = createAsyncThunk(
    "employer/cookrating",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.storeCookRating(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getSharedProfiles = createAsyncThunk(
    "employer/getsharedprofiles",
    async ( item,thunkAPI) => {
        try {
            const data = await EmployerService.getSharedProfiles(item);
            return { sharedprofiles: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const GetsharedprofileDetails = createAsyncThunk(
    "employer/getsharedprofiledetails",
    async (id, thunkAPI) => {
        try {
            const data = await EmployerService.getSharedProfileDetails(id);
            return { sharedprofiledetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//get advertisements for employer
export const GetEmployerAdvertisements = createAsyncThunk(
    "employer/getadds",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.getemployerAdvertisement(item);
            return { ads: data }; // Return only the serializable data
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//for removing shortlisting jobs from employer
export const removeshortlistchef = createAsyncThunk(
    "employer/removeshortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.removeshortlistchefs(item);
            return { removeshortlistchef: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const findResponseEmployer = createAsyncThunk(
    "/employer/fetch/reachout-activities",
    async (item, thunkAPI) => {
        try {
            const data = await EmployerService.employerreachoutactivities(item);
            return data ;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
const initialState = {
    loading: false,
    error: "",
chefdata: [],
shortlistdata:[],
cateringData:[],
empPlanHistory:[],
singleapplication:[],
empProfileData: [],
user:[],
    isLoggedIn: false,
};

const employerSlice = createSlice({
    name: "employer",
    initialState,
    extraReducers: {
        [getemployerProfile.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.empProfileData = null;
        },
        [getemployerProfile.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.empProfileData = action.payload;
        },
        [getemployerProfile.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.empProfileData = null;
        },
        [findChef.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
     
        },
        [findChef.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.chefdata = action.payload;
        },
        [findChef.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
      
        },

       
        
        [viewshortlistedchefs.fulfilled]:(state,action) =>{
            state.loading = false;
            state.error ="";
            state.isLoggedIn = true;
            state.shortlistdata = action.payload;
        }
       

        // [logout.fulfilled]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = employerSlice;
export default reducer;