// ChatBot.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../ChatBot/Helpchat.css";
import botimg from "../../../Assets/Images/chat-assistant-cookandchef.png";
import chatbg from "../../../Assets/Images/chatbg.svg";
import HelpSupportQs from "./HelpSupportQs";

const HomeChatBot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const data1 = useSelector((state) => state?.cookData?.user);

  const handleLogoClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isLoading === false && (
        <div
          style={{
            cursor: "pointer",
            transition: "width 0.5s ease",
          }}
        >
          {isExpanded === false ? (
            <img
              onClick={handleLogoClick}
              src={botimg}
              alt="Cookie"
              style={{ width: "70px", height: "70px" }}
            />
          ) : (
            <div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  background: "#fff",
                  overflowY: "auto",
                  maxHeight: "500px",
                  minHeight: "400px",
                  width: "320px",
                  // opacity: "0.2",
                  zIndex: "-1",
                  // textAlign: "right",
                }}
              >
                <div
                  style={{
                    zIndex: 9999,
                    background: " hsla(225, 78%, 59%, 1)",

                    background:
                      "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",

                    background:
                      "-moz-linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",

                    background:
                      "-webkit-linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <div className="p-1">
                    <img
                      onClick={handleLogoClick}
                      src={botimg}
                      className="me-2"
                      alt="Cookie"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />

                    <span
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Cookie
                    </span>
                  </div>
                  <div align="right" style={{ marginTop: "-35px" }}>
                    <button
                      onClick={handleLogoClick}
                      type="button"
                      className="close"
                      aria-label="Close"
                      style={{
                        border: "none",
                        background: "transparent",
                        color: "white",
                        fontSize: "25px",
                        textAlign: "right",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    // backgroundImage: `url(${chatbg})`,
                  }}
                >
                  <HelpSupportQs />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomeChatBot;
