import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaBookmark } from "react-icons/fa";
import Moment from "moment";
import { IoMdCall } from "react-icons/io";
import client from "../../../Assets/Images/client.png";
import { BsBookmark, BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import {
  CookMobileactivityLog,
  GetEventDetails,
  removeshortlistjob,
  shortlistevent,
  CookApplyEvent,
  cookCancelJob,
  getcookProfile,
} from "../../../store/slices/cookprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ReportChefModal from "./ReportModal";
import { createChatRoom } from "./CreateChatRoom";
import InitApp1 from "../../../utils/helpers/CreateOthersWeavy";
import { API_PATHS } from "../../../utils/constants/api.constants";
import ContactPivacyModal from "../../../utils/helpers/ContactPrivacyModal";
import { StarRating1 } from "../../../utils/helpers/EmployerRatingModal";
import EmployerRatingModal from "../../../utils/helpers/EmployerRatingModal";
import ReviewModal from "../../../utils/helpers/ReviewModal";

function SavedEventDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [show1, setShow1] = useState(false);
  const [reportJob, setReportJob] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientId, setClientId] = useState();
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);
  const [rateModal, setRateModal] = useState(false);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    getDetails();
  }, [id]);

  let user = JSON.parse(localStorage.getItem("user"));
  const getDetails = () => {
    dispatch(GetEventDetails(id))
      .unwrap()
      .then((data) => {
        setData(data?.jobdetails?.data);
        setphoneNumber(data?.jobdetails?.data?.eventData?.clientId?.mobile);
        setClientId(data?.jobdetails?.data?.eventData?.clientId?.id);
        setwhatsappNumber(
          data?.jobdetails?.data?.eventData?.clientId?.whatsappNumber
        );
        setIsRespTrue(true);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setProfileData(data?.user?.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const ApplyJob = () => {
    const item = {
      id: id,
    };
    if (
      profileData?.partyCookProfileStatus === 0 &&
      profileData?.cateringProfileStatus === 0
    ) {
      setShow1(true);
    } else {
      dispatch(CookApplyEvent(item))
        .then((data) => {
          if (data?.error?.message === "Rejected") {
            setModalMessage(data?.payload?.message);
            setShowModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            getDetails();
          } else {
            setModalMessage("Interest sent successfully!");
            setsuccessModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            getDetails();
          }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    }
  };

  const CancelApplication = () => {
    const item = {
      id: data?.applicationData?.id,
    };
    if (
      profileData?.partyCookProfileStatus === 0 &&
      profileData?.cateringProfileStatus === 0
    ) {
      setShow1(true);
    } else {
      dispatch(cookCancelJob(item))
        .then((data) => {
          setModalMessage("Interest cancelled successfully");
          setsuccessModal(true);
          setTimeout(() => {
            setsuccessModal(false);
          }, 3000);
          getDetails();
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  useEffect(() => {
    document.title = "CookandChef | Event Details";
  }, []);

  const toggleMobileView = () => {
    const item = {
      eventId: id,
      activity: "viewedmobilenumber",
    };
    if (
      profileData?.partyCookProfileStatus === 0 &&
      profileData?.cateringProfileStatus === 0
    ) {
      setShow1(true);
    } else if (data?.eventData?.clientId?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else {
      if (showFullMobile == false) {
        dispatch(CookMobileactivityLog(item))
          .unwrap()
          .then((data) => {
            setShowFullMobile(!showFullMobile);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
          });
      }
    }
  };

  const handleInteracction = async (value) => {
    const item = {
      eventId: id,
      activity: value,
    };
    if (
      profileData?.partyCookProfileStatus === 0 &&
      profileData?.cateringProfileStatus === 0
    ) {
      setShow1(true);
    } else {
      try {
        const data1 = await dispatch(CookMobileactivityLog(item)).unwrap();

        if (value === "mobileinteraction") {
          window.location.href = `tel:${phoneNumber}`;
        } else if (value === "whatsappinteraction") {
          window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
        } else if (value === "chatinteraction") {
          let member2;
          let res;
          const weavyid = parseInt(localStorage.getItem("weavyId"));
          const member1 = parseInt(user?.data?.weavyId) || weavyid;
          member2 = parseInt(data?.eventData?.clientId?.weavyId);

          const chatroomName = data?.eventData?.clientId?.fullName;
          if (!data?.eventData?.clientId?.weavyId) {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer  ${API_PATHS.weavyKey}`,
              },
              body: JSON.stringify({
                uid: data?.eventData?.clientId?.id,
                name: data?.eventData?.clientId?.fullName,
              }),
            };
            const rees = await InitApp1(
              requestOptions,
              data?.eventData?.clientId,
              "employer"
            );
            member2 = parseInt(localStorage.getItem("weavyId2"));
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          } else {
            member2 = parseInt(data?.eventData?.clientId?.weavyId);
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          }

          if (res?.id) {
            navigate("/cook/message", {
              state: { res: res },
            });
          }
        }
      } catch ({ message }) {
        setModalMessage(message);
        setShowModal(true);
      }
    }
  };

  const removeshortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      eventId: id,
    };

    dispatch(removeshortlistjob(elem))
      .then((data) => {
        if (data?.user?.message) {
          setModalMessage(data?.user?.message);
        }
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const shortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      id: id,
    };

    dispatch(shortlistevent(elem))
      .then((data) => {
        if (data?.user?.message) {
          setModalMessage(data?.user?.message);
        }
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const gotoEdit = () => {
    navigate("/cook/edit-party-profile");
  };

  const gotoEditcatering = () => {
    navigate("/cook/edit-catering-profile");
  };

  const handleCloseModal = () => {
    setShow1(false);
  };

  useEffect(() => {
    // Check if data exists and has ratingsList
    if (data && data?.eventData && data?.eventData?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.eventData?.ratingsList.some(
        (rating) => rating.comment
      );

      // Set reviews based on whether any rating has a comment
      setReviews(hasComment);
    }
  }, [data]);

  return (
    <>
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={id}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
        type="Client"
      />
      <ReviewModal
        showModal={reviewModal}
        data={data.eventData}
        setShowModal={setShowReviewModal}
        role="cook"
      />
      <EmployerRatingModal
        rateModal={rateModal}
        id={clientId}
        setRateModal={setRateModal}
        getDetails={getDetails}
        data={data}
      />

      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <Modal
        show={show1}
        style={{ top: "20%", left: "5%" }}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          {profileData?.memberType === 2 && (
            <p>
              Your catering profile is empty.
              <br /> <b>Please update to apply</b>
            </p>
          )}
          {profileData?.memberType === 1 && (
            <p>
              Your party profile is empty.
              <br /> <b>Please update to apply</b>
            </p>
          )}
          {profileData?.memberType === 1 && (
            <Button className="saveBtn" onClick={gotoEdit}>
              Update
            </Button>
          )}
          {profileData?.memberType === 2 && (
            <Button className="saveBtn" onClick={gotoEditcatering}>
              Update
            </Button>
          )}

          {/* <Button className="saveBtn" onClick={gotoEdit}>
            Update
          </Button> */}
          <Button className="cancelBtn ms-2" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/cook/short-listed-events" className="bcrumb">
          Saved Events
        </Link>
        &nbsp;&bull;&nbsp;Event Details
      </div>
      <Row className="mt-2">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={3}>
                {data?.eventData?.clientId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={data?.eventData?.clientId?.dp}
                    alt="user profile"
                  />
                )}
                {!data?.eventData?.clientId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={client}
                    alt="user profile"
                  />
                )}
              </Col>
              <Col
                xs={3}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && <p>Saved</p>}
                </div>
              </Col>
              <Col
                xs={1}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={7} xl={7} xs={12} md={6}>
                <h6 className="mt-2" style={{ textWrap: "wrap" }}>
                  {data?.eventData?.clientId?.fullName}
                </h6>
                <p style={{ textWrap: "wrap" }}>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.eventData?.location}, {data?.eventData?.city},
                  {data?.eventData?.pincode}
                  <br />
                  <AiOutlineMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.eventData?.clientId?.mobile}</span>
                  ) : (
                    <span>
                      {data?.eventData?.clientId?.mobile
                        ?.toString()
                        ?.substring(0, 3)}
                      xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-24px",
                          // marginLeft: "150px",
                          color: "blue",
                          // width: "40px",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col
                lg={2}
                xl={2}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && (
                    <p style={{ marginRight: "-25px" }}>Saved</p>
                  )}
                </div>
              </Col>
              <Col
                lg={1}
                md={1}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={5} xl={7} xs={12} md={5} />
              <Col lg={7} xl={5} xs={12} md={7} className="jobCard">
                <Row>
                  {data?.applicationData ? (
                    <Col lg={5} md={5} xs={5}>
                      <Link
                        onClick={CancelApplication}
                        style={{ lineHeight: "40px" }}
                      >
                        <u>Cancel Interest</u>
                      </Link>
                    </Col>
                  ) : (
                    <Col lg={5} md={5} xs={5}>
                      <button className="jobsaveBtn mt-2" onClick={ApplyJob}>
                        Send Interest
                      </button>
                    </Col>
                  )}
                  {data?.eventData?.clientId?.smsContact === 1 && (
                    <Col
                      lg={2}
                      xs={2}
                      md={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.eventData?.clientId?.whatsappContact === 1 &&
                    data?.eventData?.clientId?.whatsappNumber && (
                      <Col
                        lg={2}
                        xs={2}
                        md={2}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInteracction("whatsappinteraction")
                        }
                      >
                        <BsWhatsapp
                          style={{ color: "#059F14", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                  {data?.eventData?.clientId?.chatContact === 1 && (
                    <Col
                      lg={2}
                      md={2}
                      xs={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Row className="mt-4">
        {isLoading ? (
          <Col lg={12} className="card  p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col lg={12} xs={12} md={12} sm={12} className="card p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Event Details
            </h6>

            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading">Event Type</p>
                <p className="text-details">{data?.eventData?.eventType}</p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">Event Date</p>
                <p className="text-details">
                  {Moment.utc(data?.eventData?.eventDate).format("DD-MM-YYYY")}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">Expected Guest</p>

                <p className="text-details">{data?.eventData?.expectedGuest}</p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">Cuisines</p>
                <p className="text-details">
                  {data?.eventData?.cuisines?.join(",  ")}
                </p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">Dishes</p>

                <p className="text-details">{data?.eventData?.dishes}</p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <br />
      <br />
      <Row>
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col
            style={{ borderRadius: "15px", padding: "14px" }}
            className="card"
          >
            {data?.eventData?.ratingsData?.behaviour?.ratingAvg === 0 &&
            data?.eventData?.ratingsData?.facilities?.ratingAvg === 0 &&
            data?.eventData?.ratingsData?.salary?.ratingAvg === 0 &&
            data?.eventData?.ratingsData?.workculture?.ratingAvg === 0 ? (
              <div>
                <div className="text-start">Not rated yet!</div>
                <Link
                  className="text-start"
                  style={{
                    color: "#ff5c09",
                    textDecoration: "underline",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setRateModal(true)}
                >
                  <u>Rate this client</u>
                </Link>
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <div style={{ marginTop: "10px" }}>Work culture :</div>
                    <div style={{ marginTop: "10px" }}>Salary & increment:</div>
                    <div style={{ marginTop: "10px" }}>Facilities :</div>
                    <div style={{ marginTop: "10px" }}>Behaviour :</div>
                  </div>
                  <div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.eventData?.ratingsData?.workculture?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.eventData?.ratingsData?.salary?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.eventData?.ratingsData?.facilities?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.eventData?.ratingsData?.behaviour?.ratingAvg
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Link
                    className="text-start mt-3"
                    style={{
                      color: "#ff5c09",
                      textDecoration: "underline",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => setRateModal(true)}
                  >
                    <u>Rate this client </u>
                  </Link>

                  {reviews && (
                    <Link
                      className="text-start mt-3 ml-5"
                      style={{
                        color: "#ff5c09",
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "30px",
                      }}
                      onClick={() => setShowReviewModal(true)}
                    >
                      <u>View Reviews</u>
                    </Link>
                  )}
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
      <br />
      <br />
      <br />
      <br />
      <Link
        onClick={() => setReportJob(true)}
        className="text-start mt-5"
        style={{
          color: "#8F8686",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        <u>Report this client</u>
      </Link>
    </>
  );
}

export default SavedEventDetails;
