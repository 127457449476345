import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import client from "../../Assets/Images/client.png";

// ... other imports

function ChangeWatsappmodal({ showModal, setShowModal, message, func }) {
  // ... existing code

  const handleCloseModal = () => setShowModal(false);
  const update = () => {
    func();
    handleCloseModal();
  };

  return (
    <>
      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body
          align="left"
          style={{ maxHeight: "50vh", overflowY: "auto" }}
        >
          <h5>
            This WhatsApp number is already linked with another account. Do you
            want to update it?
          </h5>

          <div className="d-flex justify-content-center mt-3">
            <button
              type="button"
              className="modalcancelBtn ms-2"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="modalsaveBtn ms-2"
              onClick={update}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangeWatsappmodal;
