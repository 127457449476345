import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "moment";
import cook from "../../../../Assets/Images/cook.png";
import { BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";

import { EmployerMobileactivityLog } from "../../../../store/slices/employerprofile";
import { GetsharedprofileDetails } from "../../../../store/slices/employerprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import ReportChefModal from "./ReportChefModal";
import { createChatRoom } from "../../cook_dashboard/pages/CreateChatRoom";
import InitApp1 from "../../../../utils/helpers/CreateOthersWeavy";
import ContactPivacyModal from "../../../../utils/helpers/ContactPrivacyModal";

function SharedProfileDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [cookId, setCookId] = useState();
  const [reportJob, setReportJob] = useState(false);
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  let profileDetails = JSON.parse(localStorage.getItem("profiledetails"));

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Shared Profile Details";
  }, []);

  useEffect(() => {
    getDetails();
  }, [dispatch]);

  const getDetails = () => {
    dispatch(GetsharedprofileDetails(id))
      .unwrap()
      .then((data) => {
        setData(data?.sharedprofiledetails?.data);
        setCookId(data?.sharedprofiledetails?.data?.cookId?.id);
        setphoneNumber(data?.sharedprofiledetails?.data?.cookId?.mobile);
        setwhatsappNumber(
          data?.sharedprofiledetails?.data?.cookId?.whatsappNumber
        );
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const toggleMobileView = () => {
    const item = {
      cookId: id,
      activity: "viewedmobilenumber",
    };
    if (data?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else if (showFullMobile === false) {
      dispatch(EmployerMobileactivityLog(item))
        .unwrap()
        .then((data) => {
          setShowFullMobile(!showFullMobile);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleInteracction = async (value) => {
    const item = {
      cookId: id,
      activity: value,
    };

    try {
      const data1 = await dispatch(EmployerMobileactivityLog(item)).unwrap();
      if (value === "mobileinteraction") {
        window.location.href = `tel:${phoneNumber}`;
      } else if (value === "whatsappinteraction") {
        window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
      } else if (value === "chatinteraction") {
        let member2;
        let res;
        const weavyid = parseInt(localStorage.getItem("weavyId"));
        const member1 = parseInt(user?.data?.weavyId) || weavyid;
        member2 = parseInt(data?.weavyId);

        const chatroomName = data?.fullName;
        if (!data?.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.id,
              name: data?.fullName,
            }),
          };
          const rees = await InitApp1(requestOptions, data, "cook");
          member2 = parseInt(localStorage.getItem("weavyId2"));
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        } else {
          member2 = parseInt(data?.weavyId);

          const chatroomName = data?.fullName;
          if (!data?.weavyId) {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer  ${API_PATHS.weavyKey}`,
              },
              body: JSON.stringify({
                uid: data?.id,
                name: data?.fullName,
              }),
            };
            const rees = await InitApp1(requestOptions, data, "cook");
            member2 = parseInt(localStorage.getItem("weavyId2"));
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          } else {
            member2 = parseInt(data?.weavyId);
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          }
          if (res?.id) {
            navigate("/hin/client/message", {
              state: { res: res },
            });
          }
        }
      }
    } catch ({ message }) {
      setModalMessage(message);
      setShowModal(true);
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={cookId}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
      />
      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setsuccessModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/client/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/client/shared-profiles" className="bcrumb">
          सहायक द्वारा साझा
        </Link>
        &nbsp;&bull; प्रोफ़ाइल विवरण
      </div>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={2}>
                {data?.dp ? (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={data?.dp}
                    alt="user profile"
                  />
                ) : (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={cook}
                    alt="user profile"
                  />
                )}
                <p
                  style={{
                    fontSize: "11px",
                    color: "#8F8686",
                    marginTop: "5px",
                  }}
                >
                  Active on&nbsp;
                  {Moment.utc(data?.lastLoginDateTime).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col
                xs={3}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              ></Col>
              <Col
                xs={1}
                className="d-sm-block d-md-none d-lg-none"
                align="center"
              ></Col>
              <Col lg={8} xs={12} md={6}>
                <h6 className="mt-2">{data?.fullName}</h6>

                <p>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.cityName}
                  <br />
                  <AiOutlineMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.mobile}</span>
                  ) : (
                    <span>
                      {data?.mobile?.toString()?.substring(0, 3)}xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-25px",

                          color: "blue",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col
                lg={1}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              ></Col>
              <Col
                lg={1}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              ></Col>
              <Col lg={8} xl={9} xs={12} md={7} />
              <Col
                lg={4}
                xl={3}
                xs={12}
                md={5}
                className="contacticonCard"
                align="right"
              >
                <Row>
                  {data?.smsContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.whatsappContact === 1 && data?.whatsappNumber && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("whatsappinteraction")}
                    >
                      <BsWhatsapp
                        style={{ color: "#059F14", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.chatContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col
            lg={6}
            xs={12}
            md={6}
            sm={12}
            className="card me-1 detailCard p-3"
          >
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              मूल जानकारी
            </h6>
            <p className="text-heading">के बारे में</p>
            <p className="text-details">{data?.about}</p>
            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">ईमेल</p>
                <p className="text-details">{data?.email}</p>
              </Col>
              {data?.memberType === 1 && (
                <Col lg={6} xs={12}>
                  <p className="text-heading">जन्म की तारीख</p>

                  {data?.dob && (
                    <p className="text-details">
                      {Moment.utc(data?.dob).format("DD-MM-YYYY")}
                    </p>
                  )}
                </Col>
              )}

              {data?.memberType === 1 && (
                <Col lg={6} xs={12}>
                  <p className="text-heading">लिंग</p>
                  {data?.gender === 1 && <p className="text-details">पुरुष</p>}
                  {data?.gender === 2 && <p className="text-details">महिला</p>}
                  {data?.gender === 3 && <p className="text-details">अन्य</p>}
                </Col>
              )}
              {data?.memberType === 1 && (
                <Col lg={6} xs={12}>
                  <p className="text-heading">शिक्षा</p>
                  <p className="text-details">{data?.qualification}</p>
                </Col>
              )}
              <Col lg={6} xs={12}>
                <p className="text-heading">ज्ञात भाषाएँ</p>
                <p className="text-details">{data?.languages?.join(", ")}</p>
              </Col>
            </Row>
          </Col>
        )}
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col lg={6} xs={12} md={6} className="card ms-2 detailCard p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              पता
            </h6>
            <p className="text-heading">पता पंक्ति</p>
            {data?.addressLine1 && (
              <p className="text-details">
                {data?.addressLine1} {data?.addressLine2}
              </p>
            )}

            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">क्षेत्र</p>
                {data?.area && <p className="text-details">{data?.area}</p>}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">शहर</p>
                <p className="text-details">{data?.cityName}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">राज्य</p>
                {data?.provinceName && (
                  <p className="text-details">{data?.provinceName}</p>
                )}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">पिन कोड</p>
                <p className="text-details">{data?.pincode}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">लैंडमार्क</p>
                <p className="text-details">{data?.landmark}</p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {isLoading ? (
        <Row>{DataSkeleton()}</Row>
      ) : (
        <div>
          {data?.partyCook === 1 && (
            <Row className="mt-3 justify-content-center p-2 card mb-3">
              <Col lg={12} xs={12} md={12}>
                <h6
                  className="text-left mt-1"
                  style={{ fontWeight: "600", color: "#ff5c09 " }}
                >
                  पार्टी प्रोफ़ाइल
                </h6>
              </Col>

              <Col lg={12} xs={12} className="ps-3 mt-3 mb-2">
                <p className="text-heading">ज्ञात व्यंजन</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {data?.partyCuisines?.map((cookplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {cookplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">अनुभव</p>
                    {data?.partyExperience && (
                      <p className="text-details">
                        {data?.partyExperience} Years
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">स्पेशलिटी</p>
                    <p className="text-details">{data?.speciality}</p>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">अधिकतम प्लेटें</p>
                    {data?.partyMaxPlates && (
                      <p className="text-details">{data?.partyMaxPlates}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">भोजन का प्रकार</p>
                    {data?.partyCookFoodType === 1 && (
                      <p className="text-details">Pure Veg</p>
                    )}
                    {data?.partyCookFoodType === 2 && (
                      <p className="text-details">Veg/ Non-Veg</p>
                    )}
                    {data?.partyCookFoodType === 3 && (
                      <p className="text-details">Jain Food</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">बर्तन धोना</p>

                    {data?.partyCookVesselWash === 0 && (
                      <p className="text-details">No</p>
                    )}
                    {data?.partyCookVesselWash === 1 && (
                      <p className="text-details">Yes</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">उपलब्धता </p>
                    {data?.partyCookAvailability === 1 && (
                      <p className="text-details">All Days</p>
                    )}
                    {data?.partyCookAvailability === 2 && (
                      <p className="text-details">Mon-Fri Only</p>
                    )}
                    {data?.partyCookAvailability === 3 && (
                      <p className="text-details">Sat-Sun Only</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {data?.memberType === 2 && profileDetails?.eventId && (
            <Row className="mt-3 justify-content-center p-2 card mb-3">
              <Col lg={12} xs={12} md={12}>
                <h6
                  className="text-left mt-1"
                  style={{ fontWeight: "600", color: "#ff5c09 " }}
                >
                  केटारिंग प्रोफाइल
                </h6>
              </Col>

              <Col lg={12} xs={12} className="ps-3 mt-3 mb-2">
                <p className="text-heading">ज्ञात व्यंजन</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {data?.cateringCuisines?.map((cookplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {cookplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">टीम साइज</p>
                    {data?.teamSize && (
                      <p className="text-details">{data?.teamSize}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">वेबसाइट</p>
                    <p className="text-details">{data?.website}</p>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">न्यूनतम प्लेटें</p>
                    {data?.cateringMinPlates && (
                      <p className="text-details">{data?.cateringMinPlates}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">FSSAI</p>
                    {data?.fssai && (
                      <p className="text-details">{data?.fssai}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-3 mt-1">
                    <p className="text-heading">भोजन का प्रकार</p>
                    {data?.cateringFoodType === 1 && (
                      <p className="text-details">Pure Veg</p>
                    )}
                    {data?.cateringFoodType === 2 && (
                      <p className="text-details">Veg/ Non-Veg</p>
                    )}
                    {data?.cateringFoodType === 3 && (
                      <p className="text-details">Jain Food</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {data?.cookType === 1 && profileDetails?.requirementId && (
            <Row className="mt-3 justify-content-center p-2 card mb-3">
              <Col lg={12} xs={12} md={12}>
                <h6
                  className="text-left mt-1"
                  style={{ fontWeight: "600", color: "#ff5c09 " }}
                >
                  घर की प्रोफ़ाइल
                </h6>
              </Col>
              <Col lg={12} xs={12} className="ps-2 mt-3 mb-2">
                <p className="text-heading">ज्ञात व्यंजन</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {data?.householdCuisines?.map((cookplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {cookplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">जॉब का प्रकार</p>
                    {data?.jobType === 1 && (
                      <p className="text-details">Part Time</p>
                    )}
                    {data?.jobType === 2 && (
                      <p className="text-details">Full Time</p>
                    )}
                    {data?.jobType === 3 && <p className="text-details">Any</p>}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">कम से कम पेमेंट</p>
                    <p className="text-details">{data?.payment}</p>
                  </Col>

                  <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                    <p className="text-heading">बर्तन धोना</p>
                    {data?.householdVesselWash === 0 && (
                      <p className="text-details">No</p>
                    )}
                    {data?.householdVesselWash === 1 && (
                      <p className="text-details">Yes</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      )}
      <br />
      <br />
      <br />
      <Row>
        <Col style={{ borderRadius: "15px", padding: "14px" }}>
          <Link
            className="text-start mt-5"
            onClick={() => setReportJob(true)}
            style={{
              color: "#8F8686",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            <u>Report this cook</u>
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default SharedProfileDetails;
