import { IoIosAdd } from "react-icons/io";
import { TiTick } from "react-icons/ti";

const AdditionalPerks = ({ HandleClick, data }) => {
  return (
    <div className="d-flex justify-content-start perks">
      <div
        className=""
        onClick={() => HandleClick("providentFund", !data.providentFund)}
      >
        {data.providentFund ? (
          <span className="item-false">
            <span className="icon">
              <TiTick />
            </span>
            Provident Fund
          </span>
        ) : (
          <span className="item">
            <span className="icon">
              <IoIosAdd />
            </span>
            Provident Fund
          </span>
        )}
      </div>

      <div
        className=""
        onClick={() => HandleClick("incentives", !data.incentives)}
      >
        {data.incentives ? (
          <span className="item-false">
            <span className="icon">
              <TiTick />
            </span>
            Incentives
          </span>
        ) : (
          <span className="item">
            <span className="icon">
              <IoIosAdd />
            </span>
            Incentives
          </span>
        )}
      </div>
      <div className="" onClick={() => HandleClick("weekOffs", !data.weekOffs)}>
        {data.weekOffs ? (
          <span className="item-false">
            <span className="icon">
              <TiTick />
            </span>
            WeekOffs
          </span>
        ) : (
          <span className="item">
            <span className="icon">
              <IoIosAdd />
            </span>
            WeekOffs
          </span>
        )}
      </div>

      <div
        className=""
        onClick={() => HandleClick("insurance", !data.insurance)}
      >
        {data.insurance ? (
          <span className="item-false">
            <span className="icon">
              <TiTick />
            </span>
            Insurance
          </span>
        ) : (
          <span className="item">
            <span className="icon">
              <IoIosAdd />
            </span>
            Insurance
          </span>
        )}
      </div>
    </div>
  );
};

export default AdditionalPerks;
