import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { FaStar, FaRegStar } from "react-icons/fa"; 
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cookRatingEmployer } from "../../store/slices/cookprofile";
import SuccessModal from "../../components/auth_screens/SuccessModal";

function StarRating({ value, onClick }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index} onClick={() => onClick(index + 1)}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

export function StarRating1({ value }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

function EmployerRatingModal({
  rateModal,
  id,
  setRateModal,
  getDetails,
  data,
}) {
  const navigate = useNavigate();

  const [workculture, setWorkculture] = useState(0);
  const [salary, setSalary] = useState(0);
  const [facilities, setFacilities] = useState(0);
  const [behaviour, setBehaviour] = useState(0);
  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState("");
  const [role, setRole] = useState();
  const [successModal, setsuccessModal] = useState(false);
  const dispatch = useDispatch();
  const [modalMessage, setModalMessage] = useState("");
  const [ratingError, setRatingError] = useState("");
  const handleCloseModal = () => {
    setRateModal(false);
    setRatingError("");
    setWorkculture(0);
    setSalary(0);
    setFacilities(0);
    setBehaviour(0);
    setComments("");
  };

  const Submit = () => {
    if (
      workculture === 0 ||
      salary === 0 ||
      facilities === 0 ||
      behaviour === 0
    ) {
      setRatingError("All categories need to be rated");
      return;
    } else {
      setRatingError("");
    }
    const item = {
      employerId: id,
      workculture: workculture,
      salary: salary,
      facilities: facilities,
      behaviour: behaviour,
      comment: comments || undefined,
    };

    dispatch(cookRatingEmployer(item))
      .then((data) => {
        if (data?.payload?.data?.message) {
          setModalMessage(data?.payload?.data?.message);
          setsuccessModal(true);

          setTimeout(() => {
            setsuccessModal(false);
          }, 1000);
          setComments("");
          setRateModal(false);
          setWorkculture(0);
          setSalary(0);
          setFacilities(0);
          setBehaviour(0);
        }
        getDetails();
      })
      .catch(({ message }) => {});
  };
  const handleRatingClick = (factor, rating) => {
    setRatingError("");
    switch (factor) {
      case "workculture":
        setWorkculture(rating);
        break;
      case "salary":
        setSalary(rating);
        break;
      case "facilities":
        setFacilities(rating);
        break;
      case "behaviour":
        setBehaviour(rating);
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    setComments(e.target.value);
  };

  useEffect(() => {
    if (data?.eventData) {
      setRole("Client");
    } else if (data?.jobData?.clientId) {
      setRole("Client");
    } else if (data?.jobData?.employerId) {
      setRole("Employer");
    } else {
      setRole("Client");
    }
  }, [data]);

  return (
    <>
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter p-5"
        centered
        show={rateModal}
        onHide={handleCloseModal}
      >
        <Modal.Body align="left">
          {/* Display star ratings for each factor */}

          <h5 className="text-center mb-3">Rate this {role}</h5>
          <div className="d-flex justify-content-between mt-2">
            <div>
              <span>Work culture: </span>
              <StarRating
                value={workculture}
                onClick={(rating) => handleRatingClick("workculture", rating)}
              />
            </div>
            <div>
              <span>Salary & increment: </span>
              <StarRating
                value={salary}
                onClick={(rating) => handleRatingClick("salary", rating)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div>
              <span>Facilities: </span>
              <StarRating
                value={facilities}
                onClick={(rating) => handleRatingClick("facilities", rating)}
              />
            </div>
            <div>
              <span>Behaviour: </span>
              <StarRating
                value={behaviour}
                onClick={(rating) => handleRatingClick("behaviour", rating)}
              />
            </div>
          </div>
          <div style={{ color: "red", marginTop: "20px" }}>{ratingError}</div>
          <div className="mt-4">
            <Form>
              <Form.Group>
                <span>Comments</span>
                <Form.Control
                  value={comments}
                  name="comment"
                  placeholder="Enter your comments"
                  type="text"
                  className="input1"
                  onChange={handleChange}
                ></Form.Control>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>

        <div className="d-flex justify-content-center mt-3">
          <button
            type="button"
            className="modalcancelBtn ms-2"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button type="button" className="modalsaveBtn ms-2" onClick={Submit}>
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}

export default EmployerRatingModal;
