import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "./Helpchat.css";
import Contact from "./Contact";

const CateringSupport = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
  });

  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };

  return (
    <div className="ps-2">
      <div>
        <p style={{ color: "blue", fontWeight: "500" }}>
          नमस्कार, हम यहां आपकी मदद के लिए हैं।
        </p>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          aria-expanded={openCollapse.open1}
          className="help"
        >
          प्रोफ़ाइल
        </p>
        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              अपनी प्रोफ़ाइल को अपडेट करें और अधिक ऑफ़र प्राप्त करने या ग्राहकों
              से संपर्क किए जाने की संभावना बढ़ाएं:
            </p>
            <Link
              to="/cook/editcateringprofile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              बेसिक प्रोफ़ाइल अपडेट करें
            </Link>
            <Link
              to="/cook/edit-catering-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              कुक प्रोफ़ाइल अपडेट करें
            </Link>
            <Link
              to="/cook/editcateringprofile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              डीपी अपडेट करें
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          इवेंट्स
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              अपने प्रोफ़ाइल से मेल खाने वाले क्लाइंट्स को रुचि भेजें और उनसे
              संपर्क करें: अपना आवेदन तेज़ी से स्वीकृत कराने के लिए, उन
              क्लाइंट्स को सीधे अपनी रुचि भेजें जो आपके प्रोफ़ाइल से मेल खाते
              हों और उनसे संपर्क करें। इससे आपको जल्दी प्रतिक्रिया मिलने की
              संभावना बढ़ जाएगी।
            </p>
            <Link
              to="/cook/find-events"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              अपने स्थान के पास मिलते-जुलते इवेंट्स खोजें
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          संपर्क सेटिंग्स
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <Link
              to="/cook/profile"
              style={{
                display: "block",

                fontSize: "14px",
              }}
            >
              व्हाट्सऐप जोड़ें
            </Link>
            <Link
              to="/cook/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              मोबाइल नंबर बदलें
            </Link>
            <Link
              to="/cook/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              ईमेल बदलें
            </Link>
            <Link
              to="/cook/settings"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              संपर्क गोपनीयता
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          हमसे संपर्क करें
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CateringSupport;
