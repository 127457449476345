import React from "react";
import Skeleton from "react-loading-skeleton";

const ShowoverviewSkeleton = () => {
  return (
    <>
      <div className="card">
        <Skeleton height={64} />
      </div>
    </>
  );
};

export default ShowoverviewSkeleton;
