import axios from "axios";
import {
  API_PATHS,

} from "../utils/constants/api.constants";

const logout =()=>{
  localStorage.clear();
  localStorage.setItem("logout", Date.now().toString());
  localStorage.setItem("role", "client");
  }


//for getting client profile
class ClientService {
  static getProfile() {
    let api = "";
    api = API_PATHS.getemployerProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }

  static getplanHistory() {
    let api = '';
    api = API_PATHS.clientPlanHostory;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
        .get(api, { headers: {"x-access-token" : token}})
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}

  static getCateringDetails(id) {
    let api = "";
    let type="catering";
    api = API_PATHS.viewchefDetails +"?id="+id+"&cookType="+type;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api,  { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }

  static getHouseDetails(id) {
    let api = "";
    let type="housecook";
    api = API_PATHS.viewchefDetails +"?id="+id+"&cookType="+type;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api,  { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }

  static getPartyCookDetails(id) {
    let api = "";
    let type="partycook";
    api = API_PATHS.viewchefDetails +"?id="+id+"&cookType="+type;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api,  { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  static getRequirement() {
    let api = "";
    api = API_PATHS.getrequirement;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  //edit client requirement
  static editRequirement(item) {
    let api = "";
    api = API_PATHS.addrequirement;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item,{ headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  //for editing client profile
    static editclientProfile(item) {
    let api = "";
    api = API_PATHS.editemployerProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }

//post job
  static postEvents(item) {
    let api = "";
    api = API_PATHS.postEvents;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  static editEvents(item) {
    let api = "";
    api = API_PATHS.editEvents;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  //get event for client
  static getEvents(id) {
    let api = "";
    api = API_PATHS.getEvents + '?id='+id;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api,{ headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


  static getAllEvents() {
    let api = "";
    api = API_PATHS.getEvents ;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api,{ headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
  }


//find catering profiles
static findCatering(item) {
  let api = "";
  api = API_PATHS.findcatering;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}


//find house cook profiles
static findHouseCook(item) {
  let api = "";
  api = API_PATHS.findhousecook;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}

//find catering profiles
static findPartycook(item) {
  let api = "";
  api = API_PATHS.findpartycook;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}


//disable accounts for client and employer
static disableAccount() {
  let api = "";
  api = API_PATHS.disableaccount;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}

//enable accounts for client and employer
static enableAccount() {
  let api = "";
  api = API_PATHS.enableaccount;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}


//delete accounts for client and employer
static deleteAccount(item) {
  let api = "";
  api = API_PATHS.deleteaccount;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },
    data: item,
  };
  return axios.delete(api,config)
  .then((response) => {
    if (response.data) {
    }
    return response.data;
  });
}

static canceldeleteAccount() {
  let api = "";
  api = API_PATHS.canceldeleterequest;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}



static getEventRequestDetails(id) {
  let api = "";
  api = API_PATHS.vieweventrequestdetails +"?id="+id;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}

static getRequirementRequestDetails(id) {
  let api = "";
  api = API_PATHS.houseinterestdetails +"?id="+id;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
        }
        });
}



  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default ClientService;