import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "./Helpchat.css";
import Contact from "./Contact";

const HouseSupport = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
  });
  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };
  return (
    <div className="ps-2">
      <div>
        <p style={{ color: "blue", fontWeight: "500" }}>
          Hi, we are here to help you
        </p>
        <p style={{ color: "#ff5c09" }}>Tips :</p>
        <ul style={{ fontSize: "12px" }}>
          <li>
            Update your profile, and keep it 100%. It will help house clients to
            understand your location, expertise better.
          </li>
          <li>
            Try to use application regularly, this will reflect on your profile
            and you may get more house cook job offers.
          </li>
          <li>
            Save the matching house jobs near you and contact them before others
            pick them.
          </li>
        </ul>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          aria-expanded={openCollapse.open1}
          className="help"
        >
          Profile
        </p>
        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              Update your profile to get listed in the search and contacted by
              Clients.
            </p>
            <Link
              to="/cook/edit-cook-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Update Basic Profile
            </Link>
            <Link
              to="/cook/edit-house-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Update Cook Profile
            </Link>
            <Link
              to="/cook/edit-cook-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Update DP
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          House Cook Jobs
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              Send interests to House Clients and contact them to get quicker
              response.
            </p>
            <Link
              to="/cook/housejobs"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Find House Jobs
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          Contact Settings
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <Link
              to="/cook/profile"
              style={{
                display: "block",

                fontSize: "14px",
              }}
            >
              Add WhatsApp
            </Link>
            <Link
              to="/cook/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Change Mobile Number
            </Link>
            <Link
              to="/cook/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Change Email
            </Link>
            <Link
              to="/cook/settings"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Contact Privacy
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          Contact Us
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default HouseSupport;
