import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { getcookProfile } from "../../store/slices/cookprofile";
import Modal2 from "../auth_screens/Modal2";
import DataSkeleton from "../auth_screens/dataskeleton";
import { ExtractResumeNameValue } from "../../utils/helpers/ResumeNameExtract";

function CookProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [chefCuisine, setchefCuisine] = useState([]);
  const [partyCuisine, setpartyCuisine] = useState([]);
  const [houseCuisine, sethouseCuisine] = useState([]);
  const [cateringCuisine, setcateringCuisine] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [website, setWebsite] = useState();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    document.title = "CookandChef | Profile";
  }, []);

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        setWebsite(data?.user?.data?.website);
        setchefCuisine(data.user.data.chefCuisines);
        setpartyCuisine(data.user.data.partyCuisines);
        sethouseCuisine(data.user.data.householdCuisines);
        setcateringCuisine(data.user.data.cateringCuisines);
      })
      .catch(({ message }) => {});
  }, [dispatch]);
  const startsWithHttp =
    website && website.startsWith && website.startsWith("http://");
  const startsWithHttps =
    website && website.startsWith && website.startsWith("https://");

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Cook Profile
      </div>

      {isLoading ? (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          {DataSkeleton()}
        </Row>
      ) : (
        <div>
          {data?.cookType === 2 && (
            <Row className="m-1 justify-content-center card mb-5">
              <div className="card-header" style={{ background: "#fa7734" }}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <h5 style={{ color: "#fff" }}>Chef Profile</h5>
                  </Col>
                  <Col xs={6} md={6} lg={6} align="right">
                    <Link
                      to="/cook/edit-chef-profile"
                      style={{ color: "#fff" }}
                    >
                      <FaEdit style={{ fontSize: "15px", marginTop: "-2px" }} />
                      &nbsp; Edit
                    </Link>
                  </Col>
                </Row>
              </div>

              <Col lg={12} xs={12} className="ps-4 mt-3 mb-2">
                <p className="text-heading">Cuisines Known</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {chefCuisine?.map((cookplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {cookplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Experience</p>
                    {data.chefExperience && (
                      <p className="text-details">
                        {data.chefExperience} Years
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Current Company</p>
                    <p className="text-details">{data.currentCompany}</p>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Current Salary (Per Month)</p>
                    {data?.currentSalary && (
                      <p className="text-details">₹{data?.currentSalary}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Expected Salary (Per Month)</p>
                    {data.expectedSalary && (
                      <p className="text-details">₹{data.expectedSalary}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Skills</p>
                    <div className="d-flex">
                      {data?.skills?.map((el, ind) => (
                        <p className="text-details">
                          {el}
                          {ind === data?.skills?.length - 1 ? " " : ","}&nbsp;
                        </p>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Current City</p>
                    <p className="text-details">{data.currentCityName}</p>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Willing to relocate</p>
                    {data.relocate === 0 && <p className="text-details">No</p>}
                    {data.relocate === 1 && <p className="text-details">Yes</p>}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Looking for Job</p>
                    {data?.jobSeeking === 0 && (
                      <p className="text-details">No</p>
                    )}
                    {data?.jobSeeking === 1 && (
                      <p className="text-details">Yes</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                    <p className="text-heading">Resume</p>
                    <p className="text-details" style={{ fontStyle: "italic" }}>
                      {ExtractResumeNameValue(data?.resume)}
                    </p>
                    {data?.resume && (
                      <p className="text-details">
                        <Link to={data.resume}>Download</Link>
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      )}

      {isLoading ? (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          {DataSkeleton()}
        </Row>
      ) : (
        <div>
          {data?.cookType === 1 && (
            <Row className="m-1 justify-content-center card mb-5">
              <div className="card-header" style={{ background: "#fa7734" }}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <h5 style={{ color: "#fff" }}>House Profile</h5>
                  </Col>
                  <Col xs={6} md={6} lg={6} align="right">
                    <Link
                      to="/cook/edit-house-profile"
                      style={{ color: "#fff" }}
                    >
                      <FaEdit
                        style={{ fontSize: "15px", marginTop: "-2px" }}
                        className="me-1"
                      />
                      Edit
                    </Link>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={12} md={12} lg={12} className="ps-4 mt-3">
                  <p className="text-heading">Cuisines Known</p>
                  <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                    {houseCuisine?.map((houseplanVal, index) => (
                      <Col
                        xs={5}
                        sm={5}
                        md={3}
                        lg={2}
                        xl={2}
                        className="me-2"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          color: "black",
                          padding: "4px 8px",
                          margin: "8px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontSize: "14px",
                          marginRight: "4px",
                          border: "solid 1px #ff5c09",
                          width: "auto",
                        }}
                      >
                        {houseplanVal}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Job Type</p>
                  {data.jobType === 1 && (
                    <p className="text-details">Part Time</p>
                  )}
                  {data.jobType === 2 && (
                    <p className="text-details">Full Time</p>
                  )}
                  {data.jobType === 3 && <p className="text-details">Any</p>}
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Min. Payment</p>
                  {data.payment && (
                    <p className="text-details">{data.payment} </p>
                  )}
                </Col>

                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Vessel Wash</p>
                  {data.householdVesselWash === 0 && (
                    <p className="text-details">No</p>
                  )}
                  {data.householdVesselWash === 1 && (
                    <p className="text-details">Yes</p>
                  )}
                </Col>
              </Row>
            </Row>
          )}
        </div>
      )}

      {isLoading ? (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          {DataSkeleton()}
        </Row>
      ) : (
        <div>
          {data?.partyCook === 1 && (
            <Row className="m-1 justify-content-center card">
              <div className="card-header" style={{ background: "#fa7734" }}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <h5 style={{ color: "#fff" }}>Party Profile</h5>
                  </Col>
                  <Col xs={6} md={6} lg={6} align="right">
                    <Link
                      to="/cook/edit-party-profile"
                      style={{ color: "#fff" }}
                    >
                      <FaEdit
                        style={{ fontSize: "15px", marginTop: "-2px" }}
                        className="me-1"
                      />
                      Edit
                    </Link>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={12} md={12} lg={12} className="ps-4 mt-3">
                  <p className="text-heading">Cuisines Known</p>
                  <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                    {partyCuisine?.map((partyplanVal, index) => (
                      <Col
                        xs={5}
                        sm={5}
                        md={3}
                        lg={2}
                        xl={2}
                        className="me-2"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          color: "black",
                          padding: "4px 8px",
                          margin: "8px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontSize: "14px",
                          marginRight: "4px",
                          border: "solid 1px #ff5c09",
                          width: "auto",
                        }}
                      >
                        {partyplanVal}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Experience</p>
                  {data?.partyExperience && (
                    <p className="text-details">{data.partyExperience} Years</p>
                  )}
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Specialty</p>
                  <p className="text-details">{data.speciality}</p>
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Max. Plates</p>
                  <p className="text-details">{data.partyMaxPlates}</p>
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Food Type</p>

                  {data?.partyCookFoodType === 1 && (
                    <p className="text-details">Pure Veg</p>
                  )}
                  {data?.partyCookFoodType === 2 && (
                    <p className="text-details">Veg/ Non-Veg</p>
                  )}
                  {data.partyCookFoodType === 3 && (
                    <p className="text-details">Jain Food</p>
                  )}
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Vessel Wash</p>
                  {data.partyCookVesselWash === 0 && (
                    <p className="text-details">No</p>
                  )}
                  {data.partyCookVesselWash === 1 && (
                    <p className="text-details">Yes</p>
                  )}
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Availability</p>

                  {data?.partyCookAvailability === 1 && (
                    <p className="text-details">All Days</p>
                  )}
                  {data?.partyCookAvailability === 2 && (
                    <p className="text-details">Mon-Fri Only</p>
                  )}
                  {data?.partyCookAvailability === 3 && (
                    <p className="text-details">Sat-Sun Only</p>
                  )}
                </Col>
              </Row>
            </Row>
          )}
        </div>
      )}

      {isLoading ? (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          {DataSkeleton()}
        </Row>
      ) : (
        <div>
          {data?.memberType === 2 && (
            <Row className="m-1 justify-content-center card">
              <div className="card-header" style={{ background: "#fa7734" }}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <h5 style={{ color: "#fff" }}>Catering Profile</h5>
                  </Col>
                  <Col xs={6} md={6} lg={6} align="right">
                    <Link
                      to="/cook/edit-catering-profile"
                      style={{ color: "#fff" }}
                    >
                      <FaEdit
                        style={{ fontSize: "15px", marginTop: "-2px" }}
                        className="me-1"
                      />
                      Edit
                    </Link>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={12} md={12} lg={12} className="ps-4 mt-3">
                  <p className="text-heading">Cuisines Known</p>
                  <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                    {cateringCuisine?.map((partyplanVal, index) => (
                      <Col
                        xs={5}
                        sm={5}
                        md={3}
                        lg={2}
                        xl={2}
                        className="me-2"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          color: "black",
                          padding: "4px 8px",
                          margin: "8px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontSize: "14px",
                          marginRight: "4px",
                          border: "solid 1px #ff5c09",
                          width: "auto",
                        }}
                      >
                        {partyplanVal}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Website</p>

                  {data?.website && (startsWithHttp || startsWithHttps) && (
                    <Link to={data?.website} target="_blank">
                      <p className="text-details">{data?.website}</p>
                    </Link>
                  )}
                  {data?.website && !(startsWithHttp || startsWithHttps) && (
                    <Link to={`http://${data?.website}`} target="_blank">
                      <p className="text-details">{data?.website}</p>
                    </Link>
                  )}
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Team Size</p>
                  <p className="text-details">{data.teamSize}</p>
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Min. Plates</p>
                  <p className="text-details">{data.cateringMinPlates}</p>
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">FSSAI</p>
                  <p className="text-details">{data.fssai}</p>
                </Col>
                <Col xs={12} md={6} lg={6} className="ps-4 mt-3">
                  <p className="text-heading">Food Type</p>

                  {data?.cateringFoodType === 1 && (
                    <p className="text-details">Pure Veg</p>
                  )}
                  {data?.cateringFoodType === 2 && (
                    <p className="text-details">Veg/ Non-Veg</p>
                  )}
                  {data?.cateringFoodType === 3 && (
                    <p className="text-details">Jain Food</p>
                  )}
                </Col>
              </Row>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default CookProfile;
