import React from "react";
import Skeleton from "react-loading-skeleton";

const ShowtopcontentSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill()
        .map((item, index) => {
          return (
            <div key={index}>
              <div className="card">
                <Skeleton height={64} />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ShowtopcontentSkeleton;
