import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { EmployerReoprtChef } from "../../../store/slices/employerprofile";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// ... other imports

function ReportChefModal({
  reportJob,
  setReportJob,
  id,
  setModalMessage,
  setsuccessModal,
  setShowModal,
  role,
}) {
  // ... existing code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFraudSelect, setFraudSelect] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [othersReason, setOthersReason] = useState("");
  const [otherReasonError, setOtherReasonError] = useState();
  const [reasonError, setReasonError] = useState("");
  const [commentError, setCommentError] = useState("");
  const [isdisabled, setisDisabled] = useState(false);

  const [reportReason, setReportReason] = useState({
    cookId: id,
    reason: "",
    comment: "",
  });

  const handleReportChange = (e) => {
    let value = e.target.value;
    if (
      value === "Fake or False information" ||
      value === "Inappropriate" ||
      value === "Fraud"
    ) {
      setReasonError("");
      setReportReason({
        ...reportReason,
        reason: value,
        comment: "",
      });
      setFraudSelect(true);
      setIsOthers(false);
    } else if (value === "Others") {
      setReasonError("");
      setReportReason({
        ...reportReason,
        reason: value,
        comment: "",
      });

      setIsOthers(true);
      setFraudSelect(false);
    }
  };

  const handleReportCommentChange = (e) => {
    let value = e.target.value;
    setCommentError("");
    setReportReason({
      ...reportReason,
      comment: value,
    });
  };

  const handleOthersCommentChange = (e) => {
    let { value, name } = e.target;
    switch (name) {
      case "reason":
        setOtherReasonError("");
        setOthersReason(value);
        break;
      case "comment":
        setCommentError("");
        setReportReason({
          ...reportReason,
          comment: value,
        });
        break;
      default:
        break;
    }
  };
  const handleCloseModalFraud = () => {
    setReportJob(false);
    setIsOthers(false);
    setFraudSelect(false);
    setCommentError("");
    setReasonError("");
    setReportReason({
      jobId: id,
      reason: "",
      comment: "",
    });
  };

  const submitReport = () => {
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    if (reportReason.reason === "") {
      setReasonError("Please select a reason");
      return;
    } else if (reportReason.comment === "") {
      setCommentError("Please write a comment");

      return;
    } else {
      setReasonError("");
    }

    if (isOthers) {
      if (othersReason.trim() === "") {
        setOtherReasonError("Please write a reason");
        return;
      } else {
        setOtherReasonError("");
      }
    }

    // If all validations pass, proceed to dispatch the action
    let item;
    if (reportReason.reason === "Others") {
      item = {
        cookId: id,
        reason: "Others",
        comment: reportReason.comment,
      };
    } else {
      item = {
        cookId: id,
        reason: reportReason.reason,
        comment: reportReason.comment,
      };
    }

    dispatch(EmployerReoprtChef(item))
      .then((data) => {
        if (data?.payload?.data?.message) {
          setModalMessage(data?.payload?.data?.message);
          setsuccessModal(true);
          setReportJob(false);
          setTimeout(() => {
            setsuccessModal(false);
            navigate("/client/dashboard");
          }, 1000);
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      });
  };
  //

  return (
    <Modal
      show={reportJob}
      style={{ top: "10%", left: "5%" }}
      onHide={handleCloseModalFraud}
    >
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4">
            <h5 className="mb-4">Report this Profile</h5>

            <Form.Group>
              <Form.Label>Report Reason</Form.Label>
              <Form.Check
                className="mb-2 custom-radio"
                type="radio"
                style={{ color: "black", cursor: "pointer" }}
                name="reportReason"
                label="Fake or False information"
                id="Fake or False information"
                value="Fake or False information"
                checked={reportReason.reason === "Fake or False information"}
                onChange={handleReportChange}
              />
              <Form.Check
                className="mb-2 custom-radio"
                type="radio"
                style={{ color: "black", cursor: "pointer" }}
                name="reportReason"
                id="Inappropriate"
                label="Inappropriate"
                value="Inappropriate"
                checked={reportReason.reason === "Inappropriate"}
                onChange={handleReportChange}
              />
              <Form.Check
                className="mb-2 custom-radio"
                type="radio"
                style={{ color: "black", cursor: "pointer" }}
                name="reportReason"
                label="Fraud"
                value="Fraud"
                id="Fraud"
                checked={reportReason.reason === "Fraud"}
                onChange={handleReportChange}
              />
              <Form.Check
                className="mb-2 custom-radio"
                style={{ color: "black", cursor: "pointer" }}
                type="radio"
                name="reportReason"
                label="Others"
                id="Others"
                value="Others"
                checked={reportReason.reason === "Others"}
                onChange={handleReportChange}
              />
              <p style={{ color: "#DC3545", fontSize: "16px", padding: "5px" }}>
                {reasonError}
              </p>
            </Form.Group>
          </Form.Group>
          {isFraudSelect ? (
            <Form.Group className="mb-4">
              <Form.Label className="mt-2">Comment</Form.Label>
              <Form.Control
                type="text"
                name="comments"
                value={reportReason.comment}
                className="input1"
                maxLength={100}
                placeholder="Enter your comments"
                onChange={handleReportCommentChange}
                isInvalid={!!commentError}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {commentError}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ""
          )}

          {isOthers ? (
            <>
              <Form.Group className="mb-4">
                <Form.Label className="mt-2">Reason</Form.Label>
                <Form.Control
                  type="text"
                  name="reason"
                  value={othersReason}
                  className="input1"
                  maxLength={30}
                  placeholder="Please write reason"
                  onChange={handleOthersCommentChange}
                  isInvalid={!!otherReasonError}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {otherReasonError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="mt-2">Comments</Form.Label>
                <Form.Control
                  type="text"
                  name="comment"
                  value={reportReason.comment}
                  className="input1"
                  placeholder="Enter your comments"
                  onChange={handleOthersCommentChange}
                  isInvalid={!!commentError}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {commentError}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : (
            ""
          )}
        </Form>
        <div className="d-flex justify-content-center">
          <Button className="modalcancelBtn" onClick={handleCloseModalFraud}>
            Cancel
          </Button>
          <Button
            className="modalsaveBtn ms-2"
            disabled={isdisabled}
            onClick={submitReport}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ReportChefModal;
