//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import { useNavigate } from "react-router-dom";
import Footer from "../nav_bar/Footer";
import { useDispatch } from "react-redux";
import {
  CooksignupChangeMobile,
  cookotpConfirmation,
  resendOtp,
} from "../../store/slices/auth";
import { useCallback } from "react";
import login_banner from "../../Assets/Images/Enter OTP-rafiki.png";
import Modal2 from "./Modal2";
import SuccessModal from "./SuccessModal";

//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function CookVerify() {
  const [timer, setTimer] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  React.useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  const dispatch = useDispatch();
  const [otp, setotp] = useState("");
  const [cookId, setcookId] = useState("");
  const navigate = useNavigate("");
  const [errors, setErrors] = useState({});
  const [mobile, setMobile] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [resendClicks, setresendClicks] = useState(0);
  const [isdisabled, setisDisabled] = useState(false);

  useEffect(() => {
    document.title = "CookandChef | Verify OTP";
  }, []);

  const isValidOTP = (otp) => {
    return /^.{6,6}$/.test(otp);
  };
  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setcookId(data?.data?.id);
    setMobile(data?.data?.mobile || data?.data?.newMobile);
  }, [cookId, mobile]);

  const resendOtpHandler = () => {
    setresendClicks((prev) => prev + 1);

    if (resendClicks >= 3) {
      setModalMessage("Please try after sometime!");

      setShowModal(true);
    } else {
      dispatch(resendOtp({ cookId }))
        .unwrap()
        .then(() => {
          resetTimer();
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "otp":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setotp(value);
        validationErrors[name] =
          value === "" || !isValidOTP(value) ? "Please enter a valid OTP" : "";
        if (value !== "" && isValidOTP(value)) {
          validationErrors[name] = "";
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  async function confirmOtp(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!otp.trim()) {
      validationErrors.otp = "Please enter OTP";
    } else if (!isValidOTP(otp)) {
      validationErrors.otp = "OTP must have 6 characters";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      dispatch(cookotpConfirmation({ cookId, otp }))
        .unwrap()
        .then((data) => {
          navigate("/cook/dashboard");
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  const handleShowModal = () => {
    setInputError("");
    setShowModal1(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    handleCloseModal();
    setInputError("");
    setInputValue("");
  };

  const handleCloseModal = () => setShowModal1(false);
  const handleModalChange = (e) => {
    let { name, value } = e.target;
    setInputError(""); // Clear any previous error
    value = value.replace(/\D/g, "");
    // Limit the length to 10 characters
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setInputValue(value);
    if (!isValidmobile(value)) {
      setInputError("Please enter a valid mobile number");
    } else {
      setInputError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    setInputError("");
    if (!inputValue) {
      setInputError("Please enter your mobile number");
    } else if (!isValidmobile(inputValue)) {
      setInputError("Please enter valid mobile number");
    } else {
      setInputError("");

      let item = {
        cookId: cookId,
        mobile: parseInt(inputValue),
      };
      dispatch(CooksignupChangeMobile(item))
        .unwrap()
        .then((data) => {
          handleCloseModal();
          setMobile(data?.user?.newMobile);
        })
        .catch(({ message }) => {
          setShowModal(true);
          setModalMessage(
            "This phone number is associated with an account. Please try signing in"
          );
        });
    }
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={showModal1}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title align="center" style={{ fontSize: "20px" }}>
            Change Mobile Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Row className="d-flex justify-content-center ">
              <Col lg={12} className="p-1 mb-3">
                <Form.Group>
                  <Form.Control
                    name="inputValue"
                    value={inputValue}
                    onChange={handleModalChange}
                    isInvalid={!!inputError}
                    placeholder="Please enter your mobile number"
                    className="input1"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {inputError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <button className="modalcancelBtn m-2" onClick={closeModal}>
              Cancel
            </button>
            <Button
              className="modalsaveBtn m-2"
              type="submit"
              // onClick={handleSubmit}
              disabled={isdisabled}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <AuthNavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-5"
        >
          <Col
            lg={4}
            className="d-none d-lg-block"
            style={{ background: "white" }}
          >
            <img src={login_banner} alt="" style={{ height: "500px" }} />
          </Col>
          <Col
            lg={4}
            className="text-center pt-4 pb-4"
            style={{
              backgroundColor: "white",
              height: "500px",
            }}
          >
            <div align="center">
              <p className="h3 text-center mt-3 mb-3">Verify OTP</p>
              OTP has been sent to your mobile number
              <br />
              <b>+91-{mobile}</b>
              <span
                style={{
                  color: "rgb(255,92,9)",
                  fontSize: "18px",
                  cursor: "pointer",
                  marginLeft: "20px",
                }}
                onClick={handleShowModal}
              >
                Edit
              </span>
              <Form
                noValidate
                autoComplete="off"
                onSubmit={confirmOtp}
                className="text-center mt-5 p-3"
                style={{
                  height: "100%",
                  width: "90%",
                  backgroundColor: "",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                <Form.Group>
                  <Form.Control
                    name="otp"
                    onChange={handleChange}
                    value={otp}
                    isInvalid={!!errors.otp}
                    className="input1"
                    type="number"
                    placeholder="Enter OTP"
                    onWheel={(e) => e.target.blur()}
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.otp}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Button
                    className="mb-2 mt-5 saveBtn"
                    type="submit"
                    disabled={isdisabled}
                  >
                    Submit
                  </Button>
                </Form.Group>
                <p className="mt-3">
                  Resend OTP will be enabled after {timer} seconds
                </p>
                <Button
                  className="saveBtn"
                  type="submit"
                  onClick={resendOtpHandler}
                  disabled={timer > 0}
                >
                  Resend OTP
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br /> <br />
      <br />
      <br /> <br />
      <br />
      <Footer />
    </>
  );
}

export default CookVerify;
