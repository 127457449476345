import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import App from './App';
import store from "./store";
import { AxiosSetup } from "./utils/helpers/api.helpers";
import { BrowserRouter } from "react-router-dom";

AxiosSetup();

ReactDOM.render( 
    <BrowserRouter>

    <Provider store = { store }>
    <App />
    </Provider>

    </BrowserRouter>,
    document.getElementById("root")
);
