import React, { useEffect } from "react";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Cook and Chef</title>
        <meta
          name="description"
          content="CookandChef privacy policy, this page tells about the data and content policy. Disclosure of personal information. Please read the policies."
        />
        <meta
          name="keywords"
          content="Privacy policy cookandchef, policies, commis, helper, kitchen porter, Executive Chef, head chef, deputy chef, find cook online"
        />
      </Helmet>
      <NavMenu />
      <div className="container mt-5">
        <div className="d-md-flex">
          <div
            className="help-left text-left p-3 order-md-0"
            style={{ textWrap: "wrap" }}
          >
            <h3>Privacy Policy</h3>
            <h5>Overview:</h5>
            <p style={{ textWrap: "wrap" }}>
              CookandChef ("we," "us," or "our") is committed to protecting the
              privacy of our users. This Privacy Policy describes how we
              collect, use, disclose, and protect your personal information when
              you use our website, mobile app, and other related services
              (collectively, the "Services").
            </p>
            <h5 style={{ textWrap: "wrap" }}>Information We Collect</h5>
            <p style={{ textWrap: "wrap" }}>
              We collect the following types of information from you:
              <br />
              <ul>
                <li>
                  Personal Information: This includes your name, email address,
                  phone number, postal address, date of birth, gender,
                  education, work experience, skills, and other information you
                  provide to us when you create an account, apply for jobs, use
                  our Services, or communicate with us.
                </li>
                <li>
                  Demographic Information: This includes your age, gender,
                  nationality, and language preferences.
                </li>
                <li>
                  Behavioral Information: This includes information about your
                  activities on our Services, such as the pages you visit, the
                  jobs you apply for, and the links you click.
                </li>
                <li>
                  Device Information: This includes information about your
                  device, such as your browser type, operating system, IP
                  address, and device identifier.
                </li>
              </ul>
            </p>
            <h5>How We Use Your Information</h5>
            <p>
              We use your information to:
              <ul>
                <li>Provide and operate the Services;</li>
                <li>Create and manage your account;</li>
                <li>Process your job applications;</li>
                <li>Match you with potential employers;</li>
                <li>Send you job alerts and other communications;</li>
                <li>Improve our Services;</li>
                <li>Prevent fraud and abuse;</li>
                <li>Comply with legal requirements.</li>
              </ul>
            </p>
            <h5>How We Share Your Information</h5>
            <p>
              We may share your information with:
              <ul>
                <li>
                  Potential employers: We may share your information with
                  potential employers when you apply for a job through our
                  Services.
                </li>
                <li>
                  Service providers: We may share your information with
                  third-party service providers who help us operate and improve
                  our Services. These service providers are bound by contractual
                  obligations to keep your information confidential and secure.
                </li>
                <li>
                  Law enforcement and other government agencies: We may disclose
                  your information to law enforcement and other government
                  agencies if required by law or if we believe it is necessary
                  to protect our rights, the rights of others, or the public.
                </li>
              </ul>
            </p>
            <h5>Your Choices</h5>
            <p>
              You have the following choices regarding your information
              <ul>
                <li>
                  You can access and update your personal information by logging
                  into your account.
                </li>
                <li>You can delete your account by contacting us.</li>
              </ul>
            </p>
            <h5>Data Security</h5>
            <p>
              We take reasonable measures to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
              However, no website or internet transmission is completely secure,
              and we cannot guarantee the security of your information.
            </p>
            <h5>Cookies and Similar Technologies</h5>
            <p>
              We use cookies and similar technologies to track user activity on
              our Services and hold certain information. Cookies are small
              pieces of data that a website or app stores on your device. They
              allow us to remember your preferences and settings, track user
              activity, and personalize your experience. You can disable cookies
              in your browser settings, but doing so may limit your ability to
              use our Services.
            </p>
            <h5>Links to Other Websites</h5>
            <p>
              Our Services may contain links to other websites. We are not
              responsible for the privacy practices of other websites. We
              encourage you to read the privacy policies of other websites
              before you provide them with any information.
            </p>
            <div>
              <h5>Transfer of Data</h5>
              <p>
                Your information may be transferred to and processed in
                countries other than your own. These countries may have
                different data protection laws than your own country.
              </p>
            </div>
            <h5>Children's Privacy</h5>
            <p>
              Our Services are not intended for children under the age of 18. We
              do not knowingly collect personal information from children under
              the age of 18. If you are a parent or guardian and you are aware
              that your child has provided us with personal information, please
              contact us.
            </p>
            <h5>Changes to This Privacy Policy</h5>
            <p style={{ textWrap: "wrap" }}>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              Services.
            </p>
            <h5>Contact Us</h5>
            <p style={{ textWrap: "wrap" }}>
              If you have any questions about this Privacy Policy, please
              contact us support@cookandchef.in
            </p>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
