import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { getPaymentsKey } from "../../../../store/slices/config";
import { cookinitiatePayments } from "../../../../store/slices/cookplan";
import Modal2 from "../../auth_screens/Modal2";
import success from "../../../../Assets/Images/check.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";

function CookCheckout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [adress, setAdress] = useState();
  const [discount, setDiscount] = useState(0);
  const [key, setKey] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const planData = location.state?.planData || null;

  const handleChange = (e) => {
    setAdress(e.target.value);
  };

  useEffect(() => {
    document.title = "CookandChef | Checkout";
  }, []);

  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;

  useEffect(() => {
    getKey();
  }, []);

  const getKey = () => {
    dispatch(getPaymentsKey())
      .unwrap()
      .then((data) => {
        setKey(data.user.data.key);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  useEffect(() => {
    // Dynamically load Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    setAdress("");
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const item = {
      cookPlanId: planData.id,
      discount: 0,
      address: adress,
    };
    try {
      const response = await dispatch(cookinitiatePayments(item));
      const data = response.payload.user.data;
      const options = {
        key,
        amount: data.amount,
        currency: "INR",
        name: planData.clientPlanName,
        description: "Cook Plan Purchase",
        image: "",
        order_id: data.transactionNo,
        adress: adress,
        //callback_url: "http://3.111.112.30:5001/api/v1/hin/cook/payment/confirm",
        handler: async function (response) {
          const data22 = {
            transactionNo: data.transactionNo,
            transactionPaymentNo: response.razorpay_payment_id,
            transactionSignature: response.razorpay_signature,
          };
          setIsLoading(true);
          const result = await axios.put("/hin/cook/payment/confirm", data22, {
            headers: { "x-access-token": token },
          });
          setModalMessage(result.data?.message);

          setsuccessModal(true);
          setIsLoading(false);
          // setTimeout(() => {
          //   navigate("/hin/cook/dashboard");
          // }, 500);
        },
        prefill: {
          name: user.fullName,
          email: user.email,
          contact: user.mobile,
          adress: adress,
        },

        theme: {
          color: "#121212",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };
  const handleCloseModal = () => {
    navigate("/hin/cook/dashboard");
  };

  const handleJobModal = () => {
    navigate("/hin/cook/dashboard");
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      {isloading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RotatingLines
            visible={true}
            height="160"
            width="160"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="4"
            wrapperStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.175)",
              padding: "200px",
            }}
          />
        </div>
      )}
      <Modal
        style={{ left: "5%" }}
        show={successModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body align="center">
          <img src={success} alt="" style={{ height: "40px", width: "40px" }} />
          <br />
          <h4 style={{ color: "green" }}>Success!</h4>
          <h5 className="mt-2">{modalMessage}</h5>
          <br></br>

          <Button
            className="modalsaveBtn ms-2"
            style={{ width: "100px" }}
            onClick={handleJobModal}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>
      <div className="mb-5">
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull;&nbsp;
          <Link to="/hin/cook/plans" className="bcrumb">
            Plans
          </Link>
          &nbsp;&bull;&nbsp;Checkout
        </div>

        <div className="p-3">
          <Row className="mt-2 p-2  justify-content-left">
            <Col lg={5} md={5} className="card p-3 m-1">
              <h6>Price Details</h6>
              <div className="align-center p-3 m-1">
                {/* <div className="mb-3">
                  <Form.Group>
                    <Button className="cancelBtn" style={{ width: "100%" }}>
                      Apply Coupon
                    </Button>
                  </Form.Group>
                </div> */}
                <div className="d-flex justify-content-between ">
                  <div>
                    <p>Plan Price</p>
                    <p>Voucher Discount</p>
                  </div>
                  <div>
                    <p>₹ {planData.price}</p>
                    <p>₹ {discount} </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between ">
                  <p>Amount Payable</p>
                  <p>₹ {planData.price}</p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} className="card m-1 p-3">
              <h6>Plan Details</h6>

              <p>{planData.clientPlanName}</p>
              <div className="d-flex justify-content-between mt-1 p-2">
                <div>
                  <p>Plan Name</p>
                  <p>Price</p>
                  <p>Validity</p>
                </div>
                <div>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    {planData.cookPlanName}
                  </p>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    ₹ {planData.price}
                  </p>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    {planData.validityInDays} Days
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 p-1  justify-content-left">
            <Col lg={5} md={6} className="card p-3 m-1">
              <h6>Billing Details</h6>
              <Form.Group className="my-2">
                <Form.Control
                  className="input1"
                  name="address"
                  onChange={handleChange}
                  type="text"
                  maxlength="100"
                  placeholder="Billing Address"
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={5} className=" p-3 m-1">
              <div className="d-flex justify-content-center mt-1">
                <Button
                  className="saveBtn"
                  onClick={handlePayment}
                  disabled={isdisabled}
                >
                  Pay Now
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default CookCheckout;
