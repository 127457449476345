import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { BsCheck, BsX } from "react-icons/bs";
import { getclientPlans } from "../../../../store/slices/clientplan";
import { EffectCards } from "swiper/modules";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import Modal2 from "../../auth_screens/Modal2";

function ClientPlans() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData = await dispatch(getclientPlans());
        setData(plansData?.payload?.user?.data);
      } catch (error) {
        setModalMessage(error.message);
        setShowModal(true);
      }
    };

    fetchPlans();
  }, dispatch);

  useEffect(() => {
    document.title = "CookandChef | Plans";
  }, []);

  const colordata = ["#048A18", "#004EDE", "#D700DE"];

  const getColorForIndex = (index) => {
    const colorIndex = index % colordata.length;
    return colordata[colorIndex];
  };

  const handleSelectPlan = (selectedPlan) => {
    // You can customize this function to handle the navigation and data passing
    navigate(`/hin/client/checkout/${selectedPlan.id}`, {
      state: { planData: selectedPlan },
    });
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/client/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;प्लान्स
      </div>

      <Row className="p-3">
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <Col
              lg={4}
              md={4}
              xl={4}
              className=" d-none d-lg-flex d-md-flex flex-column justify-content-center align-items-center"
            >
              <div
                className="planCard"
                style={{
                  // border: `2px solid ${getColorForIndex(index)}`,
                  background: "#fff",
                  height: "66dvh",
                  borderRadius: "14px",
                  boxShadow: "8px 8px 5px #e2dfff",
                }}
              >
                <p
                  className="my-2 text-center"
                  style={{
                    fontSize: "3dvh",
                    fontWeight: "500",
                    color: `${getColorForIndex(index)}`,
                  }}
                >
                  {item?.clientPlanName}
                </p>
                <p
                  className="text-center my-2"
                  style={{ fontSize: "4dvh", fontWeight: "700" }}
                >
                  ₹&nbsp;{item?.price}
                </p>
                <p
                  className="text-center  my-2"
                  style={{ fontSize: "2.5dvh", color: "#05506B" }}
                >
                  {item?.validityInDays}&nbsp;Days
                </p>

                <div
                  className="justify-content-center align-items-center"
                  align="center"
                >
                  <Button
                    className="text-center planBtn mt-3"
                    onClick={() => handleSelectPlan(item)}
                  >
                    Select Plan
                  </Button>
                </div>
                <div className="text-start">
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "2.5dvh",
                        width: "2.5dvh",
                      }}
                    />
                    {item.eventPoints}&nbsp;
                    <span className="text-muted">Event Posts</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "2.5dvh",
                        width: "2.5dvh",
                      }}
                    />
                    {item?.houseCookPoints}&nbsp;
                    <span className="text-muted">Cook Views</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "2.5dvh",
                        width: "2.5dvh",
                      }}
                    />
                    {item?.partyCateringPoints}&nbsp;
                    <span className="text-muted">Party Catering Points</span>
                  </p>

                  <p
                    className="text-start mt-3 mb-3"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item?.supportAssistance ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    )}
                    <span className="text-muted">Support Assistance</span>
                  </p>
                  <p
                    className="text-start my-2"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item?.refundpolicy ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    )}
                    <span className="text-muted">Refund Policy</span>
                  </p>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>

      {/**mobile plan screes */}

      <Swiper
        effect={"cards"}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCards, Navigation, Pagination]}
        className="mySwiper d-sm-block d-md-block d-lg-none"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Row className="mt-4 justify-content-center">
              <Col
                className="d-flex flex-column justify-content-center align-items-center"
                xs={9}
                style={{
                  // border: `2px solid ${colordata[2]}`,
                  background: "white",
                  height: "58dvh",
                  borderRadius: "14px",
                }}
              >
                <div className="text-start">
                  <p
                    className="text-center"
                    style={{
                      fontSize: "3dvh",
                      fontWeight: "500",
                      color: `${getColorForIndex(index)}`,
                    }}
                  >
                    {item?.clientPlanName}
                  </p>
                  <p
                    className="text-center my-2"
                    style={{ fontSize: "4dvh", fontWeight: "700" }}
                  >
                    ₹&nbsp;{item?.price}
                  </p>

                  <p
                    className="text-center  my-2"
                    style={{ fontSize: "2.5dvh", color: "#05506B" }}
                  >
                    {item?.validityInDays}&nbsp;Days
                  </p>
                  <div
                    className="justify-content-center align-items-center"
                    align="center"
                  >
                    <Button
                      className="text-center planBtn ms-3"
                      onClick={() => handleSelectPlan(item)}
                    >
                      Select Plan
                    </Button>
                  </div>
                  <div className="text-start">
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                      {item?.eventPoints}&nbsp;
                      <span className="text-muted">Event Posts</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                      {item?.houseCookPoints}&nbsp;
                      <span className="text-muted">Cook Views</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                      {item?.partyCateringPoints}&nbsp;
                      <span className="text-muted">Party Catering Points</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item?.supportassistance ? (
                        <BsCheck
                          style={{
                            color: "green",
                            padding: "",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      )}
                      &nbsp;
                      <span className="text-muted">Support Assistance</span>
                    </p>

                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item?.refundpolicy ? (
                        <BsCheck
                          style={{
                            color: "green",
                            padding: "",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      )}
                      &nbsp;<span className="text-muted">Refund Policy</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default ClientPlans;
