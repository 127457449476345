// App.js or Routes.js

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import NotFoundpage from "../../../Assets/Images/NotFoundpage.jpg";
import NavBar from "./Navbar";
import DashFooter from "../../../components/nav_bar/dashboardfooter";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <NavBar />
      <div className="d-flex justify-content-center mt-5">
        <Col lg={6} sm={10}>
          <img
            src={NotFoundpage}
            style={{ borderRadius: "10px", width: "100%" }}
            alt=""
          />

          <div className="d-flex  justify-content-center  mt-3">
            <button className="notfoundbtn" onClick={() => navigate("/")}>
              Back to Homepage
            </button>
            <button className="notfoundbtn mx-3" onClick={() => navigate(-1)}>
              Back to Previous page
            </button>
          </div>
        </Col>
      </div>
      <DashFooter />
    </div>
  );
};

export default NotFoundPage;
