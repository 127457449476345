import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsCardText, BsFillBriefcaseFill } from "react-icons/bs";
import chefimg from "../../../../Assets/Images/cook.png";
import { AiFillWarning } from "react-icons/ai";
import clientimg from "../../../../Assets/Images/client.png";
import {
  getclientProfile,
  FindhouseCook,
} from "../../../../store/slices/clientprofile";
import { Link } from "react-router-dom";
import housecook from "../../../../Assets/Images/house-cook.png";
import requirement from "../../../../Assets/Images/checklists (1).png";
import events from "../../../../Assets/Images/events.png";
import plan from "../../../../Assets/Images/plan.png";
import {
  GetEmployerAdvertisements,
  UpdateLogin,
  getemployerDashboardData,
} from "../../../../store/slices/employerprofile";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Modal2 from "../../auth_screens/Modal2";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import ShowtopcontentSkeleton from "../../auth_screens/topcontentskeleton";
import ShowoverviewSkeleton from "../../auth_screens/overviewskeleton";
import InitApp from "../../../../utils/helpers/CreateWeavy";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import { ImageSlider } from "../../../cook_dashboard/pages/CookDashHome";

function ClientDashHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [chefdatas, setchefDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [dashdata, setDashData] = useState([]);
  const [loginData, setloginData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [Ads, setAds] = useState();
  const [images, setImages] = useState();

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | DashBoard";
  }, []);

  React.useEffect(() => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        if (!data.user.data.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.user?.data?.id,
              name: data?.user?.data?.fullName,
            }),
          };

          InitApp(requestOptions, data.user.data, "client");
        } else {
          localStorage.setItem("weavyId", data.user.data.weavyId);
        }
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getemployerDashboardData())
      .unwrap()
      .then((data) => {
        setDashData(data.empDashData.data[0]);
      })
      .catch(({ message }) => {
        // setShowModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(UpdateLogin())
      .unwrap()
      .then((data) => {
        setloginData(data.loginData.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [dispatch]);

  const getDetails = () => {
    const item = {
      limit: 3,
      page: 1,
    };
    dispatch(FindhouseCook(item))
      .unwrap()
      .then((data) => {
        setchefDatas(data?.housecookData?.data);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    const item = {
      limit: 100,
      page: 1,
      usertype: "client",
    };
    dispatch(GetEmployerAdvertisements(item))
      .then((data) => {
        setAds(data?.payload?.ads?.data);
        const images = data?.payload?.ads?.data?.map((el) => el?.image);
        if (images && images?.length > 0) {
          setImages(images);
        }
      })
      .catch(({ message }) => {});
  }, []);
  const startAngle = 0;
  const customStyles = buildStyles({
    rotation: startAngle / 360, // Convert startAngle to a fraction
    strokeLinecap: "butt", // Round or butt
    textSize: "16px",
    pathTransitionDuration: 0.5, // Animation duration
    pathColor: "#ff5c09", // Progress color
    textColor: "#ff5c09", // Text color
    trailColor: "#f4f4f4", // Trail color
    backgroundColor: "#3e98c7", // Background color
  });

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div className="dash_card my-4 justify-content-center align-items-center">
        {isLoading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row>
            <Col lg={3} sm={5} xs={5} md={3} xl={2}>
              <div className="cprogress">
                <CircularProgressbarWithChildren
                  value={data?.profilePercent || 0}
                  strokeWidth={4}
                  styles={customStyles}
                  className="circular-progress"
                >
                  {data?.dp ? (
                    <img src={data?.dp} alt="user profile" className="userdp" />
                  ) : (
                    <img
                      src={clientimg}
                      alt="user profile"
                      className="userdp"
                    />
                  )}
                </CircularProgressbarWithChildren>
              </div>
            </Col>
            <Col
              lg={4}
              sm={6}
              xs={6}
              xl={4}
              md={4}
              align="left"
              className="ms-4"
            >
              <div className="h4 text-muted mt-1">
                नमस्ते, {data?.fullName}!
              </div>

              <Button
                className="editprofileBtn mt-3"
                onClick={() => navigate("/hin/client/edit-profile")}
              >
                प्रोफ़ाइल संपादित करें
              </Button>
              {data?.basicProfileStatus === 0 && (
                <p className="d-flex justify-content-left align-items-left mt-2">
                  अपूर्ण प्रोफ़ाइल
                  <AiFillWarning className="m-1" />
                </p>
              )}
            </Col>

            <Col lg={4} sm={12} xs={12} md={4} xl={4} align="right">
              <Row>
                <Col className="p-1">
                  <div
                    style={{
                      backgroundColor: "#fffbde",
                      borderRadius: "15px",
                      color: "#000",
                      padding: "14px",
                    }}
                    align="left"
                  >
                    <h5 className="justify-content-left align-items-left">
                      प्रयोग
                    </h5>
                    <p style={{ fontSize: "14px" }}>
                      Events posts remaining: {dashdata?.currentEventPoints}
                      <br />
                      Housecook view remaining:
                      {dashdata?.currentHouseCookPoints}
                      <br />
                      Party/ Catering view remaining:
                      {dashdata?.currentPartyCateringPoints}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {images && (
        <div className="card mb-5 mt-5">
          <ImageSlider images={images} className="img-fluid" />
        </div>
      )}
      <div className=" my-4 justify-content-center align-items-center">
        <Row className="justify-content-center align-items-center">
          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} xl={3} align="center">
              <Link to="/hin/client/find-house-cook" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#dfffe7" }}>
                  <img src={housecook} alt="" className="homeIcon" />
                  <br />
                  घर के लिए कुक
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} xl={3} align="center">
              <Link to="/hin/client/requirement" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#fbe7f8" }}>
                  <img src={requirement} alt="" className="homeIcon" />
                  <br />
                  रीक्वारेमेंट
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} xl={3} align="center">
              <Link to="/hin/client/events" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#fcfacf" }}>
                  <img src={events} alt="" className="homeIcon" />
                  <br />
                  इवेंट्स
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} xl={3} align="center">
              <Link to="/hin/client/plans" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#e2ebf8" }}>
                  <img src={plan} alt="" className="homeIcon" />
                  <br />
                  प्लान
                </div>
              </Link>
            </Col>
          )}
        </Row>
      </div>
      <Row className="mt-3">
        <Col lg="6" md="6">
          <p className="me-2 pt-3">अवलोकन</p>
          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span style={{ color: "#ff5c09", fontSize: "18px" }}>
                  <BsCardText />
                </span>
              </div>
              <div className="ms-3">
                <br />
                {dashdata?.totalRequirementApplicationsReceived ? (
                  <b>{dashdata?.totalRequirementApplicationsReceived}</b>
                ) : (
                  <b>0</b>
                )}

                <p>House cook interests received</p>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span
                  style={{
                    color: "#ff5c09",
                    fontSize: "18px",
                    marginTop: "-5px",
                  }}
                >
                  <BsFillBriefcaseFill />
                </span>
              </div>
              <div className="ms-3">
                <br />
                {dashdata?.totalActiveEvents ? (
                  <b>{dashdata?.totalActiveEvents}</b>
                ) : (
                  <b>0</b>
                )}

                <p>Events posted</p>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span
                  style={{
                    color: "#ff5c09",
                    fontSize: "18px",
                    marginTop: "-5px",
                  }}
                >
                  <BsCardText />
                </span>
              </div>
              <div className="ms-3">
                <br />

                {dashdata?.totalEventInterestReceived ? (
                  <b>{dashdata?.totalEventInterestReceived}</b>
                ) : (
                  <b>0</b>
                )}

                <p>Event interests received</p>
              </div>
            </div>
          )}
        </Col>
        <Col lg="6" md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="pt-3">टॉप हाउस कुक</p>
            <Link
              to="/hin/client/find-house-cook"
              style={{ cursor: "pointer", color: "#fc5509", marginTop: "-5px" }}
            >
              सभी देखें
            </Link>
          </div>
          {isLoading ? (
            <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
          ) : (
            <div style={{ marginTop: "-10px" }}>
              {chefdatas?.map((item, index) => (
                <div className="overviewcard">
                  <div>
                    {item?.dp && (
                      <img
                        src={item?.dp}
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50px",
                        }}
                        alt=""
                      />
                    )}
                    {!item?.dp && (
                      <img
                        src={chefimg}
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50px",
                        }}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="ms-3">
                    <b>{item?.fullName.substring(0, 30)}</b>
                    <div>{item?.area}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <br />
      <br />
    </>
  );
}

export default ClientDashHome;
