import React, { useEffect } from "react";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
import img from "../../Assets/Images/delicious-food-by-expert-cooks.jpg";
import Family from "../../Assets/Images/For-families-looking-for-cooks.jpg";
import cookimg from "../../Assets/Images/cook-chef-hotel-kitchen.jpg";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>About | Cook and Chef | What is CookandChef </title>
        <meta
          name="description"
          content="Who are us? Know about CookandChef. What we do. How we can help in finding a Cook or Chef. How can we help Cooks to find their Jobs"
        />
        <meta
          name="keywords"
          content="About CookandChef, who cookandchef, what cook and chef, Is cook and chef"
        />
      </Helmet>
      <NavMenu />
      <div className="breadcrumb-containerabout pt-2 pb-2">
        <Row>
          <Col>
            <h2 className="text-center">About Us</h2>
          </Col>
        </Row>
      </div>

      <div className="p-2" style={{ background: "#fff" }}>
        <Container className="mt-5">
          <Row style={{ textWrap: "wrap" }}>
            <Col lg={1} md={1} sm={12} />
            <Col lg={4} md={5} sm={12}>
              <img
                src={img}
                alt="Cook and Chef jobs"
                style={{ height: "460px" }}
                className="img-fluid"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <h5 className="hero-sub-title text-grad">CookandChef</h5>
              <h2 style={{ fontWeight: "600" }}>
                Where Kitchens Sizzle and Dreams Rise
              </h2>
              <br />
              <p>
                Craving delicious home-cooked meals but short on time? Longing
                to share your culinary magic with the world? Welcome to
                <b style={{ color: "#ff5c09", fontWeight: "500" }}>
                  CookandChef
                </b>
                , the vibrant melting pot where hungry hearts meet happy
                kitchens!
                <br />
                <br />
                Tired of scouring endless job boards, sifting through resumes,
                and hoping for the best? We get it. Finding the perfect cook or
                landing the ideal cooking job can feel like searching for a lost
                spice in a crowded pantry. But fear not, foodies! We're here to
                turn up the heat on your culinary adventures.
                <br />
                <br />
                <b style={{ color: "#ff5c09", fontWeight: "500" }}>
                  CookandChef
                </b>
                is India's one-stop shop for chefs of all stripes and tastes.
                Whether you're a family seeking the warmth of authentic home
                cooking, a restaurant yearning for a culinary artist, or a
                passionate cook eager to unleash your inner chef – we're the
                secret ingredient that brings you together.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5 p-2">
        <Row style={{ textWrap: "wrap" }}>
          <Col lg={1} md={1} sm={12} />
          <Col lg={6} md={7} sm={12}>
            <h2>For families:</h2>
            <p>
              Bid farewell to endless interviews and culinary mismatches.
              Explore our curated pool of verified cooks, each with their own
              unique skills and specialties. Indian comfort food? Global gourmet
              extravaganza? We've got it all, simmering just a click away.
              <br />
              <br />
              No more compromising on taste. Find the perfect match for your
              family's palate and preferences. Enjoy the peace of mind that
              comes with secure payments and clear communication. Because a
              happy kitchen makes a happy home.
              <br />
              <br />
            </p>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <img src={Family} alt="House cooks" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <div className="p-2" style={{ background: "#fff" }}>
        <Container>
          <div align="center" style={{ textWrap: "wrap" }}>
            <h4>For Businesses</h4>
            <h3>
              Unleash Culinary Excellence: Attract More, Sell More, Grow Big
              <br />
              with the Best Chefs in India
            </h3>
          </div>
          <Row
            style={{ textWrap: "wrap" }}
            className="justify-content-center mt-5"
          >
            <Col lg={10} md={12} sm={12}>
              <p className="mt-5">
                Tired of mediocre meals and empty tables? It's time to ignite
                your business with culinary artistry!
                <b style={{ color: "#ff5c09" }}>CookandChef</b> is your secret
                weapon, connecting you with India's top chefs – just a few
                clicks away.
              </p>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <div className="overviewcardAbout">
                <p>
                  Guests raving about the exquisite flavors, your kitchen
                  buzzing with creativity, and your sales soaring.
                </p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <div className="overviewcardAbout">
                <p>
                  No more endless interviews, resumes that disappoint, and
                  precious time wasted.
                </p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <div className="overviewcardAbout">
                <p>
                  A curated talent pool at your fingertips, brimming with
                  diverse experience and culinary genius.
                </p>
              </div>
            </Col>

            <Col lg={10} md={12} sm={12} className="mt-5">
              <h3 className="mt-3 mb-3">
                With <h3 className="hero-sub-title text-grad">CookandChef</h3>
                &nbsp; you:
              </h3>
              <Row>
                <Col xs={12} lg={3} md={3} xl={3} align="center">
                  <div
                    className="homeCard"
                    style={{
                      background: "#dfffe7",
                      height: "auto",
                      textWrap: "wrap",
                    }}
                  >
                    Skip the wait, say goodbye to guesswork. Hire with
                    confidence from our pre-vetted network. <br />
                    &nbsp;
                    <br />
                  </div>
                </Col>

                <Col xs={12} lg={3} md={3} xl={3} align="center">
                  <div
                    className="homeCard"
                    style={{
                      background: "#fbe7f8",
                      height: "auto",
                      textWrap: "wrap",
                    }}
                  >
                    Find the perfect fit, from masterful international chefs to
                    regional stars who'll tantalize your customers' taste buds.
                    <br />
                    &nbsp;
                    <br />
                  </div>
                </Col>
                <Col xs={12} lg={3} md={3} xl={3} align="center">
                  <div
                    className="homeCard"
                    style={{
                      background: "#fcfacf",
                      textWrap: "wrap",
                      height: "auto",
                    }}
                  >
                    Tap into endless variety. Vegetarian delights, global
                    fusion, or traditional comfort food – your ideal culinary
                    partner awaits.
                  </div>
                </Col>
                <Col xs={12} lg={3} md={3} xl={3} align="center">
                  <div
                    className="homeCard"
                    style={{
                      background: "#e2ebf8",
                      height: "auto",
                      textWrap: "wrap",
                    }}
                  >
                    Grow your business, one satisfied customer at a time. Happy
                    diners come back for more, boosting your bottom line.
                  </div>
                </Col>
              </Row>
              <div className="mt-5">
                Don't just serve food, create a culinary experience. Join
                CookandChef and watch your restaurant or hotel rise to the top.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-5 p-2">
        <Row style={{ textWrap: "wrap" }} className="justify-content-center">
          <Col lg={6} md={7} sm={12}>
            <h2>For cooks:</h2>
            <p>
              Step out of the shadows and into the spotlight. Showcase your
              culinary prowess on our platform and connect with the perfect
              opportunity. Land your dream job in a family kitchen, a bustling
              restaurant, or a trendy cafe – the possibilities are endless.
              <br />
              <br />
              No more pounding pavements or cold-calling. We bring the jobs to
              you! Update your profile, highlight your skills, and let your
              passion do the talking. We believe every dish deserves a platform
              to shine.
              <br />
              <br />
            </p>
          </Col>
          <Col lg={4} md={5} sm={12}>
            <img
              src={cookimg}
              alt="Hotel jobs"
              style={{ height: "250px" }}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      <div className="p-2" style={{ background: "#fff" }}>
        <Container>
          <div align="center">
            <h3>
              Why <h3 className="hero-sub-title text-grad">CookandChef</h3>?
            </h3>
          </div>
          <Row style={{ textWrap: "wrap" }} className="justify-content-center">
            <Col lg={10} md={12} sm={12}>
              <p>
                Finding the perfect cook for your home can be an overwhelming
                task. While countless job portals exist, none specifically
                catered to the unique needs of home cooks and their clients.
                This often forced people to rely on shaky networks of friends
                and colleagues, leading to frustration and compromises. After
                all, food is one of life's essential joys, and shouldn't we be
                able to experience it just the way we like it, even in our own
                homes? Recognizing this critical gap, we at CookandChef are here
                to bridge the divide. We apologize for entering the scene a bit
                later than ideal, but we're determined to revolutionize the way
                you find the ideal cook for your kitchen!
              </p>

              <Row className="mt-2">
                <Col lg={12} md={12} sm={12}>
                  <div className="overviewcardAbout">
                    <p>
                      Dream big, eat deliciously: We envision becoming India's
                      premier destination for chefs of all kinds, from home
                      cooks to culinary masters. With us, the search for the
                      perfect kitchen companion ends – forever. We empower cooks
                      to find dream jobs while connecting families with chefs
                      who understand the magic of a shared meal.
                    </p>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="overviewcardAbout">
                    <p>
                      Say goodbye to the cook hunt: No more endless searching,
                      no more settling. Our platform becomes the bridge between
                      talented cooks and families yearning for culinary
                      connection. Find your ideal kitchen partner – experienced,
                      skilled, and ready to craft delicious memories on your
                      table. For cooks, discover opportunities that match your
                      passion and expertise. Join us and let the kitchens of
                      India sing!
                    </p>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="overviewcardAbout">
                    <p>
                      From home hearth to culinary haven: We're building India's
                      largest hub for cooks of every stripe, from home chefs
                      sharing their cultural treasures to professional masters
                      wielding kitchen artistry. The quest for culinary
                      perfection ends here. Cooks, step into a world of
                      opportunities; families, unlock doors to a universe of
                      flavors. Together, let's transform every meal into a
                      celebration.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default About;
