import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { cookregister } from "../../../store/slices/auth";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import login_banner from "../../../Assets/Images/catering-signup.jpg";
import { Link } from "react-router-dom";
import Footer from "../nav_bar/Footer";
import { useDispatch } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modal2 from "./Modal2";
import SuccessModal from "./SuccessModal";
import Fingerprint2 from "fingerprintjs2";

function CateringSignup() {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [errors, setErrors] = useState({});
  const [mac, setMac] = useState();
  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

      // Log or use the fingerprint as needed
      // console.log("Device Fingerprint:", fingerprint);
      setMac(fingerprint);
    });
  }, []);

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{2,}$/.test(name);
  };

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  useEffect(() => {
    document.title = "CookandChef | Catering Signup";
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;

    switch (name) {
      case "fullName":
        setfullName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "कृपया एक मान्य नाम दर्ज करें"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "email":
        setEmail(value);
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "enter a valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;

      case "mobileNumber":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobileNumber(value);
        if (value.trim() === "") {
          validationErrors[name] = "कृपया एक वैध मोबाइल नंबर दर्ज करें";
        } else if (!/^\d{10}$/.test(value.trim())) {
          validationErrors[name] = "कृपया एक वैध मोबाइल नंबर दर्ज करें";
        } else {
          delete validationErrors[name];
        }
        break;
      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" || !isValidPassword(value)
            ? "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए"
            : "";
        if (value.trim() !== "" && isValidPassword(value)) {
          validationErrors[name] = "";
        }
        break;

      case "confirmPassword":
        setConfirmPassword(value);
        validationErrors[name] =
          value.trim() === "" || value === password
            ? ""
            : "Password and पासवर्ड की पुष्टि कीजिये should be same";
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  async function signUp(e) {
    e.preventDefault();
    const validationErrors = {};
    if (!fullName.trim()) {
      validationErrors.fullName = "Please enter catering group name";
    }
    if (!email.trim()) {
      validationErrors.email = "अपना ईमेल दर्ज करें";
    }
    if (!mobileNumber.trim()) {
      validationErrors.mobileNumber = "अपना मोबाइल नंबर दर्ज करें";
    }
    if (!password.trim()) {
      validationErrors.password = "Please enter password";
    }

    if (!confirmPassword.trim()) {
      validationErrors.confirmPassword = "कृपया पुष्टि पासवर्ड दर्ज करें";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let registerIP = "0.0.0.0";
    let memberType = 2;
    let registerMAC = mac;
    let webAccess = 1;

    let mobile = parseInt(mobileNumber);
    let item = {
      fullName,
      email,
      mobile,
      password,
      memberType,
      registerIP,
      registerMAC,
      webAccess,
    };

    dispatch(cookregister(item))
      .unwrap()
      .then(() => {
        setModalMessage("OTP has been sent to your मोबाइल नंबर");
        setsuccessModal(true);
        setTimeout(() => {
          navigate("/cook-verify-otp");
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-3"
        >
          <Col
            lg={5}
            className="d-none d-lg-block"
            style={{
              backgroundColor: "white",
            }}
          >
            <img src={login_banner} alt="" style={{ height: "550px" }} />
          </Col>
          <Col
            className="justify-content-center align-items-center flex-column"
            lg={5}
            xs={11}
            md={11}
            xl={5}
            style={{
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                height: "550px",
              }}
              align="center"
            >
              <p className="h3 text-center mt-3">Catering Sign Up</p>

              <Form
                autoComplete="off"
                noValidate
                onSubmit={signUp}
                className="text-center p-2"
                style={{
                  height: "100%",
                  width: "90%",
                }}
              >
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    className="input1"
                    type="text"
                    name="fullName"
                    value={fullName}
                    onChange={handleChange}
                    isInvalid={!!errors.fullName}
                    placeholder="Enter catering group name"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="input1"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    placeholder="Enter your email"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="input1"
                    type="number"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={handleChange}
                    onWheel={(e) => e.target.blur()}
                    isInvalid={!!errors.mobileNumber}
                    placeholder="Enter मोबाइल नंबर"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4 position-relative">
                  <Form.Control
                    className="input1"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                    placeholder="Enter password"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "20%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEye
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    )}
                  </span>

                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4 position-relative">
                  <Form.Control
                    className="input1"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                    placeholder="पासवर्ड की पुष्टि कीजिये"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "20%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEye
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    )}
                  </span>

                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="text-start mb-4">
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    By clicking Sign Up, you agree to our
                    <Link
                      to="https://cookandchef.in/terms-and-conditions"
                      target="_blank"
                      className="terms_and_conditions"
                    >
                      &nbsp;terms and conditions
                    </Link>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: "12px",
                      marginTop: "-10px",
                    }}
                  >
                    &nbsp;and&nbsp;
                    <Link
                      to="https://cookandchef.in/privacy-policy"
                      target="_blank"
                      className="terms_and_conditions"
                    >
                      privacy policy
                    </Link>
                  </p>
                  <div></div>
                </Form.Group>
                <Button className="mb-2 login_btn" type="primary">
                  Sign Up
                </Button>
                <p className="text-muted mt-3">
                  Already have an account?&nbsp;
                  <NavLink to="/login">
                    <span className="forgot_password">Login</span>
                  </NavLink>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <Footer />
    </>
  );
}

export default CateringSignup;
