import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "moment";
import { BsCheck, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  GetEmployerJobDetails,
  EditjobEmployer,
} from "../../../store/slices/employerprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import { TiTick } from "react-icons/ti";

function JobDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const enableJob = (id) => {
    var elem = {
      id: id,
      status: 1,
    };

    dispatch(EditjobEmployer(elem))
      .then((data) => {
        setModalMessage("Job enabled successfully!");
        setsuccessModal(true);
        getJobDetails();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const disableJob = (id) => {
    var elem = {
      id: id,
      status: 2,
    };

    dispatch(EditjobEmployer(elem))
      .then((data) => {
        setModalMessage("Job disabled successfully!");
        setsuccessModal(true);
        getJobDetails();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  React.useEffect(() => {
    getJobDetails();
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Job Details";
  }, []);

  const getJobDetails = () => {
    dispatch(GetEmployerJobDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.jobdetails.data[0]);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

 const { providentFund, insurance, incentives } = data || {};
 const { weekOffs } = data || {};

const AdditionalPerksArray = [
    { name: "WeekOffs", value: weekOffs || 0 },
    { name: "Provident Fund", value: providentFund || 0 },
    { name: "Insurance", value: insurance || 0 },
    { name: "Incentives", value: incentives || 0 },
  ];

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/employer/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/employer/manage-jobs" className="bcrumb">
          Manage Jobs
        </Link>
        &nbsp;&bull; Job Details
      </div>
      <Row className="mt-4">
        {data?.status !== 0 ? (
          <Col lg={7} xs={12} md={7} sm={12} className="card p-3 m-1">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Job Details
            </h6>
            <Row>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Designation</p>
                <p className="text-details">{data?.designation}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Experience</p>

                <p className="text-details">{data?.experience} Years</p>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Salary</p>
                <p className="text-details">{data?.salary}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Location</p>

                <p className="text-details">{data?.location}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Pincode</p>

                <p className="text-details">{data?.pincode}</p>
              </Col>

              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Visibility</p>

                {data?.visibility === 1 && (
                  <p className="text-details">Visible to all India</p>
                )}
                {data?.visibility === 0 && (
                  <p className="text-details">Visible to same location only</p>
                )}
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Contact Number</p>

                <p className="text-details">{data?.contactNumber}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Receive applications on WhatsApp</p>

                {data?.whatsappUpdate === 1 && (
                  <p className="text-details">Yes</p>
                )}
                {data?.whatsappUpdate === 0 && (
                  <p className="text-details">No</p>
                )}
              </Col>
            </Row>
            <p className="text-heading">Job Description</p>
            <p className="text-details">{data?.description}</p>
          </Col>
        ) : (
          <Col lg={11} xs={12} md={11} sm={12} className="card p-3 m-1">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Job Details
            </h6>
            <Row>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Designation</p>
                <p className="text-details">{data?.designation}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Experience</p>

                <p className="text-details">{data?.experience} Years</p>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Salary</p>
                <p className="text-details">{data?.salary}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Location</p>

                <p className="text-details">{data?.location}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Pincode</p>

                <p className="text-details">{data?.pincode}</p>
              </Col>

              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Visibility</p>

                {data?.visibility === 1 && (
                  <p className="text-details">Visible to all India</p>
                )}
                {data?.visibility === 0 && (
                  <p className="text-details">Visible to same location only</p>
                )}
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Contact Number</p>

                <p className="text-details">{data?.contactNumber}</p>
              </Col>
              <Col lg={6} xs={6} md={6}>
                <p className="text-heading">Receive applications on WhatsApp</p>

                {data?.whatsappUpdate === 1 && (
                  <p className="text-details">Yes</p>
                )}
                {data?.whatsappUpdate === 0 && (
                  <p className="text-details">No</p>
                )}
              </Col>
            </Row>
            <p className="text-heading">Job Description</p>
            <p className="text-details">{data?.description}</p>
          </Col>
        )}

        {data?.status !== 0 && (
          <Col
            lg={4}
            xs={12}
            md={4}
            className="justify-content-center align-items-center card m-1 p-3"
          >
            {data?.status === 2 && (
              <button className="cancelBtn" onClick={() => enableJob(id)}>
                Enable Job
              </button>
            )}
            {data?.status === 1 && (
              <button className="cancelBtn" onClick={() => disableJob(id)}>
                Disable Job
              </button>
            )}
            {data?.status !== 0 && (
              <Link
                to={`/employer/edit-job/${id}`}
                className="saveBtn mt-3"
                style={{ textAlign: "center", paddingTop: "5px" }}
              >
                Edit Job
              </Link>
            )}
            {data?.status !== 0 && (
              <Link
                className="mt-3"
                to={`/employer/job-application/${id}`}
                style={{ textAlign: "center", paddingTop: "5px" }}
              >
                <u>{data?.currentResponsesCount} Application</u>
              </Link>
            )}
          </Col>
        )}
      </Row>

      <Row className="mt-3">
        <Col lg={7} xs={12} md={7} sm={12} className="card p-3 m-1">
          <Row>
            <Col lg={11} xs={12} md={11} sm={12}>
              <h6
                className="text-left mt-1"
                style={{ fontWeight: "600", color: "#ff5c09 " }}
              >
                Skills & Education
              </h6>
            </Col>
            <Col lg={11} xs={12}>
              <p className="text-heading">Cuisines</p>
              <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                {data?.cuisines?.map((cookplanVal, index) => (
                  <Col
                    xs={5}
                    sm={5}
                    md={3}
                    lg={2}
                    xl={2}
                    className="me-2"
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "10px",
                      color: "black",
                      padding: "4px 8px",
                      margin: "8px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "14px",
                      marginRight: "4px",
                      border: "solid 1px #ff5c09",
                      width: "auto",
                    }}
                  >
                    {cookplanVal}
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xs={6} md={6} lg={6}>
              <p className="text-heading">Dishes</p>
              {data?.dishes && <p className="text-details">{data?.dishes}</p>}
            </Col>
            <Col xs={6} md={6} lg={6}>
              <p className="text-heading">Qualification</p>
              <p className="text-details">{data?.qualification}</p>
            </Col>
          </Row>
        </Col>
        <Col lg={4} xs={12} md={4} className="card m-1 p-3">
          <h6
            className="text-left"
            style={{ fontWeight: "600", color: "#ff5c09 " }}
          >
            Requirement Details
          </h6>
          <Row>
            <Col lg={6} xs={6}>
              <p className="text-heading">No. of Openings</p>
              <p className="text-details">{data?.openings}</p>
            </Col>

            <Col lg={6} xs={6}>
              <p className="text-heading">Urgency</p>
              <p className="text-details">{data?.urgency}</p>
            </Col>
          </Row>
          <h6
            className="text-left"
            style={{ fontWeight: "600", color: "#ff5c09 " }}
          >
            Benefits
          </h6>
          <Row>
            <Col lg={6} xs={6}>
              <p className="text-heading">Food</p>
              <p className="text-details">
                {data?.food === 1 && (
                  <BsCheck
                    style={{
                      color: "green",
                      padding: "",

                      borderRadius: "100%",
                      height: "2.5dvh",
                      width: "2.5dvh",
                    }}
                  />
                )}
                {data?.food === 0 && (
                  <BsX
                    style={{
                      color: "red",

                      borderRadius: "100%",
                      height: "2.5dvh",
                      width: "2.5dvh",
                    }}
                  />
                )}
              </p>
            </Col>
            <Col lg={6} xs={6}>
              <p className="text-heading">Accommodation</p>
              <p className="text-details">
                {data?.accommodation === 1 ? (
                  <BsCheck
                    style={{
                      color: "green",
                      padding: "",

                      borderRadius: "100%",
                      height: "2.5dvh",
                      width: "2.5dvh",
                    }}
                  />
                ) : (
                  <BsX
                    style={{
                      color: "red",

                      borderRadius: "100%",
                      height: "2.5dvh",
                      width: "2.5dvh",
                    }}
                  />
                )}
              </p>
            </Col>
          </Row>
          <Row>
            {AdditionalPerksArray &&
              AdditionalPerksArray?.filter((i) => i.value === 1).map((i) => (
                <Col lg={6} xs={6} md={6} sm={6}>
                  <div className="perks">
                    <div className="item-ejob" key={i.name}>
                      <span className="icon">
                        <TiTick />
                      </span>
                      {i.name}
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default JobDetails;