import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsBookmark, BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import boosted from "../../../../Assets/Images/Group 2407.png";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FiFilter } from "react-icons/fi";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { FaRegClock } from "react-icons/fa";
import {
  findChef,
  removeshortlistchef,
  shortlistchef,
  getemployerDashboardData,
} from "../../../../store/slices/employerprofile";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { getCuisines } from "../../../../store/slices/config";
import { useRef } from "react";
import { useSelector } from "react-redux";
import "../employer.css";
import { MdCurrencyRupee } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { FaBookmark } from "react-icons/fa";
import { getemployerProfile } from "../../../../store/slices/employerprofile";
import chef from "../../../../Assets/Images/cook.png";
import Modal2 from "../../auth_screens/Modal2";
import Moment from "moment";
import MultiRangeSlider from "../../../../utils/helpers/MultirangeSlider";
import Skeleton from "react-loading-skeleton";
import dog from "../../../../Assets/Images/corgi.png";
import IsViewedModalEmployer from "../../../../utils/helpers/IsViewedModalEmployer";

function FindChefs() {
  const [dashdata, setDashData] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const [totaldata, setTotaldata] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [pagechange, setPageChange] = useState(false);
  const myData = useSelector((state) => state.employerData.chefdata);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [modalViewMessage, setModalViewMessage] = useState("");

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const itemsPerPage = 6;
  const pagesToShowInitially = 3;

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage || 1,
  });

  const handleapply = () => setShow(false);
  const handlePageChange = async ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setPageChange(true);
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    document.title = "CookandChef | Find Chef";
  }, []);
  const shortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: true };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      id: item._id,
      cookType: "chef",
    };
    dispatch(shortlistchef(elem))
      .then((data) => {
        getchefs1();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const [myDatas, setMyDatas] = useState([]);
  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data1) => {
        setMyDatas(data1?.user?.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(getemployerDashboardData())
      .unwrap()
      .then((data) => {
        setDashData(data.empDashData.data[0]);
      })
      .catch(({ message }) => {
        // setShowModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const removeshortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: false };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      cookId: item._id,
      cookType: "chef",
    };

    dispatch(removeshortlistchef(elem))
      .then((data) => {
        getchefs1();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const minsal = search.get("minimumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");
    const skillsq = search.get("skills");

    setTimeout(() => {
      setisLoading(true);
    }, 1300);
    setisLoading(false);
    if (!cuisineval && !minexpval && !minsal && !locationq && !skillsq) {
      getchefs();
    } else if (pagechange === true) {
      getchefs();
    }
  }, [currentPage]);

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const minsal = search.get("minimumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");
    const skillsq = search.get("skills");
    if (!cuisineval && !minexpval && !minsal && !locationq && !skillsq) {
      getchefs();
    } else {
      setData(myData?.findchef?.data);
      setTotaldata(myData?.findchef?.totalDataCount);
    }
  }, [myData]);

  const getchefs = () => {
    dispatch(findChef(item))
      .unwrap()
      .then((data) => {
        setData(data?.findchef?.data);
        setTotaldata(data?.findchef?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {});
  };

  const getchefs1 = () => {
    dispatch(findChef(item))
      .unwrap()
      .then((data) => {
        setData(data?.findchef?.data);
        setTotaldata(data?.findchef?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const errorModal = (item) => {
    setShowViewModal(true);
    setModalViewMessage(
      "इस रसोइये की प्रोफ़ाइल देखने के लिए आपके पास पर्याप्त अंक नहीं हैं। देखने के लिए प्लान खरीदें"
    );
  };

  const ShowSkeleton = () => {
    return (
      <>
        {Array(6)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <IsViewedModalEmployer
        showViewModal={showViewModal}
        modalViewMessage={modalViewMessage}
        setShowViewModal={setShowViewModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/employer/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull; शेफ खोजें
      </div>

      <div>
        <div align="right">
          <Button
            className="filter_btn d-flex mb-2 justify-content-around align-items-center d-lg-none d-md-block"
            onClick={handleShow}
            style={{ border: "solid 1px #ff5c09", padding: "2px 10px" }}
          >
            <FiFilter /> Filter
          </Button>
        </div>

        <BottomSheet
          open={show}
          onDismiss={() => setShow(false)}
          blocking={false}
          snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
        >
          <Filter
            setItem={setItem}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleapply={handleapply}
            setisLoading={setisLoading}
          />
        </BottomSheet>

        <Row>
          {data?.length === 0 ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              <Row
                className="d-flex justify-items-center align-items-center"
                style={{
                  backgroundColor: "white",

                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Col lg={12} align="center">
                  <p style={{ fontSize: "18px" }}>
                    <b>No results</b> found for your search!
                  </p>
                  <br />
                  <img src={dog} alt="" style={{ height: "250px" }} />
                  <br />
                  <br />
                  <p style={{ fontSize: "18px" }}>
                    Refine your search by using different keywords or checking
                    for spelling mistakes.
                  </p>
                </Col>
              </Row>
            </Col>
          ) : isLoading ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              {data?.map((item, index) => (
                <Row
                  className="d-flex justify-items-center align-items-center mb-2"
                  key={index}
                  style={{
                    backgroundColor: "white",

                    borderRadius: "15px",
                    padding: "8px",
                  }}
                >
                  <Col lg={2} md={2} xs={3} className="justify-content-center">
                    {item.profileBoostRank === 1 && (
                      <img
                        src={boosted}
                        alt=""
                        style={{
                          marginTop: "-20px",
                          marginLeft: "-28px",
                        }}
                      />
                    )}
                    {item?.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={item?.dp}
                        alt="user profile"
                      />
                    )}
                    {!item.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={chef}
                        alt="user profile"
                      />
                    )}
                  </Col>
                  <Col lg={9} xs={7} md={8} className="ps-3">
                    <h6
                      style={{ fontWeight: "600", marginBottom: "0.2rem" }}
                      className="ms-3"
                    >
                      {item?.fullName}
                    </h6>

                    {item?.chefCuisines?.length >= 3 ? (
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.chefCuisines?.join(", ").substring(0, 20)}
                        ...
                      </p>
                    ) : (
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.chefCuisines?.join(", ")}
                      </p>
                    )}
                    <p
                      style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <GrLocation style={{ marginBottom: "2px" }} />
                      &nbsp;
                      {item?.currentCityName}
                    </p>

                    <p
                      style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <BsBriefcase style={{ marginBottom: "2px" }} />
                      &nbsp;
                      {item?.chefExperience} Years
                      <MdCurrencyRupee
                        style={{ marginBottom: "2px" }}
                        className="ms-3"
                      />
                      {item?.expectedSalary}
                    </p>
                  </Col>

                  <Col
                    lg={1}
                    xs={2}
                    md={2}
                    align="right"
                    style={{ marginTop: "-80px" }}
                  >
                    {item.isShortlisted === true && (
                      <div
                        onClick={() => removeshortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <FaBookmark />
                      </div>
                    )}

                    {item.isShortlisted === false && (
                      <div
                        onClick={() => shortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <BsBookmark />
                      </div>
                    )}
                  </Col>
                  <Col lg={3} xs={6}>
                    <p style={{ fontSize: "11px", color: "#8F8686" }}>
                      <FaRegClock style={{ marginTop: "-2px" }} />
                      &nbsp;
                      {Moment.utc(item?.lastLoginDateTime)
                        .local("Asia/Kolkata")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </Col>
                  <Col lg={9} xs={6}>
                    <div align="right">
                      {item?.isViewed === false && (
                        <CiLock style={{ fontSize: "20px" }} />
                      )}
                      &nbsp;
                      {item?.isViewed === false &&
                        myDatas?.pointsData?.currentProfileViewPoints === 0 && (
                          <Button
                            onClick={(item) => errorModal(item)}
                            className="view_profile ms-5"
                          >
                            इसे देखें
                          </Button>
                        )}
                      {item?.isViewed === true && dashdata?.planName && (
                        <Link
                          to={`/hin/employer/chef-details/${item._id}`}
                          style={{ color: "#000" }}
                        >
                          <Button className="view_profile ms-5">
                            इसे देखें
                          </Button>
                        </Link>
                      )}
                      {item?.isViewed === true && !dashdata?.planName && (
                        <Button
                          onClick={(item) => errorModal(item)}
                          className="view_profile ms-5"
                        >
                          इसे देखें
                        </Button>
                      )}
                      {item?.isViewed === false &&
                        myDatas?.pointsData?.currentProfileViewPoints !== 0 && (
                          <Link
                            to={`/hin/employer/chef-details/${item._id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile ms-5">
                              इसे देखें
                            </Button>
                          </Link>
                        )}
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
          <Col lg="4" md={4} className="filter_parent">
            <div
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                borderRadius: "15px",
                paddingBottom: "80px",
                paddingTop: "20px",
              }}
            >
              <Filter
                setItem={setItem}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setShowModal={setShowModal}
                setModalMessage={setModalMessage}
                handleapply={handleapply}
                setisLoading={setisLoading}
              />
            </div>
          </Col>
        </Row>

        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FindChefs;

const Filter = ({
  setItem,
  setCurrentPage,
  currentPage,
  setModalMessage,
  setShowModal,
  handleapply,
  setisLoading,
}) => {
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState("");
  const [maximumExperience, setmaximumExperience] = useState("");
  const [minimumExperience, setminimumExperience] = useState("");
  const [minimumSalary, setminimumSalary] = useState(5000);
  const [maximumSalary, setmaximumSalary] = useState(100000);
  const navigate = useNavigate();
  const [min, setMin] = useState(5000);
  const [max, setMax] = useState(100000);
  const [exp, setExp] = useState("");
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const [minVal, setMinVal] = useState(5000);
  const [maxVal, setMaxVal] = useState(100000);
  const [cuisineInputval, setCuisienInputVal] = useState();
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState();
  const [skills, setSkills] = useState();
  const [skillsError, setSkillsError] = useState();

  const isValidskills = (skillsVal) => {
    return /^[a-zA-Z\s]*$/.test(skillsVal);
  };

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const handleResetFilter = (e) => {
    e.preventDefault();
    setchefCuisines([]);
    setExp("");
    setmaximumExperience("");
    setminimumExperience("");
    setMinVal(5000);
    setMaxVal(100000);
    setminimumSalary(5000);
    setmaximumSalary(100000);
    setLocation("");
    setCoordinates([]);
    setSearch([]);
    setSkills("");
    handleapply();
    const item = {
      limit: 6,
      page: 1,
    };
    setItem(item);
    localStorage.removeItem("coordinates");
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const maxexpval = search.get("maximumexp");
    const minsal = search.get("minimumSalary");
    const maxsal = search.get("maximumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");
    const skillsq = search.get("skills");

    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }
    if (minexpval === 0) {
      setminimumExperience(0);
      setmaximumExperience(2);
      setExp("0-2");
    } else if (minexpval === 2) {
      setminimumExperience(2);
      setmaximumExperience(5);
      setExp("2-5");
    } else if (minexpval === 5) {
      setminimumExperience(5);
      setmaximumExperience(8);
      setExp("5-8");
    } else if (minexpval === 8) {
      setminimumExperience(8);
      setmaximumExperience(10);
      setExp("8-10");
    } else if (minexpval === 10) {
      setminimumExperience(10);
      setmaximumExperience(15);
      setExp("10-15");
    } else if (minexpval === 15) {
      setminimumExperience(15);
      setmaximumExperience(25);
      setExp("15-25");
    } else if (minexpval === 25) {
      setminimumExperience(25);
      setmaximumExperience(50);
      setExp("25-50");
    }
    if (minsal !== null) {
      setMinVal(parseInt(minsal));
      setminimumSalary(parseInt(minsal));
    }
    if (maxsal !== null) {
      setMaxVal(parseInt(maxsal));
      setmaximumSalary(parseInt(maxsal));
    }
    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }
    if (skillsq !== null) {
      setSkills(skillsq);
    }

    if (cuisineval || minexpval || minsal || locationq || pageq || skillsq) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        minimumExperience:
          parseInt(minexpval) >= 0 ? parseInt(minexpval) : undefined,
        maximumExperience: parseInt(maxexpval) || undefined,
        minimumSalary: parseInt(minsal) || undefined,
        maximumSalary: parseInt(maxsal) || undefined,
        skills: skillsq || undefined,
        limit: 6,
        page: parseInt(pageq) || 1,
      };
      setItem(item);
      dispatch(findChef(item))
        .unwrap()
        .then((data) => {})
        .catch(({ message }) => {});
    }
  }, []);

  const handleApplyFilter = (e) => {
    e.preventDefault();

    search.set("minimumSalary", minimumSalary);
    search.set("maximumSalary", maximumSalary);
    if (chefCuisines.length) {
      search.set("cuisines", chefCuisines.join(","));
    }
    if (minimumExperience) {
      search.set("minimumexp", minimumExperience);
    }
    if (maximumExperience) {
      search.set("maximumexp", maximumExperience);
    }
    setSearch(search, {
      replace: true,
    });
    search.set("page", 1);
    setCurrentPage(1);
    setSearch(search, {
      replace: true,
    });
    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      minimumExperience:
        parseInt(minimumExperience) >= 0
          ? parseInt(minimumExperience)
          : undefined,
      maximumExperience: parseInt(maximumExperience) || undefined,
      minimumSalary: parseInt(minimumSalary) || undefined,
      maximumSalary: parseInt(maximumSalary) || undefined,
      skills: skills || undefined,
      limit: 6,
      page: 1,
    };

    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("कृपया चयन कीजिए valid cuisine");
    } else if (locationError) {
    } else {
      setItem(item);
      dispatch(findChef(item))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            setisLoading(true);
          }, [300]);
          setisLoading(false);
        })
        .catch(({ message }) => {});
      handleapply();
    }
  };

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };

  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");
    setchefCuisines(selected);
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setLocationError("कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें");
        } else {
          setLocationError("");
          setLocation(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          localStorage.setItem("coordinates", coordinates);
          setCoordinates(coordinates);
          const existingSearchParams = new URLSearchParams(search);

          // Set the new location parameter
          existingSearchParams.set("location", place.name);

          // Set the merged parameters in the search
          setSearch(existingSearchParams, {
            replace: true,
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationError("कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें");
        }
      });
    }
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  const handleExpChange = (e) => {
    const [minExp, maxExp] = e.target.value?.split("-");
    setExp(e.target.value);
    // Set the minimum and maximum experience in the state
    setminimumExperience(minExp);
    setmaximumExperience(maxExp);
    search.set("minimumexp", minExp);
    search.set("maximumexp", maxExp);
    setSearch(search, {
      replace: true,
    });
  };

  const HandleChange = ({ min, max }) => {
    setminimumSalary(min);
    setmaximumSalary(max);
  };
  const handleskillsChange = (e) => {
    if (!isValidskills(e.target.value)) {
      setSkillsError("Please enter valid skills");
    } else {
      setSkills(e.target.value);
      search.set("skills", e.target.value);
      setSkillsError("");
      setSearch(search, {
        replace: true,
      });
    }
  };

  return (
    <>
      <div
        className="mx-3 p-3"
        style={{
          overflowY: "scroll",
          height: "auto",
        }}
      >
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>भोजन</Form.Label>
            <Typeahead
              clearButton
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              className="input1"
              onChange={handleChefCuisineChange}
              options={options}
              selected={chefCuisines}
              placeholder="Cuisines"
              isInvalid={!!cuisineError}
              onInputChange={inputChangeCuisine}
            />
            <Form.Control.Feedback type="invalid">
              {cuisineError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label>सैलरी</Form.Label>

            <MultiRangeSlider
              min={min}
              max={max}
              minVal={minVal}
              maxVal={maxVal}
              setMinVal={setMinVal}
              setMaxVal={setMaxVal}
              onChange={({ min, max }) => HandleChange({ min, max })}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>अनुभव</Form.Label>
            <Form.Control
              as="select"
              name="experience"
              value={exp}
              onChange={handleExpChange}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="0-2">0-2 Years</option>
              <option value="2-5">2-5 Years</option>
              <option value="5-8">5-8 Years</option>
              <option value="8-10">8-10 Years</option>
              <option value="10-15">10-15 Years</option>
              <option value="15-25">15-25 Years</option>
              <option value="26-50">More than 25 Years</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>जगह</Form.Label>
            <Form.Control
              name="location"
              placeholder="Enter location"
              value={location}
              ref={inputRef}
              onChange={handlelocationChange}
              isInvalid={!!locationError}
            />
            <Form.Control.Feedback type="invalid">
              {locationError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Skills</Form.Label>
            <Form.Control
              name="skills"
              placeholder="Enter skills"
              value={skills}
              onChange={handleskillsChange}
              isInvalid={!!skillsError}
            />
            <Form.Control.Feedback type="invalid">
              {skillsError}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="group d-flex justify-content-around align-items-center mt-3">
            <div className="reset" onClick={handleResetFilter}>
              <Link>
                <u>रीसेट</u>
              </Link>
            </div>

            <Button className="apply_btn ms-2" onClick={handleApplyFilter}>
              जमा करना
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
