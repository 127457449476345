import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Overlay, Row } from "react-bootstrap";
import { BsBookmark, BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FiFilter } from "react-icons/fi";
import { API_PATHS } from "../../../utils/constants/api.constants";
import { BottomSheet } from "react-spring-bottom-sheet";
import { FaRegClock } from "react-icons/fa";
import "react-spring-bottom-sheet/dist/style.css";
import {
  removeshortlistchef,
  shortlistchef,
} from "../../../store/slices/employerprofile";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { getCuisines } from "../../../store/slices/config";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";
import { useCallback } from "react";
import { FaBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getemployerProfile } from "../../../store/slices/employerprofile";
import IsViewedModal from "../../../utils/helpers/IsViewedModal";

import chef from "../../../Assets/Images/cook.png";
import Moment from "moment";
import { Findcatering } from "../../../store/slices/clientprofile";
import Modal2 from "../../auth_screens/Modal2";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dog from "../../../Assets/Images/corgi.png";
function FindCatering() {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [pagechange, setPageChange] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showViewModal, setShowViewModal] = useState(false);
  const [modalViewMessage, setModalViewMessage] = useState("");
  const navigate = useNavigate();
  const [myDatas, setMyDatas] = useState([]);
  const [totaldata, setTotaldata] = useState();
  const [isRespTrue, setIsRespTrue] = useState(false);
  const myData = useSelector((state) => state.clientData.cateringData);
  const handleapply = () => setShow(false);

  const itemsPerPage = 6;
  const pagesToShowInitially = 3;

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = async ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setPageChange(true);
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    document.title = "CookandChef | Find Catering";
  }, []);

  const shortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: true };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      id: item._id,
      cookType: "catering",
    };
    dispatch(shortlistchef(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);
          setShowModal(true);
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const removeshortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: false };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      cookId: item._id,
      cookType: "catering",
    };

    dispatch(removeshortlistchef(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);

          setShowModal(true);
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");

    setTimeout(() => {
      setisLoading(true);
    }, [1000]);
    setisLoading(false);
    if (!cuisineval && !locationq) {
      getchefs();
    } else if (pagechange === true) {
      getchefs();
    }
  }, [currentPage]);

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");

    if (!cuisineval && !locationq) {
      getchefs();
    } else {
      setData(myData?.cateringData?.data);
      setTotaldata(myData?.cateringData?.totalDataCount);
    }
  }, [myData]);

  const getchefs = () => {
    dispatch(Findcatering(item))
      .unwrap()
      .then((data) => {
        setData(data?.cateringData?.data);
        setTotaldata(data?.cateringData?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {});
  };

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data1) => {
        setMyDatas(data1?.user?.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const errorModal = (item) => {
    setShowViewModal(true);
    setModalViewMessage(
      "You don't have enough points to view this cook's profile. Buy plan to view"
    );
  };
  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <IsViewedModal
        showViewModal={showViewModal}
        modalViewMessage={modalViewMessage}
        setShowViewModal={setShowViewModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/client/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Find Catering
      </div>
      <div align="right">
        <Button
          className="filter_btn d-flex mb-2 justify-content-around align-items-center d-lg-none d-md-block"
          onClick={handleShow}
          style={{ border: "solid 1px #ff5c09", padding: "5px 10px" }}
        >
          <FiFilter /> Filter
        </Button>
      </div>

      <BottomSheet
        open={show}
        onDismiss={() => setShow(false)}
        blocking={false}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
      >
        <Filter
          setItem={setItem}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handleapply={handleapply}
          setisLoading={setisLoading}
        />
      </BottomSheet>
      <div>
        <Row className="mt-4">
          {data?.length === 0 ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              <Row
                className="d-flex justify-items-center align-items-center"
                style={{
                  backgroundColor: "white",
                  // height: "20dvh",
                  // width: "100%",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Col lg={12} align="center">
                  <p style={{ fontSize: "18px" }}>
                    <b>No results</b> found!
                  </p>
                  <br />
                  <img src={dog} alt="" style={{ height: "250px" }} />
                  <br />
                  <br />
                  <p style={{ fontSize: "18px" }}>
                    Refine your search by using different keywords or checking
                    for spelling mistakes.
                  </p>
                </Col>
              </Row>
            </Col>
          ) : isLoading ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              {data?.map((item, index) => (
                <Row
                  className="d-flex justify-items-center align-items-center"
                  key={index}
                  style={{
                    backgroundColor: "white",
                    // height: "20dvh",
                    // width: "100%",
                    borderRadius: "15px",
                    padding: "8px",
                    marginTop: "8px",
                  }}
                >
                  <Col lg={2} xs={3} className="justify-content-center">
                    {item?.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={item?.dp}
                        alt="user profile"
                      />
                    )}
                    {!item.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={chef}
                        alt="user profile"
                      />
                    )}
                  </Col>
                  <Col lg={9} xs={7} md={8} className="ps-3">
                    <h6
                      style={{ fontWeight: "600", marginBottom: "0.2rem" }}
                      className="ms-3"
                    >
                      {item?.fullName.substring(0, 30)}
                    </h6>

                    {item?.cateringCuisines?.length >= 3 ? (
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cateringCuisines?.join(", ").substring(0, 20)}
                        ...
                      </p>
                    ) : (
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cateringCuisines?.join(", ")}
                      </p>
                    )}
                    <p
                      style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <GrLocation style={{ marginBottom: "2px" }} />
                      &nbsp;
                      {item?.cityName}
                    </p>
                  </Col>
                  <Col
                    lg={1}
                    xs={2}
                    md={1}
                    align="right"
                    style={{ marginTop: "-60px" }}
                  >
                    {item.isShortlisted === true && (
                      <div
                        onClick={() => removeshortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <FaBookmark />
                      </div>
                    )}

                    {item.isShortlisted === false && (
                      <div
                        onClick={() => shortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <BsBookmark />
                      </div>
                    )}
                  </Col>
                  <Col lg={3} xs={3} md={3} className="mt-2">
                    <p style={{ fontSize: "11px", color: "#8F8686" }}>
                      <FaRegClock style={{ marginTop: "-2px" }} />
                      &nbsp;
                      {Moment.utc(item?.lastLoginDateTime)
                        .local("Asia/Kolkata")
                        .startOf("seconds")
                        .fromNow()}
                    </p>
                  </Col>
                  <Col lg={9} xs={9} md={9}>
                    <div align="right">
                      {item?.isViewed === false && (
                        <CiLock style={{ fontSize: "20px" }} />
                      )}
                      &nbsp;
                      {item?.isViewed === false &&
                        myDatas?.pointsData?.currentPartyCateringPoints ===
                          0 && (
                          <Button
                            onClick={(item) => errorModal(item)}
                            className="view_profile ms-5"
                          >
                            View
                          </Button>
                        )}
                      {item?.isViewed === true && (
                        <Link
                          to={`/client/catering-details/${item._id}`}
                          style={{ color: "#000" }}
                        >
                          <Button className="view_profile ms-5">View</Button>
                        </Link>
                      )}
                      {item?.isViewed === false &&
                        myDatas?.pointsData?.currentPartyCateringPoints !==
                          0 && (
                          <Link
                            to={`/client/catering-details/${item._id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile ms-5">View</Button>
                          </Link>
                        )}
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
          <Col lg={4} className="filter_parent">
            <div
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                borderRadius: "15px",
                paddingBottom: "300px",
              }}
            >
              <Filter
                setItem={setItem}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                handleapply={handleapply}
                setisLoading={setisLoading}
              />
            </div>
          </Col>
        </Row>

        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
              renderLastPage={() => null}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FindCatering;

const Filter = ({
  setItem,
  setCurrentPage,
  currentPage,
  handleapply,
  setisLoading,
}) => {
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState("");
  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState("");

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const handleApplyFilter = () => {
    if (chefCuisines.length) {
      search.set("cuisines", chefCuisines.join(","));
    }
    setSearch(search, {
      replace: true,
    });
    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      limit: 6,
      page: 1,
    };
    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("Please Select valid cuisine");
    } else if (locationError) {
    } else {
      handleapply();
      setCurrentPage(1);
      setItem(item);
      dispatch(Findcatering(item))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            setisLoading(true);
          }, [300]);
          setisLoading(false);
        })
        .catch(({ message }) => {});
    }
  };

  const handleResetFilter = () => {
    setchefCuisines([]);
    setLocation("");
    setCoordinates([]);
    setSearch([]);
    localStorage.removeItem("coordinates");
    const item = {
      limit: 6,
      page: 1,
    };
    setItem(item);
    handleapply();
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");
    const pageq = search.get("page");

    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }

    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }

    if (cuisineval || locationq || pageq) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        limit: 6,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      dispatch(Findcatering(item))
        .unwrap()
        .then((data) => {})
        .catch(({ message }) => {});
    }
  }, []);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };
  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    const validationErrors = { ...errors };
    setchefCuisines(selected);
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisines" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
      googleMapsScript.onload = initializeAutocomplete;
      googleMapsScript.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };
      document.body.appendChild(googleMapsScript);
    };
    const initializeAutocomplete = () => {
      if (inputRef.current) {
        const autoCompleteCity = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autoCompleteCity.addListener("place_changed", () => {
          const place = autoCompleteCity.getPlace();
          if (!place.geometry || !place.geometry.location) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          } else {
            setLocationError("");
            setLocation(place.name);
            const coordinates = [
              place.geometry.location.lat(),
              place.geometry.location.lng(),
            ];
            localStorage.setItem("coordinates", coordinates);
            setCoordinates(coordinates);
            const existingSearchParams = new URLSearchParams(search);

            // Set the new location parameter
            existingSearchParams.set("location", place.name);

            // Set the merged parameters in the search
            setSearch(existingSearchParams, {
              replace: true,
            });
          }
        });
        inputRef.current.addEventListener("blur", () => {
          const place = autoCompleteCity.getPlace();
          const inputValue = inputRef.current.value.trim();

          if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          }
        });
      }
    };
    loadGoogleMapsScript();
    return () => {
      // Clean up code here, if needed
    };
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  return (
    <>
      <div className="mx-3 p-3 mt-2">
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Cuisine</Form.Label>
            <Typeahead
              clearButton
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              className="input1"
              onChange={handleChefCuisineChange}
              options={options}
              placeholder="Cuisine"
              selected={chefCuisines}
              isInvalid={!!cuisineError}
              onInputChange={inputChangeCuisine}
            />
            <Form.Control.Feedback type="invalid">
              {cuisineError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Location</Form.Label>
            <Form.Control
              name="location"
              placeholder="Enter location"
              value={location}
              ref={inputRef}
              onChange={handlelocationChange}
              isInvalid={!!locationError}
            />
            <Form.Control.Feedback type="invalid">
              {locationError}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="group d-flex justify-content-around align-items-center mt-5">
            <Link onClick={handleResetFilter}>
              <u>Reset</u>
            </Link>

            <Button onClick={handleApplyFilter} className="apply_btn ms-2">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
