import { API_PATHS } from "../../../../utils/constants/api.constants";


const setTokenInLocalStorage = (token, expirationTime) => {
  localStorage.setItem("weavyToken", token);
  localStorage.setItem("weavyTokenExpiry", expirationTime.toString());
};

const fetchAuthToken = async () => {
  try {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user?.data?.id;
    const storedToken = localStorage.getItem("weavyToken");
    const storedTokenExpiry = localStorage.getItem("weavyTokenExpiry");
    
    if (storedToken && storedTokenExpiry) {
      const currentTime = new Date().getTime();
      if (currentTime < parseInt(storedTokenExpiry, 10)) {
        return storedToken;
      }
    }
    const response = await fetch(
      `https://${API_PATHS.weavyId}.weavy.io/api/users/${id}/tokens`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_PATHS.weavyKey}`,
        },
        body: JSON.stringify({}),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const token = responseData.access_token;
      if (token) {
        const expirationTime = new Date().getTime() + 60 * 60 * 1000;
        setTokenInLocalStorage(token, expirationTime);

        return token;
      } else {
        throw new Error("Authentication token not received.");
      }
    } else {
      throw new Error(
        `Error fetching authentication token: ${response.status} - ${response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error fetching authentication token:", error);
    throw error;
  }
};

export const createChatRoom = async (chatRoomName, memberIds) => {
  try {
    const token = await fetchAuthToken(); // Wait for the token to resolve

    const existingChatRooms = await getAllUsers(token);

    let existingRoom;
    if (existingChatRooms?.data?.length) {
      existingRoom = existingChatRooms?.data?.filter(
        (room) =>
          room?.display_name?.toLowerCase() === chatRoomName?.toLowerCase()
      );
    }

    if (existingRoom?.length && existingRoom[0]?.id) {
      return existingRoom[0];
    } else {
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/conversations`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            members: memberIds,
          }),
        }
      );
      const data = await response.json();
      return data; // You can return the created chat room data if needed.
    }
  } catch (error) {
    console.error("Error creating chat room:", error);
    throw error;
  }
};

const getAllUsers = async (weavyToken) => {
  try {
    const response = await fetch(
      `https://${API_PATHS.weavyId}.weavy.io/api/conversations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${weavyToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting users:", error);
  }
};
