import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useEffect } from "react";
import { getfaq } from "../../../../store/slices/config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import NavMenu from "../../../nav_bar/NavMenu";
import Footer from "../../../nav_bar/Footer";
import { Container, Row } from "react-bootstrap";

const FAQs = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getfaq())
      .unwrap()
      .then((data) => {
        setData(data.faq.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, []);

  return (
    <>
      <NavMenu />
      <Container>
        <div className="mt-5">
          <h5>Frequently Asked Questions (FAQs)</h5>
          <div className="help-lef-inner mt-3">
            <Accordion defaultActiveKey="0">
              {data.map((faq, index) => (
                <div className="mt-3">
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>
                      <p style={{ textWrap: "wrap" }}>{faq.answer}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              ))}
            </Accordion>
          </div>
        </div>
      </Container>
      <br />
      <Footer />
    </>
  );
};

export default FAQs;