import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "./Helpchat.css";
import Contact from "./Contact";

const EmployerSupport = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
  });
  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };

  return (
    <div className="ps-2">
      <div>
        <p style={{ fontWeight: "500", color: "blue" }}>
          Hi, we are here to help you
        </p>
        <p style={{ color: "#ff5c09" }}>Tips :</p>
        <ul style={{ fontSize: "12px" }}>
          <li>Post Job, with all accurate information.</li>
          <li>
            Check application regularly, otherwise your competition may hire
            better candidates.
          </li>
          <li>
            Follow up with applications, contact the chefs directly before they
            join others.
          </li>
          <li>Make your profile 100% updated.</li>
        </ul>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          aria-expanded={openCollapse.open1}
          className="help"
        >
          Jobs
        </p>

        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              You can post job and get responses from relevant chefs to make
              your hiring quicker.
            </p>
            <Link
              to="/employer/post-job"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              Post Job
            </Link>
            <Link
              to="/employer/manage-jobs"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Manage Job
            </Link>
            <Link
              to="/employer/applications"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Applications
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          Chefs
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              <p style={{ fontSize: "12px", fontWeight: "500" }}>
                How to find Chefs?
              </p>
              If your plan is active, you can explore chefs based on your needs.
            </p>
            <Link
              to="/employer/find-chef"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Find Chefs
            </Link>
            <Link
              to="/employer/shortlisted-chefs"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Shortlisted Profiles
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          Plans
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <p style={{ fontSize: "12px" }}>
              Keep your plan active, to get responses from relevant candidates.
              You need to buy a plan to post a job or to view chef profiles.
            </p>
            <Link
              to="/employer/plans"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Buy Plan
            </Link>
            <Link
              to="/employer/plan-history"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Plan History
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          Contact Settings
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Link
              to="/employer/profile"
              style={{
                display: "block",

                fontSize: "14px",
              }}
            >
              Add WhatsApp
            </Link>
            <Link
              to="/employer/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Change Mobile Number
            </Link>
            <Link
              to="/employer/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Change Email
            </Link>
            <Link
              to="/employer/settings"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Contact Privacy
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open5")}
          aria-controls="example-fade-text4"
          aria-expanded={openCollapse.open5}
          className="mt-3 help"
        >
          Profile
        </p>
        <Collapse in={openCollapse.open5}>
          <div id="example-fade-text4">
            <p style={{ fontSize: "12px" }}>
              Update your business profile so that candidates will get more info
              about your business and it will help you for branding too.
            </p>
            <Link
              to="/employer/edit-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              Update Profile
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open6")}
          aria-controls="example-fade-text5"
          aria-expanded={openCollapse.open6}
          className="mt-3 help"
        >
          Contact Us
        </p>
        <Collapse in={openCollapse.open6}>
          <div id="example-fade-text4">
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default EmployerSupport;
