//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import { useNavigate } from "react-router-dom";
import Footer from "../nav_bar/Footer";
import { useDispatch } from "react-redux";
import { forgotpassword } from "../../store/slices/auth";
import { useCallback } from "react";
import login_banner from "../../Assets/Images/Forgot password.png";
import Modal2 from "./Modal2";
import SuccessModal from "./SuccessModal";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function ForgotPassword() {
  const [timer, setTimer] = useState(30);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  React.useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    document.title = "CookandChef | Forgot Password";
  }, []);

  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };

  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
    mobile: "",
  });
  const [count, setCount] = useState(0);
  const [employerId, setemployerId] = useState("");
  const navigate = useNavigate("");
  const [errors, setErrors] = useState({});

  const [resendClicks, setresendClicks] = useState(0);

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setemployerId(data?.data?.id);
  }, [employerId]);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidmobileNumber = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;

    switch (name) {
      case "email":
        setEmail(value);
        validationErrors[name] =
          value === "" || !isValidEmail(value)
            ? "Please enter a valid email"
            : "";
        break;
      case "mobile":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobile(value);
        validationErrors[name] =
          value === "" || !isValidmobileNumber(value)
            ? "Please enter a valid mobile number"
            : "";
        break;
      default:
        break;
    }

    setData({
      ...data,
      [name]: value,
    });

    setErrors(validationErrors);
  };

  async function sendPassword(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (email === "") {
      validationErrors.email = "Please enter email";
    } else if (!isValidEmail(email)) {
      validationErrors.email = "Plese enter a valid email";
    }

    if (mobile === "") {
      validationErrors.mobile = "Please enter mobile number";
    } else if (!isValidmobileNumber(mobile)) {
      validationErrors.mobile = "Please enter a valid mobile number";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    } else {
      dispatch(forgotpassword(data))
        .unwrap()
        .then((data) => {
          setModalMessage(
            "Otp has been sent successfully to your mobile number"
          );
          setsuccessModal(true);
          if (data.user.data.role) {
            navigate("/update-password");
          }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-5"
        >
          <Col
            lg={4}
            className="d-none d-lg-block"
            style={{ background: "white" }}
          >
            <img src={login_banner} alt="" style={{ height: "480px" }} />
          </Col>
          <Col
            lg={4}
            md={12}
            xs={12}
            className="text-center"
            style={{
              backgroundColor: "white",
              height: "480px",
            }}
            align="center"
          >
            <div align="center">
              <p className="h3 text-center mt-5 mb-3">Forgot Password!</p>
              No worries, we will send you OTP
              <br />
              <Form
                noValidate
                autoComplete="off"
                onSubmit={sendPassword}
                className="text-center mt-5 p-3"
                style={{
                  height: "100%",
                  width: "90%",
                  backgroundColor: "",
                }}
              >
                <Form.Group className="mb-4 position-relative text-start">
                  <Form.Control
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                    isInvalid={!!errors.email}
                    className="input1"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 position-relative text-start">
                  <Form.Control
                    name="mobile"
                    onChange={handleChange}
                    value={data.mobile}
                    isInvalid={!!errors.mobile}
                    className="input1"
                    maxLength={10}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter your mobile number"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Button
                    className="mb-2 mt-5 login_btn"
                    disabled={isdisabled}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default ForgotPassword;
