import React from "react";
import { Link } from "react-router-dom";
import fb from "../../../Assets/Images/facebook.png";
import insta from "../../../Assets/Images/instagram.png";
import linkedin from "../../../Assets/Images/linkedin.png";
import koo from "../../../Assets/Images/koo.png";
import youtube from "../../../Assets/Images/youtube.png";
import x from "../../../Assets/Images/x.png";

const DashFooter = () => (
  <>
    {/* <footer className="main-footer font-small black pt-3 mt-5">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12" align="center">
          <span style={{ fontSize: "12px", color: "silver" }}>
            Copyright © 2020-24 By CookandChef India Pvt. Ltd. All Rights
            Reserved.
          </span>
        </div>
      </div>
    </footer> */}
    <footer className="main-footer font-small black pt-3 mt-5">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12" align="center">
          <Link to="https://www.instagram.com/cookandchef.in/" target="_blank">
            <img src={insta} style={{ width: "30px", height: "30px" }} alt="" />
          </Link>

          <Link to="https://www.linkedin.com/company/86097906" target="_blank">
            &nbsp;
            <img
              src={linkedin}
              style={{ width: "26px", height: "26px" }}
              alt=""
            />
          </Link>

          <Link to="https://www.kooapp.com/profile/cookandchef" target="_blank">
            &nbsp;
            <img src={koo} style={{ width: "28px", height: "28px" }} alt="" />
          </Link>

          <Link to="https://www.facebook.com/cookandchef.in/" target="_blank">
            &nbsp;
            <img src={fb} style={{ width: "26px", height: "26px" }} alt="" />
          </Link>

          <Link
            to="https://www.youtube.com/channel/UCRI1TtfWY2P3-88JXQKk2EA"
            target="_blank"
          >
            &nbsp;
            <img
              src={youtube}
              style={{ width: "26px", height: "26px" }}
              alt=""
            />
          </Link>
          <Link to="https://twitter.com/CookandChef_in" target="_blank">
            &nbsp;
            <img src={x} style={{ width: "26px", height: "26px" }} alt="" />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-md-7 col-lg-7">
          <span style={{ fontSize: "12px", color: "silver" }}>
            Copyright © 2020-24 By CookandChef India Pvt. Ltd. All Rights
            Reserved.
          </span>
        </div>
        <div className="col-12 col-md-5 col-lg-5 justify-content-end">
          <Link to="/about" style={{ fontSize: "12px", color: "silver" }}>
            About
          </Link>
          &nbsp; | &nbsp;
          <Link to="/faq" style={{ fontSize: "12px", color: "silver" }}>
            FAQs
          </Link>
          &nbsp; | &nbsp;
          <Link
            to="/terms-and-conditions"
            style={{ fontSize: "12px", color: "silver" }}
          >
            Terms and Conditions
          </Link>
          &nbsp; | &nbsp;
          <Link
            to="/privacy-policy"
            style={{ fontSize: "12px", color: "silver" }}
          >
            Privacy Policy
          </Link>
          &nbsp; | &nbsp;
          <Link
            to="/refund-policy"
            style={{ fontSize: "12px", color: "silver" }}
          >
            Refund Policy
          </Link>
        </div>
      </div>
    </footer>
  </>
);

export default DashFooter;