import React from "react";
import { Button } from "react-bootstrap";

const Contact = () => {
  const phoneNumber = 9178828999;
  const email = "support@cookandchef.in";

  const CallUs = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const WatsappUs = () => {
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };

  const EmailUs = () => {
    const mailtoUrl = `mailto:${email}`;

    window.location.href = mailtoUrl;
  };

  return (
    <div>
      <div className="d-flex pb-3">
        <Button
          className="mx-2"
          style={{
            background: "#1808B1",
            border: "none",
            height: "30px",
            padding: "2px 8px",
          }}
          onClick={CallUs}
        >
          Call
        </Button>
        <Button
          className="mx-2"
          style={{
            background: "#059F14",
            border: "none",
            height: "30px",
            padding: "2px 8px",
          }}
          onClick={WatsappUs}
        >
          WhatsApp
        </Button>
        <Button
          className="mx-2"
          style={{
            background: "#F87F43",
            border: "none",
            height: "30px",
            padding: "2px 8px",
          }}
          onClick={EmailUs}
        >
          Email
        </Button>
      </div>
    </div>
  );
};

export default Contact;
