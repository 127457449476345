import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DataSkeleton from "../../auth_screens/dataskeleton";
import { getcookPlanHistory } from "../../../../store/slices/cookprofile";
import { useDispatch } from "react-redux";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import ReactPaginate from "react-paginate";

function CookPlanHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pagData, setPageData] = useState();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const [sortVal, setSortVal] = useState("");

  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };
  const [data, setData] = useState([]);
  const [dataCount, setdataCount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  React.useEffect(() => {
    dispatch(getcookPlanHistory())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        setdataCount(data.user.dataCount);
        setTotaldata(data.user.dataCount);
        setFilteredData(data.user.data);
        setPageData(data.user.data.slice(0, itemsPerPage));
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Plan History";
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  useEffect(() => {
    handleApplyFilter();
  }, [sortVal]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageData(filteredData.slice(start, end));
  }, [filteredData, currentPage, itemsPerPage]);

  const handleApplyFilter = () => {
    let sortedArray = [...data];
    if (sortVal === "status-all") {
      let sortdata = sortedArray.filter(
        (elem) => elem?.status === 1 || elem?.status === 0
      );
      setFilteredData(sortdata);
    } else if (sortVal === "status-active") {
      let sortdata = sortedArray.filter((elem) => elem?.status === 1);
      setFilteredData(sortdata);
    } else if (sortVal === "status-expired") {
      let sortdata = sortedArray.filter((elem) => elem?.status === 0);
      setFilteredData(sortdata);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setSortVal(value);
    setCurrentPage(1);
    search.set("sortVal", e.target.value);
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    handleApplyFilter();
    setCurrentPage(1);
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;प्लान्स हिस्ट्री
      </div>

      {dataCount === 0 && (
        <Row className="card mt-4 p-5">
          <Col lg={12} xs={12} md={12} align="center">
            <h3>कोई प्लान उपलब्ध नहीं है</h3>
            <br />

            <button
              onClick={() => navigate("/hin/cook/plans")}
              className="saveBtn"
            >
              प्लान खरीदें
            </button>
          </Col>
        </Row>
      )}
      {dataCount !== 0 && (
        <div>
          <Form>
            <Row>
              <Col lg={12} sm={12} xs={12} md={12}>
                <Row className="justify-content-end">
                  <Col lg={2} sm={4} md={3} xs={3} align="right">
                    <label
                      style={{
                        height: "40px",
                        lineHeight: "40px",

                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Filter by:
                    </label>
                  </Col>
                  <Col lg={3} sm={7} md={4} xs={7}>
                    <Form.Group className="mb-1">
                      <Form.Control
                        as="select"
                        style={{
                          width: "180px",
                        }}
                        name="experience"
                        value={sortVal}
                        onChange={handleChange}
                      >
                        <option value="status-all">All</option>
                        <option value="status-active">Active</option>
                        <option value="status-expired">Expired</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <div className="mt-4 card table-responsive">
            {isLoading ? (
              <Row className="card p-2 table-responsive">{DataSkeleton()}</Row>
            ) : (
              <Row className="card p-2 table-responsive">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th className="text-left">Date of Purchase</th>
                      <th className="text-left">Plan Name</th>
                      <th className="text-center">Plan Price</th>

                      <th className="text-center">Expiry</th>
                      <th className="text-center">Validity</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagData?.map((transactionVal, index) => (
                      <tr>
                        <td className="text-left">
                          {Moment.utc(
                            transactionVal?.transactionStartDateTime
                          ).format("DD-MM-YYYY")}
                        </td>
                        <td className="text-left">
                          {transactionVal?.planName}
                        </td>

                        <td className="text-center">{transactionVal?.price}</td>

                        <td className="text-center">
                          {Moment.utc(
                            transactionVal?.transactionEndDateTime
                          ).format("DD-MM-YYYY")}
                        </td>

                        <td className="text-center">
                          {transactionVal?.cookPlanId?.validityInDays}
                        </td>
                        <td className="text-center">
                          {transactionVal?.status === 1 && (
                            <span
                              style={{
                                backgroundColor: "rgb(201, 240, 175)",
                                borderRadius: "5px",
                                padding: "4px 12px",
                              }}
                            >
                              Active
                            </span>
                          )}
                          {transactionVal?.status === 0 && (
                            <span
                              style={{
                                backgroundColor: "#e9ebf0",
                                borderRadius: "5px",
                                padding: "4px 10px",
                              }}
                            >
                              Expired
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {transactionVal?.invoiceUrl && (
                            <Link
                              to={transactionVal?.invoiceUrl}
                              className="downloadBtn"
                            >
                              Download
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {filteredData?.length / itemsPerPage > 1 && (
                  <div
                    className="justify-content-right align-right"
                    align="right"
                  >
                    <ReactPaginate
                      key={currentPage}
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakLinkClassName={"page-link"}
                      pageCount={Math.ceil(filteredData?.length / itemsPerPage)}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={pagesToShowInitially}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      disabledClassName="disabled"
                      initialPage={currentPage - 1}
                      renderLastPage={() => null}
                    />
                  </div>
                )}
              </Row>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CookPlanHistory;
