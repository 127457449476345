import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Image, Offcanvas } from "react-bootstrap";
import { CgMenuLeftAlt } from "react-icons/cg";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { BsCardChecklist } from "react-icons/bs";
import logo from "../../../Assets/Images/nav_logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { SlBookOpen } from "react-icons/sl";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { MdOutlineEventAvailable } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { FaRegBookmark } from "react-icons/fa";
import { API_PATHS } from "../../../utils/constants/api.constants";

export default function ClientMobNavbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  const isActive = (path) => window.location.pathname === path;
  const weavyId = localStorage.getItem("weavyId");
  const [data, setData] = useState();
  const data1 = JSON.parse(localStorage.getItem("user"));
  const [weavyToken, setWeavyToken] = useState();
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  useEffect(() => {
    setData(data1?.data);
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  return (
    <>
      <CgMenuLeftAlt
        style={{ fontSize: "4dvh", cursor: "pointer", margin: "14px" }}
        variant="primary"
        onClick={handleShow}
        className="sticky-top d-sm-block d-lg-none"
      />
      <Offcanvas show={show} onHide={handleClose} backdrop={false}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarMenu className="d-flex justify-content-center flex-column align-items-center">
            <SidebarMenu.Brand>
              <Image
                className="text-center my-4 img-fluid"
                src={logo}
                style={{ height: "70px" }}
              />
            </SidebarMenu.Brand>

            <SidebarMenu.Collapse className="">
              <SidebarMenu.Body onClick={() => setShow(!show)}>
                <SidebarMenu.Nav
                  className="mb-3"
                  onClick={() => navigate("/hin/client/dashboard")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/dashboard") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoHomeOutline className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>डैशबोर्ड</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/profile")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/profile") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegUser className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>प्रोफ़ाइल</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/requirement")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/requirement") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <SlBookOpen className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>रीक्वारेमेंट</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/find-house-cook")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/find-house-cook")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbChefHat className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      घर के लिए कुक खोजें
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/find-party-cook")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/find-party-cook")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbChefHat className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      पार्टी कुक खोजें
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/find-catering")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/find-catering") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbChefHat className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>कैटरिंग खोजें</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/plans")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/plans") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbCoinRupee className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>प्लान खरीदें</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/plan-history")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/plan-history") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbCoinRupee className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      प्लान्स हिस्ट्री
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/requirement-request")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/requirement-request")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <BsCardChecklist />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      होउसेकूक आवेदन
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/event-requests")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/event-requests")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <BsCardChecklist />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      इवेंट्स इन्तेरेस्ट्स
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/events")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/events") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <MdOutlineEventAvailable className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      इवेंट प्रबंधित करें
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/post-event")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/post-event") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <MdOutlineEventAvailable className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      इवेंट पोस्ट करे
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/settings")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/settings") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoSettingsOutline className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>सेटिंग</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/short-listed-profiles")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/short-listed-profiles")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="pe-2">
                      <FaRegBookmark
                        style={{ height: "20px", width: "20px" }}
                        className="navIcon"
                      />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      शॉर्टलिस्टेड प्रोफाइल
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/client/shared-profiles")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/shared-profiles")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <MdSupportAgent className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      सहायक द्वारा साझा
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/hin/client/message")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/client/message") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoChatboxEllipsesOutline className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      मैसेज
                      {messagesNo.count > 0 && (
                        <div
                          style={{
                            backgroundColor: "#ff5c09",
                            height: "6px",
                            width: "6px",
                            marginTop: "-13px",
                            marginLeft: "110px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      )}
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              </SidebarMenu.Body>
            </SidebarMenu.Collapse>
          </SidebarMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
