import React, { useState } from "react";
import { Modal } from "react-bootstrap";
// ... other imports

function ResumeTemplate({
  showModal,
  image,
  setShowModal,
  data,
  formSelected,
  setFormSelected,
  setTemplateId,
}) {
  // ... existing code

  const handleCloseModal = () => setShowModal(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const SelectTemplate = (elem) => {
    setSelectedTemplate(elem.id);

    // Add to selected templates
    setTimeout(() => {
      setFormSelected(true);
    }, 100);
    setTemplateId(elem.id);
  };
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginLeft: "5%" }}
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body align="center">
          <img src={image} alt="" style={{ height: "400px", width: "300px" }} />

          <div className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className="cancelBtn"
              onClick={handleCloseModal}
            >
              Close
            </button>

            <button
              type="button"
              style={{ width: "150px", marginLeft: "10px", height: "40px" }}
              className="modalsaveBtn"
              onClick={() => SelectTemplate(data)}
            >
              Select Template
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResumeTemplate;
