import React from "react";
import { Col, Row } from "react-bootstrap";
import ClientSideMenu from "./ClientSideMenu";
import { Route, Routes } from "react-router-dom";
import ClientDashHome from "./Pages/ClientDashHome";
import ClientProfile from "./Pages/ClientProfile";
import EditClientProfile from "./Pages/EditClientProfile";
import PostEvent from "./Pages/PostEvent";
import ClientPlans from "./Pages/ClientPlans";
import ClientNavBar from "./ClientNavBar";
import ClientRequirement from "./Pages/clientrequirement";
import EditEvent from "./Pages/editEvent";
import ClientCheckout from "./Pages/clientCheckout";
import DashFooter from "../nav_bar/dashboardfooter";
import EditRequirement from "./Pages/EditRequirement";
import Events from "./Pages/Events";
import FindCatering from "./Pages/FindCatering";
import FindHouseCook from "./Pages/FindHouseCook";
import FindPartyCook from "./Pages/FindpartyCook";
import ClientChangepassword from "./Pages/ClientChangePassword";
import ClientContactPrivacy from "./Pages/ClientPrivacyContact";
import ClientSettings from "./Pages/ClientSettings";
import CateringProfile from "./Pages/CateringProfile";
import HouseProfile from "./Pages/HouseProfile";
import PartyProfile from "./Pages/PartyProfile";
import EventDetails from "./Pages/Eventdetails";
import GetEventRequest from "./Pages/GetEventRequest";
import ClientPlanHistory from "./Pages/ClientPlanHistory";
import EventRequestDetails from "./Pages/eventrequestdetails";
import RequirementRequestDetails from "./Pages/requirementinterestdetails";
import GetRequirementRequest from "./Pages/RequirementInterests";
import ChatSupport from "../cook_dashboard/pages/ChatSupport";
import ChatBot from "../../utils/helpers/ChatBot/HelpChatSupport";
import GetSharedProfiles from "./Pages/GetSharedProfiles";
import SharedProfileDetails from "./Pages/SharedProfileDetails";
import Shortlistedprofiles from "./Pages/ShortlistedProfiles";
import ShortlistedHouseProfile from "./Pages/shortlisted-house-profile";
import ShortlistedPartyProfile from "./Pages/shortlisted-party-profile";
import ShortlistedCateringProfile from "./Pages/shortlisted-catering-profile";
import EmployerErrorPage from "../employer/404";
import ClientResponse from "./Pages/Responses";

function ClientDashBoard() {
  return (
    <>
      <ClientNavBar />

      <Row className="d-flex justify-content-center">
        <Col lg={2} md={3} xl={2} xxl={2} className="d-none d-lg-block dashbar">
          <ClientSideMenu />
        </Col>
        <Col xs={11} md={9} lg={7} className="mx-3 mb-5">
          <Routes>
            <Route path="/dashboard" element={<ClientDashHome />} />
            <Route path="/profile" element={<ClientProfile />} />
            <Route path="/edit-profile" element={<EditClientProfile />} />
            <Route path="/post-event" element={<PostEvent />} />
            <Route path="/edit-event/:id" element={<EditEvent />} />
            <Route path="/event-details/:id" element={<EventDetails />} />
            <Route path="/catering-details/:id" element={<CateringProfile />} />
            <Route
              path="/shortlisted-catering-details/:id"
              element={<ShortlistedCateringProfile />}
            />
            <Route path="/house-cook-details/:id" element={<HouseProfile />} />
            <Route
              path="/shortlisted-house-cook-details/:id"
              element={<ShortlistedHouseProfile />}
            />
            <Route path="/party-cook-details/:id" element={<PartyProfile />} />
            <Route
              path="/shortlisted-party-cook-details/:id"
              element={<ShortlistedPartyProfile />}
            />
            <Route path="/checkout/:id" element={<ClientCheckout />} />
            <Route path="/find-catering" element={<FindCatering />} />
            <Route path="/find-house-cook" element={<FindHouseCook />} />
            <Route path="/find-party-cook" element={<FindPartyCook />} />
            <Route path="/change-password" element={<ClientChangepassword />} />
            <Route path="/plans" element={<ClientPlans />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event-requests" element={<GetEventRequest />} />
            <Route path="/shared-profiles" element={<GetSharedProfiles />} />
            <Route
              path="/short-listed-profiles"
              element={<Shortlistedprofiles />}
            />
            <Route
              path="/shared-profile-details/:id"
              element={<SharedProfileDetails />}
            />
            <Route
              path="/requirement-request"
              element={<GetRequirementRequest />}
            />
            <Route
              path="/event-request-details/:id"
              element={<EventRequestDetails />}
            />
            <Route
              path="/requirement-request-details/:id"
              element={<RequirementRequestDetails />}
            />
             <Route path="/response" element={<ClientResponse />} />
            <Route path="/edit-requirement" element={<EditRequirement />} />
            <Route path="/requirement" element={<ClientRequirement />} />
            <Route path="/contact-privacy" element={<ClientContactPrivacy />} />
            <Route path="/settings" element={<ClientSettings />} />
            <Route path="/message" element={<ChatSupport />} />
            <Route path="/plan-history" element={<ClientPlanHistory />} />
            <Route path="*" element={<EmployerErrorPage />} />
          </Routes>
        </Col>
      </Row>
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: 30, // Adjust the distance from the bottom as needed
          right: 30, // Adjust the distance from the right as needed
        }}
      >
        <ChatBot />
      </div>
      <DashFooter />
    </>
  );
}

export default ClientDashBoard;
