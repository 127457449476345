import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Image, Offcanvas } from "react-bootstrap";
import { CgMenuLeftAlt } from "react-icons/cg";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { BsCardChecklist } from "react-icons/bs";
import logo from "../../../Assets/Images/nav_logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { IoSearchSharp } from "react-icons/io5";
import { LuCalendarSearch } from "react-icons/lu";
import { TbHomeSearch } from "react-icons/tb";
import { SiTinyletter } from "react-icons/si";
import { MdOutlineEventAvailable } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { API_PATHS } from "../../../utils/constants/api.constants";
import { getcookProfile } from "../../../store/slices/cookprofile";

export default function CookMobNavbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [expand, setExpand] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const data1 = JSON.parse(localStorage.getItem("user"));
  const userData = useSelector((state) => state?.cookData?.user);
  const weavyId = localStorage.getItem("weavyId");
  const [weavyToken, setWeavyToken] = useState();
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {}
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const isActive = (path) => window.location.pathname === path;
  return (
    <>
      <CgMenuLeftAlt
        style={{ fontSize: "4dvh", cursor: "pointer", margin: "14px" }}
        variant="primary"
        onClick={handleShow}
        className="sticky-top d-sm-block d-lg-none"
      />

      <Offcanvas show={show} onHide={handleClose} backdrop={false}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarMenu className="d-flex justify-content-center flex-column align-items-center">
            <SidebarMenu.Brand>
              <Image
                className="text-center img-fluid"
                src={logo}
                style={{ height: "70px" }}
              />
            </SidebarMenu.Brand>

            <SidebarMenu.Collapse>
              <SidebarMenu.Body onClick={() => setShow(!show)}>
                <SidebarMenu.Nav
                  className="mb-3"
                  onClick={() => navigate("/hin/cook/dashboard")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/dashboard") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoHomeOutline />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/profile")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/profile") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegUser />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Profile</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/cook-profile")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/cook-profile") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbChefHat />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>कुक प्रोफ़ाइल</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                {data?.cookType === 2 && (
                  <SidebarMenu.Nav className="mb-3 menu_body_menu">
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/plans") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <TbCoinRupee />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>Buy Plans</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.cookType === 2 && (
                  <SidebarMenu.Nav className="mb-3 menu_body_menu">
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/plan-history") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <TbCoinRupee />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>My Plans</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.cookType === 2 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/jobs")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/jobs") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <IoSearchSharp />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>नौकरी ढूंढे</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.cookType === 1 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/housejobs")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/housejobs") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <IoSearchSharp />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        Find House Job
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.partyCook === 1 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/find-events")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/find-events") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <LuCalendarSearch />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>ईवेंट खोजें</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}
                {data?.memberType === 2 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/find-events")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/find-events") ? "active-link" : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <LuCalendarSearch />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>ईवेंट खोजें</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.cookType === 2 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/resume-builder")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/resume-builder")
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <BsCardChecklist />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        Resume Builder
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}
                {data?.cookType === 1 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/short-listed-housejobs")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/short-listed-housejobs")
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <FaRegBookmark />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        नौकरियाँ बचाई गईं
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.cookType === 2 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/short-listed-jobs")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/short-listed-jobs")
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <FaRegBookmark />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        नौकरियाँ बचाई गईं
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.partyCook === 1 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/short-listed-events")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/short-listed-events")
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <FaRegBookmark />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        सहेजे गए इवेंट
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                {data?.memberType === 2 && (
                  <SidebarMenu.Nav
                    className="mb-3 menu_body_menu"
                    onClick={() => navigate("/hin/cook/short-listed-events")}
                  >
                    <SidebarMenu.Nav.Link
                      className={`${
                        isActive("/hin/cook/short-listed-events")
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <SidebarMenu.Nav.Icon className="px-2">
                        <FaRegBookmark />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        सहेजे गए इवेंट
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                )}

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/settings")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/settings") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoSettingsOutline />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Settings</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/message")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/message") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoChatboxEllipsesOutline />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      Message
                      {messagesNo.count > 0 && (
                        <div
                          style={{
                            backgroundColor: "#ff5c09",
                            height: "6px",
                            width: "6px",
                            marginTop: "-13px",
                            marginLeft: "110px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      )}
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              </SidebarMenu.Body>
            </SidebarMenu.Collapse>
          </SidebarMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
