import axios from "axios";
import {
  API_PATHS,
} from "../utils/constants/api.constants";

class ClientPlanService {
  static getPlans() {
    let api = "";
    api = API_PATHS.getclientplans;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


//initiate payments service
  static initiatepayments(item) {
    let api = "";
    api = API_PATHS.clientinitiatepayments;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api,item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getclientshortPlans() {
    let api = "";
    api = API_PATHS.getcookShortplan;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getclientLongPlans() {
    let api = "";
    api = API_PATHS.getcookLongplan;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default ClientPlanService;
