import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class AuthService {
  static cooklogin(item) {
    return axios.post(API_PATHS.cookLogin, item).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static cookregister(item) {
    return axios.post(API_PATHS.cookRegister, item).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static cookotpVerification({ cookId, otp }) {
    return axios
      .post(API_PATHS.cookverifyOtp, {
        cookId,
        otp,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  //employer signup change mobile
  static employersignupChangeMobile(item) {
    return axios
      .put(API_PATHS.employersignupchangemobile, item)
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  // cook signup change mobile
  static cooksignupChangeMobile({ cookId, mobile }) {
    return axios
      .put(API_PATHS.cooksignupchangemobile, {
        cookId,
        mobile,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  // resend otp
  static resendotp({ cookId }) {
    return axios
      .post(API_PATHS.resendotp, {
        cookId,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static employeResendotp({ employerId }) {
    return axios
      .post(API_PATHS.employerResendotp, {
        employerId,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  // forgotpassword function
  static forgotPassword(item) {
    return axios
      .post(API_PATHS.forgotpassword, {
        email: item.email,
        mobile: item.mobile,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  //for updating password
  static updatePassword(item) {
    return axios
      .post(API_PATHS.updatepassword, {
        mobile: item.mobile,
        otp: item.otp,
        newPassword: item.newPassword,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static employerlogin(item) {
    return axios.post(API_PATHS.employerLogin, item).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static employerregister(item) {
    return axios.post(API_PATHS.employerRegister, item).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static employerotpVerification({ employerId, otp }) {
    return axios
      .post(API_PATHS.employerVerifyOtp, {
        employerId,
        otp,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  // for verify email
  static verifyEmail() {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.verifyemail, {}, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  // for verify client email
  static clientVerifyEmail() {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.clientverifyemail, {}, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //for changing password
  static changePassword(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
     
    };
    return axios
      .post(API_PATHS.changepassword,item,config)
      .then((response) => {
        if (response.data) {
         
        }
        return response.data;
      });
  }


  //for contact privacy 
  static Contactprivay(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
     
    };
    return axios
      .post(API_PATHS.contactprivacy,item,config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  // for verify cook email
  static cookVerifyEmail() {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.cookverifyemail, {}, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  // for employer change email
  static employerChangeEmail(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.changeEmail, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  // for client change email
  static clientChangeEmail(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.clientchangeEmail, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  // for cook change email
  static cookChangeEmail(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .post(API_PATHS.cookchangeEmail, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //changemobile  from clinet side
  static clientChangeMobile(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.changemobile, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //changemobile  from cook side
  static cookChangeMobile(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.cookchangemobile, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //client change watsapp number
  static clientChangeWatsapp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.changewatsapp, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //cook change watsapp number
  static cookChangeWatsapp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.cookchangeWhatsap, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  
  //cook change watsapp number
  static cookCheckWatsapp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios.post(API_PATHS.cookcheckwatsappnumber, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  static clientCheckWatsapp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios.post(API_PATHS.clientcheckwatsapp, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //verify  change mobile otp for client
  static clientVerifyMobileOtp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.verifyotpchangemobile, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //verify change mobile otp for cook
  static cookVerifyMobileOtp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.cookverifymobileotp, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

  //verify opt for client change whatsapp
  static clientVerifyWatsappOtp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.verifywatsappotp, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }

    //get website cooks list 
    static getWebsiteCookslist(item) {
      let api = "";
      api = API_PATHS.getwebsiteCookslisthome  + `?cook=${item.cook}&limit=${item.limit}&page=${item.page}` ;
     
      return axios
        .get(api)
        .then((response) => {
          if (response.data) {
          }
          return response.data;
        })
        .catch((error) => {
          console.error("Error:", error);
          throw error;
        });
    }

  //verify opt for cook change whatsapp
  static cookVerifyWatsappOtp(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .put(API_PATHS.cookverifywatsappotp, item, config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  }
  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default AuthService;
