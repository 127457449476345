import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.data?.mobileVerified) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const user1 = JSON.parse(localStorage.getItem("user"));

  const pathname = location.pathname; // Extract pathname from URL
  const role = localStorage.getItem("role");
  localStorage.setItem("applicationUrl", pathname);
  if (auth && location.pathname === "/login") {
    if (user1?.data?.role === "cook") {
      return <Navigate to="/cook/dashboard" />;
    } else if (user1?.data?.role === "client") {
      return <Navigate to="/client/dashboard" />;
    } else {
      return <Navigate to="/employer/dashboard" />;
    }
  }
  if (
    !auth &&
    pathname &&
    (pathname?.includes("/employer") || pathname?.includes("/client"))
  ) {
    return <Navigate to="/employer-login" />;
  } else if (!auth && pathname && pathname?.includes("/cook")) {
    return <Navigate to="/login" />;
  } else if (!auth && role === "employer") {
    return <Navigate to="/employer-login" />;
  } else if (!auth && role === "cook") {
    return <Navigate to="/login" />;
  }
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
