import React, { useState, useCallback } from "react";
import { Button, Col, Form, Offcanvas, Overlay, Row } from "react-bootstrap";
import { BsBookmark, BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaBookmark } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import Moment from "moment";
import {
  getemployerProfile,
  removeshortlistchef,
  shortlistchef,
  viewshortlistedchefs,
} from "../../../store/slices/employerprofile";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";
import { Link } from "react-router-dom";
import chef from "../../../Assets/Images/cook.png";

function Shortlistedprofiles() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [cookType, setCookType] = useState("housecook");
  const [activeTab, setActiveTab] = useState("houseCook");
  const myData = useSelector((state) => state.clientData.housecookData);
  const [myDatas, setMyDatas] = useState([]);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
    cookType: cookType,
  });

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      limit: itemsPerPage,
      page: newPage,
      cookType: cookType,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    getchefs(item);
  }, [item]);

  useEffect(() => {
    document.title = "CookandChef | Shortlisted Profiles";
  }, []);

  useEffect(() => {
    setCookType("housecook");
    setActiveTab("housecook");
  }, []);

  const getchefs = (item) => {
    setisLoading(false);
    dispatch(viewshortlistedchefs(item))
      .unwrap()
      .then((data) => {
        setData(data?.shortlistedchef?.data);
        setTotaldata(data?.shortlistedchef?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setTimeout(() => {
          setisLoading(true);
        }, 500);
      });
  };

  const removeshortList = (item1, name) => {
    setIsRespTrue(false);

    var elem = {
      cookId: item1._id,
      cookType: name,
    };

    dispatch(removeshortlistchef(elem))
      .then((data) => {
        if (data?.payload?.message) {
          //setModalMessage(data?.payload?.message);
          //setShowModal(true);
          getchefs();
        }
      })
      .catch(({ message }) => {
        //setModalMessage(message);
        //setShowModal(true);
      })
      .finally(() => {
        getchefs(item);
      });
  };

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data1) => {
        setMyDatas(data1.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const handleTabChange = (tab) => {
    setCookType(tab);

    setItem({
      limit: itemsPerPage,
      page: 1,
      cookType: tab,
    });
    setCurrentPage(1);
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    setActiveTab(tab);
  };
  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/client/dashboard" className="bcrumb">
            Home
          </Link>
          &nbsp;&bull; Shortlisted Profiles
        </div>
        <Row className="mt-4">
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange}
            className="p-3 my-2"
          >
            <Tab eventKey="housecook" title="House Cook">
              {totaldata === 0 ? (
                <Col
                  lg={10}
                  xs={12}
                  md={10}
                  className="card p-5"
                  align="center"
                >
                  <h3>You have not shortlisted any cook!</h3>
                </Col>
              ) : isLoading ? (
                <div>
                  <Col lg={12} sm={12} xs={12}>
                    <Row>
                      {data?.map((item, index) => (
                        <Col lg={8} sm={12} xs={12}>
                          <Link
                            to={`/client/shortlisted-house-cook-details/${item?._id}`}
                            style={{ color: "#000" }}
                          >
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              key={index}
                              style={{
                                backgroundColor: "white",

                                borderRadius: "15px",
                                padding: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <Col
                                lg={2}
                                xs={3}
                                className="justify-content-center"
                              >
                                {item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={item?.dp}
                                    alt="user profile"
                                  />
                                )}
                                {!item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={chef}
                                    alt="user profile"
                                  />
                                )}
                              </Col>
                              <Col lg={9} xs={7}>
                                <h6
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "0.2rem",
                                  }}
                                  className="ms-2"
                                >
                                  {item?.fullName}
                                </h6>

                                {item?.householdCuisines?.length >= 3 ? (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.householdCuisines
                                      ?.join(", ")
                                      .substring(0, 20)}
                                    ...
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.householdCuisines?.join(", ")}
                                  </p>
                                )}
                                <p
                                  style={{
                                    marginBottom: "0.25rem",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                >
                                  <GrLocation style={{ marginBottom: "2px" }} />
                                  &nbsp;
                                  {item?.area}
                                  <MdCurrencyRupee
                                    style={{ marginBottom: "2px" }}
                                    className="ms-5"
                                  />
                                  {item?.payment}
                                </p>
                              </Col>
                              <Col
                                lg={1}
                                xs={2}
                                md={2}
                                align="right"
                                style={{ marginTop: "-70px" }}
                              >
                                {item?.shortlistId && isRespTrue && (
                                  <Link
                                    onClick={() =>
                                      removeshortList(item, "housecook")
                                    }
                                    style={{ color: "#000" }}
                                  >
                                    <FaBookmark />
                                  </Link>
                                )}
                                {item?.shortlistId && isRespTrue === false && (
                                  <Link style={{ color: "#000" }}>
                                    <FaBookmark />
                                  </Link>
                                )}
                              </Col>
                              <Col
                                lg={3}
                                xs={3}
                                className="justify-content-center"
                              >
                                <div
                                  className="text-left m-2"
                                  style={{ fontSize: "10px", color: "grey" }}
                                >
                                  <FaRegClock style={{ marginTop: "-2px" }} />
                                  &nbsp;
                                  {Moment.utc(item?.lastLoginDateTime)
                                    .local("Asia/Kolkata")
                                    .startOf("seconds")
                                    .fromNow()}
                                </div>
                              </Col>
                              <Col lg={9} xs={9}>
                                <div align="right">
                                  {item?.isViewed === true && (
                                    <CiUnlock style={{ fontSize: "20px" }} />
                                  )}
                                  {item?.isViewed === false && (
                                    <CiLock style={{ fontSize: "20px" }} />
                                  )}
                                  &nbsp;
                                  <Button className="view_profile ms-5">
                                    View
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </div>
              ) : (
                <Col lg={8} xs={12} md={12} align="center">
                  {ShowSkeleton()}
                </Col>
              )}
            </Tab>
            <Tab eventKey="partycook" title="Party Cook">
              {totaldata === 0 ? (
                <Col
                  lg={10}
                  xs={12}
                  md={10}
                  className="card p-5"
                  align="center"
                >
                  <h3>You have not shortlisted any cook!</h3>
                </Col>
              ) : isLoading ? (
                <div>
                  <Col lg={12} sm={12} xs={12}>
                    <Row>
                      {data?.map((item, index) => (
                        <Col lg={8} sm={12} xs={12}>
                          <Link
                            to={`/client/shortlisted-party-cook-details/${item?._id}`}
                            style={{ color: "#000" }}
                          >
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              key={index}
                              style={{
                                backgroundColor: "white",

                                borderRadius: "15px",
                                padding: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <Col
                                lg={2}
                                xs={3}
                                className="justify-content-center"
                              >
                                {item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={item?.dp}
                                    alt="user profile"
                                  />
                                )}
                                {!item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={chef}
                                    alt="user profile"
                                  />
                                )}
                              </Col>
                              <Col lg={9} xs={7}>
                                <h6
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "0.2rem",
                                  }}
                                  className="ms-2"
                                >
                                  {item?.fullName}
                                </h6>

                                {item?.partyCuisines?.length >= 3 ? (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.partyCuisines
                                      ?.join(", ")
                                      .substring(0, 20)}
                                    ...
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.partyCuisines?.join(", ")}
                                  </p>
                                )}
                                <p
                                  style={{
                                    marginBottom: "0.25rem",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                >
                                  <GrLocation style={{ marginBottom: "2px" }} />
                                  &nbsp;
                                  {item?.cityName}
                                </p>
                              </Col>
                              <Col
                                lg={1}
                                xs={2}
                                md={2}
                                align="right"
                                style={{ marginTop: "-70px" }}
                              >
                                {item?.shortlistId && (
                                  <Link
                                    onClick={() =>
                                      removeshortList(item, "partycook")
                                    }
                                    style={{ color: "#000" }}
                                  >
                                    <FaBookmark />
                                  </Link>
                                )}
                              </Col>

                              <Col
                                lg={3}
                                xs={3}
                                className="justify-content-center"
                              >
                                <div
                                  className="text-left m-2"
                                  style={{ fontSize: "10px", color: "grey" }}
                                >
                                  <FaRegClock style={{ marginTop: "-2px" }} />
                                  &nbsp;
                                  {Moment.utc(item?.lastLoginDateTime)
                                    .local("Asia/Kolkata")
                                    .startOf("seconds")
                                    .fromNow()}
                                </div>
                              </Col>
                              <Col lg={9} xs={9}>
                                <div align="right">
                                  {item?.isViewed === true && (
                                    <CiUnlock style={{ fontSize: "20px" }} />
                                  )}
                                  {item?.isViewed === false && (
                                    <CiLock style={{ fontSize: "20px" }} />
                                  )}
                                  &nbsp;
                                  <Button className="view_profile ms-5">
                                    View
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </div>
              ) : (
                <Col lg={8} xs={12} md={12} align="center">
                  {ShowSkeleton()}
                </Col>
              )}
            </Tab>
            <Tab eventKey="catering" title="Catering">
              {totaldata === 0 ? (
                <Col
                  lg={10}
                  xs={12}
                  md={10}
                  className="card p-5"
                  align="center"
                >
                  <h3>You have not shortlisted any cook!</h3>
                </Col>
              ) : isLoading ? (
                <div>
                  <Col lg={12} sm={12} xs={12}>
                    <Row>
                      {data?.map((item, index) => (
                        <Col lg={8} sm={12} xs={12}>
                          <Link
                            to={`/client/shortlisted-catering-details/${item?._id}`}
                            style={{ color: "#000" }}
                          >
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              key={index}
                              style={{
                                backgroundColor: "white",

                                borderRadius: "15px",
                                padding: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <Col
                                lg={2}
                                xs={3}
                                className="justify-content-center"
                              >
                                {item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={item?.dp}
                                    alt="user profile"
                                  />
                                )}
                                {!item?.dp && (
                                  <img
                                    className="align-self-center"
                                    style={{
                                      borderRadius: "10px",
                                      height: "80px",
                                      width: "80px",
                                    }}
                                    src={chef}
                                    alt="user profile"
                                  />
                                )}
                              </Col>
                              <Col lg={9} xs={7}>
                                <h6
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "0.2rem",
                                  }}
                                  className="ms-2"
                                >
                                  {item?.fullName}
                                </h6>

                                {item?.cateringCuisines?.length >= 3 ? (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.cateringCuisines
                                      ?.join(", ")
                                      .substring(0, 20)}
                                    ...
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      marginBottom: "0.25rem",
                                      fontSize: "14px",
                                    }}
                                    className="ms-2"
                                  >
                                    <MdOutlineFoodBank
                                      style={{ marginBottom: "2px" }}
                                    />
                                    &nbsp;
                                    {item?.cateringCuisines?.join(", ")}
                                  </p>
                                )}
                                <p
                                  style={{
                                    marginBottom: "0.25rem",
                                    fontSize: "14px",
                                  }}
                                  className="ms-2"
                                >
                                  <GrLocation style={{ marginBottom: "2px" }} />
                                  &nbsp;
                                  {item?.cityName}
                                </p>
                              </Col>
                              <Col
                                lg={1}
                                xs={2}
                                md={2}
                                align="right"
                                style={{ marginTop: "-70px" }}
                              >
                                {item?.shortlistId && (
                                  <Link
                                    onClick={() =>
                                      removeshortList(item, "catering")
                                    }
                                    style={{ color: "#000" }}
                                  >
                                    <FaBookmark />
                                  </Link>
                                )}
                              </Col>

                              <Col
                                lg={3}
                                xs={3}
                                className="justify-content-center"
                              >
                                <div
                                  className="text-left m-2"
                                  style={{ fontSize: "10px", color: "grey" }}
                                >
                                  <FaRegClock style={{ marginTop: "-2px" }} />
                                  &nbsp;
                                  {Moment.utc(item?.lastLoginDateTime)
                                    .local("Asia/Kolkata")
                                    .startOf("seconds")
                                    .fromNow()}
                                </div>
                              </Col>
                              <Col lg={9} xs={9}>
                                <div align="right">
                                  {item?.isViewed === true && (
                                    <CiUnlock style={{ fontSize: "20px" }} />
                                  )}
                                  {item?.isViewed === false && (
                                    <CiLock style={{ fontSize: "20px" }} />
                                  )}
                                  &nbsp;
                                  <Button className="view_profile ms-5">
                                    View
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </div>
              ) : (
                <Col lg={8} xs={12} md={12} align="center">
                  {ShowSkeleton()}
                </Col>
              )}
            </Tab>
          </Tabs>
        </Row>
        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Shortlistedprofiles;
