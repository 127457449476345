import React, { useEffect, useState } from "react";
import { Col, Row, Form, Nav, Image, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
import backimg from "../../Assets/Images/client-signup.jpg";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { employerregister } from "../../store/slices/auth";
import Modal2 from "./Modal2";
import SuccessModal from "./SuccessModal";
import { Helmet } from "react-helmet";
import Fingerprint2 from "fingerprintjs2";

function EmployerSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [genderVal, setGenderVal] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [fullNameEmployer, setFullNameEmployer] = useState("");
  const [emailEmployer, setEmailEmployer] = useState("");
  const [passwordEmployer, setPasswordEmployer] = useState("");
  const [confirmPasswordEmployer, setconfirmPasswordEmployer] = useState("");
  const [mobileNumberEmployer, setMobileNumberEmployer] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [key, setKey] = useState("");
  const [showModal, setShowModal] = useState(true);
  const handleCloseModal = () => setShowModal(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [mac, setMac] = useState();
  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

      setMac(fingerprint);
    });
  }, []);

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isValidName = (fullName) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9_ ']{2,}$/.test(fullName);
  };

  const isValidemployerName = (fullNameEmployer) => {
    return /^[a-zA-Z0-9@&'’ -]{2,}$/.test(fullNameEmployer);
  };

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  useEffect(() => {
    const initialTab = 1;
    setKey(initialTab === 1 ? "client" : "employer");
  }, []);

  //dhhdhdhdh
  const setkeyClient = (value) => {
    setKey(value);
    handleCloseModal();
  };

  const setKeyEmployer = (value) => {
    setKey(value);
    handleCloseModal();
  };

  async function clientSubmit(e) {
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    e.preventDefault();
    const validationErrors = {};
    if (!fullName.trim()) {
      validationErrors.fullName = "Please enter your name";
    } else if (!isValidName(fullName)) {
      validationErrors.fullName = "Please enter a valid name";
    }
    if (!email) {
      validationErrors.email = "Please enter your email";
    } else if (!isValidEmail(email)) {
      validationErrors.email = "Please enter a valid email";
    }
    if (!mobileNumber.trim()) {
      validationErrors.mobileNumber = "Please enter your mobile number";
    } else if (!isValidmobile(mobileNumber)) {
      validationErrors.mobileNumber = "Please enter a valid mobile number";
    }
    if (!password.trim()) {
      validationErrors.password = "Please enter password";
    } else if (!isValidPassword(password)) {
      validationErrors.password =
        "Password must be alphanumeric and min 8 characters";
    }

    if (!confirmPassword.trim()) {
      validationErrors.confirmPassword = "Please enter confirm password";
    } else if (!isValidPassword(confirmPassword)) {
      validationErrors.confirmPassword =
        "Password must be alphanumeric and min 8 characters";
    } else if (password != confirmPassword) {
      validationErrors.confirmPassword =
        "Confirm password should be same as password";
    }
    if (!genderVal) {
      validationErrors.genderVal = "Please select your gender";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let registerIP = "0.0.0.0";
    let memberType = 1;
    let registerMAC = mac;
    let webAccess = 1;

    let mobile = parseInt(mobileNumber);
    let gender = parseInt(genderVal);
    let item = {
      fullName,
      email,
      mobile,
      password,
      gender,
      memberType,
      registerIP,
      registerMAC,
      webAccess,
    };

    dispatch(employerregister(item))
      .unwrap()
      .then(() => {
        setModalMessage("OTP has been sent to your mobile number");
        setsuccessModal(true);
        setTimeout(() => {
          navigate("/employer-verify-otp");
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal1(true);
        setTimeout(() => {
          setShowModal1(false);
        }, 3000);
      });
  }

  async function employerSubmit(e) {
    e.preventDefault();

    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    const validationErrors = {};

    if (!fullNameEmployer.trim()) {
      validationErrors.fullNameEmployer = "Please enter your property name";
    } else if (!isValidemployerName(fullNameEmployer)) {
      validationErrors.fullNameEmployer = "Please enter a valid property name";
    }

    if (!emailEmployer.trim()) {
      validationErrors.emailEmployer = "Please enter your email";
    } else if (!isValidEmail(emailEmployer)) {
      validationErrors.emailEmployer = "Please enter a valid email";
    }
    if (!mobileNumberEmployer.trim()) {
      validationErrors.mobileNumberEmployer = "Please enter your mobile number";
    } else if (!isValidmobile(mobileNumberEmployer)) {
      validationErrors.mobileNumberEmployer =
        "Please enter a valid mobile number";
    }

    if (!passwordEmployer.trim()) {
      validationErrors.passwordEmployer = "Please enter password";
    } else if (!isValidPassword(passwordEmployer)) {
      validationErrors.passwordEmployer =
        "Password must be alphanumeric and min 8 characters";
    }
    if (!confirmPasswordEmployer.trim()) {
      validationErrors.confirmPasswordEmployer =
        "Please enter confirm password";
    } else if (!isValidPassword(confirmPasswordEmployer)) {
      validationErrors.confirmPasswordEmployer =
        "Password must be alphanumeric and min 8 characters";
    } else if (passwordEmployer !== confirmPasswordEmployer) {
      validationErrors.confirmPasswordEmployer =
        "Confirm password should be same as password";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const registerIP = "0.0.0.0";
    let memberType = 2;
    let registerMAC = mac;
    let webAccess = 1;

    let mobile = parseInt(mobileNumberEmployer);
    let email = emailEmployer;
    let fullName = fullNameEmployer;
    let password = passwordEmployer;
    let item = {
      fullName,
      email,
      mobile,
      password,
      memberType,
      registerIP,
      registerMAC,
      webAccess,
    };

    dispatch(employerregister(item))
      .unwrap()
      .then(() => {
        setModalMessage("OTP has been sent to your mobile number");
        setsuccessModal(true);
        setTimeout(() => {
          navigate("/employer-verify-otp");
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal1(true);
        setTimeout(() => {
          setShowModal1(false);
        }, 3000);
      });
  }
  const handleChangeclient = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "fullName":
        setFullName(value);
        validationErrors[name] =
          value.trim() === "" || !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (value.trim() !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "email":
        setEmail(value);
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "Please enter a valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;
      case "mobileNumber":
        value = value.replace(/\D/g, "");
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobileNumber(value);
        validationErrors[name] =
          value.trim() === "" || !isValidmobile(value)
            ? "Please enter a valid mobile number"
            : "";
        if (value.trim().length === "") {
          validationErrors[name] = "";
        }
        break;
      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" || !isValidPassword(value)
            ? "Password must be alphanumeric and min 8 characters"
            : "";
        if (value.trim() !== "" && isValidPassword(value)) {
          validationErrors[name] = "";
        }
        break;

      case "confirmPassword":
        setconfirmPassword(value);
        validationErrors[name] =
          value.trim() === "" || value === password
            ? ""
            : "Password and confirm password should be same";
        break;

      case "genderVal":
        setGenderVal(value);
        validationErrors[name] =
          value.trim() === "" ? "Please select your gender" : "";
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };
  const handleChangeemployer = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;

    switch (name) {
      case "fullNameEmployer":
        setFullNameEmployer(value);
        validationErrors[name] =
          value === "" || !isValidemployerName(value)
            ? "Please enter a valid property name"
            : "";
        if (value !== "" && isValidemployerName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "emailEmployer":
        setEmailEmployer(value);
        validationErrors[name] =
          value === "" || !isValidEmail(value)
            ? "Please enter a valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;

      case "mobileNumberEmployer":
        value = value.replace(/\D/g, "");
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobileNumberEmployer(value);
        if (value.trim() === "") {
          validationErrors[name] = "Please enter a valid mobile number";
        } else if (!/^\d{10}$/.test(value.trim())) {
          validationErrors[name] = "Please enter a valid mobile nunber";
        } else {
          delete validationErrors[name];
        }
        break;
      case "passwordEmployer":
        setPasswordEmployer(value);
        validationErrors[name] =
          value.trim() === "" || !isValidPassword(value)
            ? "Password must be alphanumeric and min 8 characters"
            : "";
        if (value.trim() !== "" && isValidPassword(value)) {
          validationErrors[name] = "";
        }
        break;

      case "confirmPasswordEmployer":
        setconfirmPasswordEmployer(value);
        validationErrors[name] =
          value.trim() === "" || value === passwordEmployer
            ? ""
            : "Password and confirm password should be same";
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };

  return (
    <>
      <Helmet>
        <title>Sign up to CookandChef | Register your profile</title>
        <meta
          name="description"
          content="New to CookandChef.in? Create an Account and update your profile or post your requirement."
        />
        <meta
          name="keywords"
          content="Sign up CookandChef, create account, register Cook, Caterings, Events, Party Cook, Commis 3, Chef De Partie, Kitchen helper"
        />
      </Helmet>
      <Modal2
        showModal={showModal1}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title align="center">
            <h4>Select user type</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              sm={12}
              style={{
                borderRadius: "5px",
                padding: "5px 2px",
                border: "none",
                cursor: "pointer",
                background: "rgba(0,172,14,0.4)",
                color: "#000",
              }}
              className="m-1 p-2 "
              onClick={() => setkeyClient("client")}
            >
              <b>Need Cook</b>
              <br />
              <span style={{ fontSize: "12px", lineHeight: "8px" }}>
                If you are looking for a house cook, party cook or catering
              </span>
            </Col>

            <Col
              md={12}
              lg={12}
              sm={12}
              className="m-1 p-2"
              style={{
                borderRadius: "5px",
                padding: "5px 0px",
                border: "none",
                cursor: "pointer",
                background: "rgba(213,16,161,0.5)",
                color: "#000",
              }}
              onClick={() => setKeyEmployer("employer")}
            >
              <b>Need Chef</b>
              <br />
              <span style={{ fontSize: "12px", lineHeight: "1" }}>
                If you need a Chef for your hotel, cafe, restaurant, cloud
                kitchen or for business purpose
              </span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <NavMenu />

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="mt-3"
      >
        <Col
          lg={4}
          className="d-none d-lg-block"
          style={{ background: "#fff" }}
        >
          <img
            src={backimg}
            alt="Looking for chef"
            style={{ height: "708px" }}
          />
        </Col>
        <Col
          className="justify-content-center align-items-center flex-column"
          lg={4}
          xs={11}
          md={12}
          xl={4}
          style={{
            backgroundColor: "white",
            // borderRadius: "15px",

            height: "auto",
          }}
        >
          <p className="h3 text-center mt-3 mb-3">Employer Sign Up</p>

          <Row>
            <Tabs
              id="justify-tab-example"
              justify
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="client" title="Need a house cook" align="center">
                <div style={{ overflowY: "scroll", height: "600px" }}>
                  <Form
                    autoComplete="off"
                    noValidate
                    onSubmit={clientSubmit}
                    className="text-center mt-3"
                    style={{
                      height: "100%",
                      width: "80%",
                      backgroundColor: "",

                      borderRadius: "0px 15px 15px 0px",
                    }}
                    align="center"
                  >
                    <p style={{ color: "#000", fontSize: "14px" }}>
                      If you need a House Cook, Party Cook or Catering
                    </p>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="text"
                        name="fullName"
                        value={fullName}
                        onChange={handleChangeclient}
                        isInvalid={!!errors.fullName}
                        placeholder="Enter your name"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.fullName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChangeclient}
                        isInvalid={!!errors.email}
                        placeholder="Enter your email"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="text"
                        maxlength="10"
                        name="mobileNumber"
                        onChange={handleChangeclient}
                        value={mobileNumber}
                        isInvalid={!!errors.mobileNumber}
                        placeholder="Enter your mobile number"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative">
                      <Form.Control
                        className="input1"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChangeclient}
                        name="password"
                        value={password}
                        isInvalid={!!errors.password}
                        placeholder="Enter password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "20%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#000",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <AiOutlineEye
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        )}
                      </span>
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className=" position-relative">
                      <Form.Control
                        className="input1"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChangeclient}
                        isInvalid={!!errors.confirmPassword}
                        placeholder="Confirm password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "20%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <AiOutlineEye
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        )}
                      </span>
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-4">
                      <Form.Control
                        as="select"
                        name="genderVal"
                        className="input1"
                        value={genderVal}
                        onChange={handleChangeclient}
                        isInvalid={!!errors.genderVal}
                        isValid={errors.genderVal}
                      >
                        <option value="" selected disabled hidden>
                          Select Gender
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Other</option>
                      </Form.Control>
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.genderVal}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="text-start mb-4">
                      <p
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: "12px",
                          color: "#000",
                        }}
                      >
                        By clicking Sign Up, you agree to our
                        <Link
                          to="https://cookandchef.in/terms-and-conditions"
                          target="_blank"
                          className="terms_and_conditions"
                        >
                          &nbsp;terms and conditions
                        </Link>
                        &nbsp;and &nbsp;
                        <Link
                          to="https://cookandchef.in/privacy-policy"
                          target="_blank"
                          className="terms_and_conditions"
                        >
                          privacy policy
                        </Link>
                      </p>
                      <div></div>
                    </Form.Group>
                    <Button
                      className="mb-2 login_btn"
                      type="primary"
                      disabled={isdisabled}
                    >
                      Sign Up
                    </Button>
                    <p className="text-muted mt-3 mb-3">
                      Already have an account?&nbsp;
                      <NavLink to="/employer-login">
                        <span className="forgot_password">Login</span>
                      </NavLink>
                    </p>
                  </Form>
                </div>
              </Tab>

              <Tab eventKey="employer" title="Need a chef" align="center">
                <div style={{ overflowY: "scroll", height: "600px" }}>
                  <Form
                    autoComplete="off"
                    noValidate
                    onSubmit={employerSubmit}
                    className="text-center mt-3"
                    style={{
                      height: "100%",
                      width: "80%",
                      backgroundColor: "",
                      borderRadius: "0px 15px 15px 0px",
                    }}
                  >
                    <p style={{ color: "#000", fontSize: "14px" }}>
                      If you need a Chef for your Restaurant, Hotel, Cloud
                      Kitchen or for Business purpose
                    </p>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="text"
                        name="fullNameEmployer"
                        value={fullNameEmployer}
                        onChange={handleChangeemployer}
                        isInvalid={!!errors.fullNameEmployer}
                        placeholder="Enter your property name"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.fullNameEmployer}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="email"
                        name="emailEmployer"
                        value={emailEmployer}
                        onChange={handleChangeemployer}
                        isInvalid={!!errors.emailEmployer}
                        placeholder="Enter your email"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.emailEmployer}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="input1"
                        type="text"
                        maxlength="10"
                        name="mobileNumberEmployer"
                        onChange={handleChangeemployer}
                        value={mobileNumberEmployer}
                        isInvalid={!!errors.mobileNumberEmployer}
                        placeholder="Enter your mobile number"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.mobileNumberEmployer}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative">
                      <Form.Control
                        className="input1"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChangeemployer}
                        name="passwordEmployer"
                        value={passwordEmployer}
                        isInvalid={!!errors.passwordEmployer}
                        placeholder="Enter password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "20%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <AiOutlineEye
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        )}
                      </span>
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.passwordEmployer}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className=" position-relative">
                      <Form.Control
                        className="input1 mb-4"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPasswordEmployer"
                        value={confirmPasswordEmployer}
                        onChange={handleChangeemployer}
                        isInvalid={!!errors.confirmPasswordEmployer}
                        placeholder="Confirm password"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "20%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <AiOutlineEye
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        )}
                      </span>
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-start"
                      >
                        {errors.confirmPasswordEmployer}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="text-start mb-4 mt-4">
                      <p
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: "12px",
                          color: "#000",
                        }}
                      >
                        By clicking Sign Up, you agree to our
                        <Link
                          to="https://cookandchef.in/terms-and-conditions"
                          target="_blank"
                          className="terms_and_conditions"
                        >
                          &nbsp;terms and conditions
                        </Link>
                        &nbsp;and &nbsp;
                        <Link
                          to="https://cookandchef.in/privacy-policy"
                          target="_blank"
                          className="terms_and_conditions"
                        >
                          privacy policy
                        </Link>
                      </p>
                    </Form.Group>
                    <Button
                      className="mb-2 login_btn"
                      type="primary"
                      disabled={isdisabled}
                    >
                      Sign Up
                    </Button>
                    <p className="text-muted mt-3">
                      Already have an account?&nbsp;
                      <NavLink to="/employer-login">
                        <span className="forgot_password">Login</span>
                      </NavLink>
                    </p>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}

export default EmployerSignup;
