import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.data?.mobileVerified) {
    return true;
  } else {
    return false;
  }
};
//public routes
const PublicRoutes = (props) => {
  const auth = useAuth();
  const user1 = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  if (auth && location.pathname === "/login") {
    if (user1?.data?.role === "cook") {
      return <Navigate to="/cook/dashboard" />;
    } else if (user1?.data?.role === "client") {
      return <Navigate to="/client/dashboard" />;
    } else {
      return <Navigate to="/employer/dashboard" />;
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));
  return auth ? <Navigate to="/login" /> : <Outlet />;
};

export default PublicRoutes;
