import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getshortListedHouseJobs } from "../../../../store/slices/cookprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import chef from "../../../../Assets/Images/cook.png";
import Moment from "moment";
import { FaBookmark } from "react-icons/fa";
import Modal2 from "../../auth_screens/Modal2";
import { removeshortlistjob } from "../../../../store/slices/cookprofile";

function ShortListedHouseJobs() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [totaldata, setTotaldata] = useState();
  const [isRespTrue, setIsRespTrue] = useState(false);

  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      limit: itemsPerPage,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    getchefs(item);
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Saved House Jobs";
  }, []);

  const getchefs = (item) => {
    setisLoading(false);
    dispatch(getshortListedHouseJobs(item))
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setTotaldata(data?.user?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setTimeout(() => {
          setisLoading(true);
        }, 1000);
      });
  };

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const removeshortList = (item1) => {
    setIsRespTrue(false);

    var elem = {
      requirementId: item1?.requirementId?._id,
    };
    dispatch(removeshortlistjob(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);
          setShowModal(true);
        }
        getchefs(item);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull; हॉउस कुक जॉब्स बचाईं
        </div>

        {totaldata === 0 ? (
          <Row className="card mt-4 p-5">
            <Col lg={12} xs={12} md={12} align="center">
              <h5>आपने कोई हॉउस कुक जॉब्स नहीं बचाया!</h5>
              <br />
              <button
                className="saveBtn mt-3"
                style={{ color: "#fff", width: "180px" }}
              >
                <Link
                  to="/hin/cook/housejobs"
                  style={{ color: "#fff", width: "150px" }}
                >
                  हॉउस कुक जॉब्स खोजें
                </Link>
              </button>
            </Col>
          </Row>
        ) : isLoading ? (
          <Col lg={12} sm={11} xs={11}>
            <Row>
              {data?.map((item, index) => (
                <Col lg={9} md={10} sm={12} xs={12}>
                  <Row
                    className="d-flex justify-items-center align-items-center"
                    key={index}
                    style={{
                      backgroundColor: "white",

                      borderRadius: "15px",
                      padding: "8px",
                      marginTop: "8px",
                    }}
                  >
                    <Col lg={2} xs={3} className="justify-content-center">
                      {item?.employerId?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={item?.employerId?.dp}
                          alt="user profile"
                        />
                      )}
                      {!item?.employerId?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={chef}
                          alt="user profile"
                        />
                      )}
                    </Col>
                    <Col lg={9} xs={7} md={8} className="ps-3">
                      <h6 className="ms-3"> {item?.employerId?.fullName}</h6>

                      {item?.requirementId?.cuisines?.length >= 3 ? (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.requirementId?.cuisines
                            ?.join(", ")
                            .substring(0, 20)}
                          ...
                        </p>
                      ) : (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.requirementId?.cuisines?.join(", ")}
                        </p>
                      )}

                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <GrLocation style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.employerId?.area}
                      </p>
                    </Col>

                    <Col
                      lg={1}
                      xs={2}
                      md={2}
                      align="right"
                      style={{ marginTop: "-60px" }}
                    >
                      {isRespTrue && (
                        <Link
                          onClick={() => removeshortList(item, "housecook")}
                          style={{ color: "#000" }}
                        >
                          <FaBookmark />
                        </Link>
                      )}
                      {isRespTrue === false && (
                        <Link style={{ color: "#000" }}>
                          <FaBookmark />
                        </Link>
                      )}
                    </Col>
                    <Col lg={3} xs={6} md={3}>
                      <div
                        className="text-left mt-2"
                        style={{ fontSize: "10px", color: "grey" }}
                      >
                        Posted on&nbsp;
                        {Moment.utc(item?.requirementId?.updatedAt).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </Col>
                    <Col lg={7} xs={3} md={7}>
                      <div align="right"></div>
                    </Col>
                    <Col lg={2} xs={3} md={2}>
                      <div
                        align="left"
                        style={{ marginTop: "-20px", marginLeft: "-15px" }}
                      >
                        <Link
                          to={`/hin/cook/saved-house-job-details/${item?.requirementId?.id}`}
                          style={{ color: "#000" }}
                        >
                          <Button className="view_profile">इसे देखें</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          <Col lg={8} xs={12} md={12} align="center">
            {ShowSkeleton()}
          </Col>
        )}

        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ShortListedHouseJobs;
