import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getshortListedEvents } from "../../../../store/slices/cookprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import chef from "../../../../Assets/Images/client.png";
import Moment from "moment";
import { FaBookmark } from "react-icons/fa";
import Modal2 from "../../auth_screens/Modal2";
import { removeshortlistjob } from "../../../../store/slices/cookprofile";

function ShortListedEvents() {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const [isRespTrue, setIsRespTrue] = useState(false);
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      limit: itemsPerPage,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    getapplications(item);
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Applied Events";
  }, []);

  const getapplications = (item) => {
    setisLoading(false);
    dispatch(getshortListedEvents(item))
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setTotaldata(data?.user?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setTimeout(() => {
          setisLoading(true);
        }, 1000);
      });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const removeshortList = (item1) => {
    setIsRespTrue(false);
    var elem = {
      eventId: item1?.eventId?._id,
    };

    dispatch(removeshortlistjob(elem))
      .then((data) => {
        if (data?.payload?.message) {
          setModalMessage(data?.payload?.message);
          setShowModal(true);
        }
        getapplications(item);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull;&nbsp;सहेजे गए इवेंट
        </div>
        <Row className=" mt-4 ">
          {totaldata === 0 ? (
            <Row className="card mt-4 p-5">
              <Col lg={12} xs={12} md={12} align="center">
                <h5>आपने कोई ईवेंट सहेजा नहीं है!</h5>
                <br />
                <button className="saveBtn mt-3">
                  <Link to="/hin/cook/find-events" style={{ color: "#fff" }}>
                    ईवेंट खोजें
                  </Link>
                </button>
              </Col>
            </Row>
          ) : isLoading ? (
            <Col lg={12} sm={12} xs={12}>
              <Row>
                {data?.map((item, index) => (
                  <Col lg={8} sm={12} xs={12} md={12}>
                    <Row
                      className="d-flex justify-items-center align-items-center"
                      key={index}
                      style={{
                        backgroundColor: "white",
                        // height: "20dvh",
                        // width: "100%",
                        borderRadius: "15px",
                        padding: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <Col lg={2} xs={3} className="justify-content-center">
                        {item?.employerId?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "70px",
                              width: "70px",
                              marginTop: "-10px",
                            }}
                            src={item?.employerId?.dp}
                            alt="user profile"
                          />
                        )}
                        {!item?.employerId?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "70px",
                              width: "70px",
                              marginTop: "-10px",
                            }}
                            src={chef}
                            alt="user profile"
                          />
                        )}
                      </Col>
                      <Col lg={9} xs={7} md={8} className="ps-3">
                        <h6
                          style={{
                            fontWeight: "600",
                            marginBottom: "0.2rem",
                          }}
                          className="ms-3"
                        >
                          {item?.eventId?.eventType}
                        </h6>
                        <p
                          style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                          className="ms-3"
                        >
                          {item?.employerId?.fullName.substring(0, 50)}
                        </p>
                        {item?.eventId?.cuisines?.length >= 3 ? (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.eventId?.cuisines
                              ?.join(", ")
                              .substring(0, 20)}
                            ...
                          </p>
                        ) : (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.eventId?.cuisines?.join(", ")}
                          </p>
                        )}

                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <GrLocation style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.eventId?.location}
                        </p>
                      </Col>
                      <Col
                        lg={1}
                        xs={2}
                        md={2}
                        align="right"
                        style={{ marginTop: "-90px" }}
                      >
                        {isRespTrue && (
                          <Link
                            onClick={() => removeshortList(item, "housecook")}
                            style={{ color: "#000" }}
                          >
                            <FaBookmark />
                          </Link>
                        )}
                        {isRespTrue === false && (
                          <Link style={{ color: "#000" }}>
                            <FaBookmark />
                          </Link>
                        )}
                      </Col>
                      <Col lg={3} xs={6} md={3}>
                        <p
                          className="text-left"
                          style={{ fontSize: "10px", color: "grey" }}
                        >
                          Posted on&nbsp;
                          {Moment.utc(item?.eventId?.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </Col>
                      <Col lg={7} xs={3} md={7}>
                        <div align="right"></div>
                      </Col>
                      <Col lg={2} xs={3} md={2}>
                        <div
                          align="left"
                          style={{ marginTop: "-20px", marginLeft: "-15px" }}
                        >
                          <Link
                            to={`/hin/cook/saved-event-details/${item?.eventId?.id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile">इसे देखें</Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
        </Row>
        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ShortListedEvents;
