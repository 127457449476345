import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getappliedhousejobs } from "../../../store/slices/cookprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import chef from "../../../Assets/Images/cook.png";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";

function AppliedHousejobs() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();

  const itemsPerPage = 8;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getchefs();
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Applied House Jobs";
  }, []);

  const getchefs = () => {
    dispatch(getappliedhousejobs(item))
      .unwrap()
      .then((data) => {
        setData(data?.allhouseapplication?.data);
        setTotaldata(data?.allhouseapplication?.totalDataCount);
      })
      .catch(({ message }) => {});
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; House Job Interests
      </div>

      {totaldata === 0 && (
        <Row className="card mt-4 p-5">
          <Col lg={12} xs={12} md={12} align="center">
            <h5>You have not applied for any house job!</h5>
            <p style={{ color: "grey" }}>
              Start applying to house jobs and get updates on the status of your
              applications.
            </p>
            <button className="saveBtn mt-3" style={{ width: "150px" }}>
              <Link to="/cook/housejobs" style={{ color: "#fff" }}>
                Find House Jobs
              </Link>
            </button>
          </Col>
        </Row>
      )}
      {totaldata !== 0 && (
        <div>
          <Row className="mt-4 justify-items-center align-items-center">
            <Col lg={12} sm={11} xs={11}>
              <Row>
                {data?.map((item, index) => (
                  <Col lg={9} md={10} sm={12} xs={12}>
                    <Row
                      className="d-flex justify-items-center align-items-center"
                      key={index}
                      style={{
                        backgroundColor: "white",

                        borderRadius: "15px",
                        padding: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <Col lg={2} xs={3} className="justify-content-center">
                        {item?.requirementId?.clientId?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={item?.requirementId?.clientId?.dp}
                            alt="user profile"
                          />
                        )}
                        {!item.requirementId.clientId.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={chef}
                            alt="user profile"
                          />
                        )}
                      </Col>
                      <Col lg={9} xs={7} md={8} className="ps-3">
                        <h6 className="ms-3">
                          {item?.requirementId?.clientId?.fullName}
                        </h6>

                        {item?.requirementId?.cuisines?.length >= 3 ? (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.requirementId?.cuisines
                              ?.join(", ")
                              .substring(0, 20)}
                            ...
                          </p>
                        ) : (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.requirementId?.cuisines?.join(", ")}
                          </p>
                        )}

                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <GrLocation style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.requirementId?.area}
                        </p>
                      </Col>
                      <Col lg={3} xs={6} md={3}>
                        <div
                          className="text-left"
                          style={{ fontSize: "10px", color: "grey" }}
                        >
                          Interest sent on&nbsp;
                          {Moment.utc(item.appliedAt).format("DD-MM-YYYY")}
                        </div>
                      </Col>
                      <Col lg={7} xs={3} md={7}>
                        <div align="right">
                          {item?.applicationStatus === "applied" && (
                            <p
                              style={{
                                color: "#929402",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FEFFD3",
                              }}
                              align="center"
                            >
                              Applied
                            </p>
                          )}
                          {item?.applicationStatus === "on Hold" && (
                            <p
                              style={{
                                color: "#000000",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FFAF03",
                              }}
                              align="center"
                            >
                              On Hold
                            </p>
                          )}
                          {item?.applicationStatus === "shortlisted" && (
                            <p
                              style={{
                                color: "#0700F5",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "rgb(209 235 250)",
                              }}
                              align="center"
                            >
                              Shortlisted
                            </p>
                          )}
                          {item?.applicationStatus === "rejected" && (
                            <p
                              style={{
                                color: "#FF0606",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FFDDD5",
                              }}
                              align="center"
                            >
                              Rejected
                            </p>
                          )}
                          {item?.applicationStatus === "hired" && (
                            <p
                              style={{
                                color: "#028D36",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#e7f8c6",
                              }}
                              align="center"
                            >
                              Hired
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg={2} xs={3} md={2}>
                        <div
                          align="left"
                          style={{ marginTop: "-20px", marginLeft: "-15px" }}
                        >
                          <Link
                            to={`/cook/applied-house-job-details/${item?.requirementId?.id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile">View</Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          {Math.floor(totaldata / itemsPerPage) > 1 && (
            <div className="justify-content-right align-right">
              <ReactPaginate
                key={currentPage}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakLinkClassName={"page-link"}
                pageCount={Math.ceil(totaldata / itemsPerPage)}
                marginPagesDisplayed={0}
                pageRangeDisplayed={pagesToShowInitially}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                initialPage={currentPage - 1}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AppliedHousejobs;
