import React, { useEffect } from "react";

const Thank = () => {
  useEffect(() => {
    // Google Ads Conversion Tracking Code
    window.gtag("event", "conversion", {
      send_to: "AW-417008983/oo07CLvfqoMDENea7MYB",
    });
  }, []);

  return null; // This component doesn't render anything
};

export default Thank;
