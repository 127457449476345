import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Button, Col, Form, Row } from "react-bootstrap";
import {
  edithouseProfile,
  getcookProfile,
} from "../../../store/slices/cookprofile";
import { getCuisines } from "../../../store/slices/config";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EditHouse() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Edit House Profile";
  }, []);

  const [cuisines, setCuisines] = useState([]);
  const [householdCuisines, sethouseholdCuisines] = useState([]);
  const [payment, setPayment] = useState("");
  const [jobTypeVal, setjobTypeVal] = useState(3);
  const [householdVesselWash, sethouseholdVesselWash] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const cuisineRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        sethouseholdCuisines(data?.user?.data?.householdCuisines);
        setPayment(data?.user?.data?.payment);
        setjobTypeVal(data?.user?.data?.jobType || 3);
        sethouseholdVesselWash(data?.user?.data?.householdVesselWash || 0);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "payment":
        setPayment(value);
        validationErrors[name] = value === "" ? "Please select payment" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "householdCuisines":
        sethouseholdCuisines(value);
        validationErrors[name] =
          value === "" ? "Please enter cuisines known" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };

  async function submitHandler(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!householdCuisines?.length) {
      validationErrors.householdCuisines = "Please enter cuisines known";
    }

    if (!jobTypeVal) {
      validationErrors.jobTypeVal = "Please select job type ";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.householdCuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      }

      return;
    } else {
      const jobType = parseInt(jobTypeVal);
      let item = {
        householdCuisines,
        payment,
        jobType,
        householdVesselWash,
      };
      dispatch(edithouseProfile(item))
        .unwrap()
        .then(() => {
          setModalMessage("Profile updated successfully");
          setsuccessModal(true);
          setTimeout(() => {
            navigate("/cook/cook-profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
      //  }
    }
  }
  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    sethouseholdCuisines(selected);
    validationErrors.householdCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.householdCuisines;
    }

    setErrors(validationErrors);
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/cook/cook-profile" className="bcrumb">
          Cook Profile
        </Link>
        &nbsp;&bull; Edit House Profile
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row>
          <div
            style={{
              minHeight: "40dvh",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px 40px",
            }}
          >
            <Row className="p-3">
              <Col lg={6} className="p-3">
                <Form.Group>
                  <label className="mb-2 label">Cuisines Known *</label>
                  <div ref={cuisineRef}>
                    <Typeahead
                      clearButton
                      name="householdCuisines"
                      id="basic-typeahead-multiple"
                      labelKey="householdCuisines"
                      multiple
                      className="input1"
                      onChange={handleCuisineChange}
                      options={options}
                      placeholder="Cuisines known"
                      selected={householdCuisines}
                      isInvalid={!!errors.householdCuisines}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.householdCuisines}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">Min. Payment</label>
                  <Form.Control
                    as="select"
                    name="payment"
                    className="input1"
                    value={payment}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="1000 to 2000">1000 to 2000</option>
                    <option value="2000 to 3000">2000 to 3000</option>
                    <option value="3000 to 5000">3000 to 5000</option>
                    <option value="5000 to 10000">5000 to 10000</option>
                    <option value="10000 to 15000">10000 to 15000</option>
                    <option value="15000 to 25000">15000 to 25000</option>
                    <option value="Above 25000">Above 25000</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col lg={6} className="p-3">
                <Form.Group>
                  <label className="mb-2 label">Job Type</label>
                  <Row>
                    <Col lg={5} md={6} xs={6}>
                      <Form.Check
                        type="switch"
                        id="no"
                        label="Part Time"
                        name="jobTypeVal"
                        value={jobTypeVal}
                        onChange={() => setjobTypeVal(1)}
                        checked={jobTypeVal === 1}
                        className="switchBtn"
                      />
                    </Col>
                    <Col lg={5} md={6} xs={6}>
                      <Form.Check
                        type="switch"
                        id="no"
                        label="Full Time"
                        name="jobTypeVal"
                        value={jobTypeVal}
                        onChange={() => setjobTypeVal(2)}
                        checked={jobTypeVal === 2}
                        className="switchBtn"
                      />
                    </Col>
                    <Col lg={5} md={6} xs={6}>
                      <Form.Check
                        type="switch"
                        id="yes"
                        label="Any"
                        name="jobTypeVal"
                        value={jobTypeVal}
                        onChange={() => setjobTypeVal(3)}
                        checked={jobTypeVal === 3}
                        className="switchBtn"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="justify-content-start align-items-center">
                  <label className="mb-2 label">Vessel Wash</label>
                  <Row>
                    <Col lg={3} md={3} xs={6}>
                      <Form.Check
                        type="switch"
                        id="yes"
                        label="Yes"
                        name="householdVesselWash"
                        value={householdVesselWash}
                        onChange={() => sethouseholdVesselWash(1)}
                        checked={householdVesselWash === 1}
                        className="switchBtn"
                      />
                    </Col>
                    <Col lg={3} md={3} xs={6}>
                      <Form.Check
                        type="switch"
                        id="no"
                        label="No"
                        name="householdVesselWash"
                        value={householdVesselWash}
                        onChange={() => sethouseholdVesselWash(0)}
                        checked={householdVesselWash === 0}
                        className="switchBtn"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-4">
              <Col lg={4} xs={8}>
                <div className="d-flex justify-content-around my-2 align-items-center">
                  <Button onClick={() => navigate(-1)} className="cancelBtn">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isdisabled}
                    className="saveBtn ms-2"
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
}

export default EditHouse;
