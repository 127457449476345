// export const API_BASE_URL = process.env.REACT_APP_ENVIROMENT==='stage'?process.env.REACT_APP_API_BASE_URL:"https://apiv2.cookandchef.in/api/v1/"

// // export const API_BASE_URL = 'https://testapi203.cookandchef.in/api/v1'

// // export const API_BASE_URL = "https://apiv2.cookandchef.in/api/v1/";
// // export const GOOGLE_SEARCH_APIKEY = "AIzaSyCMMDmDdRD71ao1oc67wqhOGJ8k1D_JuhY";
export const API_BASE_URL =

process.env.API_BASE_URL || "https://apiv2.cookandchef.in/api/v1/";
export const GOOGLE_SEARCH_APIKEY = "AIzaSyCMMDmDdRD71ao1oc67wqhOGJ8k1D_JuhY";

export const API_PATHS = {
  //auth
  cookRegister: "/cook/signup",
  cookverifyOtp: "/cook/signup/verifyOtp",
  cookLogin: "/cook/login",
  resendotp: "/cook/signup/resendOtp",
  employerRegister: "/employer/signup",
  employerVerifyOtp: "/employer/signup/verifyOtp",
  employerLogin: "/employer/login",
  employerResendotp: "/employer/signup/resendOtp",
  verifyemail: "/employer/login/verifyEmail",
  cookverifyemail: "/cook/login/verifyEmail",
  clientverifyemail: "/employer/login/verifyEmail",
  changeEmail: "/employer/login/changeEmail",
  cookchangeEmail: "/cook/login/changeEmail",
  clientchangeEmail: "/employer/login/changeEmail",
  changemobile: "/employer/login/changemobilenumber",
  clientcheckwatsapp :"/employer/check/whatsappNumber",
  verifyotpchangemobile: "/employer/login/updateMobile/verifyOtp",
  changewatsapp: "/employer/edit/whatsappNumber",
  verifywatsappotp: "/employer/verify/whatsappOtp",
  cookchangemobile: "/cook/login/changemobilenumber",
  cookchangeWhatsap: "/cook/edit/whatsappNumber",
  cookcheckwatsappnumber :"/cook/check/whatsappNumber",
 
  cookverifymobileotp: "/cook/login/updateMobile/verifyOtp",
  cookverifywatsappotp: "/cook/verify/whatsappOtp",
  changepassword:"/common/login/changePassword",
  contactprivacy : "/common/edit/privacy-settings",
  employersignupchangemobile:"/employer/signup/changemobilenumber",
  cooksignupchangemobile:"/cook/signup/changemobilenumber",
  

  //cook apis
  getProfile: "/cook/fetch/profile",
  getViewEmployerProfile: "/cook/view-employer/fetch",
  editProfile: "/cook/edit/profile",
  editPartyProfile: "/cook/edit/profile/partycook",
  editHouseProfile: "/cook/edit/profile/houseCook",
  editChefProfile: "/cook/edit/profile/chef",
  getcookplan: "/cook/cookplans/fetch",
  cookinitiatepayments: "/cook/payment/initiate",
  cookconfirmpayments: "/cook/payment/confirm",
  uploadFileCook: API_BASE_URL + "/cook/upload",
  forgotpassword: "/cook/signup/forgotPassword",
  updatepassword: "/cook/signup/forgotPassword/verifyOtp",
  findjobs: "/cook/find-jobs",
  cookplanHistory:"/cook/payments/history",
  editcatering:"/cook/edit/profile/catering",
  getcookPlans: "/cook/cookplans/fetch",
  appliedjobs:"/cook/applied-chef-jobs/fetch",
  findeventcook:"/cook/find-events",
  shortlistEvent:"/cook/shortlist-event",
  shortlistHousejob:"/cook/shortlist-housecook-job",
  removeshortlisthousejob:"/cook/remove-shortlist-housecook-job",
  shortlistjob:"/cook/shortlist-chef-job",
  removeshortlistjob:"/cook/remove-shortlist/application",
  viewjobdetails:"/cook/view-job",
  employerjobdetails:"/employer/jobpost/fetch",
  cookmobieactivity:"/cook/store/activity-logs",
  eventdetails:"/cook/view-event",
  appliedevents:"/cook/applied-events/fetch",
  findhousejobs :"/cook/find-housecook-jobs",
  cookcancelapplication: "/cook/cancel/application",
  cookapplyjob :"/cook/apply-chef-job",
  applyevent:"/cook/apply-event",
  disablecookaccount:"/cook/disable-account",
  enablecookaccount:"/cook/enable-account",
  deletecookaccount:"/cook/delete-account/request/add",
  cancelcookdeleterequest:"/cook/revoke-delete-request",
  housecookjobdetails :"/cook/view-housecook-job",
  applyhousecookjob:"/cook/apply-housecook-job",
  updateLastLogin:"/cook/update/last-login-time",
  generateResume :"/cook/generate-resume",
  getDashboardDataCook:"/cook/dashboard",
  appliedhousejobs:"/cook/applied-housecook-jobs/fetch",
  cookreportjob:"/cook/report/submit",
  getresumetemplates :"/cook/templates-list/fetch",
  updatecookWeavyid :"/cook/update/WeavyId",
  shortlistedjobs :"/cook/shortlisted-chef-jobs/fetch",
  shortlistedEvents :"/cook/shortlisted-events/fetch",
  shortlistedHouseJobs :"/cook/shortlisted-housecook-jobs/fetch",
  changeRole :"/cook/change/role",
employerRating:"/cook/store/employer-rating",

  getSharedprofile:"/employer/share/cookProfiles/fetch",
  getsharedprofileDetails:"/employer/shared/cookProfile/viewDetails",
  cookreachoutactivities: "/cook/fetch/reachout-activities",
  //client apis
  getemployerProfile: "/employer/fetch/profile",
  editemployerProfile: "/employer/edit/profile",
  getrequirement: "/employer/requirements/fetch",
  addrequirement: "/employer/requirements/add",
  getclientplans: "/employer/clientplans/fetch",
  postEvents: "/employer/clientEvents/create",
  editEvents: "/employer/clientEvents/edit",
  getEvents: "/employer/clientEvents/fetch",
  clientLongplan: "/employer/employerplans/fetch?plantype=2",
  clientShortplan: "/employer/employerplans/fetch?plantype=1",
  disableaccount :"/employer/disable-account",
  enableaccount:"/employer/enable-account",
  uploadFileClient: API_BASE_URL + "employer/upload",
  clientPlanHostory:"/employer/clientsplan/payment/history",
  deleteaccount:"/employer/delete-account/request/add",
  canceldeleterequest:"/employer/revoke-delete-request",
  updateLastLoginEmployer:"/employer/update/last-login-time",
  houseinterests:"/employer/housejob-applications-list/fetch",
  houseinterestdetails:"/employer/housejob-application/view",
  updateemployerWeavyid :"/employer/update/WeavyId",


  //config
  getCuisine: "/common/fetch/cusiniesList",
  getQualification: "/dropdown/fetch-qualification",
  getLanguages: "/dropdown/fetch-languages",
  getState: "/dropdown/fetch-province",
  getFaq:"/common/faqs/fetch",

  //empoyer
  postjob: "/employer/jobpost/create",
  editjob: "/employer/jobpost/edit",
  getjob: "/employer/jobpost/fetch",
  getemployerLongplan: "/employer/employerplans/fetch?plantype=2",
  getemployerShortplan: "/employer/employerplans/fetch?plantype=1",
  findchef: "/employer/find-chefs",
  shortlistcook: "/employer/shortlist/cook-profile",
  removeshortchef: "/employer/shortlist-profile/delete",
  viewshortlistchef: "/employer/shortlisted-profiles/fetch",
  viewchefDetails: "/employer/cook-details/fetch",
  employerplanHistory: "/employer/employerplan/payment/history",
  geteventrequests:"/employer/event-applications-list/fetch",
  uploadFileEmployer: API_BASE_URL + "employer/upload",
  eventrequeststatus:"/employer/event-application/edit",
  employerreachoutactivities: "/employer/fetch/reachout-activities",

  getapplicationsReceived:"/employer/job-applications-list/fetch",
  employerapplicationstatus:"/employer/job-application/edit",
  employercheckoutinitiate : "/employer/employerplan/payment/initiate",
  employermobieactivity:"/employer/store/activity-logs",
  viewApplicationDetail:"/employer/job-application/view",
  vieweventrequestdetails:"/employer/event-application/view",
  purchageassistance :"/employer/employerplan/supportAssistance/payment/initiate",
  findcatering:"/employer/find-caterings",
  findpartycook:"/employer/find-party-cooks",
  findhousecook :"/employer/find-house-cooks",
  getDashboardData:"/employer/dashboard",
  reportchefprofile:"/employer/report/submit",
  employerplanpurchase:"/employer/employerplan/supportAssistance/payment/initiate",
cookrating : "/employer/store/cook-rating",

  //weavy
//   weavyId : "009c9ffe18594d13bacdea6dd57d3b41", //weavyID 
// weavyKey :"wys_9Xq10KMQQrFJXzhhNO8QTUuz49pBcB1NN1tQ", //weavyKey
weavyId : "8cfaa74deeb14900be7d2e7c222c6490", //weavyID 
weavyKey :"wys_pW7fvZOVnpA3dr3sC4tzXIRNjqwH8f0O2xHG", //weavyKey
othersweavyId :"/common/chat/initiateWeavyId",
  //payments
 

  employerinitiatepayments: "/employer/employerplan/payment/initiate",
  clientinitiatepayments: "/employer/clientplan/payment/initiate",
  gepaymentkey: "/common/fetch/config?rzp=1",



  //website data
  topChefs:"/common/website/fetch/topchefs",
  topJobs:"/common/website/fetch/topjobs",
  testimonial:"/common/website/fetch/testimonials",
  contactUs:"/common/contactUs/submit/ticket",
  getAdvertisements :"/cook/advertisement-list/fetch",
  getemployerAdvertisements :"/employer/advertisement-list/fetch",
  getwebsiteCookslisthome :"/common/website/fetch/cookslist",
  googlemapsapikey :"AIzaSyCMMDmDdRD71ao1oc67wqhOGJ8k1D_JuhY"
   
};