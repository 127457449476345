import { updateWeavyEmployer } from "../../store/slices/employerprofile";
import { API_PATHS } from "../constants/api.constants";
import axios from "axios"

const FetchWeavyUserDetails = async (requestOptions, data,role) => {
  try {
    const response = await fetch(
      `https://${API_PATHS.weavyId}.weavy.io/api/users/${data.id}`,
      requestOptions
    );
    const data1 = await response.json();
    const weavyId = data1?.id;
    if (weavyId) {
      let item = {
        weavyId: data1?.id,
        role:role,
        id:data.id
      };
      let api;
     api = API_PATHS.othersweavyId
      const response = await axios.post(api,item );
    }
    localStorage.setItem("weavyId2",data1?.id)
  } catch (error) {
    console.error("Error fetching Weavy user details:", error);
  }
};

const InitApp1 = async (requestOptions1, userdata,role) => { 
      try {
        const response = await fetch(
          `https://${API_PATHS.weavyId}.weavy.io/api/users`,
          requestOptions1
        );

        if (response.status === 400) {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_PATHS.weavyKey}`,
            },
          };
        }else{
        const data = await response.json();
        let item = {
          weavyId: data?.id,
          id:userdata.id,
          role:role
        };
        if (data?.id) {
          let user = JSON.parse(localStorage.getItem("user"));
          const token = user.data.token;
          let api;
         api = API_PATHS.othersweavyId
          const response = await axios.post(api,item);
          
        }
        localStorage.setItem("weavyId2", data?.id)
        return data?.id
      }
      } catch (error) {
        console.error("Error initializing app:", error);
        throw error;
      }
};

export default InitApp1;
