import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import {
  CookDeleteAccount,
  CookDisableAccount,
  CookEnableAccount,
  getcookProfile,
  CookCancelDeleteAccount,
} from "../../../../store/slices/cookprofile";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import CookChangepassword from "./CookChangePassword";
import CookContactPrivacy from "./CookContactPrivacy";
import CookChangeRole from "./CookChangeRole";

function CookSettings() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal1, setShowModal1] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState();
  const [successModal, setsuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [changePassword, setChangePassword] = useState(false);
  const [contactPrivacy, setContactPrivacy] = useState(false);
  const [changeRole, setChangeRole] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  React.useEffect(() => {
    getProfile();
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Settings";
  }, []);

  const getProfile = () => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const handleOpenModal = (type) => {
    setShowModal1(true);
    setType(type);
  };

  const handleCloseModal = () => {
    setShowModal1(false);
  };

  const handleDisableAccount = () => {
    dispatch(CookDisableAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getProfile();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };

  const handleDeleteAccount = () => {
    dispatch(CookDeleteAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        localStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };

  const handlecancelDeleteAccount = () => {
    dispatch(CookCancelDeleteAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getProfile();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };

  const handlEnableAccount = () => {
    dispatch(CookEnableAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getProfile();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };

  const getModalContent = () => {
    switch (type) {
      case "disable":
        return (
          <>
            <Modal.Header>
              <Modal.Title>खाता अक्षम करें</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>क्या आप वाकई अपना खाता अक्षम करना चाहते हैं?</p>
              <Button className="modalsaveBtn" onClick={handleDisableAccount}>
                हाँ
              </Button>
              <Button
                className="modalcancelBtn ms-2"
                onClick={handleCloseModal}
              >
                रद्द कीजिये
              </Button>
            </Modal.Body>
          </>
        );
      case "delete":
        return (
          <>
            <Modal.Header>
              <Modal.Title>खाता हटा दो</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं?</p>
              <Button className="modalsaveBtn" onClick={handleDeleteAccount}>
                हाँ
              </Button>
              <Button
                className="modalcancelBtn ms-2"
                onClick={handleCloseModal}
              >
                रद्द कीजिये
              </Button>
            </Modal.Body>
          </>
        );
        break;
      case "enable":
        return (
          <>
            <Modal.Header>
              <Modal.Title>खाता सक्षम करें</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>क्या आप वाकई अपना खाता सक्षम करना चाहते हैं?</p>
              <Button className="saveBtn" onClick={handlEnableAccount}>
                हाँ
              </Button>
              <Button className="cancelBtn ms-2" onClick={handleCloseModal}>
                रद्द कीजिये
              </Button>
            </Modal.Body>
          </>
        );
        break;
      case "canceldelete":
        return (
          <>
            <Modal.Header>
              <Modal.Title>हटाने का अनुरोध रद्द करें</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>क्या आप वाकई डिलीट अनुरोध रद्द करना चाहते हैं?</p>
              <Button className="saveBtn" onClick={handlecancelDeleteAccount}>
                हाँ
              </Button>
              <Button className="cancelBtn ms-2" onClick={handleCloseModal}>
                रद्द कीजिये
              </Button>
            </Modal.Body>
          </>
        );
        break;
      default:
        return null;
    }
  };

  const setChangepassword = () => {
    setContactPrivacy(false);
    setChangePassword(true);
    setChangeRole(false);
  };

  const setContactprivacy = () => {
    setContactPrivacy(true);
    setChangePassword(false);
    setChangeRole(false);
  };

  const setChangeRolefunc = () => {
    setChangeRole(true);
    setContactPrivacy(false);
    setChangePassword(false);
  };

  return (
    <>
      <Modal
        style={{ top: "20%", left: "5%" }}
        show={showModal1}
        onHide={handleCloseModal}
      >
        {getModalContent()}
      </Modal>
      <div style={{ height: "100vh" }}>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull; सेटिंग
        </div>
        <Modal2
          showModal={showModal}
          modalMessage={modalMessage}
          setShowModal={setShowModal}
        />
        <SuccessModal
          showModal={successModal}
          modalMessage={modalMessage}
          setShowModal={setsuccessModal}
        />
        {isLoading ? (
          <Row className="mt-2">{ShowprofileSkeleton()}</Row>
        ) : (
          <Row className="mt-2">
            <div className=" my-4 justify-content-center align-items-center">
              <Row className="justify-content-left align-items-left">
                <Col
                  xs={6}
                  lg={3}
                  md={3}
                  xl={3}
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={setChangepassword}
                >
                  <div className="homeCard">पासवर्ड बदलें</div>
                </Col>

                <Col
                  xs={6}
                  lg={3}
                  md={3}
                  xl={3}
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={setContactprivacy}
                >
                  <div className="homeCard">कांटेक्ट प्राइवेसी</div>
                </Col>

                {data?.status === 1 && (
                  <Col xs={6} lg={3} md={3} xl={3} align="center">
                    <Link
                      onClick={() => handleOpenModal("disable")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">खाता अक्षम करें</div>
                    </Link>
                  </Col>
                )}
                {data?.status === 2 && (
                  <Col xs={6} lg={3} md={3} xl={3} align="center">
                    <Link
                      onClick={() => handleOpenModal("enable")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">खाता सक्षम करें</div>
                    </Link>
                  </Col>
                )}

                <Col xs={6} lg={3} md={3} xl={3} align="center">
                  {data?.status !== 4 && (
                    <Link
                      onClick={() => handleOpenModal("delete")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">खाता हटा दो</div>
                    </Link>
                  )}

                  {data?.status === 4 && (
                    <Link
                      onClick={() => handleOpenModal("canceldelete")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">हटाएँ अनुरोध रद्द करें</div>
                    </Link>
                  )}
                </Col>

                <Col
                  xs={6}
                  lg={3}
                  md={3}
                  xl={3}
                  align="center"
                  onClick={setChangeRolefunc}
                >
                  {data?.status === 1 && (
                    <Link style={{ color: "#000" }}>
                      <div className="homeCard">भूमिका बदलें</div>
                    </Link>
                  )}
                </Col>
              </Row>
            </div>
            <div>
              {changePassword ? (
                <CookChangepassword />
              ) : contactPrivacy ? (
                <CookContactPrivacy />
              ) : changeRole ? (
                <CookChangeRole />
              ) : (
                ""
              )}
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default CookSettings;
