import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  getcookProfile,
  editpartyProfile,
} from "../../../../store/slices/cookprofile";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { getCuisines } from "../../../../store/slices/config";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EditParty() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Edit Party Profile";
  }, []);

  const dispatch = useDispatch();
  const [cuisines, setCuisines] = useState([]);
  const [partyCuisines, setpartyCuisines] = useState([]);
  const [partyExperienceVal, setpartyExperienceVal] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [partyMaxPlatesVal, setpartyMaxPlatesVal] = useState("");
  const [partyCookFoodTypeVal, setpartyCookFoodTypeVal] = useState("");
  const [partyCookAvailabilityVal, setpartyCookAvailabilityVal] = useState("");
  const [partyCookVesselWash, setpartyCookVesselWash] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const cuisineRef = useRef(null);
  const availabilityRef = useRef(null);
  const expRef = useRef(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setpartyCuisines(data?.user?.data?.partyCuisines);
        setpartyMaxPlatesVal(data?.user?.data?.partyMaxPlates);
        setpartyExperienceVal(data?.user?.data?.partyExperience);
        setpartyCookFoodTypeVal(data?.user?.data?.partyCookFoodType);
        setpartyCookAvailabilityVal(data?.user?.data?.partyCookAvailability);
        setSpeciality(data?.user?.data?.speciality);
        setpartyCookVesselWash(data?.user?.data?.partyCookVesselWash || 0);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "partyCuisines":
        setpartyCuisines(value);
        validationErrors[name] =
          value === "" ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "partyExperienceVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setpartyExperienceVal(value);
        validationErrors[name] =
          value === "" || !isValidpartyExperienceVal(value)
            ? "कृपया एक वैध अनुभव दर्ज करें. अधिकतम 50 वर्ष का अनुभव स्वीकार किया जाता है"
            : "";
        if (!value === "" && isValidpartyExperienceVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "speciality":
        setSpeciality(value);
        validationErrors[name] =
          value !== "" && !isValidspeciality(value)
            ? "कृपया मान्य विशेषता दर्ज करें"
            : "";
        if (!value === "" && isValidspeciality(value)) {
          delete validationErrors[name];
        }
        break;

      case "partyMaxPlatesVal":
        setpartyMaxPlatesVal(value);
        validationErrors[name] = value === "" ? "" : "";
        if (!value === "" && isValidpartyMaxPlatesVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "partyCookFoodTypeVal":
        setpartyCookFoodTypeVal(value);
        validationErrors[name] =
          value === "" ? "कृपया भोजन का प्रकार चुनें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "partyCookAvailabilityVal":
        setpartyCookAvailabilityVal(value);
        validationErrors[name] = value === "" ? "कृपया उपलब्धता चुनें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };

  const isValidpartyExperienceVal = (partyExperienceVal) => {
    return /^(0?[1-9]|[1-4][0-9]|50)$/.test(partyExperienceVal);
  };

  const isValidpartyMaxPlatesVal = (partyMaxPlatesVal) => {
    return /^(0|[1-9]|[1-2][0-9]|[1-9][0-9][0-9])$/.test(partyMaxPlatesVal);
  };

  const isValidspeciality = (speciality) => {
    return /^[a-zA-Z0-9 ,]{2,100}$/.test(speciality);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!partyCuisines?.length) {
      validationErrors.partyCuisines = "कृपया ज्ञात व्यंजन दर्ज करें";
    }
    if (!partyExperienceVal) {
      validationErrors.partyExperienceVal = "कृपया अनुभव दर्ज करें";
    }

    if (!partyCookAvailabilityVal) {
      validationErrors.partyCookAvailabilityVal = "कृपया उपलब्धता चुनें ";
    }
    if (!isValidpartyExperienceVal(partyExperienceVal)) {
      validationErrors.partyExperienceVal = "कृपया अनुभव दर्ज करें";
    }
    if (speciality && !isValidspeciality(speciality)) {
      validationErrors.speciality = "कृपया मान्य विशेषता दर्ज करें";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.partyCuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.partyExperienceVal) {
        expRef.current.focus();
      } else if (validationErrors.partyCookAvailabilityVal) {
        availabilityRef.current.focus();
      }

      return;
    } else {
      const partyExperience = parseInt(partyExperienceVal);
      const partyMaxPlates = parseInt(partyMaxPlatesVal);
      const partyCookAvailability = parseInt(partyCookAvailabilityVal);
      const partyCookFoodType = parseInt(partyCookFoodTypeVal);

      if (partyCookFoodTypeVal === "") {
        let item = {
          partyCuisines,
          partyExperience,
          speciality,
          partyMaxPlates: partyMaxPlates || undefined,
          partyCookAvailability,
          partyCookVesselWash,
        };
        dispatch(editpartyProfile(item))
          .unwrap()
          .then(() => {
            setModalMessage("Profile updated successfully");
            setsuccessModal(true);
            setTimeout(() => {
              navigate("/hin/cook/cook-profile");
            }, 3000);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
          });
      } else if (partyCookFoodTypeVal !== "") {
        let item = {
          partyCuisines,
          partyExperience,
          speciality,
          partyMaxPlates: partyMaxPlates || undefined,
          partyCookAvailability,
          partyCookFoodType: partyCookFoodType || undefined,
          partyCookVesselWash,
        };
        dispatch(editpartyProfile(item))
          .unwrap()
          .then(() => {
            setModalMessage("Profile updated successfully");
            setsuccessModal(true);
            setTimeout(() => {
              navigate("/hin/cook/cook-profile");
            }, 3000);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
          });
      }
    }
  };

  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };

    setpartyCuisines(selected);
    validationErrors.partyCuisines =
      selected.length === 0 ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
    if (!selected.length === 0) {
      delete validationErrors.partyCuisines;
    }

    setErrors(validationErrors);
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/cook/cook-profile" className="bcrumb">
          कुक प्रोफ़ाइल
        </Link>
        &nbsp;&bull; एडिट पार्टी प्रोफ़ाइल
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row className="mt-4 gx-3 d-flex justify-content-center ">
          <Col lg={12} xs={11} className="mb-4">
            <Row className="=d-flex justify=-content-center align-items-center">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "20px 40px",
                }}
              >
                <Col lg={12}>
                  <Row>
                    <Col lg={6} className="p-3">
                      <Form.Group className="">
                        <label className="mb-2 label">ज्ञात व्यंजन *</label>
                        <div ref={cuisineRef}>
                          <Typeahead
                            clearButton
                            id="basic-typeahead-multiple"
                            name="cuisines"
                            multiple
                            className="input1"
                            onChange={handleCuisineChange}
                            options={options}
                            placeholder="ज्ञात व्यंजन"
                            selected={partyCuisines}
                            isInvalid={!!errors.partyCuisines}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.partyCuisines}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label"> अनुभव *</label>
                        <Form.Control
                          type="text"
                          name="partyExperienceVal"
                          value={partyExperienceVal}
                          isInvalid={!!errors.partyExperienceVal}
                          isValid={!errors.partyExperienceVal}
                          onChange={handleChange}
                          onWheel={(e) => e.target.blur()}
                          className="input1"
                          placeholder="पार्टी का अनुभव दर्ज करें"
                          ref={expRef}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.partyExperienceVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">स्पेशलिटी</label>
                        <Form.Control
                          type="text"
                          name="speciality"
                          value={speciality}
                          isInvalid={!!errors.speciality}
                          isValid={!errors.speciality}
                          onChange={handleChange}
                          className="input1"
                          placeholder="अपनी स्पेशलिटी दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.speciality}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">अधिकतम प्लेटें</label>

                        <Form.Control
                          as="select"
                          name="partyMaxPlatesVal"
                          className="input1"
                          value={partyMaxPlatesVal}
                          onChange={handleChange}
                          isInvalid={!!errors.partyMaxPlatesVal}
                          isValid={errors.partyMaxPlatesVal}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.partyMaxPlatesVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">भोजन का प्रकार</label>
                        <Form.Control
                          as="select"
                          name="partyCookFoodTypeVal"
                          className="input1"
                          value={partyCookFoodTypeVal}
                          onChange={handleChange}
                          isInvalid={!!errors.partyCookFoodTypeVal}
                          isValid={errors.partyCookFoodTypeVal}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="1">Pure Veg</option>
                          <option value="2">Veg/ Non-Veg</option>
                          <option value="3">Jain Food</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.partyCookFoodTypeVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">उपलब्धता *</label>
                        <Form.Control
                          as="select"
                          name="partyCookAvailabilityVal"
                          className="input1"
                          value={partyCookAvailabilityVal}
                          onChange={handleChange}
                          isInvalid={!!errors.partyCookAvailabilityVal}
                          isValid={errors.partyCookAvailabilityVal}
                          ref={availabilityRef}
                        >
                          <option value="" selected disabled hidden>
                            Select
                          </option>
                          <option value="1">All Days</option>
                          <option value="2">Mon-Fri Only</option>
                          <option value="3">Sat-Sun Only</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.partyCookAvailabilityVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className=" justify-content-start align-items-center">
                        <label className="mb-2 label">बर्तन धोना</label>
                        <Row>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="yes"
                              label="Yes"
                              name="partyCookVesselWash"
                              value={partyCookVesselWash}
                              onChange={() => setpartyCookVesselWash(1)}
                              checked={partyCookVesselWash === 1}
                              className="switchBtn"
                            />
                          </Col>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="no"
                              label="No"
                              name="partyCookVesselWash"
                              value={partyCookVesselWash}
                              onChange={() => setpartyCookVesselWash(0)}
                              checked={partyCookVesselWash === 0}
                              className="switchBtn"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center mt-4">
                    <Col lg={4} xs={8}>
                      <div className="d-flex justify-content-around my-2 align-items-center">
                        <Button
                          onClick={() => navigate(-1)}
                          className="cancelBtn"
                        >
                          रद्द कीजिये
                        </Button>
                        <Button
                          type="submit"
                          disabled={isdisabled}
                          className="saveBtn ms-2"
                        >
                          जमा करना
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EditParty;
