import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsBookmark, BsBriefcase } from "react-icons/bs";
import { BiLockAlt } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";

function ViewedProfiles() {
  const userDetails = [
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
    {
      name: "john",
      cuisine: "North indian, South indian, Chinese",
      location: "Bangalore",
      experience: "5",
      active: "22/2/23",
    },
  ];
  return (
    <>
      <Row className="mt-4">
        <Col lg="8">
          {userDetails.map((item, index) => (
            <Row
              className="d-flex justify-items-center align-items-center"
              key={index}
              style={{
                backgroundColor: "white",
                height: "126px",
                width: "100%",
                borderRadius: "15px",
                padding: "8px",
                marginTop: "8px",
                // border: "2px solid black",
              }}
            >
              <Col lg="2">
                <div>
                  <img
                    height={70}
                    width={62}
                    style={{ borderRadius: "15px" }}
                    src="https://res.cloudinary.com/dgcy4qkiz/image/upload/v1661248394/samples/people/kitchen-bar.jpg"
                    alt="user profile"
                  />
                  <div
                    style={{ fontSize: "10px", fontWeight: "400" }}
                    className="mt-1"
                  >
                    viewed on <br />
                    {item.active}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    fontSize: "18px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                  }}
                >
                  {item.name}
                </div>
                <div style={{ fontSize: "12px" }}>{item.cuisine}</div>
                <div style={{ fontSize: "14px" }}>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  {item.location}
                </div>
                <div style={{ fontSize: "14px" }}>
                  <BsBriefcase style={{ marginBottom: "2px" }} />
                  {item.experience}
                </div>
              </Col>
              <Col lg="4">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <BiLockAlt />
                    <BsBookmark />
                  </div>
                </div>
                <div>
                  <Button className="view_profile">View again</Button>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}

export default ViewedProfiles;
