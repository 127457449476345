import React,{useEffect, useState} from "react";
import { Route, Routes ,useNavigate} from "react-router-dom";
import "./App.css";
import CookLogin from "./components/auth_screens/CookLogin";
import EmployerLogin from "./components/auth_screens/EmployerLogin";
import EmployerSignup from "./components/auth_screens/EmployerSignup";
import CateringSignup from "./components/auth_screens/CateringSignup";
import ClientSignup from "./components/auth_screens/ClientSignup";
import CookSignup from "./components/auth_screens/CookSignup";
import Dashboard from "./components/employer/Dashboard";
import Dashboardh from "./components/Hindi/employer/Dashboard";
import SuccessEmail from "./components/auth_screens/successemail.jsx";
import FailEmail from "./components/auth_screens/failemail.jsx";
import Home from "./components/Home/Home";
import ClientDashBoard from "./components/client_dashboard/ClientDashBoard";
import ClientDashBoardh from "./components/Hindi/client_dashboard/ClientDashBoard";
import CookDashboardh from "./components/Hindi/cook_dashboard/CookDashboard";
import CookDashboard from "./components/cook_dashboard/CookDashboard";
import CookVerify from "./components/auth_screens/CookVerify";
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from './components/PublicRoutes';
import EmployerVerify from "./components/auth_screens/employerVerify";
import ForgotPassword from "./components/auth_screens/ForgotPassword";
import UpdatePassword from "./components/auth_screens/updatepassword";
import FAQs from "./components/auth_screens/faq";
import Termsandconditions from './components/auth_screens/Termsandconditions';
import PrivacyPolicy from './components/auth_screens/privacy-policy';
import RefundPolicy from "./components/auth_screens/refundpolicy";
import ContactUs from "./components/auth_screens/ContactUs";
import About from "./components/auth_screens/about";
import CateringSignuph from "./components/Hindi/auth_screens/CateringSignup";
import ClientSignuph from "./components/Hindi/auth_screens/ClientSignup";
import EmployerLoginh from "./components/Hindi/auth_screens/EmployerLogin";
import CookLoginh from "./components/Hindi/auth_screens/CookLogin";
import ForgotPasswordh from "./components/Hindi/auth_screens/ForgotPassword";
import UpdatePasswordh from "./components/Hindi/auth_screens/updatepassword";
import CookSignuph from "./components/Hindi/auth_screens/CookSignup";
import EmployerSignuph from "./components/Hindi/auth_screens/EmployerSignup";
import CookVerifyh from "./components/Hindi/auth_screens/CookVerify";
import EmployerVerifyh from "./components/Hindi/auth_screens/employerVerify";
import FAQsh from "./components/Hindi/client_dashboard/Pages/faq";
import Termsandconditionsh from './components/Hindi/auth_screens/Termsandconditions';
import PrivacyPolicyh from './components/Hindi/auth_screens/privacy-policy';
import RefundPolicyh from "./components/Hindi/auth_screens/refundpolicy";
import ContactUsh from "./components/Hindi/auth_screens/ContactUs";
import Abouth from "./components/Hindi/auth_screens/about";
import NotFoundPage from "./utils/helpers/Nofound/NotfoundPage.jsx";
import WebsiteCooksList from "./components/auth_screens/WebsiteCooks.jsx";
import ErrorPage from "./components/auth_screens/404.jsx";


function App() {
  return (
    <Routes>
        <Route path="/faq" element={<FAQs />} />
        <Route path="/terms-and-conditions" element={<Termsandconditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<About/>}/>
      <Route path="/hin/faq" element={<FAQsh />} />
      <Route path="/hin/terms-and-conditions" element={<Termsandconditionsh />} />
      <Route path="/hin/privacy-policy" element={<PrivacyPolicyh />} />
      <Route path="/hin/refund-policy" element={<RefundPolicyh />} />
      <Route path="/hin/contact" element={<ContactUsh />} />
      <Route path="/hin/about" element={<Abouth />}/>
      <Route path="/" element={<PublicRoutes />}>
        <Route index element={<Home />} />
        <Route path="/catering_signup" element={<CateringSignup />} />
        <Route path="/client_signup" element={<ClientSignup />} />
        <Route path="/employer-login" element={<EmployerLogin />} />
        <Route path="/login"  element={<CookLogin/>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/cook-signup" element={<CookSignup />} />
        <Route path="/employer-registration" element={<EmployerSignup />} />
        <Route path="/cook-verify-otp" element={<CookVerify />} />
        <Route path="/employer-verify-otp" element={<EmployerVerify />} />


        <Route path="/cooks/:name" element={<WebsiteCooksList  />}/>
        <Route path="/success-email" element={<SuccessEmail/>}/>
        <Route path="/fail-email" element={<FailEmail/>}/>
        <Route path="/hin/catering_signup" element={<CateringSignuph />} />
        <Route path="/hin/client_signup" element={<ClientSignuph />} />
        <Route path="/hin/employer-login" element={<EmployerLoginh />} />
        <Route path="/hin/login"  element={<CookLoginh />} />
        <Route path="/hin/forgot-password" element={<ForgotPasswordh />} />
        <Route path="/hin/update-password" element={<UpdatePasswordh />} />
        <Route path="/hin/cook-signup" element={<CookSignuph />} />
        <Route path="/hin/employer-registration" element={<EmployerSignuph />} />
        <Route path="/hin/cook-verify-otp" element={<CookVerifyh />} />
        <Route path="/hin/employer-verify-otp" element={<EmployerVerifyh />} />
      </Route>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/employer/*" element={<Dashboard />} />
        <Route path="/client/*" element={<ClientDashBoard />} />
        <Route path="/cook/*" element={<CookDashboard />} />
        <Route path="/employer/not-found" element={<NotFoundPage />} />
        <Route path="/hin/employer/*" element={<Dashboardh />} />
        <Route path="/hin/client/*" element={<ClientDashBoardh />} />
        <Route path="/hin/cook/*" element={<CookDashboardh />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;