import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import oops from "../../Assets/Images/wrong.png";
// ... other imports

function ContactPivacyModal({ showModal, setShowModal }) {
  // ... existing code

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginLeft: "5%" }}
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Body align="center">
          <img src={oops} alt="" style={{ height: "30px", width: "30px" }} />
          <br />

          <h5 className="mt-4">
            The user has disabled contact number or calling option.
            <br /> Try chat option
          </h5>
          <button
            type="button"
            className="modalsaveBtn mt-2"
            onClick={handleCloseModal}
          >
            Ok
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactPivacyModal;
