import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsCardText, BsFillBriefcaseFill } from "react-icons/bs";
import Moment from "moment";
import { AiFillWarning } from "react-icons/ai";
import empimg from "../../../Assets/Images/restaurant.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import plan from "../../../Assets/Images/plan.png";
import jobs from "../../../Assets/Images/jobs.png";
import applications from "../../../Assets/Images/applications.png";
import shortlist from "../../../Assets/Images/ribbon.png";
import chefimg from "../../../Assets/Images/cook.png";
import { Link } from "react-router-dom";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import ShowtopcontentSkeleton from "../../auth_screens/topcontentskeleton";
import ShowoverviewSkeleton from "../../auth_screens/overviewskeleton";
import { API_PATHS } from "../../../utils/constants/api.constants";
import InitApp from "../../../utils/helpers/CreateWeavy";
import {
  getemployerProfile,
  UpdateLogin,
  getemployerDashboardData,
  getallemployerApplications,
  GetEmployerAdvertisements,
} from "../../../store/slices/employerprofile";
import { topChefs } from "../../../store/slices/config";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Modal2 from "../../auth_screens/Modal2";
import EnableProfileModal from "../../auth_screens/EnableProfileModal";
import { ImageSlider } from "../../cook_dashboard/pages/CookDashHome";

function DashHome() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [dashdata, setDashData] = useState([]);
  const [loginData, setloginData] = useState();
  const [chefdatas, setchefDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [weavyid, setWeavyId] = useState();
  const [enableProfile, setEnableProfile] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [Ads, setAds] = useState();
  const [images, setImages] = useState();

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    getalldata();
  }, []);

  const getalldata = () => {
    dispatch(getallemployerApplications())
      .unwrap()
      .then((data) => {
        setIsNew(data?.allapplication?.isNew);
      })
      .catch(({ message }) => {});
  };

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);

        if (!data.user.data.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.user?.data?.id,
              name: data?.user?.data?.fullName,
            }),
          };
          InitApp(requestOptions, data.user.data, "employer");
        } else {
          localStorage.setItem("weavyId", data.user.data.weavyId);
        }
      })
      .catch(({ message }) => {
        // setShowModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getemployerDashboardData())
      .unwrap()
      .then((data) => {
        setDashData(data.empDashData.data[0]);
      })
      .catch(({ message }) => {
        // setShowModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(UpdateLogin())
      .unwrap()
      .then((data) => {
        setloginData(data.logindata.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Home";
  }, []);

  useEffect(() => {
    getDetails();
  }, [dispatch]);

  const getDetails = () => {
    const item = {
      limit: 3,
      page: 1,
    };
    dispatch(topChefs(item))
      .unwrap()
      .then((data) => {
        setchefDatas(data?.topchef?.data.slice(0, 3));
      })
      .catch(({ message }) => {});
  };

  const gotoPostJob = () => {
    if (data.status === 2) {
      setEnableProfile(true);
    } else {
      navigate("/employer/post-job");
    }
  };

  useEffect(() => {
    const item = {
      limit: 100,
      page: 1,
      usertype: "employer",
    };
    dispatch(GetEmployerAdvertisements(item))
      .then((data) => {
        setAds(data?.payload?.ads?.data);
        const images = data?.payload?.ads?.data?.map((el) => el?.image);
        if (images && images?.length > 0) {
          setImages(images);
        }
      })
      .catch(({ message }) => {});
  }, []);
  const startAngle = 0;
  const customStyles = buildStyles({
    rotation: startAngle / 360, // Convert startAngle to a fraction
    strokeLinecap: "butt", // Round or butt
    textSize: "16px",
    pathTransitionDuration: 0.5, // Animation duration
    pathColor: "#ff5c09", // Progress color
    textColor: "#ff5c09", // Text color
    trailColor: "#f4f4f4", // Trail color
    backgroundColor: "#3e98c7", // Background color
  });

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <EnableProfileModal
        showModal={enableProfile}
        setShowModal={setEnableProfile}
      />

      <div className="dash_card my-4 justify-content-center align-items-center">
        {isLoading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row>
            <Col lg={3} sm={5} xs={5} md={3} xl={2}>
              <div className="cprogress">
                <CircularProgressbarWithChildren
                  value={data?.profilePercent || 0}
                  strokeWidth={4}
                  styles={customStyles}
                  className="circular-progress"
                >
                  {data?.dp ? (
                    <img
                      loading="lazy"
                      src={data?.dp}
                      alt="user profile"
                      className="userdp"
                    />
                  ) : (
                    <img
                      src={empimg}
                      loading="lazy"
                      alt="user profile"
                      className="userdp"
                    />
                  )}
                </CircularProgressbarWithChildren>
              </div>
            </Col>

            <Col
              lg={4}
              sm={6}
              xs={6}
              xl={4}
              md={4}
              align="left"
              className="ms-4"
            >
              <div className="h4 text-muted mt-1">Hey, {data?.fullName}!</div>

              <Button
                className="editprofileBtn mt-3"
                onClick={() => navigate("/employer/edit-profile")}
              >
                Edit Profile
              </Button>
              {data.basicProfileStatus === 0 && (
                <p className="d-flex justify-content-left align-items-left mt-2">
                  Incomplete profile <AiFillWarning className="m-1" />
                </p>
              )}
            </Col>
            <Col lg={4} sm={12} xs={12} md={4} xl={4} align="right">
              <Row>
                <Col className="p-1">
                  <div
                    style={{
                      backgroundColor: "#fffbde",
                      borderRadius: "15px",
                      color: "white",
                      padding: "14px",
                    }}
                    align="center"
                  >
                    {!dashdata?.planName && (
                      <h5 className="mt-4" style={{ color: "#000" }}>
                        No active plan
                      </h5>
                    )}
                    {dashdata?.planName && (
                      <h6 style={{ color: "#404040" }}>{dashdata?.planName}</h6>
                    )}
                    {dashdata?.planExpiryDate && (
                      <p className="mt-2" style={{ color: "#404040" }}>
                        Valid till &nbsp;
                        {Moment.utc(dashdata?.planExpiryDate).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    )}
                    {dashdata?.planExpiryDate && (
                      <Button
                        style={{
                          backgroundColor: "#FFFFFF",
                          color: "black",
                          borderRadius: "10px",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={() => navigate("/employer/plan-history")}
                      >
                        View Details
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>

      <div className="mt-5 justify-content-center align-items-center">
        <Row className="justify-content-center align-items-center">
          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} align="center">
              <Link to="/employer/plans" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#dfffe7" }}>
                  <img src={plan} alt="" className="homeIcon" />
                  <br />
                  Plans
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} align="center" onClick={gotoPostJob}>
              <Link to="" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#fbe7f8" }}>
                  <img src={jobs} alt="" className="homeIcon" />
                  <br />
                  Post Job
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} align="center">
              <Link to="/employer/applications" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#fcfacf" }}>
                  <img src={applications} alt="" className="homeIcon" />
                  {isNew === true && (
                    <div
                      style={{
                        backgroundColor: "#ff5c09",
                        height: "10px",
                        width: "10px",
                        marginTop: "-30px",
                        marginLeft: "130px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  )}
                  <br />
                  Applications
                </div>
              </Link>
            </Col>
          )}

          {isLoading ? (
            <Col xs={6} lg={3} md={3} align="center">
              {ShowprofileSkeleton()}
            </Col>
          ) : (
            <Col xs={6} lg={3} md={3} align="center">
              <Link to="/employer/shortlisted-chefs" style={{ color: "#000" }}>
                <div className="homeCard" style={{ background: "#e2ebf8" }}>
                  <img src={shortlist} alt="" className="homeIcon" />
                  <br />
                  Shortlisted
                </div>
              </Link>
            </Col>
          )}
        </Row>
      </div>
      {images && (
        <div className="mb-5 mt-5">
          <ImageSlider images={images} className="img-fluid" />
        </div>
      )}
      <Row className="mt-3">
        <Col lg="6" md="6">
          <p className="me-2 pt-3">Overview</p>
          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span
                  style={{
                    color: "#ff5c09",
                    fontSize: "18px",
                    marginTop: "-5px",
                  }}
                >
                  <BsCardText />
                </span>
              </div>
              <div className="ms-3">
                <br />
                {dashdata?.totalJobApplications ? (
                  <b>{dashdata?.totalJobApplications}</b>
                ) : (
                  <b>0</b>
                )}

                <p>Applications received</p>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span
                  style={{
                    color: "#ff5c09",
                    fontSize: "18px",
                    marginTop: "-5px",
                  }}
                >
                  <BsFillBriefcaseFill />
                </span>
              </div>
              <div className="ms-3">
                <br />
                {dashdata?.totalActiveJobs ? (
                  <b>{dashdata?.totalActiveJobs}</b>
                ) : (
                  <b>0</b>
                )}

                <p>Jobs posted</p>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="overviewcard">{ShowoverviewSkeleton()}</div>
          ) : (
            <div className="overviewcard">
              <div className="overviewcircle">
                <span
                  style={{
                    color: "#ff5c09",
                    fontSize: "18px",
                    marginTop: "-5px",
                  }}
                >
                  <BsCardText />
                </span>
              </div>
              <div className="ms-3">
                <br />
                {dashdata?.totalProfileShortlists ? (
                  <b>{dashdata?.totalProfileShortlists}</b>
                ) : (
                  <b>0</b>
                )}

                <p>Profiles shortlisted</p>
              </div>
            </div>
          )}
        </Col>
        <Col lg="6" md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="pt-3">Top Chefs</p>
            <Link
              to="/employer/find-chef"
              style={{ cursor: "pointer", color: "#fc5509" }}
            >
              See all
            </Link>
          </div>
          {isLoading ? (
            <div className="overviewcard">{ShowtopcontentSkeleton()}</div>
          ) : (
            <div style={{ marginTop: "-10px" }}>
              {chefdatas?.map((item, index) => (
                <div className="overviewcard">
                  <div>
                    {item?.dp && (
                      <img
                        src={item?.dp}
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50px",
                        }}
                        alt=""
                      />
                    )}
                    {!item?.dp && (
                      <img
                        src={chefimg}
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50px",
                        }}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="ms-3">
                    <b>{item?.fullName}</b>
                    <div>{item?.currentCityName}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <br />
      <br />
    </>
  );
}

export default DashHome;
