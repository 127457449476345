//-------------------------------IMPORT START--------------------------//
import React, { useEffect, useState } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { SlBookOpen } from "react-icons/sl";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { SiTinyletter } from "react-icons/si";
import { MdOutlineChatBubbleOutline, MdOutlineEventAvailable } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { FaRegBookmark } from "react-icons/fa";
import { API_PATHS } from "../../utils/constants/api.constants";
import { getclientProfile } from "../../store/slices/clientprofile";
// import { getMessageNotification } from "../../store/slices/config";
//-------------------------------IMPORT END--------------------------//

function ClientSideMenu() {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(true);
  const [expand, setExpand] = useState({
    findCooks: false,
    plans: false,
    interests: false,
    events: false,
  });

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const data1 = JSON.parse(localStorage.getItem("user"));
  const [weavyToken, setWeavyToken] = useState();
  const weavyId = localStorage.getItem("weavyId");
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  useEffect(() => {
    setData(data1?.data);
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      // console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  React.useEffect(() => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        if (data?.user?.response?.status === 401) {
          logout();
          return;
        }
        setData(data?.user?.data);
      })
      .catch(({ message }) => {});
  }, []);

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  // useEffect(() => {
  //   if (weavyToken) {
  //     dispatch(getMessageNotification(weavyToken))
  //       .unwrap()
  //       .then((data) => {})
  //       .catch(({ message }) => {});
  //   }
  // }, [weavyToken]);

  const toggleCollapse = (section) => {
    setExpand((prevExpand) => ({
      ...Object.fromEntries(Object.keys(prevExpand).map((key) => [key, false])),
      [section]: !prevExpand[section],
    }));
  };
  const handleNavTitleClick = (section, e) => {
    e.stopPropagation();
    e.preventDefault();

    toggleCollapse(section);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/employer-login");
  };

  const isActive = (path) => window.location.pathname === path;

  return (
    <>
      <div>
        <SidebarMenu className="mt-4" expanded={collapse}>
          <SidebarMenu.Collapse className="sidebar">
            <SidebarMenu.Body>
              <SidebarMenu.Nav
                className="mb-3"
                onClick={() => navigate("/client/dashboard")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/dashboard") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <IoHomeOutline className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/profile")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/profile") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <FaRegUser className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Profile</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/requirement")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/requirement") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <SlBookOpen className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Requirement</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  onClick={(e) => handleNavTitleClick("findCooks", e)}
                  className="d-flex justify-content-center align-items-left"
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <TbChefHat
                      className="navIcon"
                      style={{
                        height: "22px",
                        width: "22px",
                        marginLeft: "-6px",
                      }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Find Cooks</SidebarMenu.Nav.Title>
                  {expand.findCooks ? (
                    <BiChevronUp
                      style={{ marginLeft: "55px", marginTop: "5px" }}
                    />
                  ) : (
                    <BiChevronDown
                      style={{ marginLeft: "55px", marginTop: "5px" }}
                    />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.findCooks}>
                  <SidebarMenu.Nav className="d-flex justify-content-left align-items-left flex-column">
                    <SidebarMenu.Nav.Link
                      onClick={() => navigate("/client/find-house-cook")}
                    >
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/find-house-cook")
                            ? "active-links"
                            : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        House Cook
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                  <SidebarMenu.Nav className="d-flex justify-content-left align-items-left flex-column">
                    <SidebarMenu.Nav.Link
                      onClick={() => navigate("/client/find-party-cook")}
                    >
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/find-party-cook")
                            ? "active-links"
                            : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Party Cook
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>

                  <SidebarMenu.Nav className="d-flex justify-content-left align-items-left flex-column">
                    <SidebarMenu.Nav.Link
                      onClick={() => navigate("/client/find-catering")}
                    >
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/find-catering")
                            ? "active-links"
                            : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Catering
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  onClick={(e) => handleNavTitleClick("plans", e)}
                  className="d-flex justify-content-center align-items-center"
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <TbCoinRupee
                      style={{
                        height: "21px",
                        width: "21px",
                        marginLeft: "-5px",
                      }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Plans</SidebarMenu.Nav.Title>
                  {expand.plans ? (
                    <BiChevronUp style={{ marginLeft: "97px" }} />
                  ) : (
                    <BiChevronDown style={{ marginLeft: "97px" }} />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.plans}>
                  <SidebarMenu.Nav
                    className="justify-content-left align-items-left"
                    onClick={() => navigate("/client/plans")}
                  >
                    <SidebarMenu.Nav.Link>
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/plans") ? "active-links" : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Buy Plans
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>

                  <SidebarMenu.Nav
                    className="justify-content-left align-items-left"
                    onClick={() => navigate("/client/plan-history")}
                  >
                    <SidebarMenu.Nav.Link>
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/plan-history") ? "active-links" : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        My Plans
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  onClick={(e) => handleNavTitleClick("interests", e)}
                  className="d-flex justify-content-center align-items-center"
                >
                  <SidebarMenu.Nav.Icon className="mx-2">
                    <SiTinyletter
                      style={{
                        height: "22px",
                        width: "22px",
                        marginLeft: "-13px",
                      }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    Interests Received
                  </SidebarMenu.Nav.Title>
                  {expand.interests ? (
                    <BiChevronUp style={{ marginLeft: "3px" }} />
                  ) : (
                    <BiChevronDown style={{ marginLeft: "3px" }} />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.interests}>
                  <SidebarMenu.Nav
                    className="justify-content-left align-items-left"
                    onClick={() => navigate("/client/requirement-request")}
                  >
                    <SidebarMenu.Nav.Link>
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/requirement-request")
                            ? "active-links"
                            : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        House Interests
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>

                  <SidebarMenu.Nav
                    className="justify-content-left align-items-left"
                    onClick={() => navigate("/client/event-requests")}
                  >
                    <SidebarMenu.Nav.Link>
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/event-requests")
                            ? "active-links"
                            : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Event Interests
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  onClick={(e) => handleNavTitleClick("events", e)}
                  className="  d-flex justify-content-center align-items-center"
                >
                  <SidebarMenu.Nav.Icon className="mx-2">
                    <MdOutlineEventAvailable
                      style={{
                        height: "22px",
                        width: "22px",
                        marginLeft: "-13px",
                      }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Events</SidebarMenu.Nav.Title>
                  {expand.events ? (
                    <BiChevronUp style={{ marginLeft: "87px" }} />
                  ) : (
                    <BiChevronDown style={{ marginLeft: "87px" }} />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.events}>
                  <SidebarMenu.Nav className="d-flex justify-content-left align-items-left flex-column">
                    <SidebarMenu.Nav.Link
                      onClick={() => navigate("/client/events")}
                    >
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/events") ? "active-links" : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Manage Event
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>

                  <SidebarMenu.Nav className="d-flex justify-content-left align-items-left flex-column">
                    <SidebarMenu.Nav.Link
                      onClick={() => navigate("/client/post-event")}
                    >
                      <SidebarMenu.Nav.Title
                        className={`${
                          isActive("/client/post-event") ? "active-links" : ""
                        }`}
                        style={{ marginLeft: "25px" }}
                      >
                        Post Event
                      </SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </SidebarMenu.Nav>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>
              <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/client/response")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${isActive("/client/response") ? "active-link" : ""}`}
                  >
                    {/* <SidebarMenu.Nav.Icon className="mr-2">
                    <CiChat1 className="navIcon" style={{ height: "20px", width: "20px" }}/>
                    </SidebarMenu.Nav.Icon> */}
                    <SidebarMenu.Nav.Icon className="pe-2">
                    <MdOutlineChatBubbleOutline
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Responses</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/settings")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/settings") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <IoSettingsOutline
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Settings</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/short-listed-profiles")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/short-listed-profiles")
                      ? "active-link"
                      : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <FaRegBookmark
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    Shortlisted Profiles
                  </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/shared-profiles")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/shared-profiles") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <MdSupportAgent
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Shared by Asst</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/client/message")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/client/message") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <IoChatboxEllipsesOutline
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    Message
                    {messagesNo.count > 0 && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "6px",
                          width: "6px",
                          marginTop: "-13px",
                          marginLeft: "110px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    )}
                  </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          </SidebarMenu.Collapse>
        </SidebarMenu>
      </div>
    </>
  );
}

export default ClientSideMenu;
