import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { contactPrivacy } from "../../../../store/slices/auth";
import { getemployerProfile } from "../../../../store/slices/employerprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import { useEffect } from "react";

function ContactPrivacy() {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [smsContact, setsmsContact] = useState(0);
  const [whatsappContact, setwhatsappContact] = useState(0);
  const [chatContact, setchatContact] = useState(0);
  const [notificationStatus, setnotificationStatus] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);

  const [data, setData] = useState({
    smsContact: 0,
    whatsappContact: 0,
    notificationStatus: 0,
    chatContact: 0,
  });

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        setsmsContact(data.user.data.smsContact);
        setwhatsappContact(data.user.data.whatsappContact);
        setchatContact(data.user.data.chatContact);
        setnotificationStatus(data.user.data.notificationStatus);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  const handleToggle = (value) => {
    if (value === "smsContact") {
      setData({
        ...data,
        smsContact: data.smsContact === 1 ? 0 : 1,
      });
    }
    if (value === "whatsappContact") {
      setData({
        ...data,
        whatsappContact: data.whatsappContact === 1 ? 0 : 1,
      });
    }
    if (value === "notificationStatus") {
      setData({
        ...data,
        notificationStatus: data.notificationStatus === 1 ? 0 : 1,
      });
    }
    if (value === "chatContact") {
      setData({
        ...data,
        chatContact: data.chatContact === 1 ? 0 : 1,
      });
    }
  };

  useEffect(() => {
    document.title = "CookandChef | Contact Privacy";
  }, []);

  const update = () => {
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (
      data.smsContact === 0 &&
      data.chatContact === 0 &&
      data.whatsappContact === 0
    ) {
      setModalMessage(
        "प्रतिक्रियाओं की संख्या घट सकती है, कृपया कम से कम एक विकल्प चयन कीजिए"
      );

      setShowModal(true);
    } else {
      dispatch(contactPrivacy(data))
        .unwrap()
        .then((data) => {
          setModalMessage(data?.user?.message);
          setsuccessModal(true);
          setTimeout(() => {
            setsuccessModal(false);
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <Row
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Col lg={8} className="card text-center p-3">
          <div className="d-flex justify-content-between p-2">
            <div className="text-start">
              <b className="mb-1">संपर्क नंबर</b>
              <p className="mb-1" style={{ textWrap: "wrap" }}>
                उम्मीदवारों के लिए संपर्क नंबर छुपाएं या प्रदर्शित करें
              </p>
            </div>
            <div className="mt-2">
              <Form>
                <Form.Check
                  type="switch"
                  id="contact-number"
                  style={{ height: "4rem", width: "4rem" }}
                  checked={data.smsContact === 1}
                  onChange={() => handleToggle("smsContact")}
                />
              </Form>
            </div>
          </div>
          <hr className="mt-0"></hr>

          <div className="d-flex justify-content-between p-2">
            <div className="text-start">
              <b className="mb-1">WhatsApp नंबर</b>
              <p className="mb-1" style={{ textWrap: "wrap" }}>
                उम्मीदवारों के लिए व्हाट्सएप नंबर छुपाएं या प्रदर्शित करें
              </p>
            </div>
            <div className="mt-2">
              <Form>
                <Form.Check
                  type="switch"
                  id="whatsapp-number"
                  style={{ height: "4rem", width: "4rem" }}
                  checked={data.whatsappContact === 1}
                  onChange={() => handleToggle("whatsappContact")}
                />
              </Form>
            </div>
          </div>
          <hr className="mt-0"></hr>
          <div className="d-flex justify-content-between p-2">
            <div className="text-start">
              <b className="mb-1">चैट विकल्प</b>
              <p className="mb-1" style={{ textWrap: "wrap" }}>
                उम्मीदवारों के साथ चैट सुविधा छिपाएँ या प्रदर्शित करें
              </p>
            </div>
            <div className="mt-2">
              <Form>
                <Form.Check
                  type="switch"
                  id="chat-contact"
                  style={{ height: "4rem", width: "4rem" }}
                  checked={data.chatContact === 1}
                  onChange={() => handleToggle("chatContact")}
                />
              </Form>
            </div>
          </div>
          <hr className="mt-0"></hr>
          <div className="d-flex justify-content-between p-2">
            <div className="text-start">
              <b className="mb-1">सूचनाएं</b>
              <p className="mb-1" style={{ textWrap: "wrap" }}>
                सूचनाएं सक्षम या अक्षम करें
              </p>
            </div>
            <div className="mt-2">
              <Form>
                <Form.Check
                  type="switch"
                  id="notification-status"
                  style={{ height: "4rem", width: "4rem" }}
                  checked={data.notificationStatus === 1}
                  onChange={() => handleToggle("notificationStatus")}
                />
              </Form>
            </div>
          </div>
          <hr className="mt-1"></hr>
          <div className="mb-3 mt-3">
            <Button onClick={update} disabled={isdisabled} className="saveBtn">
              अपडेट
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ContactPrivacy;
