import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import DataSkeleton from "../../auth_screens/dataskeleton";
import { Link } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Modal2 from "../../auth_screens/Modal2";
import { findResponseEmployer } from "../../../store/slices/employerprofile";
import moment from "moment";


function ClientResponse() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        document.title = "CookandChef | Find Jobs";
    }, []);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [search, setSearch] = useSearchParams();
    const querypage = parseInt(search.get("page")) || 1;
    const [data, setData] = useState([]);
    const [totaldata, setTotaldata] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(querypage);
    const itemsPerPage = 25;
    const pagesToShowInitially = 3;
    const [item, setItem] = useState({
        limit: itemsPerPage,
        page: currentPage,
    });


 useEffect(() => {
        const getjobs = () => {
            setisLoading(true);
            dispatch(findResponseEmployer(item))
                .unwrap()
                .then((data) => {
                    setisLoading(false);
                    setData(data)
                    setTotaldata(data?.totalDataCount)
                })
                .catch(({ message }) => {
                    setisLoading(false);
                 })
        };
        getjobs();
    }, [item])

    const handlePageChange = ({ selected }) => {
        const newPage = selected + 1 || currentPage;
        setCurrentPage(newPage);
        setItem({
            ...item,
            page: newPage,
        });
    };

    const handleNavigation = (item) => {
        navigate(`/client/house-cook-details/${item.id}`)
    }

    const activityTypeMapping = {
        'chatinteraction': 'Chat',
        'mobileinteraction': 'Call',
        'whatsappinteraction': 'Whatsapp',
        'applied': 'Applied',
        'shortlisted': 'Shortlisted',
        'viewed': 'Viewed Contact',
        'cancelled': 'Cancelled',
        'viewedmobilenumber': 'Viewed Mobile Number'
    };

    return (
        <>
            <Modal2
                showModal={showModal}
                modalMessage={modalMessage}
                setShowModal={setShowModal}
            />
            <div>
                <div
                    className="text-start my-4"
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#ff5c09",
                    }}
                >
                    <Link to="/employer/dashboard" className="bcrumb">
                        Home
                    </Link>
                    &nbsp;&bull;&nbsp;Responses
                </div>
                <div className="tablecontainer">
                    <Table className="table-custom" size="lg">
                        <thead >
                    {isLoading?(DataSkeleton()):
                            (<tr style={{fontSize: '16px'}}>
                                <th >Name</th>
                                <th className="text-center">Activity</th>
                                <th></th>
                            </tr>)}
                        </thead>
                        {isLoading?(DataSkeleton()): (data && data?.data?.length > 0 ?  <tbody>
                            {data && data?.data?.length > 0 && data.data.map((i, index) => {
                                return <tr key={index} className="borderBt-tr">
                                    <td>
                                        <p  style={{fontSize: '16px', fontWeight: 700, lineHeight: '10px', marginTop: '20px'}}>{i?.cookId?.fullName}</p>
                                        <p  style={{fontSize: '10px' }}>{moment(i?.updatedAt).format('DD MMM yyyy')}</p>
                                    </td>
                                    <td className="text-center vertical-middle" style={{ fontSize: '14px', fontWeight: 500 }}>
                                        {activityTypeMapping[i?.activity] || i?.activity?.charAt(0).toUpperCase() + i?.activity?.slice(1)}
                                    </td>
                                    {/* <td className="text-center vertical-middle" style={{fontSize: '14px', fontWeight: 500}}>{i?.activity?.charAt(0).toUpperCase() + i?.activity?.slice(1)}                                    </td> */}
                                    <td className="text-end vertical-middle">
                                        <button type="button" class="view_btn btn" onClick={()=>handleNavigation(i?.cookId)} style={{fontSize: '12px'}}>View Profile</button>
                                    </td>
                                </tr>
                            })}

                        </tbody>  :
                        <>
                        <p className="px-2 mt-2">No records found!</p>
                        </> 
                        )}
                    </Table>

                </div>
                {totaldata / itemsPerPage > 1 && (
                    <div className="justify-content-right align-right">
                        <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totaldata / itemsPerPage)}
                            marginPagesDisplayed={0}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default ClientResponse;
