import React, { useEffect } from "react";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
const RefundPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "Refund Policy | CookandChef";
  }, []);

  return (
    <>
      <NavMenu />
      <div className="container mt-5">
        <div className="d-md-flex">
          <div className="help-left text-left p-3 order-md-0">
            <h5>Refund Policy</h5>
            <div
              className="help-lef-inner mt-3"
              style={{ textAlign: "justify", textWrap: "wrap" }}
            >
              <p style={{ textWrap: "wrap" }}>
                <ul>
                  <li>
                    CookandChef India Private Limited does neither provide
                    salary to a Cook(House hold, Party Cook, Chef or Catering)
                    nor takes commission from Client or Employer. We only sell
                    plan to avail the services.
                  </li>
                  <li>
                    CookandChef India will not be involved in any payment deal
                    made between Clients or Cooks. So, no refunds should be
                    expected from CookandChef India Private Limited.
                  </li>
                </ul>
              </p>
              <p>
                1. Eligibility for Refunds:
                <ul>
                  <li>
                    Full Refunds:
                    <ul>
                      <li>
                        Technical issues: If the platform experiences technical
                        issues that significantly hinder your ability to use it,
                        and we are unable to resolve them within 5 business
                        days, you may be eligible for a full refund.
                      </li>
                      <li>
                        Duplicate payments: If you are charged twice for the
                        same service, you will receive a full refund for the
                        duplicate payment.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Partial Refunds:
                    <ul>
                      <li>
                        Early cancellation: If you cancel your subscription
                        before the end of the term, you may be eligible for a
                        partial refund based on the unused portion of your
                        subscription.
                      </li>
                      <li>
                        Unsatisfied with results: If you are not satisfied with
                        the number of applications received or the overall
                        results after a reasonable period of using paid
                        features, you may be eligible for a partial refund based
                        if brought to our notice within 15 days or as mentioned
                        in the subscription.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
              <p>
                2. Non-Refundable Services:
                <ul>
                  <li>
                    Additional support assistance amount is non-refundable after
                    7 days of the plan purchase.
                  </li>
                  <li>
                    Jobs Posted: If a user has posted the maximum number of jobs
                    allowed under their plan, no refunds can be processed after
                    15 days from the date of purchase.
                  </li>
                  <li>
                    Boost Plans: No refund or cancellation would be permitted
                    after payment.
                  </li>
                  <li>
                    CV builder plans: No refund or cancellation would be
                    permitted after payment
                  </li>
                  <li>
                    In case of violation of any terms and conditions CookandChef
                    holds the rights to refuse or cancel the refund request.
                  </li>
                  <li>
                    To ensure a fair and direct hiring experience, our
                    application is strictly reserved for individual job seekers
                    or providers. Refunds will not be issued to accounts
                    identified as belonging to consultancies or agencies.
                  </li>
                </ul>
              </p>
              <p>
                3. Requesting a Refund:
                <ul>
                  <li>
                    All refund requests must be submitted in writing to
                    support@cookandchef.in within 15 days or as specified in the
                    plans.
                  </li>
                  <li>
                    You will be required to provide a detailed explanation of
                    the reasons for requesting a refund.
                  </li>
                  <li>
                    Our team will review your request and respond within 7-8
                    business days.
                  </li>
                </ul>
              </p>
              <p>
                4. Refund Processing:
                <ul>
                  <li>
                    Approved refunds will be processed to the original payment
                    method used within 3-5 business days.
                  </li>
                  <li>
                    Processing fees or bank charges may apply depending on the
                    payment method.
                  </li>
                </ul>
              </p>
              <p>
                CookandChef is not responsible in the above situations, hence
                any refund can’t be processed.
                <ul>
                  <li>
                    Employee Leaving: CookandChef is not liable for any
                    replacements, recovery, or liability in the event of an
                    employee leaving their position after joining.
                  </li>
                  <li>
                    Employee Misconduct: We are not responsible for any
                    replacements, recovery, or liability resulting from Cooks
                    misconduct, including unprofessional activities or
                    misbehaviour by cooks.
                  </li>
                  <li>
                    Employee Background Verification: The company assumes no
                    liability for replacements, recovery, or liability if a
                    cook, provides false information in their profile during
                    background verification.
                  </li>
                  <li>
                    Employee Criminal Activities: Our company is not responsible
                    for any replacements, recovery, or liability arising from
                    unlawful acts committed by cooks.
                  </li>
                </ul>
              </p>
              <p>
                We take no responsibility in the above situations and unable to
                process any refunds.
              </p>
              <p>
                Additional Information:
                <ul>
                  <li>
                    This refund policy is subject to change at any time without
                    prior notice.
                  </li>
                  <li>
                    We encourage you to carefully review the features and
                    services offered before making a purchase.
                  </li>
                  <li>
                    If you have any questions about this policy, please contact
                    us at support@cookandchef.in
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default RefundPolicy;
