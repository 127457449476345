import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { BsCheck, BsX } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
//swiper
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getcookPlans } from "../../../../store/slices/cookplan";
import { useEffect } from "react";
import Modal2 from "../../auth_screens/Modal2";
import { EffectCards } from "swiper/modules";
function CookPlans() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [data, setData] = useState([]);
  const [planType, setPlanType] = useState("short");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData = await dispatch(getcookPlans());

        setData(plansData?.payload?.user?.data);
      } catch (error) {
        setModalMessage(error);
        setShowModal(true);
      }
    };

    fetchPlans();
  }, [dispatch, planType]);

  useEffect(() => {
    document.title = "CookandChef | Plan";
  }, []);

  const colordata = ["#048A18", "#004EDE", "#D700DE"];

  const getColorForIndex = (index) => {
    const colorIndex = index % colordata.length;
    return colordata[colorIndex];
  };

  const handleSelectPlan = (selectedPlan) => {
    navigate(`/hin/cook/checkout/${selectedPlan.id}`, {
      state: { planData: selectedPlan },
    });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;प्लान
      </div>

      <Row
        className="mt-4 justify-content-center align-items-center"
        align="center"
      >
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            <Col
              lg={4}
              md={4}
              xl={4}
              className=" d-none d-lg-flex d-md-none flex-column justify-content-center align-items-center mt-3"
            >
              <div
                className="planCard"
                style={{
                  background: "#fff",
                  height: "66dvh",
                  borderRadius: "14px",
                  boxShadow: "8px 8px 5px #e2dfff",
                }}
              >
                <p
                  className="my-2 text-center"
                  style={{
                    fontSize: "3dvh",
                    fontWeight: "500",
                    color: `${getColorForIndex(index)}`,
                  }}
                >
                  {item?.cookPlanName}
                </p>
                <p
                  className="text-center my-2"
                  style={{ fontSize: "4dvh", fontWeight: "700" }}
                >
                  ₹&nbsp;{item?.price}
                </p>
                <p
                  className="text-center"
                  style={{ fontSize: "2.5dvh", color: "#05506B" }}
                >
                  {item?.validityInDays}&nbsp;Days
                </p>

                <div
                  className="justify-content-center align-items-center"
                  align="center"
                >
                  <Button
                    className="text-center planBtn"
                    onClick={() => handleSelectPlan(item)}
                  >
                    Select Plan
                  </Button>
                </div>
                <div className="text-start">
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item?.profileBoostRank === 1 ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    )}
                    <span className="text-muted">Profile boost</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "2.5dvh",
                        width: "2.5dvh",
                      }}
                    />
                    Apply {item?.actionPerDay} jobs per day
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "2.5dvh",
                        width: "2.5dvh",
                      }}
                    />

                    <span className="text-muted">
                      Apply {item?.actionPerMonth} jobs per month
                    </span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item?.resumeBuilder === 1 ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                    )}
                    <span className="text-muted">Resume builder</span>
                  </p>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>
      <Swiper
        effect={"cards"}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCards, Navigation, Pagination]}
        className="mySwiper d-sm-block d-md-block d-lg-none"
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <Row className="mt-1 justify-content-center">
              <Col
                className="d-flex flex-column justify-content-center align-items-center"
                xs={9}
                style={{
                  // border: `2px solid ${colordata[2]}`,
                  background: "white",
                  height: "58dvh",
                  borderRadius: "14px",
                }}
              >
                <div className="text-start">
                  <p
                    className="text-center"
                    style={{
                      fontSize: "3dvh",
                      fontWeight: "500",
                      color: `${getColorForIndex(index)}`,
                    }}
                  >
                    {item?.cookPlanName}
                  </p>
                  <p
                    className="text-center my-2"
                    style={{ fontSize: "4dvh", fontWeight: "700" }}
                  >
                    ₹&nbsp;{item?.price}
                  </p>

                  <p
                    className="text-center  my-2"
                    style={{ fontSize: "2.5dvh", color: "#05506B" }}
                  >
                    {item?.validityInDays}&nbsp;Days
                  </p>
                  <div
                    className="justify-content-center align-items-center"
                    align="center"
                  >
                    <Button
                      className="text-center planBtn mt-3"
                      onClick={() => handleSelectPlan(item)}
                    >
                      Select Plan
                    </Button>
                  </div>
                  <div className="text-start">
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item?.profileBoostRank === 1 ? (
                        <BsCheck
                          style={{
                            color: "green",
                            padding: "",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      )}
                      <span className="text-muted">Profile boost</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",
                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                      <span className="text-muted">
                        Apply {item?.actionPerDay} jobs per day
                      </span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",
                          borderRadius: "100%",
                          height: "2.5dvh",
                          width: "2.5dvh",
                        }}
                      />
                      <span className="text-muted">
                        Apply {item?.actionPerMonth} jobs per month
                      </span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item?.resumeBuilder === 1 ? (
                        <BsCheck
                          style={{
                            color: "green",
                            padding: "",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",

                            borderRadius: "100%",
                            height: "2.5dvh",
                            width: "2.5dvh",
                          }}
                        />
                      )}
                      <span className="text-muted">Resume builder</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default CookPlans;
