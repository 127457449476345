import React, { useState } from "react";
import { Badge, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import logo from "../../../Assets/Images/nav_logo.png";
import empimg from "../../../Assets/Images/restaurant.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import EmployerMobNavbar from "./employermobilenav";
import settings from "../../../Assets/Images/settings.png";
import signout from "../../../Assets/Images/logout.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import DPSkeleton from "../auth_screens/dpskeleton";

function EmployerNavBar() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [language, setLanguage] = useState("hindi");

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [myData, setMydata] = useState();

  const logout = () => {
    navigate("/employer/dashboard", { replace: true });

    setTimeout(() => {
      // Clear localStorage and set necessary items
      localStorage.clear();
      localStorage.setItem("logout", Date.now().toString());
      localStorage.setItem("role", "employer");
      // Navigate to the login page
      navigate("/employer-login");
    }, 100);
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        // Perform logout operations in this tab
        // For example, redirect to the login page
        window.location.href = "/employer-login";
      }
    };

    // Attach the event listener
    window.addEventListener("storage", handleStorageChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const data1 = useSelector((state) => state.employerData.empProfileData);
  useEffect(() => {
    setMydata(data1);
  }, [data1]);

  const popover = (
    <Popover>
      <Popover.Body>
        <Link className="d-flex justify-content-left align-items-center mt-1">
          <img src={settings} className="navIcon" alt="" />
          <Link
            style={{ fontSize: "14px", color: "#000" }}
            to="/employer/settings"
          >
            &nbsp;सेटिंग
          </Link>
        </Link>

        <Link className="d-flex justify-content-left align-items-center mt-2">
          <img src={signout} className="navIcon" alt="" />

          <div style={{ fontSize: "14px", color: "#000" }} onClick={logout}>
            &nbsp;लॉग आउट
          </div>
        </Link>
      </Popover.Body>
    </Popover>
  );

  const languageChange = (e) => {
    if (e.target.value === "hindi") {
      navigate("/hin/employer/dashboard");
    } else {
      navigate("/employer/dashboard");
    }
  };

  return (
    <>
      <Row
        className="d-flex justify-content-center"
        style={{ backgroundColor: "white" }}
      >
        <Col
          xs={11}
          md={10}
          lg={9}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <div className="d-lg-none d-sm-block d-md-block">
              <EmployerMobNavbar />
            </div>
            <img
              style={{ cursor: "pointer", height: "7.5dvh", width: "19dvh" }}
              onClick={() => navigate("/")}
              src={logo}
              className="d-none d-md-none d-lg-block d-xl-block m-3"
              alt="app logo"
            />
          </div>
          <div className="position-relative">
            {isLoading ? (
              <div>
                <OverlayTrigger>{DPSkeleton()}</OverlayTrigger>
              </div>
            ) : (
              <div className="d-flex">
                <select
                  value={language}
                  className="m-2 select-no-border"
                  onChange={languageChange}
                >
                  <option className="p-2" value="english">
                    English
                  </option>
                  <option className="p-2" value="hindi">
                    हिंदी
                  </option>
                </select>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popover}
                >
                  {myData?.user?.data?.dp ? (
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "150px",
                        border: "solid 1px #a020f0",
                        padding: "2px",
                      }}
                      src={myData?.user?.data?.dp}
                      alt="user"
                    />
                  ) : (
                    <img
                      alt="user"
                      src={empimg}
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "100%",
                        padding: "2px",
                        border: "solid 1px #a020f0",
                      }}
                    />
                  )}
                </OverlayTrigger>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default EmployerNavBar;
