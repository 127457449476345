// ChatBot.js

import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import botimg from "../../../Assets/Images/chat-assistant-cookandchef.png";

import "./Helpchat.css";
import EmployerSupport from "./EmployerSupport";
import ClientSupport from "./ClientSupport";
import ChefSupport from "./chefSupport";
import HouseSupport from "./HouseSupport";
import PartyCookSupport from "./PartyCookSupport";
import ChefPartySupport from "./ChefPartySuport";
import HousePartySupport from "./HousePartySuport";
import CateringSupport from "./CateringSupport";

const ChatBothind = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const [userData,setUserData] = useState()
  const [data1,setData1] = useState()
  const userData1 = JSON.parse(localStorage.getItem("user"));
  const data2 = useSelector((state) => state?.cookData?.user);

  // useEffect(() => {
  //   if (window.location.pathname === "/cook/help") {
  //     setIsExpanded(true);
  //   }
  // }, [window.location.pathname]);

  useEffect(()=>{

  setUserData(userData1)


  setData1(data2)

  },[isExpanded])

  const handleLogoClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isLoading === false && (
        <div
          style={{
            cursor: "pointer",
            transition: "width 0.5s ease",
          }}
        >
          {isExpanded === false ? (
            <img
              onClick={handleLogoClick}
              src={botimg}
              alt="Cookie"
              style={{ width: "70px", height: "70px" }}
            />
          ) : (
            <div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginTop: "10px",
                  backgroundColor: "#fff",
                  overflowY: "auto",
                  maxHeight: "500px",
                  minHeight: "400px",
                  width: "320px",
                }}
              >
                <div
                  style={{
                    zIndex: 9999,
                    background: " hsla(225, 78%, 59%, 1)",

                    background:
                      "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",

                    background:
                      "-moz-linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",

                    background:
                      "-webkit-linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)",
                    position: "sticky",
                    top: 0,
                  }}
                  className="pe-2"
                >
                  <div className="p-1">
                    <img
                      onClick={handleLogoClick}
                      src={botimg}
                      className="me-2"
                      alt="Cookie"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />

                    <span
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Cookie
                    </span>
                  </div>
                  <div align="right" style={{ marginTop: "-35px" }}>
                    <button
                      onClick={handleLogoClick}
                      type="button"
                      className="close"
                      aria-label="Close"
                      style={{
                        border: "none",
                        background: "transparent",
                        color: "white",
                        fontSize: "25px",
                        textAlign: "right",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "left", padding: "5px" }}>
                  {userData?.data?.role === "employer" ? (
                    <div>
                      <EmployerSupport />
                    </div>
                  ) : userData?.data?.role === "client" ? (
                    <div>
                      <ClientSupport />
                    </div>
                  ) : userData?.data?.role === "cook" &&
                    data1?.data?.cookType === 2 &&
                    !data1?.data?.partyCook ? (
                    <div>
                      <ChefSupport />
                    </div>
                  ) : userData?.data?.role === "cook" &&
                    data1?.data?.cookType === 1 &&
                    !data1?.data?.partyCook ? (
                    <div>
                      <HouseSupport />
                    </div>
                  ) : data1?.data?.partyCook === 1 && !data1?.data?.cookType ? (
                    <div>
                      <PartyCookSupport />
                    </div>
                  ) : data1?.data?.partyCook === 1 &&
                    data1?.data?.cookType === 2 ? (
                    <div>
                      <ChefPartySupport />
                    </div>
                  ) : data1?.data?.partyCook === 1 &&
                    data1?.data?.cookType === 1 ? (
                    <div>
                      <HousePartySupport />
                    </div>
                  ) : data1?.data?.memberType === 2 ? (
                    <div>
                      <CateringSupport />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatBothind;
