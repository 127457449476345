//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangePassword } from "../../../store/slices/auth";
import {
  DisableAccount,
  EnableAccount,
  getclientProfile,
} from "../../../store/slices/clientprofile";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function Changepassword() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [type, setType] = useState("");

  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);

  const dispatch = useDispatch();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const handleOpenModal = (type) => {
    setShowModal(true);
    setType(type);
  };

  React.useEffect(() => {
    getProfile();
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | ChangePassword";
  }, []);

  const getProfile = () => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const navigate = useNavigate("");
  const [errors, setErrors] = useState({});

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;

    switch (name) {
      case "oldPassword":
        validationErrors[name] =
          value === "" ? "Please enter your current password" : "";
        break;
      case "newPassword":
        validationErrors[name] =
          value === "" || !isValidPassword(value)
            ? "Password must be alphanumeric and min 8 characters"
            : "";
        break;
      case "confirmPassword":
        validationErrors[name] =
          value === "" || !isValidPassword(value)
            ? "New password and confirm password should be same"
            : "";
        break;
      default:
        break;
    }

    setData({
      ...data,
      [name]: value,
    });

    setErrors(validationErrors);
  };

  async function changePassword(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!data.oldPassword) {
      validationErrors.oldPassword = "Please enter your current password";
    }
    if (!data.newPassword) {
      validationErrors.newPassword = "Please enter password";
    } else if (!isValidPassword(data.newPassword)) {
      validationErrors.newPassword =
        "Password must be alphanumeric and min 8 characters";
    }
    if (!data.confirmPassword) {
      validationErrors.confirmPassword = "Please enter confirm password";
    } else if (data.newPassword !== data.confirmPassword) {
      validationErrors.confirmPassword =
        "New password and confirm password should be same";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const item = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      dispatch(ChangePassword(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Password changed successfully");
          setsuccessModal(true);
          // if (data.status === 1) {
          setTimeout(() => {
            setsuccessModal(false);
            navigate("/employer/dashboard");
          }, 3000);

          //   }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Row>
        <Col
          lg={8}
          xl={8}
          md={10}
          xs={12}
          sm={12}
          className="card text-center p-4 ms-3"
        >
          <Form
            noValidate
            autoComplete="off"
            onSubmit={changePassword}
            className="text-center mt-3 p-2"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "",
              borderRadius: "0px 15px 15px 0px",
            }}
          >
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4  position-relative text-start">
                <Form.Control
                  name="oldPassword"
                  onChange={handleChange}
                  value={data.oldPassword}
                  isInvalid={!!errors.oldPassword}
                  className="input1"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter current password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.oldPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4 position-relative text-start">
                <Form.Control
                  name="newPassword"
                  onChange={handleChange}
                  value={data.newPassword}
                  isInvalid={!!errors.newPassword}
                  className="input1"
                  type={showPassword2 ? "text" : "password"}
                  placeholder="Enter new password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4 position-relative text-start">
                <Form.Control
                  name="confirmPassword"
                  onChange={handleChange}
                  value={data.confirmPassword}
                  isInvalid={!!errors.confirmPassword}
                  className="input1"
                  type={showPassword3 ? "text" : "password"}
                  placeholder="Confirm password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword3(!showPassword3)}
                >
                  {showPassword3 ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group>
                <Button
                  className="cancelBtn"
                  onClick={() => navigate("/employer/dashboard")}
                >
                  Cancel
                </Button>
                <Button
                  className="saveBtn ms-2"
                  disabled={isdisabled}
                  type="submit"
                >
                  Submit
                </Button>
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
    </>
  );
}

export default Changepassword;
