import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button ,Form} from "react-bootstrap";
import { FaStar, FaRegStar } from "react-icons/fa"; // Import star icons
import { useNavigate } from "react-router-dom";
import { StoreCookRating } from "../../store/slices/employerprofile";
import { useDispatch } from "react-redux";
import SuccessModal from "../../components/auth_screens/SuccessModal";

function StarRating({ value, onClick }) {

  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index} onClick={() => onClick(index + 1)}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

export function StarRating1({ value }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index} >
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}


function CookRatingModal({ rateModal, id, setRateModal,getDetails ,data,role}) {
  const navigate = useNavigate();

  const [hygiene, setHygiene] = useState(0 );
  const [taste, setTaste] = useState(0);
  const [punctuality, setPunctuality] = useState(0);
  const [behaviour, setBehaviour] = useState(0);
  const [comments,setComments] = useState("");
  const [commentsError,setCommentsError]= useState("");
  const [successModal, setsuccessModal] = useState(false);
  const dispatch = useDispatch();
  const [modalMessage, setModalMessage] = useState("");
  const [ratingError, setRatingError] = useState("");
  const handleCloseModal = () => {
    setRateModal(false);
    setRatingError("");
    setHygiene(0);
          setComments("")
          setTaste(0);
          setPunctuality(0);
          setBehaviour(0);
  };

  

 
  const Submit = () => {
    if (hygiene === 0 || taste === 0 || punctuality === 0 || behaviour === 0) {
      setRatingError("All categories need to be rated");
      return;
    } else {
      setRatingError("");
    }
    const item = {
      cookId: id,
      hygiene: hygiene,
      taste: taste,
      punctuality: punctuality,
      behaviour: behaviour,
      comment:comments || undefined
    };

    dispatch(StoreCookRating(item))
      .then((data) => {
        if (data?.payload?.data?.message) {
          setModalMessage(data?.payload?.data?.message)
          setsuccessModal(true)

          setTimeout(() => {
            setsuccessModal(false)
          }, 1000);
          setRateModal(false);
          setHygiene(0);
          setComments("")
          setTaste(0);
          setPunctuality(0);
          setBehaviour(0);
        }
        getDetails()
      })
      .catch(({ message }) => {});
  };
  const handleRatingClick = (factor, rating) => {
    setRatingError("");
    switch (factor) {
      case "hygiene":
        setHygiene(rating);
        break;
      case "taste":
        setTaste(rating);
        break;
      case "punctuality":
        setPunctuality(rating);
        break;
      case "behaviour":
        setBehaviour(rating);
        break;
      default:
        break;
    }
  };

  const handleChange =(e)=>{
    setComments(e.target.value)
  }

  return (
    <>
    <SuccessModal
    showModal={successModal}
    modalMessage={modalMessage}
    setShowModal={setsuccessModal}
  />
      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={rateModal}
        onHide={handleCloseModal}
      >
        <Modal.Body align="left">
          {/* Display star ratings for each factor */}

          <h6 className="text-center mb-3">Rate this {role}</h6>
          <div className="d-flex justify-content-between mt-2">
          <div>
              <span>Behaviour: </span>
              <StarRating
                value={behaviour}
                onClick={(rating) => handleRatingClick("behaviour", rating)}
              />
            </div>
            <div>
              <span>Hygiene: </span>
              <StarRating
                value={hygiene}
                onClick={(rating) => handleRatingClick("hygiene", rating)}
              />
            </div>
           
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div>
              <span>Punctuality: </span>
              <StarRating
                value={punctuality}
                onClick={(rating) => handleRatingClick("punctuality", rating)}
              />
            </div>
            <div>
              <span>Taste: </span>
              <StarRating
                value={taste}
                onClick={(rating) => handleRatingClick("taste", rating)}
              />
            </div>
          </div>
          <div style={{ color: "red", marginTop: "20px" }}>{ratingError}</div>
          <div className="mt-4">
          <Form>
           
          <Form.Group>
          <span >Comments</span>
          <Form.Control
            value={comments}
            name="comment"
            placeholder="Write a review"
            type="text"
            className="input1"
            onChange={handleChange}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
           
          </Form.Control.Feedback>
        </Form.Group>
        </Form>
          </div>

         
        </Modal.Body>

        <div className="d-flex justify-content-center mt-3">
          <button
            type="button"
            className="modalcancelBtn ms-2"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button type="button" className="modalsaveBtn ms-2" onClick={Submit}>
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}

export default CookRatingModal;
