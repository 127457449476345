export function ExtractResumeNameValue(resumeUrl) {
  let stageorprod = [];
  let split = [];
  let splitremove = [];


  if (resumeUrl && resumeUrl.includes('cnc')) {
    if (resumeUrl.includes('prod')) {
      stageorprod = resumeUrl.split('prod/');
      split = stageorprod[1].split('-');
      splitremove = split.slice(1);
      return splitremove.join("-")
    } else {
      stageorprod = resumeUrl.split('stage/');
      split = stageorprod[1].split('-');
      splitremove = split.slice(1);
      return splitremove.join("-")
    }
  } else if (resumeUrl && resumeUrl.includes('resume')) {
    if (resumeUrl.includes('prod')) {
      stageorprod = resumeUrl.split('prod/');
      split = stageorprod[1].split('-');
      splitremove = split;
      return splitremove.join("-")
    } else {
      stageorprod = resumeUrl.split('stage/');
      split = stageorprod[1].split('-');
      splitremove = split;
      return splitremove.join("-")
    }
  }
  }