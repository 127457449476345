//-------------------------------IMPORT START--------------------------//
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import logo from "../../../Assets/Images/nav_logo.png";
import { useNavigate, NavLink } from "react-router-dom";
import { BsCardChecklist } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { IoSearchSharp } from "react-icons/io5";
import { LuCalendarSearch } from "react-icons/lu";
import { TbHomeSearch } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { API_PATHS } from "../../../utils/constants/api.constants";
import { getcookProfile } from "../../../store/slices/cookprofile";

//-------------------------------IMPORT END--------------------------//

function CookSideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [data, setData] = useState();
  const data1 = JSON.parse(localStorage.getItem("user"));
  const weavyId = localStorage.getItem("weavyId");
  const userData = useSelector((state) => state?.cookData?.user);
  const [weavyToken, setWeavyToken] = useState();
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const isActive = (path) => window.location.pathname === path;

  return (
    <>
      <div>
        <SidebarMenu className="mt-4" expanded={collapse}>
          <SidebarMenu.Collapse className="sidebar">
            <SidebarMenu.Body>
              <SidebarMenu.Nav
                className="mb-3"
                onClick={() => navigate("/hin/cook/dashboard")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/hin/cook/dashboard") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoHomeOutline className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>डैशबोर्ड</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/hin/cook/profile")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/hin/cook/profile") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <FaRegUser className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>प्रोफ़ाइल</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>
              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/hin/cook/cook-profile")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/hin/cook/cook-profile") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <TbChefHat className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>कुक प्रोफाइल</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              {data?.cookType === 2 && (
                <SidebarMenu.Sub className="mb-1 menu_body_menu">
                  <SidebarMenu.Sub.Toggle
                    onClick={() => setExpand(!expand)}
                    className="justify-content-left align-items-left"
                  >
                    <SidebarMenu.Nav.Icon className="pe-2">
                      <TbCoinRupee
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "2px",
                        }}
                      />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>प्लान</SidebarMenu.Nav.Title>
                    {expand ? (
                      <BiChevronUp style={{ marginLeft: "80px" }} />
                    ) : (
                      <BiChevronDown style={{ marginLeft: "80px" }} />
                    )}
                  </SidebarMenu.Sub.Toggle>
                  <SidebarMenu.Sub.Collapse>
                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left flex-column"
                      onClick={() => navigate("/hin/cook/plans")}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/hin/cook/plans") ? "active-links" : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          प्लान खरीदें
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left flex-column"
                      onClick={() => navigate("/hin/cook/plan-history")}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/hin/cook/plan-history")
                              ? "active-links"
                              : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          प्लान्स हिस्ट्री
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                  </SidebarMenu.Sub.Collapse>
                </SidebarMenu.Sub>
              )}

              {data?.cookType === 2 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/jobs")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/jobs") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoSearchSharp className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>नौकरी ढूंढो</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.cookType === 1 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/housejobs")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/housejobs") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbHomeSearch className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      हॉउस कुक जॉब्स खोजें
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.partyCook === 1 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/find-events")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/find-events") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <LuCalendarSearch className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>ईवेंट खोजें</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.memberType === 2 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/find-events")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/find-events") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <LuCalendarSearch className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>ईवेंट खोजें</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.cookType === 2 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/resume-builder")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/resume-builder") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <BsCardChecklist />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      बायोडाटा बिल्डर
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.cookType === 1 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/short-listed-housejobs")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/short-listed-housejobs")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegBookmark className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      नौकरियाँ बचाई गईं
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.cookType === 2 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/short-listed-jobs")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/short-listed-jobs")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegBookmark />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      नौकरियाँ बचाई गईं
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              {data?.partyCook === 1 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/short-listed-events")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/short-listed-events")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegBookmark />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      ईवेंट बचाई गईं
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}
              {data?.memberType === 2 && (
                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/hin/cook/short-listed-events")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/hin/cook/short-listed-events")
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegBookmark />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      ईवेंट बचाई गईं
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              )}

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/hin/cook/settings")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/hin/cook/settings") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoSettingsOutline />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>सेटिंग</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/hin/cook/message")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/hin/cook/message") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoChatboxEllipsesOutline />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    मैसेज&nbsp;
                    {messagesNo.count > 0 && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "6px",
                          width: "6px",
                          marginTop: "-13px",
                          marginLeft: "110px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    )}
                  </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          </SidebarMenu.Collapse>
        </SidebarMenu>
      </div>
    </>
  );
}

export default CookSideMenu;
