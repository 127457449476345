import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { WeavyClient, WeavyProvider, Chat } from "@weavy/uikit-react";
import "@weavy/uikit-react/dist/css/weavy.css";
import { useLocation } from "react-router-dom";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import uimg from "../../../../Assets/Images/user.png";

function ChatSupport() {
  const [allUsers, setAllUsers] = useState([]);
  const [myUid, setMyUid] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const data = JSON.parse(localStorage.getItem("user"));
  const [weavyToken, setWeavyToken] = useState();
  const location = useLocation();
  const [clickedIndex, setClickedIndex] = useState(0);

  useEffect(() => {
    setMyUid(data?.data?.id);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (data?.data?.role === "employer") {
      document.title = "CookandChef | Message";
    } else if (data?.data?.role === "cook") {
      document.title = "CookandChef | Message";
    } else {
      document.title = "CookandChef | Message";
    }
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 50 * 60 * 1000); // 50 minutes

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  const getAllUsers = async () => {
    try {
      // Check if the token is present and not expired
      if (!weavyToken) {
        const refreshedToken = await fetchAuthToken();
        localStorage.setItem("WeavyToken", refreshedToken);
        setWeavyToken(refreshedToken);
      } else {
        // Make the API call with the updated token
        const response = await fetch(
          `https://${API_PATHS.weavyId}.weavy.io/api/conversations`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${weavyToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        localStorage.setItem("allChatUsers", JSON.stringify(data?.data) || []);
        setAllUsers(data?.data);
      }
    } catch (error) {
      // console.error("Error getting users:", error);
      // setError("Failed to fetch user data.");
    }
  };

  useEffect(() => {
    if (weavyToken) {
      getAllUsers();
    }
  }, [weavyToken]);

  let weavyClient;
  if (weavyToken) {
    weavyClient = new WeavyClient({
      url: `https://${API_PATHS.weavyId}.weavy.io`,
      tokenFactory: async (refresh) => weavyToken,
    });
  }

  const res = location.state?.res || null;

  const handleUserClick = (user, index) => {
    setSelectedId(user?.id);
    setClickedIndex(index);
  };

  useEffect(() => {
    if (selectedId === "" && res?.id && allUsers?.length > 0) {
      setSelectedId(res?.id);
      // Find the index of the user with matching id and set the clicked index
      const index = allUsers?.findIndex((el) => el?.id === res?.id);
      if (index !== -1) {
        setClickedIndex(index);
      }
    } else if (selectedId === "" && !res?.id && allUsers?.length > 0) {
      setSelectedId(allUsers[0]?.id);
      // Set the clicked index to the first user if res.id is not available
      setClickedIndex(0);
    }
  }, [selectedId, allUsers, res]);

  return (
    <>
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          Home &bull; Message
        </div>
        {isLoading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : !allUsers || allUsers?.length === 0 ? (
          <Col lg={9} xs={12} md={12} className="card p-3" align="center">
            <h5>No messages</h5>
          </Col>
        ) : (
          <Row
            lg={10}
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              height: "100%",
            }}
          >
            <Col
              xs={12}
              lg={4}
              className="align-items-center justify-content-center m-2"
              style={{
                height: "550px",
                overflowY: "auto",
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "white",
              }}
            >
              {allUsers?.map((elem, index) => (
                <div
                  key={elem.id}
                  onClick={() => handleUserClick(elem, index)}
                  className="d-flex align-items-center mb-2 p-2"
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "50px",
                    backgroundColor:
                      clickedIndex === index ? "#FFFBB3" : "#FEFFD3",

                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                    src={uimg}
                    alt={`${elem.display_name}'s DP`}
                  />
                  <p
                    className="text-center ms-3 mt-3"
                    style={{
                      cursor: "pointer",
                      maxWidth: "60%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {elem.display_name}
                  </p>
                </div>
              ))}
            </Col>
            <Col
              xs={12}
              lg={6}
              className="m-2"
              style={{
                height: "550px",
                borderRadius: "10px",
                paddingBottom: "20px",
                backgroundColor: "white",
              }}
            >
              {weavyToken && (
                <WeavyProvider client={weavyClient}>
                  <Chat uid={selectedId} />
                </WeavyProvider>
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default ChatSupport;
