import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "./Helpchat.css";
import Contact from "./Contact";

const ClientSupport = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
  });

  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };

  return (
    <div className="ps-2">
      <div>
        <p style={{ color: "blue", fontWeight: "500" }}>
          नमस्कार, हम यहां आपकी मदद के लिए हैं।
        </p>
        <p style={{ color: "#ff5c09" }}>सुझाव :</p>
        <ul style={{ fontSize: "12px", lineHeight: "20px" }}>
          <li>
            अपनी प्रोफ़ाइल अपडेट करें और उसे 100% पूरा करें: अपनी प्रोफ़ाइल को
            पूरी तरह अपडेट करें, जिसमें आपका स्थान, भाषाएं, जरूरतों का विवरण और
            कोई खास रीक्वारेमेंटएं शामिल हों। यह घर के रसोइयों को आपके बारे में
            बेहतर समझने में मदद करेगा।
          </li>
          <li>
            अपनी प्रोफ़ाइल अपडेट करें और उसे 100% पूरा करें: अपनी प्रोफ़ाइल को
            पूरी तरह अपडेट करें, जिसमें आपका स्थान, भाषाएं, जरूरतों का विवरण और
            कोई खास रीक्वारेमेंटएं शामिल हों। यह घर के रसोइयों को आपके बारे में
            बेहतर समझने में मदद करेगा।
          </li>
          <li>
            अपनी प्रोफ़ाइल अपडेट करें और उसे 100% पूरा करें: अपनी प्रोफ़ाइल को
            पूरी तरह अपडेट करें, जिसमें आपका स्थान, भाषाएं, जरूरतों का विवरण और
            कोई खास रीक्वारेमेंटएं शामिल हों। यह घर के रसोइयों को आपके बारे में
            बेहतर समझने में मदद करेगा।
          </li>
        </ul>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          className="help"
          aria-expanded={openCollapse.open1}
        >
          प्रोफ़ाइल
        </p>
        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              अपनी प्रोफ़ाइल को अपडेट करें ताकि घर के रसोइयों को आपके स्थान और
              अन्य जानकारी को आसानी से समझने में मदद मिल सके।
            </p>
            <Link
              to="/hin/client/edit-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              बेसिक प्रोफ़ाइल अपडेट करें
            </Link>
            <Link
              to="/hin/client/edit-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              डीपी अपडेट करें
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          जरूरतें
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              अपनी जरूरतों को अपडेट करें ताकि ऐसे घर के रसोइयों से प्रतिक्रियाएँ
              प्राप्त हो सकें जो ग्राहक ढूंढ रहे हैं। <br />
              आपकी रीक्वारेमेंट हर 45 दिनों में स्वचालित रूप से अक्षम हो जाती
              है। आप इसे पुनः सक्षम कर सकते हैं।
            </p>
            <Link
              to="/hin/client/edit-requirement"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              रेक्विरेमेंट अपडेट करें
            </Link>
            <Link
              to="/hin/client/requirement"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              रेक्विरेमेंट सक्षम/अक्षम करें
            </Link>
          </div>
        </Collapse>
        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          योजनाएं
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <p style={{ fontSize: "12px" }}>
              सक्रिय योजना बनाए रखें: प्रासंगिक रसोइयों से अधिक प्रतिक्रियाएँ
              प्राप्त करने के लिए अपनी योजना सक्रिय रखें।
              <br />
              अधिक रसोइया प्रोफाइल देखने और हमारी टीम से सहायता प्राप्त करने के
              लिए आपको एक योजना खरीदनी होगी
            </p>
            <Link
              to="/hin/client/plans"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              योजना खरीदें
            </Link>
            <Link
              to="/hin/client/plan-history"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              योजना इतिहास
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          संपर्क सेटिंग्स
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Link
              to="/hin/client/profile"
              style={{
                display: "block",

                fontSize: "14px",
              }}
            >
              अपने व्हाट्सऐप नंबर को जोड़ें
            </Link>
            <Link
              to="/hin/client/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              ईमेल बदलें
            </Link>
            <Link
              to="/hin/client/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              ईमेल बदलें
            </Link>
            <Link
              to="/hin/client/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              संपर्क गोपनीयता
            </Link>
          </div>
        </Collapse>
        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open5")}
          aria-controls="example-fade-text4"
          aria-expanded={openCollapse.open5}
          className="mt-3 help"
        >
          हमसे संपर्क करें
        </p>
        <Collapse in={openCollapse.open5}>
          <div id="example-fade-text4">
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ClientSupport;
