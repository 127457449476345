import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editbasicProfile,
  getcookProfile,
} from "../../../../store/slices/cookprofile";

import {
  getQualification,
  getLanguages,
  getStates,
} from "../../../../store/slices/config";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link } from "react-router-dom";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import AvatarModel from "../../../../utils/helpers/ImageCropper/AvatarModal";

function EditCateringProfile() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | EditCateringProfile";
  }, []);

  const [fullName, setfullName] = useState("");
  const [languages, setLanguages] = useState([]);
  const [about, setabout] = useState("");
  const [addressline1, setaddressLine1] = useState("");
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const [addressline2, setaddressLine2] = useState("");
  const [cityName, setcityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");
  const [provinceName, setprovinceName] = useState("");
  const [dob, setDob] = useState("");
  const [dp, setDp] = useState("");
  const [errors, setErrors] = useState({});

  const [language, setLanguage] = useState([]);
  const [state, setState] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [citycoord, setcitycoord] = useState([]);
  const [areacoord, setareacoord] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const fullNameRef = useRef(null);
  const languagesRef = useRef(null);
  const adressLineref = useRef(null);
  const pincodeRef = useRef(null);

  React.useEffect(() => {
    getcatProfile();
  }, [dispatch]);

  const getcatProfile = () => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        setLanguages(data?.user?.data?.languages);
        setcitycoord(data?.user?.data?.cityCoordinates?.coordinates);
        setareacoord(data?.user?.data?.areaCoordinates?.coordinates);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  };

  var options = language.map(function (item) {
    return item["languageName"];
  });

  React.useEffect(() => {
    dispatch(getStates())
      .unwrap()
      .then((data) => {
        setState(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getLanguages())
      .unwrap()
      .then((data) => {
        setLanguage(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  var options = language.map(function (item) {
    return item["languageName"];
  });

  const isValidName = (fullName) => {
    return /^[a-zA-Z0-9@&'’ -]{3,50}$/.test(fullName);
  };

  const isValidAddressline1 = (addressline1) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,100}$/.test(addressline1);
  };

  const isValidAddressline2 = (addressline2) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,50}$/.test(addressline2);
  };

  const isValidLandmark = (landmark) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,50}$/.test(landmark);
  };
  function isValidCity(city) {
    return /^[a-zA-Z0-9\-',&@ ]{3,100}$/.test(city);
  }
  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[0-9]{6}$/.test(pincode);
  };

  const isValidAbout = (about) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\s)[\s\S]{3,}$/.test(about);
  };
  const cityinputRef = useRef(null);

  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setcitycoord([]);
          setErrors({
            ...errors,
            cityName: "कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें",
          });
        } else {
          setErrors({
            ...errors,
            cityName: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setcityName(place.name);

          setcitycoord(coordinates);
          setcity(coordinates, place.name);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();

        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setcitycoord([]);
          setErrors({
            ...errors,
            cityName: "कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें",
          });
        }
      });
    }
  }, []);

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      cityName: city,
      cityCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const areainputRef = useRef(null);

  useEffect(() => {
    if (areainputRef.current) {
      const autoCompleteArea = new window.google.maps.places.Autocomplete(
        areainputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );
      autoCompleteArea.addListener("place_changed", () => {
        const place = autoCompleteArea.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setareacoord([]);
          setErrors({
            ...errors,
            area: "कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें",
          });
        } else {
          setErrors({
            ...errors,
            area: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setArea(place.name);
          setarea(coordinates, place.name);
          setareacoord(coordinates);
        }
      });
      areainputRef.current.addEventListener("blur", () => {
        const place = autoCompleteArea.getPlace();
        const inputValue = areainputRef.current.value.trim();

        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setareacoord([]);
          setErrors({
            ...errors,
            area: "कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें",
          });
        }
      });
    }
  }, []);

  const setarea = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      area: city,
      areaCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!data?.fullName) {
      validationErrors.fullName = "कृपया अपना नाम दर्ज करें";
    } else if (!isValidName(data?.fullName)) {
      validationErrors.fullName = "कृपया एक मान्य नाम दर्ज करें";
    }

    if (!data?.addressLine1) {
      validationErrors.addressLine1 = "कृपया अपना पता दर्ज करें line 1";
    } else if (!isValidAddressline1(data?.addressLine1)) {
      validationErrors.addressLine1 = "Please enter valid पता पंक्ति 1";
    }

    if (!data?.cityName) {
      validationErrors.cityName = "कृपया अपने शहर का नाम दर्ज करें";
    } else if ((data?.cityName && !isValidCity) || !citycoord?.length) {
      validationErrors.cityName = "कृपया एक वैध शहर का नाम दर्ज करें";
    }

    if (!data?.pincode) {
      validationErrors.pincode = "कृपया अपना पिनकोड दर्ज करें";
    } else if (!isValidPincode(data.pincode)) {
      validationErrors.pincode = "कृपया एक वैध पिनकोड दर्ज करें";
    }
    // if (!landmark) {
    //   validationErrors.landmark = "Please enter your landmark";
    // }
    if (!data?.area) {
      validationErrors.area = "कृपया अपने क्षेत्र का नाम दर्ज करें";
    } else if ((data.area && !isValidCity(data.area)) || !areacoord?.length) {
      validationErrors.area = "कृपया मान्य क्षेत्र का नाम दर्ज करें";
    }

    if (data?.about && !isValidAbout(data?.about)) {
      validationErrors.about = "Enter at least three words about yourself";
    }
    if (data?.landmark && !isValidLandmark(data?.landmark)) {
      validationErrors.landmark = "कृपया एक वैध लैंडमार्क दर्ज करें";
    }
    const isValid = validateLanguages(languages);
    if (!isValid) {
      validationErrors.languages = "कृपया कम से कम एक भाषा दर्ज करें";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.fullName) {
        fullNameRef.current.focus();
      } else if (validationErrors.languages) {
        languagesRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.addressLine1) {
        adressLineref.current.focus();
      } else if (validationErrors.cityName) {
        cityinputRef.current.focus();
      } else if (validationErrors.area) {
        areainputRef.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      }

      return;
    } else {
      const item = {
        fullName: data.fullName,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,

        languages: languages,

        cityName: data.cityName,
        dp: data.dp,
        area: data.area,
        provinceName: data.provinceName,
        pincode: data.pincode,
        landmark: data.landmark,
        about: data.about,
        cityCoordinates: citycoord
          ? {
              type: "Point",
              coordinates: citycoord,
            }
          : undefined,
        areaCoordinates: areacoord
          ? {
              type: "Point",
              coordinates: areacoord,
            }
          : undefined,
      };

      dispatch(editbasicProfile(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Profile updated succesfully");

          setsuccessModal(true);
          setTimeout(() => {
            navigate("/hin/cook/profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "fullName":
        setfullName(value);
        setData({
          ...data,
          fullName: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "कृपया एक मान्य नाम दर्ज करें"
            : "";
        if (!value === "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;

      case "about":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setabout(value);
        setData({
          ...data,
          about: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAbout(value)
            ? "Enter at least 3 characters"
            : "";
        if (value !== "" && isValidAbout(value)) {
          validationErrors[name] = "";
        }
        break;

      case "addressLine1":
        setaddressLine1(value);
        setData({
          ...data,
          addressLine1: value,
        });
        validationErrors[name] =
          value === ""
            ? "कृपया अपना पता दर्ज करें"
            : value === "" || !isValidAddressline1(value)
            ? "कृपया एक वैध पता दर्ज करें"
            : "";
        if (value !== "" && isValidAddressline1(value)) {
          delete validationErrors[name];
        }
        break;
      case "addressLine2":
        setaddressLine2(value);
        setData({
          ...data,
          addressLine2: value !== "" ? value : undefined,
        });
        validationErrors[name] =
          value !== "" && !isValidAddressline2(value)
            ? "कृपया एक वैध पता दर्ज करें"
            : "";
        if (value !== "" && isValidAddressline2(value)) {
          delete validationErrors[name];
        }
        break;
      case "cityName":
        setcityName(value);
        setData({
          ...data,
          cityName: value,
        });
        validationErrors[name] =
          value === "" ? "कृपया अपने शहर का नाम दर्ज करें" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "area":
        setArea(value);
        setData({
          ...data,
          area: value,
        });
        validationErrors[name] =
          value === "" ? "कृपया अपने क्षेत्र का नाम दर्ज करें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "pincode":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setPincode(value);
        setData({
          ...data,
          pincode: value,
        });
        validationErrors[name] =
          value === ""
            ? "कृपया अपना पिनकोड दर्ज करें"
            : value === "" || !isValidPincode(value)
            ? "कृपया एक वैध पिनकोड दर्ज करें"
            : "";
        if (value !== "" && isValidPincode(value)) {
          delete validationErrors[name];
        }
        break;

      case "landmark":
        setLandmark(value);
        setData({
          ...data,
          landmark: value,
        });
        validationErrors[name] =
          value !== "" && !isValidLandmark(value)
            ? "कृपया एक वैध लैंडमार्क दर्ज करें"
            : "";
        if (value !== "" && isValidLandmark(value)) {
          delete validationErrors[name];
        }
        break;

      case "provinceName":
        setprovinceName(value);
        setData({
          ...data,
          provinceName: value,
        });
        validationErrors[name] =
          value === "" ? "कृपया अपने राज्य का नाम दर्ज करें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }

    setErrors(validationErrors);
  };

  const handleLanguageChange = (selectedOptions) => {
    setLanguages(selectedOptions);
    const isValid = validateLanguages(selectedOptions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      languages: isValid ? "" : "कृपया कम से कम एक भाषा चुनें",
    }));
  };

  const validateLanguages = (selectedOptions) => {
    return selectedOptions?.length > 0;
  };

  async function handleSave(requestOptions) {
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    let response = await fetch(API_PATHS.uploadFileCook, requestOptions);
    let data2 = await response.json();
    setData({
      ...data,
      dp: data2.data.location,
    });
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <AvatarModel
        showModal1={avatarModalOpen}
        image={imageData}
        setShowModal1={setAvatarModalOpen}
        handleSave={handleSave}
        setData={setData}
        data={data}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/cook/profile" className="bcrumb">
          Profile
        </Link>
        &nbsp;&bull;&nbsp;Edit Profile
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row className="d-flex justify-content-center mt-4 card p-3">
          <Col lg={12} xs={12} md={12} className="mb-4">
            <Col lg={12} className=" p-3">
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} align="center">
                  {data?.dp && (
                    <img
                      className="mt-2"
                      src={data?.dp}
                      alt="user profile"
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "20px",
                      }}
                    />
                  )}
                  {!data?.dp && (
                    <div
                      className="pt-5 mt-2"
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "20px",
                        background: "#303030",
                      }}
                    >
                      <p style={{ color: "#fff" }}>तस्विर अपलोड करना</p>
                    </div>
                  )}
                </Col>

                <Col lg={4} md={4} xs={12} className="mt-5" align="left">
                  <label for="fileInput" className="custom-button">
                    चित्र को बदलें
                  </label>
                  <Form.Control
                    type="file"
                    name="profiledp"
                    id="fileInput"
                    accept="image/*"
                    onChange={(e) => handleChangeImage(e)}
                    className="input1"
                    placeholder="कृपया प्रोफ़ाइल फ़ोटो चुनें"
                  />
                </Col>
              </Row>
            </Col>
            <hr />
            <h5 className="text-left heading orange ms-2">सामान्य विवरण</h5>

            <Row className="gx-2 d-flex justify-content-center">
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label"> नाम *</label>
                  <Form.Control
                    className="input1"
                    name="fullName"
                    value={data?.fullName}
                    onChange={handleChange}
                    type="text"
                    maxlength="100"
                    isInvalid={!!errors.fullName}
                    placeholder="अपना नाम दर्ज करें"
                    ref={fullNameRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">ज्ञात भाषाएँ *</label>
                  <div ref={languagesRef}>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      className="input1"
                      onChange={handleLanguageChange}
                      options={options}
                      placeholder="ज्ञात भाषाएँ"
                      selected={languages}
                    />
                  </div>
                </Form.Group>
                <p style={{ color: "#DC3545", fontSize: "14px" }}>
                  {errors.languages}
                </p>
              </Col>

              <Col lg={12} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">के बारे में</label>
                  <Form.Control
                    name="about"
                    value={data?.about}
                    onChange={handleChange}
                    isInvalid={!!errors.about}
                    className="input1"
                    maxLength={500}
                    as="textarea"
                    style={{ resize: "none" }}
                    rows={1}
                    placeholder="अपने बारे में दर्ज करें"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.about}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={12}>
            <h5 className="text-left heading orange ms-2">
              सम्पर्क करने का विवरण
            </h5>

            <Row className="gx-2 d-flex justify-content-center">
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">पता पंक्ति 1 *</label>
                  <Form.Control
                    name="addressLine1"
                    value={data?.addressLine1}
                    onChange={handleChange}
                    isInvalid={!!errors.addressLine1}
                    className="input1"
                    type="text"
                    maxlength="100"
                    placeholder="पता पंक्ति 1"
                    ref={adressLineref}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.addressLine1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">पता पंक्ति 2</label>
                  <Form.Control
                    name="addressLine2"
                    value={data?.addressLine2}
                    onChange={handleChange}
                    maxlength="50"
                    isInvalid={!!errors.addressLine2}
                    className="input1"
                    type="text"
                    placeholder="पता पंक्ति 2"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.addressLine2}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label"> शहर *</label>
                  <Form.Control
                    name="cityName"
                    value={data.cityName}
                    onChange={handleChange}
                    isInvalid={!!errors.cityName}
                    className="input1"
                    type="text"
                    maxlength="50"
                    ref={cityinputRef}
                    placeholder="शहर का नाम दर्ज करें"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cityName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">पिन कोड *</label>
                  <Form.Control
                    name="pincode"
                    value={data.pincode}
                    onChange={handleChange}
                    isInvalid={!!errors.pincode}
                    className="input1"
                    type="number"
                    placeholder="पिनकोड दर्ज करें"
                    onWheel={(e) => e.target.blur()}
                    ref={pincodeRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pincode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">लैंडमार्क </label>
                  <Form.Control
                    name="landmark"
                    value={data.landmark}
                    onChange={handleChange}
                    isInvalid={!!errors.landmark}
                    className="input1"
                    maxlength="50"
                    type="text"
                    placeholder="लैंडमार्क दर्ज करें"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.landmark}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label"> क्षेत्र *</label>
                  <Form.Control
                    name="area"
                    value={data.area}
                    onChange={handleChange}
                    isInvalid={!!errors.area}
                    className="input1"
                    type="text"
                    maxlength="50"
                    ref={areainputRef}
                    placeholder="क्षेत्र दर्ज करें"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.area}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3">
                <Form.Group className="my-2">
                  <label className="mb-2 label">State </label>

                  <Form.Control
                    as="select"
                    name="provinceName"
                    className="input1"
                    value={data.provinceName}
                    onChange={handleChange}
                    isInvalid={!!errors.provinceName}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    {state.map((edudata, index) => (
                      <option key={index} value={edudata.provinceName}>
                        {edudata.provinceName}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.state}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="p-3"></Col>
            </Row>
          </Col>
          <Row className="d-flex justify-content-center mt-4">
            <Col lg={4} xs={8}>
              <div className="d-flex justify-content-around my-2 align-items-center">
                <Button onClick={() => navigate(-1)} className="cancelBtn">
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" className="saveBtn">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
}

export default EditCateringProfile;
