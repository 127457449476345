import React, { useEffect, useState } from "react";
import { Button, Col, Row, Carousel } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import chef_banner from "../../Assets/Images/chef banner home.jpeg";
import { Link, useNavigate } from "react-router-dom";
import chef_banner_mobile from "../../Assets/Images/cook-chef-find-cook-bestcook.jpg";
import Helmet from "react-helmet";
import NavMenu from "../nav_bar/NavMenu";
import { IoBriefcaseOutline } from "react-icons/io5";
import Footer from "../nav_bar/Footer";
import { RiUserLocationLine } from "react-icons/ri";
import { BsCurrencyRupee } from "react-icons/bs";
import appimg from "../../Assets/Images/image.png";
import { ImSpoonKnife } from "react-icons/im";
import chef from "../../Assets/Images/chef.png";
import catering from "../../Assets/Images/catering.png";
import partycook from "../../Assets/Images/party-cook.png";
import housecook from "../../Assets/Images/house-cook.png";
import { useDispatch } from "react-redux";
import appstore from "../../Assets/Images/App-Store-cookandchef.in.png";
import HomeChatBot from "../../utils/helpers/ChatBot2/HomeChatBot";

// swiper js start
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// const image = "";

import { topjob, topChefs, testimonial } from "../../store/slices/config";
import { useRef } from "react";

export default function Home() {
  const dispatch = useDispatch();
  const [chefData, setchefData] = useState([]);
  const [jobData, setjobData] = useState([]);
  const [testimonialData, settestimonialData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);

    if (user?.data?.role === "cook") {
      navigate("/cook/dashboard");
    } else if (user?.data?.role === "employer") {
      navigate("/employer/dashboard");
    } else if (user?.data?.role === "client") {
      navigate("/client/dashboard");
    }
  }, []);

  const navigatetoCookList = (name) => {
    navigate(`/cooks/${name}`);
  };

  const setDeepLinkforfindchef = () => {
    localStorage.setItem("deeplinkpage", "find-chef");
  };

  React.useEffect(() => {
    dispatch(topChefs())
      .unwrap()
      .then((data) => {
        setchefData(data.topchef.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(topjob())
      .unwrap()
      .then((data) => {
        setjobData(data.topjobs.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(testimonial())
      .unwrap()
      .then((data) => {
        settestimonialData(data.testimonial.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundColor: "orange",
          zIndex: "5",
          right: "",
          borderRadius: "100%",
          padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundColor: "orange",
          zIndex: "5",
          left: "",
          borderRadius: "100%",
          // padding: "1px 0px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  }
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>CookandChef | Find a Cook online</title>
        <meta
          name="description"
          content="CookandChef is a job portal for House Cooks, Chefs, Party Cooks and Caterings. Any one can search Cook or Chef as per their requirements."
        />
        <meta
          name="keywords"
          content="Part time Cook, Full time Cook, Cooks, Cooking Jobs, Cooks for Hotel, South Indian Cooks in Bangalore, Bakery, Pastry, Naan, Tasty food, Healthy Food, Chef. Coook, Jobs "
        />
      </Helmet>
      <NavMenu />
      <Row
        className="d-flex pb-4 justify-content-center align-items-center"
        style={{ background: "#fff" }}
      >
        <Col lg="10" md="12" sm="12">
          <Row className="d-none d-lg-flex d-md-none justify-content-left align-items-left ">
            <Col
              lg="6"
              md="6"
              sm="12"
              className="text_container"
              align="center"
            >
              <div className="text_container_child ms-5">
                <h1 style={{ fontWeight: "600" }}>Find a cook online</h1>
                <p
                  className="mt-3"
                  style={{
                    textAlign: "",
                    fontSize: "18px",
                  }}
                >
                  from a vast pool of over 50,000 culinary experts,
                </p>
                <p
                  className=" mb-3"
                  style={{
                    textAlign: "",
                    fontSize: "18px",

                    marginTop: "-20px",
                  }}
                >
                  specializing in a diverse array of cuisines.
                </p>
                <br />
                <Row className="justify-content-center align-items-center">
                  <Col md={6} lg={4}>
                    <Link className="mb-5 mt-5" to="/employer-registration">
                      <button className="webBtn1">Find Cook</button>
                    </Link>
                    <br />
                    <br />
                    <p claassNam="mt-5">
                      If you are looking for a<br /> Cook / Chef
                    </p>
                  </Col>

                  <Col md={6} lg={4}>
                    <Link className="mb-5 mt-5" to="/cook-signup">
                      <button className="webBtn2">Find Job</button>
                    </Link>
                    <br />
                    <br />
                    <p>
                      If you are looking for a <br />
                      Job
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="6" md="6" style={{ float: "right" }}>
              <div className="banner_container text-center">
                <img
                  src={chef_banner}
                  alt="Find chef online"
                  style={{ height: "500px" }}
                  loading="lazy"
                />
              </div>
            </Col>
          </Row>
          <Row className="d-sm-block d-md-block d-lg-none ">
            <Col lg="8" md="12" style={{ float: "right" }}>
              <div className="banner_container text-end">
                <img
                  src={chef_banner_mobile}
                  alt="Find chef online"
                  style={{ maxWidth: "100%" }}
                  loading="lazy"
                />
              </div>
            </Col>
            <Col
              md="12"
              sm="12"
              className=" mt-2 text_container justify-content-center align-items-center"
            >
              <div align="center">
                <h1 style={{ fontWeight: "600" }} className="mt-2">
                  Find a cook online
                </h1>
                <p
                  className="mt-3 ps-2"
                  style={{
                    textAlign: "",
                    fontSize: "18px",
                    marginLeft: "0px",
                  }}
                  align="left"
                >
                  from a vast pool of over 50,000 culinary experts, specializing
                  in a diverse array of cuisines.
                </p>
                <br />
                <br />
                <Row className="justify-content-center align-items-center">
                  <Col md={6} sm={6} xs={6}>
                    <Link className="mb-5 mt-5" to="/employer-registration">
                      <button className="webBtn1">Find Cook</button>
                    </Link>
                    <br />
                    <br />
                    <p claassNam="mt-5">
                      If you are looking for a <br /> Cook / Chef
                    </p>
                  </Col>

                  <Col md={6} sm={6} xs={6}>
                    <Link className="mb-5 mt-5" to="/cook-signup">
                      <button className="webBtn2">Find Job</button>
                    </Link>
                    <br />
                    <br />
                    <p>
                      If you are looking for a <br />
                      Job
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/**COOK TYPE START */}
      <Row
        style={{ backgroundColor: "#FFEFD3" }}
        className="d-flex justify-content-center p-5"
      >
        <Col lg="11" md="12" sm="12">
          <h3 className="mb-5 text-left">Cook Types</h3>

          <Slider {...settings}>
            <div onClick={() => navigatetoCookList("chef")}>
              <div className="banner_box">
                <img
                  src={chef}
                  alt=""
                  className="home-img mb-3"
                  loading="lazy"
                />
                <h4>Chef</h4>
                <p className="home-text mt-2">
                  They are trained Chefs with experience. You can see their
                  profiles and shortlist them accordingly your requirements.
                  This is for Restaurant or Hotel Owners. It’s a premium
                  feature.
                </p>
              </div>
            </div>

            <div onClick={() => navigatetoCookList("housecook")}>
              <div className="banner_box">
                <img
                  src={housecook}
                  alt=""
                  className="home-img mb-3"
                  loading="lazy"
                />
                <h4>Household Cook</h4>
                <p className="home-text mt-2">
                  They are personal Chefs that ensures delicious, mouth-watering
                  homely food in your plates. You can get part time Cooks or
                  full time Cooks. You can find a cook by the cuisine.
                </p>
              </div>
            </div>
            <div onClick={() => navigatetoCookList("partycook")}>
              <div className="banner_box">
                <img
                  src={partycook}
                  alt=""
                  className="home-img mb-3"
                  loading="lazy"
                />
                <h4>Party Cook</h4>
                <p className="home-text mt-2">
                  They are the chefs for special occasions. Best for small house
                  parties (birthdays, anniversaries etc.) or get-togethers. If
                  you except no. of guests between 10 to 50, a Party Cook will
                  be a best choice for you.
                </p>
              </div>
            </div>
            <div onClick={() => navigatetoCookList("catering")}>
              <div className="banner_box">
                <img
                  src={catering}
                  alt=""
                  className="home-img mb-3"
                  loading="lazy"
                />
                <h4>Catering</h4>
                <p className="home-text mt-2">
                  These are a group of chefs who generally cook for party
                  events. Planning for a big delicious party. Why to select near
                  by catering. Have multiple options. Check the reviews, get the
                  price quote and then select.
                </p>
              </div>
            </div>
          </Slider>
        </Col>
      </Row>
      <Row
        style={{ backgroundColor: "#ffffff" }}
        className="d-flex justify-content-center p-5"
      >
        <Col lg="11" md="12" sm="12">
          <h3 className="text-left">Top Chefs</h3>

          <Swiper
            key={3}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation, Pagination]}
            className="mySwiper"
          >
            {chefData?.map((item, index) => (
              <SwiperSlide key={index} style={{ zIndex: 1 }}>
                <Row className="d-flex my-4 justify-content-center align-items-center">
                  <Col
                    className="my-2"
                    lg={9}
                    md={9}
                    sm={11}
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "15px",
                      padding: "15px",
                    }}
                  >
                    <Row className="p-1 ps-3 d-flex justify-content-center align-items-center">
                      <Col
                        className="d-flex justify-content-center align-items-center"
                        xs={12}
                        md={5}
                        lg={6}
                        xl={4}
                        sm={12}
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "10%",
                          }}
                          alt="user"
                          src={item?.dp}
                          loading="lazy"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={5}
                        lg={8}
                        xl={8}
                        sm={12}
                        className="d-flex justify-content-left align-items-left flex-column mt-2"
                        align="center"
                      >
                        <span
                          style={{ fontSize: "16px", fontWeight: "700" }}
                          className="text-muted"
                        >
                          {item?.fullName}
                        </span>
                        <span
                          style={{ fontSize: "14px" }}
                          className="text-muted"
                        >
                          <RiUserLocationLine />{" "}
                          {item?.currentCityName?.substring(0, 40)}
                        </span>
                        <span
                          className="text-muted"
                          style={{ fontSize: "14px" }}
                        >
                          <IoBriefcaseOutline /> {item?.chefExperience} Years
                        </span>
                        <Link to="/employer-login">
                          <Button className="profileBtn">View Profile</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>

      <Row
        style={{ backgroundColor: "#f6f6f6" }}
        className="d-flex justify-content-center p-5"
      >
        <Col lg={11} md={12} xs={12}>
          <h3 className="text-left">Top Jobs</h3>

          <Swiper
            key={3}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            centeredSlides={false}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation, Pagination]}
            className="mySwiper"
          >
            {jobData?.map((item, index) => (
              <SwiperSlide key={index}>
                <Row className="d-flex justify-content-center align-items-center">
                  <Col
                    className="my-2"
                    lg={9}
                    md={9}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                    }}
                  >
                    <Row
                      style={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        padding: "15px 20px",
                      }}
                    >
                      <Col sm={12} lg={12} md={12}>
                        <div className="h5 my-2 text-muted font-weight-bolder">
                          {item?.designation?.substring(0, 20)}
                        </div>

                        <div className="my-2 d-flex justify-content-start align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <GrLocation style={{ marginBottom: "2px" }} />
                            &nbsp;
                            {item?.location?.substring(0, 15)}
                          </div>
                          &nbsp; &nbsp;
                          <span className=" d-flex justify-content-start align-items-center">
                            <BsCurrencyRupee style={{ marginBottom: "2px" }} />
                            {item?.salary}
                          </span>
                        </div>
                        <div className="my-2 d-flex justify-content-start align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <ImSpoonKnife />
                            &nbsp;
                            {item?.cuisines?.slice(0, 2).join(", ")}
                          </div>
                        </div>
                        <div className="my-2 d-flex justify-content-between align-items-center">
                          <span className="text-sm text-muted">
                            <IoBriefcaseOutline style={{ marginTop: "-5px" }} />{" "}
                            {item?.experience} Years
                          </span>

                          <div className="d-flex justify-content-center align-items-center">
                            <Link to="/login">
                              <Button className="profileBtn">View</Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* </Carousel> */}
        </Col>
      </Row>
      {/**TOP JOBS END */}
      {/**HOW IT WORKS START */}
      <div
        className="p-4"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="text-center position-relative mb-2">
          <h2 style={{ fontWeight: "700", color: "#000" }}>How it works?</h2>
        </div>
        <Row className="gx-2 p-2 mt-3 d-md-flex justify-content-center g-4 align-items-center">
          <Col
            lg={3}
            xs={12}
            md={3}
            style={{ border: "", height: "80%" }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <div className="d-flex justify-content-center align-items-center">
              <h1 style={{ fontWeight: "800", color: "silver" }}>01</h1>
            </div>
            <div
              className="homeCard"
              style={{
                background: "#56d8b1",
                padding: "20px",
                width: "220px",
                cursor: "auto",
              }}
            >
              <span
                style={{ fontWeight: "500", color: "#fff", cursor: "auto" }}
                className=" text-center"
              >
                Register Your Account
              </span>
            </div>

            <p
              style={{ fontSize: "14px", color: "#000", padding: "20px" }}
              className="text-left"
            >
              Choose appropriate role and fill the required details and&nbsp;
              <b>Sign Up</b>.
            </p>
          </Col>
          <Col
            lg={3}
            xs={12}
            md={3}
            style={{ border: " ", height: "80%" }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <h1 style={{ fontWeight: "800", color: "silver" }}>02</h1>
            <div
              className="homeCard"
              style={{
                background: "#F5A5EC",
                padding: "20px",
                width: "220px",
                cursor: "auto",
              }}
              align="center"
            >
              <div
                style={{ fontWeight: "500", color: "#fff", cursor: "auto" }}
                className="text-center"
              >
                Update Your Profile
              </div>
            </div>
            <p
              style={{
                fontSize: "14px",
                color: "#000",
                padding: "20px",
                cursor: "auto",
              }}
              className="text-left"
            >
              <b>Update</b> your basic info and Professional details.
            </p>
          </Col>
          <Col
            lg={3}
            xs={12}
            md={3}
            style={{ border: "", height: "80%" }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <h1
              style={{ fontWeight: "800", color: "silver", marginTop: "-30px" }}
            >
              03
            </h1>
            <div
              className="homeCard"
              style={{
                background: "#3898e2",
                padding: "20px",
                width: "220px",
                cursor: "auto",
              }}
              align="center"
            >
              <span
                style={{ fontWeight: "500", color: "#fff", cursor: "auto" }}
                className="text-center"
              >
                Find Your Work
              </span>
            </div>
            <p
              style={{ fontSize: "14px", color: "#000", padding: "20px" }}
              className="text-left"
            >
              Find the best job for you in your area.
            </p>
          </Col>
        </Row>
      </div>
      {/**HOW IT WORKS END */}
      {/*TESTIMONIALS*/}

      <Row
        style={{ backgroundColor: "#ffffff" }}
        className="d-flex justify-content-center p-5"
      >
        <Col lg={11} md={12} sm={12}>
          <h3 className="text-left mb-3">Testimonials</h3>
          <Swiper
            key={3}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            centeredSlides={false}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {testimonialData.map((item, index) => (
              <SwiperSlide key={index} style={{ zIndex: 1 }}>
                <div align="center">
                  <img
                    src={item?.image}
                    alt=""
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "50%",
                    }}
                    loading="lazy"
                  />

                  <div className="myCarousel">
                    <h3 className="mt-4">{item?.name}</h3>

                    <span style={{ fontWeight: "400", fontSize: "13px" }}>
                      {item?.message.substring(0, 200)}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
      {/*------------- */}
      <div
        className="p-4"
        style={{
          backgroundColor: "#EAFDFF",
        }}
      >
        <Row className="gx-2 d-flex justify-content-center align-items-center">
          <Col
            lg={6}
            xs={12}
            sm={12}
            md={8}
            style={{ border: "", height: "80%" }}
            className="justify-content-center align-items-start flex-column"
          >
            <h3 style={{ fontWeight: "600" }}>
              Download <span style={{ color: "#ff5c09" }}> CookandChef </span>
              App
            </h3>
            <p style={{ fontSize: "16px", fontWeight: "400" }}>
              Find the Perfect Cook Online at Your Fingertips
              <br />
              Download our App from
            </p>
            <Row className="mt-3 justify-content-start ">
              <Col lg={5} xs={12} md={6} align="center">
                <a href="https://apps.apple.com/us/app/cook-and-chef/id6476929550">
                  <img
                    style={{
                      height: "90px",
                      marginTop: "5px",
                    }}
                    alt="Get it on App Store"
                    src={appstore}
                    loading="lazy"
                  />
                </a>
              </Col>
              <Col lg={5} xs={12} md={6} align="center">
                <a href="https://play.google.com/store/apps/details?id=in.cookandchef&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    style={{
                      height: "100px",
                    }}
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    loading="lazy"
                  />
                </a>
              </Col>
            </Row>
          </Col>

          <Col
            lg={3}
            xs={12}
            sm={12}
            md={3}
            style={{ border: "", height: "80%" }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <img
              src={appimg}
              alt="Download CookandChef mobile app"
              style={{ height: "400px" }}
              loading="lazy"
            />
          </Col>
        </Row>
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: 50, // Adjust the distance from the bottom as needed
          right: 30, // Adjust the distance from the right as needed
        }}
      >
        <HomeChatBot />
      </div>
      <Footer />
    </>
  );
}
