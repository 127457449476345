import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CookService from "../../services/cook.service";

export const getcookProfile = createAsyncThunk(
    "cook/getprofile",
    async ( thunkAPI) => {
        try {
            const data = await CookService.getProfile();
         
            return { user: data };
           
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const getemployerProfile = createAsyncThunk(
    "cook/getEmployerProfile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getemployerProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getshortListedJobs = createAsyncThunk(
    "cook/getShortListedJobs",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getShortListedJobs(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//get advertisements for cook
export const GetAdvertisements = createAsyncThunk(
    "cook/getadds",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.getAdvertisement(item);
            return { ads: data }; // Return only the serializable data
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);





export const getshortListedEvents = createAsyncThunk(
    "cook/getShortListedEvents",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getShortListedEvents(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const ChangeRoleCook = createAsyncThunk(
    "cook/ChangeRoleCook",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.ChangeRolecook(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getshortListedHouseJobs = createAsyncThunk(
    "cook/getShortListedHouseJobs",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getShortListedHouseJobs(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);





export const UpdateLogin = createAsyncThunk(
    "employer/updatelogin",
    async (thunkAPI) => {
        try {
            const data = await CookService.UpdateLogin();
            return { logindata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const getcookDashboardData = createAsyncThunk(
    "employer/getprofile",
    async ( thunkAPI) => {
        try {
            const data = await CookService.getcookDashbaordData();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editpartyProfile = createAsyncThunk(
    "cook/editpartyprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.editpartyProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editCateringProfile = createAsyncThunk(
    "cook/editpartyprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.editcateringProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const edithouseProfile = createAsyncThunk(
    "cook/edithouseprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.edithouseProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const editchefProfile = createAsyncThunk(
    "cook/editchefprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.editchefProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editbasicProfile = createAsyncThunk(
    "cook/editbasicprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.editbasicProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const generateResume = createAsyncThunk(
    "cook/generateResume",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.createResume(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// finding house jobs
export const findJobs = createAsyncThunk(
    "cook/findjobs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.findjobs(item);
            return { jobsdata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//finding response cook
export const findResponse = createAsyncThunk(
    "/cook/fetch/reachout-activities",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookreachoutactivities(item);
            return data ;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//finding house jobs
export const findHouseJobs = createAsyncThunk(
    "cook/findhousejobs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.findHousejobs(item);
            return { housejobsdata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//cook apply jobs
export const CookApplyJob = createAsyncThunk(
    "cook/cookapplyjob",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookApplyjob(item);
      
            return { user: data };
        
        } catch (error) {
        
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



//cook apply house cook  jobs
export const ApplyHousecookJob = createAsyncThunk(
    "cook/cookapplyHousecookjob",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.Applyhousecookjob(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//disable account for cook
export const CookDisableAccount = createAsyncThunk(
    "cook/disableaccount",
    async (thunkAPI) => {
        try {
            const data = await CookService.cookDisableAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//housejob details
export const HouseJobdetails = createAsyncThunk(
    "cook/housejobdetails",
    async (id,thunkAPI) => {
        try {
            const data = await CookService.Housejobdetails(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



//enable account for cook
export const CookDeleteAccount = createAsyncThunk(
    "cook/deleteaccount",
    async (item,thunkAPI) => {
        try {
            const data = await CookService.cookDeleteAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const CookCancelDeleteAccount = createAsyncThunk(
    "cook/canceldeleteaccount",
    async (thunkAPI) => {
        try {
            const data = await CookService.cancelcookDeleteAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//enable account for cook
export const CookEnableAccount = createAsyncThunk(
    "cook/enableaccount",
    async (thunkAPI) => {
        try {
            const data = await CookService.cookEnableAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//finding house jobs
export const cookCancelJob = createAsyncThunk(
    "cook/cookcanceljob",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookCanceljob(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const CookApplyEvent = createAsyncThunk(
    "cook/cookapplyEvent",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookApplyEvent(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);





export const removeshortlistjob = createAsyncThunk(
    "employer/removeshortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.removeshortlistjobss(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const shortlistjob = createAsyncThunk(
    "employer/shortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.shortlistjobs(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const shortlistevent = createAsyncThunk(
    "employer/shortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.shortlistevent(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const shortlisthousejob = createAsyncThunk(
    "employer/shortlistchefs",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.shortlisthousejob(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getappliedJobs = createAsyncThunk(
    "cook/getallapplication",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getAllAppliedJobs(item);
            return { allapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getappliedevents = createAsyncThunk(
    "cook/getallapplication",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getAllAppliedEvents(item);
            return { allapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getappliedhousejobs = createAsyncThunk(
    "cook/getallapplication",
    async ( item,thunkAPI) => {
        try {
            const data = await CookService.getAllAppliedHouseJobs(item);
            return { allhouseapplication: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const ChangeApplicationStatus = createAsyncThunk(
    "cook/applicationstatus",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.employerAplicationSatus(item);
            return { chefdata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const FindEventcook = createAsyncThunk(
    "client/findeventcook",
    async (item,thunkAPI) => {
        try {
            const data = await CookService.findeventCook(item);
            return { eventsData: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getcookPlanHistory = createAsyncThunk(
    "cook/getplanhistory",
    async ( thunkAPI) => {
        try {
            const data = await CookService.getplanHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const GetJobDetails = createAsyncThunk(
    "cook/getchefdetails",
    async (id, thunkAPI) => {
        try {
            const data = await CookService.getJobDetails(id);
            return { jobdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const GetEventDetails = createAsyncThunk(
    "cook/geteventdetails",
    async (id, thunkAPI) => {
        try {
            const data = await CookService.getEventDetails(id);
            return { jobdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const CookMobileactivityLog = createAsyncThunk(
    "cook/mobileactivity",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookMobileactivityLog(item);
            return { chefdata: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const CookreportJob = createAsyncThunk(
    "cook/reportJob",
    async (item, thunkAPI) => {
        try {
            const data = await CookService.cookreportjobService(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetResumeTemplates = createAsyncThunk(
    "cook/getresumeteplates",
    async (thunkAPI) => {
        try {
            const data = await CookService.getResumeTemplates();
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const updateWeavycook = createAsyncThunk(
    "cook/updateweavyid",
    async (item,thunkAPI) => {
        try {
            const data = await CookService.updateCookWeavy(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//cookratingemloyer
export const cookRatingEmployer = createAsyncThunk(
    "cook/cookratingemployer",
    async (item,thunkAPI) => {
        try {
            const data = await CookService.CookRatingEmployer(item);
            return { data: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



const initialState = {
    loading: false,
    error: "",
    jobsdata: [],
    user: null,
    eventsData:[],
    housejobsdata:[],
    isLoggedIn: false,
};

const cookSlice = createSlice({
    name: "cook",
  initialState,
    extraReducers: {
        [getcookProfile.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getcookProfile.fulfilled]: (state, action) => {
           
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getcookProfile.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [findJobs.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
           
        },
        [findJobs.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.jobsdata = action.payload;
        },
        [findJobs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
           
        },
        [FindEventcook.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.eventsData = action.payload;
        },
        [findHouseJobs.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.housejobsdata = action.payload;
        },
        // [logout.fulfilled]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = cookSlice;
export default reducer;