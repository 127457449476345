import React, { useState, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Modal, Form, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import {
  DeleteAccount,
  DisableAccount,
  EnableAccount,
  getclientProfile,
  cancelDelete,
} from "../../../store/slices/clientprofile";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ClientContactPrivacy from "./ClientPrivacyContact";
import ClientChangepassword from "./ClientChangePassword";

function ClientSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal1, setShowModal1] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [changePassword, setChangePassword] = useState(false);
  const [contactPrivacy, setContactPrivacy] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  React.useEffect(() => {
    getProfile();
  }, [dispatch]);

  const getProfile = () => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {});
  };

  const handleOpenModal = (type) => {
    setShowModal1(true);
    setType(type);
  };

  useEffect(() => {
    document.title = "CookandChef | Settings";
  }, []);

  const handleCloseModal = () => {
    setShowModal1(false);
    setPassword("");
    setPasswordError("");
  };

  const handleDisableAccount = () => {
    dispatch(DisableAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        getProfile();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleDeleteAccount = () => {
    if (!password) {
      setPasswordError("Please enter your password");
      return;
    } else {
      const item = {
        password: password,
      };
      dispatch(DeleteAccount(item))
        .unwrap()
        .then((data) => {
          if (data?.user?.message) {
            setModalMessage(data?.user?.message);
            setsuccessModal(true);
            localStorage.clear();
            setTimeout(() => {
              navigate("/employer-login");
            }, 3000);
          }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
      handleCloseModal();
    }
  };

  const handlEnableAccount = () => {
    dispatch(EnableAccount())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        setsuccessModal(true);
        getProfile();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
    handleCloseModal();
  };
  const cancelDeleteAccount = () => {
    dispatch(cancelDelete())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user?.message);
        // setShowModal(true);
        getProfile();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        //setShowModal(true);
      });
    handleCloseModal();
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleDeleteAccount1 = () => {
    handleOpenModal("deletePasswordModal");
  };

  const getModalContent = () => {
    switch (type) {
      case "disable":
        return (
          <>
            <Modal.Header>
              <Modal.Title>Disable account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to disable your account?</p>

              <Button className="modalsaveBtn" onClick={handleDisableAccount}>
                Yes
              </Button>
              <Button
                className="modalcancelBtn ms-2"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Modal.Body>
          </>
        );
      case "delete":
        return (
          <>
            <Modal.Header>
              <Modal.Title>Delete account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete your account?</p>

              <Button className="modalsaveBtn" onClick={handleDeleteAccount1}>
                Yes
              </Button>
              <Button
                className="modalcancelBtn ms-2"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Modal.Body>
          </>
        );
        break;
      case "canceldelete":
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Cancel delete request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to cancel delete request?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="apply_btn" onClick={cancelDeleteAccount}>
                Yes
              </Button>
              <Button className="cancel_btn" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </>
        );
      case "enable":
        return (
          <>
            <Modal.Header>
              <Modal.Title>Enable account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to enable your account?</p>
              <Button className="apply_btn" onClick={handlEnableAccount}>
                Yes
              </Button>
              <Button className="cancel_btn ms-2" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Body>
          </>
        );
        break;
      case "deletePasswordModal":
        return (
          <>
            <Modal.Header>
              <Modal.Title>Delete account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Please enter your password </p>
              <Form>
                <Form.Group className="my-2">
                  <label className="mb-2 label">Password</label>
                  <Form.Control
                    name="password"
                    value={password}
                    onChange={handleChangePassword}
                    isInvalid={!!passwordError}
                    isValid={!passwordError}
                    className="input1"
                    type="text"
                    placeholder="Enter your password"
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
              <Button className="modalsaveBtn" onClick={handleDeleteAccount}>
                Submit
              </Button>
              <Button
                className="modalcancelBtn ms-2"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Modal.Body>
          </>
        );
        break;
      default:
        return null;
    }
  };

  const setChangepassword = () => {
    setContactPrivacy(false);
    setChangePassword(true);
  };

  const setContactprivacy = () => {
    setContactPrivacy(true);
    setChangePassword(false);
  };

  return (
    <>
      <Modal
        style={{ top: "20%", left: "5%" }}
        show={showModal1}
        onHide={handleCloseModal}
      >
        {getModalContent()}
      </Modal>
      <div>
        <Modal2
          showModal={showModal}
          modalMessage={modalMessage}
          setShowModal={setShowModal}
        />

        <SuccessModal
          showModal={successModal}
          modalMessage={modalMessage}
          setShowModal={setsuccessModal}
        />
      </div>
      <div style={{ height: "100vh" }}>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/client/dashboard" className="bcrumb">
            Home
          </Link>
          &nbsp;&bull; Settings
        </div>
        {isLoading ? (
          <Row className="mt-2">{DataSkeleton()}</Row>
        ) : (
          <Row className="mt-2">
            <div className=" my-4 align-items-center">
              <Row className=" align-items-center">
                <Col
                  xs={6}
                  lg={3}
                  md={3}
                  xl={3}
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={setChangepassword}
                >
                  <div className="homeCard">Change Password</div>
                </Col>

                <Col
                  xs={6}
                  lg={3}
                  md={3}
                  xl={3}
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={setContactprivacy}
                >
                  <div className="homeCard">Contact Privacy</div>
                </Col>

                {data?.status === 1 && (
                  <Col xs={6} lg={3} md={3} xl={3} align="center">
                    <Link
                      onClick={() => handleOpenModal("disable")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">Disable Account</div>
                    </Link>
                  </Col>
                )}
                {data?.status === 2 && (
                  <Col xs={6} lg={3} md={3} xl={3} align="center">
                    <Link
                      onClick={() => handleOpenModal("enable")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">Enable account</div>
                    </Link>
                  </Col>
                )}

                <Col xs={6} lg={3} md={3} xl={3} align="center">
                  {data?.status !== 4 && (
                    <Link
                      onClick={() => handleOpenModal("delete")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">Delete Account</div>
                    </Link>
                  )}

                  {data?.status === 4 && (
                    <Link
                      onClick={() => handleOpenModal("canceldelete")}
                      style={{ color: "#000" }}
                    >
                      <div className="homeCard">Cancel Delete Request</div>
                    </Link>
                  )}
                </Col>
              </Row>
            </div>
            <div>
              {changePassword ? (
                <ClientChangepassword />
              ) : contactPrivacy ? (
                <ClientContactPrivacy />
              ) : (
                ""
              )}
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default ClientSettings;
