import React, { useEffect } from "react";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
import { Helmet } from "react-helmet";
const Termsandconditions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | CookandChef</title>
        <meta
          name="description"
          content="Cook and Chef Terms and Conditions, please read the terms of use and conditions. CookandChef does not give any guarantee for a job. It is a just job portal."
        />
        <meta
          name="keywords"
          content="Terms and Conditions CookandChef, terms of use, job updates, jobs, policies, Chef policies, Junior Chef, assistant cook, how to find"
        />
      </Helmet>
      <NavMenu />
      <div className="container mt-5">
        <div className="d-md-flex">
          <div
            className="help-left text-left p-3 order-md-0"
            style={{ textWrap: "wrap" }}
          >
            <h3>Terms and Conditions</h3>
            <p style={{ textWrap: "wrap" }}>
              Please read these Terms and Conditions carefully before accessing
              or using the CookandChef website or any related services.
            </p>
            <h5>Overview:</h5>
            <p style={{ textWrap: "wrap" }}>
              This website is operated by
              <b>CookandChef India Private Limited.</b>
              Throughout the site, the terms “we”, “us” and “our” refer to
              <b>CookandChef India Private Limited.</b> CookandChef India
              Private Limited offers this website, including all information,
              tools and services available from this site to you, the user,
              conditioned upon your acceptance of all terms, conditions,
              policies and notices stated here.
            </p>

            <h5 style={{ textWrap: "wrap" }}>
              Use To Be In Conformity With Purpose:
            </h5>
            <p style={{ textWrap: "wrap" }}>
              <ul>
                <li>
                  CookandChef India Private Limited is a public website which
                  has free access and CookandChef India Private Limited does not
                  guarantee the genuineness and quality shared by users.
                </li>
                <li>
                  CookandChef India Private Limited cannot monitor the responses
                  that a person may receive to information he/she has displayed
                  on the website.
                </li>
                <li>
                  The company/individual would have to conduct a background
                  verification/check on the bonafide nature of all responses.
                </li>
                <li>
                  CookandChef India Private Limited will not be liable on
                  account of any inaccuracy of information on this web site. It
                  is the responsibility of the visitor to further research the
                  information on the site.
                </li>
                <li>
                  Any breach of privacy or of the information provided by the
                  consumer to CookandChef India Private Limited to be placed on
                  the website by technical or any other means is not the
                  responsibility of CookandChef India Private Limited.
                </li>
                <li>
                  CookandChef India Private Limited does not guarantee
                  confidentiality of information provided to it by any person
                  acquiring/using all/any information displayed on the
                  cookandChef.in website or any of its other websites / domains
                  owned and operated by CookandChef India Private Limited
                </li>
                <li>
                  The user expressly states that the sharing/insertion or
                  information/ data being fed into the network of CookandChef
                  India Private Limited by the user is correct and complete in
                  all respects and does not contain any false, distorted,
                  manipulated, fraudulent or misleading facts or averments.
                </li>
                <li>
                  CookandChef India Private Limited expressly disclaims any
                  liability arising out of the said resume
                  insertion/information/ data so fed into the network of
                  CookandChef India Private Limited by the user. Further, the
                  user agrees to indemnify CookandChef India Private Limited for
                  all losses incurred by CookandChef India Private Limited due
                  to any false, distorted, manipulated, defamatory, libelous,
                  vulgar, obscene, fraudulent or misleading facts or otherwise
                  objectionable averments made by the user on the network of
                  CookandChef India Private Limited.
                </li>
              </ul>
            </p>
            <h5>General Conditions:</h5>
            <p>
              We reserve the right to refuse service to anyone for any reason at
              any time. You understand that your content (not including credit
              card information), may be transferred unencrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks. You agree not to reproduce, duplicate,
              copy, sell, resell or exploit any portion of the Service, use of
              the Service, or access to the Service or any contact on the
              website through which the service is provided, without express
              written permission by us. The headings used in this agreement are
              included for convenience only and will not limit or otherwise
              affect these Terms.
            </p>
            <h5>Modification to the service and prices:</h5>
            <p>
              Prices for our services are subject to change without notice. We
              reserve the right at any time to modify or discontinue the Service
              (or any part or content thereof) without notice at any time. We
              shall not be liable to you or to any third-party for any
              modification, price change, suspension or discontinuance of the
              Service.
            </p>
            <h5>Information collection and use:</h5>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you. Personally, identifiable information (“Personal
              Information”) may include, but is not limited to:
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Telephone number</li>
                <li>Address</li>
              </ul>
            </p>
            <h5>Security:</h5>
            <p>
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
            <h5>Acceptance of the terms and conditions:</h5>
            <p>
              <ul>
                <li>
                  The User undertakes to be bound by the Agreement each time the
                  User accesses the Site, completes the registration process.
                </li>
                <li>
                  The User represents and warrants to CookandChef India Private
                  Limited that the User is 18 (eighteen) years of age or above,
                  and is capable of entering into, performing and adhering to
                  the Agreement. Individuals under the age of 18 (eighteen) may
                  access the Site or avail a Service only with the involvement,
                  guidance, supervision and/or prior consent of their parents
                  and/or legal guardians, through the Account of or under the
                  guidance of such parent/ legal guardian. CookandChef India
                  Private Limited shall not be responsible for verifying if the
                  User is atleast 18 (eighteen) years of age.
                </li>
              </ul>
            </p>
            <h5>Pricing:</h5>
            <p>
              CookandChef India Private Limited holds the right to decide any of
              its service prices and can modify them at any point of time
              without notice.
            </p>
            <div>
              <h5>User Information:</h5>
              <p>
                <ul>
                  <li>
                    CookandChef India Private Limited shall not be responsible
                    for the accuracy, quality, integrity, legality, reliability,
                    appropriateness and Intellectual Property Rights of and/or
                    in relation to the User Information.
                  </li>
                  <li>
                    CookandChef India Private Limited shall not be responsible
                    or liable for the deletion, correction, destruction, damage,
                    loss or failure to store any User Information, in whole or
                    in part.
                  </li>
                  <li>
                    CookandChef India Private Limited reserves the right to
                    withhold access, remove or discard any User Information from
                    the Site, in whole or in part at on its sole discretion.
                  </li>
                  <li>
                    CookandChef India Private Limited shall have the right but
                    not an obligation to maintain or forward any User
                    Information and to verify the authenticity of any User
                    Information made available to it through the Site or
                    otherwise.
                  </li>
                </ul>
              </p>
            </div>
            <h5>Cook:</h5>
            <p>
              <ul>
                <li>
                  CookandChef India Private Limited offers neither guarantee nor
                  warranties that there would be a satisfactory response or any
                  response at all for the profiles updated.
                </li>
                <li>
                  It is the responsibility of Cook/Chefs to verify the
                  correctness and authenticity of the claims made by Clients. We
                  merely act as facilitators and do not as agents for any
                  Client.
                </li>
                <li>
                  CookandChef India Private Limited neither guarantees nor
                  offers any warranty about the credentials of the prospective
                  client/organization which visits the profiles and uses it to
                  contact the prospective employee / visitor / user /
                  subscriber.
                </li>
                <li>
                  CookandChef India Private Limited would not be held liable for
                  loss of any data technical or otherwise, and particulars
                  supplied by subscribers due to reasons beyond its control like
                  corruption of data or delay or failure to perform as a result
                  of any causes or conditions that are beyond CookandChef India
                  Private Limited reasonable control including but not limited
                  to strikes, riots, civil unrest, Govt. policies, tampering of
                  data by unauthorized persons like hackers, war and natural
                  calamities.
                </li>
                <li>
                  Cook should verify client and all related details before
                  finalizing deal. CookandChef India Private Limited is not
                  liable and responsible in case of any discrepancies/
                  violations.
                </li>
              </ul>
            </p>
            <h5>Client:</h5>
            <p style={{ textWrap: "wrap" }}>
              <ul>
                <li>
                  CookandChef India Private Limited is to provide the
                  information only for the duration to the best of its ability
                  and CookandChef India Private Limited shall in no way be held
                  liable for any information received by client and it shall be
                  the sole responsibility of the subscriber to check,
                  authenticate and verify information/response received at its
                  own cost and expense.
                </li>
                <li>
                  CookandChef India Private Limited will make best efforts to
                  use the parameters provided by client to short list, but takes
                  no responsibility for the accuracy of the short listing based
                  on parameters for selection as specified by the
                  client/subscriber.
                </li>
                <li>
                  Client should verify cook and all related details before
                  finalizing deal. CookandChef India Private Limited is not
                  liable and responsible in case of any discrepancies/
                  violations.
                </li>
              </ul>
            </p>
            <h5>1. Acceptance of Terms</h5>
            <p style={{ textWrap: "wrap" }}>
              By accessing or using the CookandChef website/application or any
              related services, you agree to be bound by these Terms and
              Conditions. If you do not agree to these Terms and Conditions, you
              may not use the CookandChef website or any related services.
            </p>
            <h5>2. User Accounts</h5>
            <p style={{ textWrap: "wrap" }}>
              a. You may create an account on the CookandChef website by
              providing certain information about yourself, including your name,
              email address, and password. You are responsible for maintaining
              the confidentiality of your account information and are fully
              responsible for all activities that occur under your account. b.
              You must be at least 18 years old to create an account on the
              CookandChef website.
            </p>

            <h5>3. User Conduct</h5>
            <p style={{ textWrap: "wrap" }}>
              a. You agree to use the CookandChef website and any related
              services in a lawful and responsible manner. b. You agree not to
              use the CookandChef website or any related services for any
              illegal or unauthorized purpose, including, but not limited to: *
              posting fraudulent or misleading information * harassing, abusing,
              or threatening others * discriminating against others * infringing
              on the intellectual property rights of others * engaging in any
              activity that could damage the reputation of CookandChef
            </p>
            <h5>4. Content Ownership</h5>
            <p style={{ textWrap: "wrap" }}>
              a. You retain all ownership rights to the content you submit to
              the CookandChef website, such as your resume, cover letter, and
              profile information. b. By submitting content to the CookandChef
              website, you grant CookandChef a non-exclusive, worldwide,
              royalty-free license to use, reproduce, modify, publish, and
              distribute such content for the purposes of operating the
              CookandChef website and providing its services.
            </p>
            <h5>5. Disclaimer of Warranties</h5>
            <p style={{ textWrap: "wrap" }}>
              The CookandChef website and any related services are provided "as
              is" and without any warranties of any kind, express or implied.
              CookandChef disclaims all warranties, express or implied,
              including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </p>
            <h5>6. Limitation of Liability</h5>
            <p style={{ textWrap: "wrap" }}>
              CookandChef shall not be liable for any damages arising out of or
              in connection with the use of the CookandChef website or any
              related services. This includes, but is not limited to, direct,
              indirect, incidental, consequential, and punitive damages.
            </p>
            <h5>7. Indemnity</h5>
            <p style={{ textWrap: "wrap" }}>
              You agree to indemnify and hold harmless CookandChef India and its
              officers, directors, employees, agents, and licensors from and
              against any and all claims, losses, damages, liabilities, costs,
              and expenses (including reasonable attorneys' fees) arising out of
              or in connection with your use of the CookandChef website or any
              related services.
            </p>
            <h5>8. Termination</h5>
            <p style={{ textWrap: "wrap" }}>
              CookandChef may terminate your account or access to the
              CookandChef website or any related services at any time, for any
              reason, without notice.
            </p>
            <h5>9. Governing Law</h5>
            <p style={{ textWrap: "wrap" }}>
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of the [State/Country] without regard to
              its conflict of laws provisions.
            </p>
            <h5>10. Entire Agreement</h5>
            <p style={{ textWrap: "wrap" }}>
              These Terms and Conditions constitute the entire agreement between
              you and CookandChef with respect to your use of the CookandChef
              website and any related services.
            </p>
            <h5>11. Amendments</h5>
            <p style={{ textWrap: "wrap" }}>
              CookandChef may amend these Terms and Conditions at any time by
              posting the amended terms on the CookandChef website. You are
              responsible for reviewing the amended terms periodically. Your
              continued use of the CookandChef website or any related services
              following the posting of amended terms constitutes your acceptance
              of those terms.
            </p>
            <h5>12. Contact Us</h5>
            <p style={{ textWrap: "wrap" }}>
              If you have any questions about these Terms and Conditions, please
              contact us at support@cookandchef.in
            </p>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Termsandconditions;
