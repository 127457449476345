import React from "react";
import Skeleton from "react-loading-skeleton";

    const ShowprofileSkeleton = () => {
        return (
          <>
            <div className="card">
              <Skeleton height={120} />
            </div>
          </>
        );
      };
    
     
    export default ShowprofileSkeleton;