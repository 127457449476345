import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FaBookmark } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "moment";
import chef from "../../../Assets/Images/cook.png";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { IoMdCall } from "react-icons/io";
import { BsBookmark, BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import {
  EmployerMobileactivityLog,
  removeshortlistchef,
  shortlistchef,
} from "../../../store/slices/employerprofile";
import { GetHouseCookDetails } from "../../../store/slices/clientprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ReportChefModal from "./ReportChefModal";
import { createChatRoom } from "../../cook_dashboard/pages/CreateChatRoom";
import CookRatingModal from "../../../utils/helpers/CookRatingModal";
import { StarRating1 } from "../../../utils/helpers/CookRatingModal";
import { API_PATHS } from "../../../utils/constants/api.constants";
import InitApp1 from "../../../utils/helpers/CreateOthersWeavy";
import ContactPivacyModal from "../../../utils/helpers/ContactPrivacyModal";
import ReviewModal from "../../../utils/helpers/ReviewModal";

function HouseProfile() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [rateModal, setRateModal] = useState(false);
  const [reportJob, setReportJob] = useState(false);
  const [isRespTrue, setIsRespTrue] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  React.useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    dispatch(GetHouseCookDetails(id))
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setphoneNumber(data?.user?.data?.mobile);
        setwhatsappNumber(data?.user?.data?.whatsappNumber);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const removeshortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      cookId: id,
      cookType: "housecook",
    };

    dispatch(removeshortlistchef(elem))
      .then((data) => {
        // setModalMessage("Shortlisted removed successfully");
        // setsuccessModal(true);
        // setTimeout(() => {
        //   setsuccessModal(false);
        // }, 3000);
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const shortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      id: id,
      cookType: "housecook",
    };
    dispatch(shortlistchef(elem))
      .then((data) => {
        // setModalMessage("Profile shortlisted successfully!");
        // setsuccessModal(true);
        // setTimeout(() => {
        //   setsuccessModal(false);
        // }, 3000);
        getDetails();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const [clicked, setClicked] = useState(false);

  const toggleMobileView = () => {
    const item = {
      cookId: id,
      activity: "viewedmobilenumber",
    };
    if (data?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else if (showFullMobile == false) {
      dispatch(EmployerMobileactivityLog(item))
        .unwrap()
        .then((data) => {
          setShowFullMobile(!showFullMobile);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleInteracction = async (value) => {
    const item = {
      cookId: id,
      activity: value,
    };
    try {
      const data1 = await dispatch(EmployerMobileactivityLog(item)).unwrap();

      if (value === "mobileinteraction") {
        window.location.href = `tel:${phoneNumber}`;
      } else if (value === "whatsappinteraction") {
        window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
      } else if (value === "chatinteraction") {
        let member2;
        let res;
        const weavyid = parseInt(localStorage.getItem("weavyId"));
        const member1 = parseInt(user?.data?.weavyId) || weavyid;

        const chatroomName = data?.fullName;
        if (!data.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.id,
              name: data?.fullName,
            }),
          };
          const rees = await InitApp1(requestOptions, data, "cook");
          member2 = parseInt(localStorage.getItem("weavyId2"));
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        } else {
          member2 = parseInt(data?.weavyId);
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        }

        if (res?.id) {
          navigate("/client/message", {
            state: { res: res },
          });
        }
      }
    } catch ({ message }) {
      setModalMessage(message);
      setShowModal(true);
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | House Profile";
  }, []);
  useEffect(() => {
    // Check if data exists and has ratingsList
    if (data && data.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data.ratingsList.some((rating) => rating.comment);

      // Set reviews based on whether any rating has a comment
      setReviews(hasComment);
    }
  }, [data]);

  return (
    <>
      <ReviewModal
        showModal={reviewModal}
        data={data}
        setShowModal={setShowReviewModal}
      />
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={id}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
        role="House Cook"
      />
      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setsuccessModal={setsuccessModal}
      />
      <CookRatingModal
        rateModal={rateModal}
        id={id}
        setRateModal={setRateModal}
        getDetails={getDetails}
        data={data}
        role="House Cook"
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/client/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/client/find-house-cook" className="bcrumb">
          Find House Cook
        </Link>
        &nbsp;&bull; House Cook Profile
      </div>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={3}>
                {data?.dp ? (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={data?.dp}
                    alt="user profile"
                  />
                ) : (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={chef}
                    alt="user profile"
                  />
                )}
                <p
                  style={{
                    fontSize: "11px",
                    color: "#8F8686",
                    marginTop: "5px",
                  }}
                >
                  Active on&nbsp;
                  {Moment.utc(data?.lastLoginDateTime).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col
                xs={3}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.isShortlisted === true && <p>Saved</p>}
                </div>
              </Col>
              <Col xs={1} className="d-sm-block d-md-none d-lg-none">
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.isShortlisted === true && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.isShortlisted === true && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.isShortlisted === false && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.isShortlisted === false && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={7} xl={7} xs={12} md={6}>
                <h6 className="mt-2">{data?.fullName}</h6>

                <p>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.cityName}
                  <br />
                  <HiOutlineDevicePhoneMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.mobile}</span>
                  ) : (
                    <span>
                      {data?.mobile?.toString()?.substring(0, 3)}xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-25px",

                          color: "blue",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col
                lg={2}
                xl={2}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.isShortlisted === true && (
                    <p style={{ marginRight: "-25px" }}>Saved</p>
                  )}
                </div>
              </Col>
              <Col
                lg={1}
                md={1}
                className="d-none d-md-block d-lg-block"
                align="center"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.isShortlisted === true && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.isShortlisted === true && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.isShortlisted === false && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.isShortlisted === false && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={8} xl={9} xs={12} md={7} />
              <Col
                lg={4}
                xl={3}
                xs={12}
                md={5}
                className="contacticonCard"
                align="right"
              >
                <Row align="right">
                  {data.smsContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data.whatsappContact === 1 && data?.whatsappNumber && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("whatsappinteraction")}
                    >
                      <BsWhatsapp
                        style={{ color: "#059F14", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data.chatContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Row className="mt-4">
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col
            lg={6}
            xs={12}
            md={6}
            sm={12}
            className="card me-1 detailCard p-3"
          >
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Basic Information
            </h6>
            <p className="text-heading">About</p>
            <p className="text-details">{data?.about}</p>
            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">Email </p>
                <p className="text-details">{data?.email}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">Date of Birth</p>

                <p className="text-details">
                  {Moment.utc(data.dob).format("DD-MM-YYYY")}
                </p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">Gender </p>
                {data?.gender === 1 && <p className="text-details">Male</p>}
                {data?.gender === 2 && <p className="text-details">Female</p>}
                {data?.gender === 3 && <p className="text-details">Other</p>}
              </Col>

              <Col lg={6} xs={12}>
                <p className="text-heading">Education </p>
                <p className="text-details">{data?.qualification}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">Languages Known </p>
                <p className="text-details">{data?.languages?.join(", ")}</p>
              </Col>
            </Row>
          </Col>
        )}
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col lg={6} xs={12} md={6} className="card ms-2 detailCard p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              Address
            </h6>
            <p className="text-heading">Address</p>
            <p className="text-details">
              {data?.addressLine1} {data?.addressLine2}
            </p>

            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">Area</p>
                <p className="text-details">{data?.area}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">City</p>
                <p className="text-details">{data?.cityName}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">State</p>
                <p className="text-details">{data?.provinceName}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">Pincode</p>
                <p className="text-details">{data?.pincode}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">Landmark</p>
                <p className="text-details">{data?.landmark}</p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {isLoading ? (
        <Row className="card me-1 detailCard p-3">{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-3 justify-content-center p-2 card mb-3">
          <Col lg={12} xs={12} md={12}>
            <h6
              className="text-left mt-1"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              House Profile
            </h6>
          </Col>
          <Col lg={12} xs={12} className="ps-2 mt-3 mb-2">
            <p className="text-heading">Cuisines Known</p>
            <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
              {data?.householdCuisines?.map((cookplanVal, index) => (
                <Col
                  xs={5}
                  sm={5}
                  md={3}
                  lg={2}
                  xl={2}
                  className="me-2"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    color: "black",
                    padding: "4px 8px",
                    margin: "8px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "14px",
                    marginRight: "4px",
                    border: "solid 1px #ff5c09",
                    width: "auto",
                  }}
                >
                  {cookplanVal}
                </Col>
              ))}
            </Row>
          </Col>

          <Col>
            <Row>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">Job Type</p>
                {data.jobType === 1 && (
                  <p className="text-details">Part Time</p>
                )}
                {data.jobType === 2 && (
                  <p className="text-details">Full Time</p>
                )}
                {data.jobType === 3 && <p className="text-details">Any</p>}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">Payment</p>
                <p className="text-details">{data.payment}</p>
              </Col>

              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">Vessel Wash</p>
                {data.householdVesselWash === 0 && (
                  <p className="text-details">No</p>
                )}
                {data.householdVesselWash === 1 && (
                  <p className="text-details">Yes</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col
            style={{ borderRadius: "15px", padding: "14px" }}
            className="card"
          >
            {data?.ratingsData?.behaviour?.ratingAvg === 0 &&
            data?.ratingsData?.hygiene?.ratingAvg === 0 &&
            data?.ratingsData?.punctuality?.ratingAvg === 0 &&
            data?.ratingsData?.taste?.ratingAvg === 0 ? (
              <div>
                <div className="text-start">Not rated yet!</div>
                <Link
                  className="text-start"
                  style={{
                    color: "#ff5c09",
                    textDecoration: "underline",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setRateModal(true)}
                >
                  <u>Rate now</u>
                </Link>
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <div style={{ marginTop: "10px" }}>Behaviour :</div>
                    <div style={{ marginTop: "10px" }}>Hygiene :</div>
                    <div style={{ marginTop: "10px" }}>Punctuality :</div>
                    <div style={{ marginTop: "10px" }}>Taste :</div>
                  </div>
                  <div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.ratingsData?.behaviour?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(data?.ratingsData?.hygiene?.ratingAvg)}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.ratingsData?.punctuality?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(data?.ratingsData?.taste?.ratingAvg)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Link
                    className="text-start mt-3"
                    style={{
                      color: "#ff5c09",
                      textDecoration: "underline",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => setRateModal(true)}
                  >
                    <u>Rate now</u>
                  </Link>

                  {reviews && (
                    <Link
                      className="text-start mt-3 ml-5"
                      style={{
                        color: "#ff5c09",
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "30px",
                      }}
                      onClick={() => setShowReviewModal(true)}
                    >
                      <u>View Reviews</u>
                    </Link>
                  )}
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col style={{ borderRadius: "15px", padding: "14px" }}>
          <Link
            onClick={() => setReportJob(true)}
            className="text-start mt-5"
            style={{
              color: "#8F8686",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <u>Report this profile</u>
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default HouseProfile;
