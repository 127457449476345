import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FaBookmark } from "react-icons/fa";
import {
  removeshortlistchef,
  viewemployershortlistedchefs,
} from "../../../store/slices/employerprofile";
import Moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import "../employer.css";
import { MdCurrencyRupee } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";
import { Link } from "react-router-dom";
import chef from "../../../Assets/Images/cook.png";

function ShortlistedChefs() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const [isRespTrue, setIsRespTrue] = useState(false);
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();

  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      limit: itemsPerPage,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    getchefs(item);
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Shortlisted Profiles";
  }, []);

  const getchefs = (item) => {
    setisLoading(false);
    dispatch(viewemployershortlistedchefs(item))
      .unwrap()
      .then((data) => {
        setData(data?.shortlistedchef?.data);
        setTotaldata(data?.shortlistedchef?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setTimeout(() => {
          setisLoading(true);
        }, 1000);
      });
  };

  const removeshortList = (item1) => {
    setIsRespTrue(false);
    var elem = {
      cookId: item1._id,
      cookType: "chef",
    };

    dispatch(removeshortlistchef(elem))
      .then((data) => {
        if (data?.payload?.message) {
          //setModalMessage(data?.payload?.message);
          //setShowModal(true);
        }
      })
      .catch(({ message }) => {
        //setModalMessage(message);
        //setShowModal(true);
      })
      .finally(() => {
        getchefs(item);
      });
  };

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/employer/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Shortlisted Profiles
      </div>
      <Row className="mt-4">
        {totaldata === 0 ? (
          <Col lg={10} xs={12} md={10} className="card p-5" align="center">
            <h3>You have not shortlisted any profile!</h3>
          </Col>
        ) : isLoading ? (
          <div>
            <Col lg={12} sm={12} xs={12}>
              <Row>
                {data?.map((item, index) => (
                  <Link
                    to={`/employer/shortlisted-chef-details/${item._id}`}
                    style={{ color: "#000" }}
                  >
                    <Col lg={8} sm={12} xs={12}>
                      <Row
                        className="d-flex justify-items-center align-items-center"
                        key={index}
                        style={{
                          backgroundColor: "white",
                          // height: "20dvh",
                          // width: "100%",
                          borderRadius: "15px",
                          padding: "8px",
                          marginTop: "8px",
                        }}
                      >
                        <Col lg={2} xs={3} className="justify-content-center">
                          {item?.dp && (
                            <img
                              className="align-self-center"
                              style={{
                                borderRadius: "10px",
                                height: "80px",
                                width: "80px",
                              }}
                              src={item?.dp}
                              alt="user profile"
                            />
                          )}
                          {!item?.dp && (
                            <img
                              className="align-self-center"
                              style={{
                                borderRadius: "10px",
                                height: "80px",
                                width: "80px",
                              }}
                              src={chef}
                              alt="user profile"
                            />
                          )}
                        </Col>
                        <Col lg={9} xs={7}>
                          <h6
                            style={{
                              fontWeight: "600",
                              marginBottom: "0.2rem",
                            }}
                            className="ms-2"
                          >
                            {item?.fullName}
                          </h6>

                          {item?.chefCuisines?.length >= 3 ? (
                            <p
                              style={{
                                marginBottom: "0.25rem",
                                fontSize: "14px",
                              }}
                              className="ms-2"
                            >
                              <MdOutlineFoodBank
                                style={{ marginBottom: "2px" }}
                              />
                              &nbsp;
                              {item?.chefCuisines?.join(", ").substring(0, 20)}
                              ...
                            </p>
                          ) : (
                            <p
                              style={{
                                marginBottom: "0.25rem",
                                fontSize: "14px",
                              }}
                              className="ms-2"
                            >
                              <MdOutlineFoodBank
                                style={{ marginBottom: "2px" }}
                              />
                              &nbsp;
                              {item?.chefCuisines?.join(", ")}
                            </p>
                          )}
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-2"
                          >
                            <GrLocation style={{ marginBottom: "2px" }} />
                            &nbsp;
                            {item?.currentCityName}
                          </p>

                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-2"
                          >
                            <BsBriefcase style={{ marginBottom: "2px" }} />
                            &nbsp;
                            {item?.chefExperience} Years
                            <MdCurrencyRupee
                              style={{ marginBottom: "2px" }}
                              className="ms-5"
                            />
                            {item?.expectedSalary}
                          </p>
                        </Col>
                        <Col
                          lg={1}
                          xs={2}
                          md={2}
                          align="right"
                          style={{ marginTop: "-80px" }}
                        >
                          {item?.shortlistId && isRespTrue && (
                            <Link
                              onClick={() => removeshortList(item, "housecook")}
                              style={{ color: "#000" }}
                            >
                              <FaBookmark />
                            </Link>
                          )}
                          {item?.shortlistId && isRespTrue === false && (
                            <Link style={{ color: "#000" }}>
                              <FaBookmark />
                            </Link>
                          )}
                        </Col>
                        <Col lg={2} xs={3} className="justify-content-center">
                          <div
                            className="text-left"
                            style={{ fontSize: "10px", color: "grey" }}
                          >
                            Shortlisted on
                            {Moment.utc(item?.lastLoginDateTime).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </Col>
                        <Col lg={10} xs={9}>
                          <div align="right">
                            {item?.isViewed === true && (
                              <CiUnlock style={{ fontSize: "20px" }} />
                            )}
                            {item?.isViewed === false && (
                              <CiLock style={{ fontSize: "20px" }} />
                            )}
                            &nbsp;
                            <Button className="view_profile ms-5">View</Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Link>
                ))}
              </Row>
            </Col>
          </div>
        ) : (
          <Col lg={8} xs={12} md={12} align="center">
            {ShowSkeleton()}
          </Col>
        )}
      </Row>
      {totaldata / itemsPerPage > 1 && (
        <div className="justify-content-right align-right">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakLinkClassName={"page-link"}
            pageCount={Math.ceil(totaldata / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={pagesToShowInitially}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            disabledClassName="disabled"
            initialPage={currentPage - 1}
          />
        </div>
      )}
    </>
  );
}

export default ShortlistedChefs;
