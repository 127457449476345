import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import ImageCropper from "./ImageCropper";

function AvatarModel({
  showModal1,
  image,
  setShowModal1,
  handleSave,
  setData,
  data,
}) {
  const handleCloseModal = () => setShowModal1(false);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginLeft: "5%" }}
        show={showModal1}
      >
        <Modal.Body style={{ width: "auto", backgroundColor: "white" }}>
          <ImageCropper
            imageSrc={image}
            handleSave={handleSave}
            handleCloseModal={handleCloseModal}
            setData={setData}
            data={data}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AvatarModel;
