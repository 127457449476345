import React, { useState, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Container, Modal, Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import verified from "../../Assets/Images/checked.png";
import {
  editbasicProfile,
  getcookProfile,
} from "../../store/slices/cookprofile";
import Moment from "moment";
import cook from "../../Assets/Images/cook.png";
import { MdOutlineEdit } from "react-icons/md";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  CookChangeEmail,
  CookChangeMobile,
  CookChangeWhatsapp,
  CookCheckWhatsapp,
  CookVerifyEmail,
  CookVerifyMobileOtp,
  CookVerifyWhatsapOtp,
} from "../../store/slices/auth";
import Modal2 from "../auth_screens/Modal2";
import SuccessModal from "../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../auth_screens/profileskeleton";
import DataSkeleton from "../auth_screens/dataskeleton";
import { API_PATHS } from "../../utils/constants/api.constants";
import AvatarModel from "../../utils/helpers/ImageCropper/AvatarModal";
import ChangeWatsappmodal from "../../utils/helpers/ChangeWatsappModal";

function Profile() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleCloseModal = () => setShowModal1(false);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newMobile, setNewMobile] = useState("");
  const [modalType, setModalType] = useState(""); // "email", "mobile", "whatsapp"
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const [isWhatsAppSameAsMobile, setIsWhatsAppSameAsMobile] = useState(false);
  const [watsappchangemodal, setWatsapChangeModal] = useState(false);
  const [watsappchangemodalmessage, setWatsapChangeModalmessage] = useState("");

  const handleWhatsAppCheckboxChange = (e) => {
    setIsWhatsAppSameAsMobile(e.target.checked);

    if (e.target.checked) {
      setInputValue(data.mobile);
      setInputError("");
    } else {
      setInputValue("");
    }
  };

  useEffect(() => {
    document.title = "CookandChef | Profile";
  }, []);

  useEffect(() => {
    if (modalType === "mobile") {
      setInputValue(data.mobile || "");
    } else if (modalType === "email") {
      setInputValue(data.email || "");
    } else if (modalType === "whatsapp") {
      setInputValue(data.whatsappNumber || "");
    }
  }, [modalType]);

  const isValidEmail = (email) => {
    // Regular expression for a basic email validation
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidMobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setInputError(""); // Clear any previous error

    // Validate based on modalType
    if (modalType === "email") {
      setInputValue(value);
      if (value.trim() === "" && !isValidEmail(value)) {
        setInputError("Please enter a valid email");
      } else {
        setInputError("");
      }
    } else if (modalType === "mobile") {
      value = value.replace(/\D/g, "");

      // Limit the length to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setInputValue(value);
      if (!isValidMobile(value)) {
        setInputError("Please enter a valid mobile number");
      }
    } else if (modalType === "whatsapp") {
      value = value.replace(/\D/g, "");

      // Limit the length to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setInputValue(value);
      if (!isValidMobile(value)) {
        setInputError("Please enter a valid WhatsApp number");
      }
    } else if (modalType === "otp") {
      value = value.replace(/\D/g, "");

      if (value.length > 6) {
        value = value.slice(0, 6);
      }
      setInputValue(value);
      if (value.length < 6 || value.length > 6) {
        setInputError("OTP must be 6 digits");
      }
    } else if (modalType === "whatsappotp") {
      value = value.replace(/\D/g, "");

      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      setInputValue(value);
      if (value.length < 4 || value.length > 4) {
        setInputError("OTP must be 4 digits");
      }
    } else {
      setInputError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle submission based on modalType
    if (modalType === "email") {
      setInputError("");
      if (!inputValue.trim()) {
        setInputError("Please enter your email");
      } else if (!isValidEmail(inputValue.trim())) {
        setInputError("Please enter valid email");
      } else {
        setInputError("");
        let item = {
          email: inputValue,
        };
        dispatch(CookChangeEmail(item))
          .unwrap()
          .then((data) => {
            setModalMessage(data?.user.message);
            setsuccessModal(true);
            getProfile();
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);

            if (data?.user.status === 1) {
              handleCloseModal();
              setInputValue(data.user.data.email);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "mobile") {
      setInputError("");
      if (!inputValue) {
        setInputError("Please enter your mobile number");
      } else if (!isValidMobile(inputValue)) {
        setInputError("Please enter valid mobile number");
      } else {
        setInputError("");
        let item = {
          mobile: parseInt(inputValue),
        };
        dispatch(CookChangeMobile(item))
          .unwrap()
          .then((data) => {
            handleCloseModal();
            setModalMessage(data?.user?.message);
            setsuccessModal(true);

            if (data?.user?.responseCode === 1) {
              setNewMobile(data?.user?.data?.newMobile);
              handleShowModal("otp");
              setsuccessModal(false);
              setInputValue("");
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "whatsapp") {
      setInputError("");
      if (!inputValue) {
        setInputError("Please enter your WhatsApp number");
      } else if (!isValidMobile(inputValue)) {
        setInputError("Please enter valid WhatsApp number");
      } else {
        setInputError("");
        let item = {
          whatsappNumber: parseInt(inputValue),
        };
        dispatch(CookCheckWhatsapp(item))
          .unwrap()
          .then((data) => {
            setIsWhatsAppSameAsMobile(false);

            changeWatsapp();
          })
          .catch(({ message }) => {
            if (
              message ===
              "This whatsapp number is already linked with another account. Do you want to update it?"
            ) {
              setWatsapChangeModalmessage(message);
              setWatsapChangeModal(true);
              setShowModal1(false);
            } else if (
              message ===
              "This whatsapp number is currently linked with your account."
            ) {
              setInputError(
                "This number is currently linked with your account"
              );
            }
          });
      }
    } else if (modalType === "otp") {
      setInputError("");
      if (!inputValue) {
        setInputError("Please enter OTP");
      } else if (inputValue.length < 6 || inputValue.length > 6) {
        setInputError("OTP must be 6 digits");
      } else {
        setInputError("");
        let item = {
          otp: inputValue,
          mobile: parseInt(newMobile),
        };
        dispatch(CookVerifyMobileOtp(item))
          .unwrap()
          .then((data) => {
            handleCloseModal();
            setModalMessage(data?.user?.message);
            setsuccessModal(true);

            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            setLoading(false);
            getProfile();

            if (data?.user?.status === 1) {
              handleCloseModal();
              setInputValue(data.user.data.mobile);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "whatsappotp") {
      setInputError("");
      if (!inputValue) {
        setInputError("Please enter OTP");
      } else if (inputValue.length < 4 || inputValue.length > 4) {
        setInputError("OTP must be 4 digits");
      } else {
        setInputError("");
        let item = {
          otp: inputValue,
          whatsappNumber: parseInt(newMobile),
        };
        dispatch(CookVerifyWhatsapOtp(item))
          .unwrap()
          .then((data) => {
            setModalMessage(data?.user.message);
            setsuccessModal(true);
            handleCloseModal();
            setLoading(true);
            getProfile();

            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);

            if (data?.user.status === 1) {
              handleCloseModal();
              setInputValue(data.user.data.whatsappNumber);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    }
  };

  const changeWatsapp = () => {
    let item = {
      whatsappNumber: parseInt(inputValue),
    };
    dispatch(CookChangeWhatsapp(item))
      .unwrap()
      .then((data) => {
        handleCloseModal();
        setModalMessage(data?.user.message);
        setsuccessModal(true);
        setLoading(false);
        if (data?.user?.responseCode === 1) {
          setNewMobile(data?.user?.data?.whatsappNumber);
          handleShowModal("whatsappotp");
          setInputValue("");
          setsuccessModal(false);
        }
      })
      .catch(({ message }) => {
        setInputError(message);
      });
  };

  const handleShowModal = (type) => {
    setModalType(type);
    if (type !== "whatsapp") {
      setIsWhatsAppSameAsMobile(false);
    }
    setInputError("");
    setShowModal1(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    handleCloseModal();
    setInputError("");
  };

  const verifyemail = () => {
    dispatch(CookVerifyEmail())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user.message);
        setsuccessModal(true);
        getProfile();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        setLoading(false);
        handleCloseModal();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };
  React.useEffect(() => {
    getProfile();
  }, [dispatch]);

  const getProfile = () => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data?.user.data);
        setLanguages(data?.user.data?.languages);
        setLoading(false);
      })
      .catch(({ error }) => {});
  };

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFileCook, requestOptions);
    let data2 = await response.json();
    const item = {
      dp: data2.data.location,
    };

    if (data2.data.location) {
      dispatch(editbasicProfile(item))
        .unwrap()
        .then((data1) => {
          setModalMessage("Profile Photo updated succesfully");

          setsuccessModal(true);
          getProfile();

          setTimeout(() => {
            setsuccessModal(false);
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  return (
    <>
      <ChangeWatsappmodal
        showModal={watsappchangemodal}
        setShowModal={setWatsapChangeModal}
        modalMessage={watsappchangemodalmessage}
        func={changeWatsapp}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <AvatarModel
        showModal1={avatarModalOpen}
        image={imageData}
        setShowModal1={setAvatarModalOpen}
        handleSave={handleSave}
        setData={setData}
        data={data}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={showModal1}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title align="center" style={{ fontSize: "20px" }}>
            {modalType === "email" && "Email"}
            {modalType === "mobile" && "Mobile Number"}
            {modalType === "whatsapp" && "WhatsApp number"}
            {modalType === "otp" && "Enter your OTP"}
            {modalType === "whatsappotp" && "Enter your OTP"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body align="left">
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Row className="d-flex justify-content-left ">
              <Col lg={12} className="p-1 mb-3">
                {modalType === "whatsapp" && (
                  <Form.Group controlId="whatsappCheckbox" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Same as mobile number"
                      onChange={handleWhatsAppCheckboxChange}
                      checked={isWhatsAppSameAsMobile}
                    />
                  </Form.Group>
                )}

                <Form.Group>
                  <Form.Control
                    name="inputValue"
                    value={inputValue}
                    onChange={handleChange}
                    isInvalid={!!inputError}
                    placeholder={
                      modalType === "email"
                        ? "Please enter your email"
                        : modalType === "mobile"
                        ? "Please enter your mobile number"
                        : modalType === "whatsapp"
                        ? "Please enter WhatsApp number"
                        : "Please enter OTP"
                    }
                    className="input1"
                    type={
                      modalType === "email"
                        ? "text"
                        : modalType === "mobile"
                        ? "text"
                        : "text"
                    }
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {inputError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <button className="modalcancelBtn m-2" onClick={closeModal}>
              Cancel
            </button>
            <Button className="modalsaveBtn m-2" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Profile
      </div>
      <Container>
        {loading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row className="mt-2">
            <Col lg={12} className="card">
              <Row>
                <Col lg={2} sm={4} xs={4} className="pb-3">
                  {data?.dp ? (
                    <img
                      className="mt-2"
                      src={data?.dp}
                      alt="user profile"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "10%",
                      }}
                    />
                  ) : (
                    <img
                      className="mt-2"
                      src={cook}
                      alt="user profile"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "10%",
                      }}
                    />
                  )}
                  <div style={{ marginTop: "-20px" }}>
                    <label for="fileInput" className="custom-buttonProfile">
                      <MdOutlineEdit />
                    </label>
                    <Form.Control
                      type="file"
                      name="profiledp"
                      id="fileInput"
                      width="20%"
                      accept="image/*"
                      onChange={(e) => handleChangeImage(e)}
                      className="input1"
                      placeholder="Please choose profile photo"
                    />
                  </div>
                </Col>

                <Col
                  lg={7}
                  sm={8}
                  xs={8}
                  className="justify-content-left align-items-left ps-4"
                >
                  <h5 className="mt-3" style={{ textWrap: "wrap" }}>
                    {data?.fullName}
                  </h5>
                  <Row className="mt-2">
                    <Col xs={12} lg={7} md={7}>
                      <span className="mb-2" style={{ fontSize: "12px" }}>
                        Profile Completeness {data?.profilePercent} %
                      </span>
                      <ProgressBar now={data?.profilePercent} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={11} md={2} lg={3} align="right" className="mt-3 mb-2">
                  {data?.memberType !== 2 && (
                    <button
                      className="editBtn"
                      onClick={() => navigate("/cook/edit-cook-profile")}
                    >
                      <MdOutlineEdit
                        style={{ fontSize: "15px", marginTop: "-2px" }}
                      />
                      Edit
                    </button>
                  )}
                  {data?.memberType === 2 && (
                    <button
                      className="editBtn"
                      onClick={() => navigate("/cook/editcateringprofile")}
                    >
                      <MdOutlineEdit
                        style={{ fontSize: "15px", marginTop: "-2px" }}
                      />
                      &nbsp;Edit
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {loading ? (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            {DataSkeleton()}
          </Row>
        ) : (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            <Col lg={12} xs={12} md={12} className="mb-4">
              <h5 className="text-left heading orange">General Details</h5>
              <Row className="gx-2">
                <Col lg={12} cm={12} md={12}>
                  <p className="text-heading">About</p>
                  {data?.about && <p className="text-details">{data?.about}</p>}
                </Col>
                {data?.memberType !== 2 && (
                  <Col lg={6} sm={12} md={6}>
                    <p className="text-heading">Date of Birth</p>
                    {data?.dob && (
                      <p className="text-details">
                        {Moment.utc(data?.dob).format("DD-MM-YYYY")}
                      </p>
                    )}
                  </Col>
                )}
                {data?.memberType !== 2 && (
                  <Col lg={6} sm={12}>
                    <p className="text-heading">Qualification</p>
                    {data?.dob && (
                      <p className="text-details">{data?.qualification}</p>
                    )}
                  </Col>
                )}
                {data?.memberType !== 2 && (
                  <Col lg={6} xs={6}>
                    <p className="text-heading">Gender</p>
                    {data?.gender === 1 && <p className="text-details">Male</p>}
                    {data?.gender === 2 && (
                      <p className="text-details">Female</p>
                    )}
                    {data?.gender === 3 && (
                      <p className="text-details">Other</p>
                    )}
                  </Col>
                )}
                <Col lg={6} sm={12}>
                  <p className="text-heading">Languages Known</p>
                  <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                    {languages?.map((partyplanVal, index) => (
                      <Col
                        xs={5}
                        sm={5}
                        md={3}
                        lg={2}
                        xl={2}
                        className="me-2"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          color: "black",
                          padding: "4px 8px",
                          margin: "8px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontSize: "14px",
                          marginRight: "4px",
                          border: "solid 1px #ff5c09",
                          width: "auto",
                        }}
                      >
                        {partyplanVal}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>

              <h5 className="text-left heading mt-3 orange">Contact Details</h5>
              <Row className="gx-2">
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">
                    Mobile Number
                    <span
                      style={{
                        color: "#3352D0",

                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => handleShowModal("mobile")}
                    >
                      <u>Change</u>
                    </span>
                  </p>
                  <p className="text-details">{data?.mobile}</p>
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <div className="d-flex">
                    <p className="text-heading">
                      Email &nbsp;
                      {data?.emailVerified === 0 && (
                        <>
                          <span
                            style={{
                              color: "#ff5c09",
                              fontStyle: "italic",
                              fontSize: "12px",
                              cursor: "pointer",
                              fontWeight: "500",
                            }}
                            onClick={() => verifyemail()}
                          >
                            Verify
                          </span>
                        </>
                      )}
                      <span
                        style={{
                          color: "#3352D0",
                          fontSize: "12px",
                          fontWeight: "500",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => handleShowModal("email")}
                      >
                        <u>Change</u>
                      </span>
                    </p>
                  </div>
                  {data?.email && (
                    <p className="text-details">
                      {data?.email}
                      {data?.emailVerified === 1 && (
                        <img
                          src={verified}
                          style={{ height: "15px", width: "15px" }}
                          alt=""
                        />
                      )}
                    </p>
                  )}
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">
                    WhatsApp Number
                    <span
                      style={{
                        color: "#3352D0",

                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => handleShowModal("whatsapp")}
                    >
                      {data?.whatsappNumber && <u>Change</u>}
                      {!data?.whatsappNumber && <u>Add</u>}
                    </span>
                  </p>
                  {data?.whatsappNumber && (
                    <p className="text-details">{data?.whatsappNumber}</p>
                  )}
                </Col>
                <Col lg={6} sm={12} className="mt-2" />
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">Address Line 1</p>
                  {data?.addressLine1 && (
                    <p className="text-details">{data?.addressLine1}</p>
                  )}
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">Address Line 2</p>
                  {data?.addressLine2 && (
                    <p className="text-details">{data?.addressLine2}</p>
                  )}
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">Area</p>
                  {data?.area && <p className="text-details">{data?.area}</p>}
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">City</p>
                  {data?.cityName && (
                    <p className="text-details">{data?.cityName}</p>
                  )}
                </Col>

                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">State</p>
                  {data?.provinceName && (
                    <p className="text-details">{data?.provinceName}</p>
                  )}
                </Col>

                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">Pincode</p>
                  {data?.pincode && (
                    <p className="text-details">{data?.pincode}</p>
                  )}
                </Col>
                <Col lg={6} sm={12} className="mt-2">
                  <p className="text-heading">Landmark</p>
                  {data?.landmark && (
                    <p className="text-details">{data?.landmark}</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Profile;
