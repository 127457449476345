import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsBookmark } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FiFilter } from "react-icons/fi";
import { API_PATHS } from "../../../utils/constants/api.constants";
import { CiWallet } from "react-icons/ci";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Skeleton from "react-loading-skeleton"; 
import dog from "../../../Assets/Images/no-result.jpg";

import {
  findHouseJobs,
  removeshortlistjob,
  shortlisthousejob,
} from "../../../store/slices/cookprofile";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { getCuisines } from "../../../store/slices/config";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { FaBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import client from "../../../Assets/Images/client.png";
import Moment from "moment";
import newtag from "../../../Assets/Images/new.png";
import Modal2 from "../../auth_screens/Modal2";
import { IoMdEye } from "react-icons/io";

function FindHouseJob() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const handleapply = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setisLoading] = useState(false);
  // const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const myData = useSelector((state) => state.cookData.housejobsdata);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [pagechange, setPageChange] = useState(false);
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = async ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setPageChange(true);
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    document.title = "CookandChef | Find House Jobs";
  }, []);

  const shortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: true };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      id: item._id,
    };

    dispatch(shortlisthousejob(elem))
      .then((data) => {})
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };
  const removeshortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: false };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      requirementId: item._id,
    };

    dispatch(removeshortlistjob(elem))
      .then((data) => {})
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");
    const genderQ = search.get("gender");
    const jobtypeQ = search.get("jobType");
    const paymentQ = search.get("payment");
    // const pageq = search.get("page");
    setTimeout(() => {
      setisLoading(true);
    }, [300]);
    setisLoading(false);
    if (!cuisineval && !locationq && !genderQ && !jobtypeQ && !paymentQ) {
      getEvents();
    } else if (pagechange === true) {
      getEvents();
    }
  }, [currentPage]);

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");
    const genderQ = search.get("gender");
    const jobtypeQ = search.get("jobType");
    const paymentQ = search.get("payment");
    // const page = search.get("page");
    if (!cuisineval && !locationq && !genderQ && !jobtypeQ && !paymentQ) {
      getEvents();
    } else {
      setData(myData?.housejobsdata?.data);
      setTotaldata(myData?.housejobsdata?.totalDataCount);
    }
  }, [myData]);

  const getEvents = () => {
    dispatch(findHouseJobs(item))
      .unwrap()
      .then((data) => {
        setData(data?.housejobsdata?.data);
        setTotaldata(data?.housejobsdata?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {});
  };

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const renderViews = (count) => {
    if (!count ||count < 1) return null;
    return `${count} view${count > 1 ? 's' : ''}`;
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;Find House Job
      </div>

      <div>
        <div align="right">
          <Button
            className="filter_btn d-flex justify-content-around align-items-center d-lg-none"
            onClick={handleShow}
            style={{ border: "solid 2px #ff5c09", padding: "5px 10px" }}
          >
            <FiFilter />
            &nbsp;Filter
          </Button>
        </div>

        <BottomSheet
          open={show}
          onDismiss={() => setShow(false)}
          blocking={false}
          snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
        >
          <Filter
            setItem={setItem}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleapply={handleapply}
            setisLoading={setisLoading}
          />
        </BottomSheet>
        <Row className="mt-4">
          {data?.length === 0 ? (
            <Col lg={8} md={8} sm={12} xs={12}>
              <Row
                className="d-flex justify-items-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Col lg={12} align="center">
                  <p style={{ fontSize: "18px" }}>
                    <b>No results</b> found for your search!
                  </p>
                  <br />
                  <img src={dog} alt="" style={{ height: "250px" }} />
                  <br />
                  <br />
                  <p style={{ fontSize: "18px" }}>
                    Refine your search by using different keywords or checking
                    for spelling mistakes.
                  </p>
                </Col>
              </Row>
            </Col>
          ) : isLoading ? (
            <Col lg={8} sm={12} xs={12} md={12}>
              {data?.map((item, index) => (
                <Row
                  className="d-flex justify-items-center align-items-center"
                  key={index}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    padding: "8px",
                    marginTop: "8px",
                  }}
                >
                  <Col lg={2} xs={3} className="justify-content-center">
                    {item?.isViewed === false && (
                      <img src={newtag} alt="" className="newTag" />
                    )}
                    {item?.clientId?.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={item?.clientId?.dp}
                        alt="user profile"
                      />
                    )}
                    {!item.clientId.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={client}
                        alt="user profile"
                      />
                    )}
                  </Col>
                  <Col lg={9} xs={8} md={8} className="ps-3">
                    <h6
                      style={{ fontWeight: "600", marginBottom: "0.2rem" }}
                      className="ms-3"
                    >
                      {item?.clientId?.fullName.substring(0, 30)}
                    </h6>
                    {item?.cuisines?.length >= 3 ? (
                      <p
                        style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cuisines?.join(", ").substring(0, 20)}...
                      </p>
                    ) : (
                      <p
                        style={{ marginBottom: "5px", fontSize: "14px" }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cuisines?.join(", ")}
                      </p>
                    )}
                    <p
                      style={{ marginBottom: "5px", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <GrLocation style={{ marginBottom: "2px" }} />
                      &nbsp;
                      {item?.clientId?.area}
                    </p>
                    <p
                      style={{ marginBottom: "5px", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <CiWallet style={{ marginBottom: "2px" }} />
                      &nbsp;
                      {item?.minimumPayment}
                    </p>
                  </Col>
                  <Col
                    lg={1}
                    xs={1}
                    align="right"
                    style={{ marginTop: "-80px" }}
                  >
                    {item?.isShortlisted === true && (
                      <div
                        onClick={() => removeshortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <FaBookmark />
                      </div>
                    )}

                    {item?.isShortlisted === false && (
                      <div
                        onClick={() => shortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <BsBookmark />
                      </div>
                    )}
                  </Col>
                  <Col lg={3} xs={6} className="justify-content-center">
                    <p
                      className="text-left my-1"
                      style={{ fontSize: "1.5dvh" }}
                    >
                      Posted on &nbsp;
                      {Moment.utc(item?.updatedAt).format("DD-MM-YYYY")}
                    </p>
                  </Col>
                  <Col lg={7} xs={6}>
                    <div className="text-end">
                      <p className="text-right my-1" style={{ fontSize: "1.2dvh", color: 'grey', fontWeight: 400 }}>
                        {item?.viewsCount>0&&<IoMdEye style={{ color: 'grey', fontSize: '18px' }} />}
                        &nbsp;
                        {renderViews(item?.viewsCount)}
                      </p>
                    </div>
                    </Col>
                  <Col lg={2} xs={6}>
                    <div align="right">
                      <Link
                        to={`/cook/house-job-details/${item._id}`}
                        style={{ color: "#000" }}
                      >
                        <Button className="view_profile ms-5">View</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
          <Col lg="4" className="filter_parent">
            <div
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                borderRadius: "15px",
                paddingBottom: "100px",
              }}
            >
              <Filter
                setItem={setItem}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                handleapply={handleapply}
                setisLoading={setisLoading}
              />
            </div>
          </Col>
        </Row>
        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
              renderLastPage={() => null}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FindHouseJob;

const Filter = ({
  setItem,
  setCurrentPage,
  currentPage,
  handleapply,
  setisLoading,
}) => {
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState();
  const [jobType, setJobType] = useState();
  const [payment, setPayment] = useState();
  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState();

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const handleApplyFilter = () => {
    if (jobType) {
      search.set("jobType", jobType);
    }
    if (gender) {
      search.set("gender", gender);
    }
    if (payment) {
      search.set("payment", payment);
    }
    if (chefCuisines.length) {
      search.set("cuisines", chefCuisines.join(","));
    }
    setSearch(search, {
      replace: true,
    });

    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      gender: parseInt(gender) || undefined,
      jobType: parseInt(jobType) || undefined,
      payment: payment || undefined,
      limit: 6,
      page: 1,
    };
    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("Please Select valid cuisine");
    } else if (locationError) {
    } else {
      setCurrentPage(1);
      setItem(item);
      dispatch(findHouseJobs(item))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            setisLoading(true);
          }, [300]);
          setisLoading(false);
        })
        .catch(({ message }) => {});
      handleapply();
    }
  };

  const handleResetFilter = () => {
    // Reset the local filter state to the initial value
    setchefCuisines([]);
    setLocation("");
    setGender("");
    setJobType("");
    setPayment("");
    setCoordinates([]);
    setSearch([]);
    localStorage.removeItem("coordinates");
    const item = {
      limit: 6,
      page: 1,
    };
    setItem(item);
    handleapply();
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const locationq = search.get("location");
    const genderQ = search.get("gender");
    const jobtypeQ = search.get("jobType");
    const paymentQ = search.get("payment");
    const pageq = search.get("page");
    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }
    if (jobtypeQ !== null) {
      setJobType(jobtypeQ);
    }
    if (genderQ !== null) {
      setGender(genderQ);
    }
    if (paymentQ !== null) {
      setPayment(payment);
    }
    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }

    if (cuisineval || locationq || genderQ || jobtypeQ || paymentQ || pageq) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        gender: genderQ ? parseInt(genderQ) : undefined,
        jobType: jobtypeQ ? parseInt(jobtypeQ) : undefined,
        payment: paymentQ ? paymentQ : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        limit: 6,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      dispatch(findHouseJobs(item))
        .unwrap()
        .then((data) => {})
        .catch(({ message }) => {});
    }
  }, []);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };

  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    const validationErrors = { ...errors };
    setchefCuisines(selected);
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };

  const inputRef = useRef(null);
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
      googleMapsScript.onload = initializeAutocomplete;
      googleMapsScript.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };
      document.body.appendChild(googleMapsScript);
    };
    const initializeAutocomplete = () => {
      if (inputRef.current) {
        const autoCompleteCity = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autoCompleteCity.addListener("place_changed", () => {
          const place = autoCompleteCity.getPlace();
          if (!place.geometry || !place.geometry.location) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          } else {
            setLocationError("");
            setLocation(place.name);
            const coordinates = [
              place.geometry.location.lat(),
              place.geometry.location.lng(),
            ];
            localStorage.setItem("coordinates", coordinates);
            setCoordinates(coordinates);
            const existingSearchParams = new URLSearchParams(search);

            // Set the new location parameter
            existingSearchParams.set("location", place.name);

            // Set the merged parameters in the search
            setSearch(existingSearchParams, {
              replace: true,
            });
          }
        });
        inputRef.current.addEventListener("blur", () => {
          const place = autoCompleteCity.getPlace();
          const inputValue = inputRef.current.value.trim();
          if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          }
        });
      }
    };
    loadGoogleMapsScript();
    return () => {
      // Clean up code here, if needed
    };
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };
  const handleJobtypeChange = (e) => {
    setJobType(e.target.value);
    search.set("jobType", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  const handlegenderChange = (e) => {
    setGender(e.target.value);

    search.set("gender", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  const handlePaymentChange = (e) => {
    setPayment(e.target.value);
    search.set("payment", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  return (
    <>
      <div className="mt-2 p-3">
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Cuisine</Form.Label>
            <Typeahead
              clearButton
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              className="input1"
              onChange={handleChefCuisineChange}
              options={options}
              placeholder="Cuisines"
              selected={chefCuisines}
              isInvalid={!!cuisineError}
              onInputChange={inputChangeCuisine}
            />
            <Form.Control.Feedback type="invalid">
              {cuisineError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Location</Form.Label>
            <Form.Control
              name="location"
              placeholder="Enter location"
              value={location}
              ref={inputRef}
              onChange={handlelocationChange}
              isInvalid={!!locationError}
            />
            <Form.Control.Feedback type="invalid">
              {locationError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={gender}
              onChange={handlegenderChange}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="1">Male</option>
              <option value="2">Female</option>
              <option value="4">Any</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Job Type</Form.Label>
            <Form.Control
              as="select"
              name="job type"
              value={jobType}
              onChange={handleJobtypeChange}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="1">Full Time</option>
              <option value="2">Part Time</option>
              <option value="3">Any</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="my-2">
            <Form.Label>Min. Payment</Form.Label>
            <Form.Control
              as="select"
              name="payment"
              className="input1"
              value={payment}
              onChange={handlePaymentChange}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="1000 to 2000">1000 to 2000</option>
              <option value="2000 to 3000">2000 to 3000</option>
              <option value="3000 to 5000">3000 to 5000</option>
              <option value="5000 to 10000">5000 to 10000</option>
              <option value="10000 to 15000">10000 to 15000</option>
              <option value="15000 to 25000">15000 to 25000</option>
              <option value="Above 25000">Above 25000</option>
            </Form.Control>
          </Form.Group>
          <div className="group d-flex justify-content-around align-items-center mt-5">
            <Link className="reset" onClick={handleResetFilter}>
              <u>Reset</u>
            </Link>

            <Button onClick={handleApplyFilter} className="apply_btn ms-2">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
