import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { getappliedJobs } from "../../../store/slices/cookprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import chef from "../../../Assets/Images/cook.png";
import newtag from "../../../Assets/Images/new.png";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";

function AppliedJobs() {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();

  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getapplications();
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Applied Jobs";
  }, []);

  const getapplications = () => {
    setisLoading(false);
    dispatch(getappliedJobs(item))
      .unwrap()
      .then((data) => {
        setData(data?.allapplication?.data);
        setTotaldata(data?.allapplication?.totalDataCount);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setisLoading(true);
      });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/cook/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Applied Jobs
      </div>
      <Row className=" mt-4 ">
        {totaldata === 0 ? (
          <Row className="card mt-4 p-5">
            <Col lg={12} xs={12} md={12} align="center">
              <h5>You have not applied for any jobs!</h5>
              <p style={{ color: "grey" }}>
                Start applying to jobs and get updates on the status of your
                applications.
              </p>
              <button className="saveBtn mt-3">
                <Link to="/cook/jobs" style={{ color: "#fff" }}>
                  Find Jobs
                </Link>
              </button>
            </Col>
          </Row>
        ) : isLoading ? (
          <Col lg={12} sm={12} xs={12}>
            <Row>
              {data?.map((item, index) => (
                <Col lg={9} xs={12} md={8} className="ps-3">
                  <Row
                    className="d-flex justify-items-center align-items-center"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      // height: "20dvh",
                      // width: "100%",
                      borderRadius: "15px",
                      padding: "8px",
                      marginTop: "8px",
                    }}
                  >
                    <Col lg={2} xs={3} className="justify-content-center">
                      {item?.isViewed === false && (
                        <img
                          src={newtag}
                          alt=""
                          style={{ marginTop: "-20px", marginLeft: "-28px" }}
                        />
                      )}
                      {item?.jobId?.employerId?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={item?.jobId?.employerId?.dp}
                          alt="user profile"
                        />
                      )}
                      {!item?.jobId?.employerId?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={chef}
                          alt="user profile"
                        />
                      )}
                    </Col>
                    <Col lg={9} xs={7}>
                      <h6
                        style={{
                          fontWeight: "600",
                          marginBottom: "0.2rem",
                        }}
                        className="ms-3"
                      >
                        {item?.jobId?.designation}
                      </h6>
                      <p
                        style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                        className="ms-3"
                      >
                        {item?.jobId?.employerId?.fullName.substring(0, 50)}
                      </p>
                      {item?.jobId?.cuisines?.length >= 3 ? (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.jobId?.cuisines?.join(", ")}
                          ...
                        </p>
                      ) : (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.jobId?.cuisines?.join(", ").substring(0, 20)}
                        </p>
                      )}

                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <GrLocation style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.jobId?.location}
                      </p>
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <BsBriefcase style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.jobId?.experience} Years
                        <MdCurrencyRupee
                          style={{ marginBottom: "2px" }}
                          className="ms-3"
                        />
                        {item?.jobId?.salary}
                      </p>
                    </Col>
                    <Col xs={1} md={1} lg={1} align="right">
                      {/* <Dropdown
                          style={{
                            marginTop: "-50px",
                            background: "none",
                          }}
                        >
                          <Dropdown.Toggle
                            // id="dropdown-basic"
                            style={{
                              width: "25px",
                              height: "25px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <BiDotsVerticalRounded
                              style={{
                                marginTop: "-20px",
                                fontSize: "20px",
                                color: "#000",
                              }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              
                              onClick={() =>
                                SetApplicationStatus(item, "shortlisted")
                              }
                            >
                              Shortlisted
                            </Dropdown.Item>
                            <Dropdown.Item
                              
                              onClick={() =>
                                SetApplicationStatus(item, "hired")
                              }
                            >
                              Hired
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "on Hold")
                              }
                            >
                              On Hold
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "rejected")
                              }
                            >
                              Rejected
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                    </Col>
                    <Col lg={3} xs={6} md={3}>
                      <p style={{ fontSize: "10px", color: "grey" }}>
                        Applied on&nbsp;
                        {Moment.utc(item?.appliedAt).format("DD-MM-YYYY")}
                      </p>
                    </Col>
                    <Col lg={7} xs={3} md={7}>
                      <div align="right">
                        {item?.jobId?.status === 1 &&
                          item?.applicationStatus === "applied" && (
                            <p
                              style={{
                                color: "#929402",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "70px",
                                fontSize: "12px",
                                height: "25px",
                                backgroundColor: "#FEFFD3",
                              }}
                              align="center"
                            >
                              Applied
                            </p>
                          )}
                        {item?.jobId?.status === 1 &&
                          item?.applicationStatus === "on Hold" && (
                            <p
                              style={{
                                color: "#000000",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "70px",
                                fontSize: "12px",
                                height: "25px",
                                backgroundColor: "#FFAF03",
                              }}
                              align="center"
                            >
                              On Hold
                            </p>
                          )}
                        {item?.jobId?.status === 1 &&
                          item?.applicationStatus === "shortlisted" && (
                            <p
                              style={{
                                color: "#0700F5",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "70px",
                                fontSize: "12px",
                                height: "25px",
                                backgroundColor: "rgb(209 235 250)",
                              }}
                              align="center"
                            >
                              Shortlisted
                            </p>
                          )}
                        {item?.jobId?.status === 1 &&
                          item?.applicationStatus === "rejected" && (
                            <p
                              style={{
                                color: "#FF0606",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "90px",
                                fontSize: "12px",
                                height: "25px",
                                backgroundColor: "#FFDDD5",
                              }}
                              align="center"
                            >
                              Not Selected
                            </p>
                          )}
                        {item?.jobId?.status === 1 &&
                          item?.applicationStatus === "hired" && (
                            <p
                              style={{
                                color: "#028D36",
                                padding: "5px",
                                borderRadius: "5px",
                                width: "70px",
                                fontSize: "12px",
                                height: "25px",
                                backgroundColor: "#e7f8c6",
                              }}
                              align="center"
                            >
                              Hired
                            </p>
                          )}
                      </div>
                    </Col>
                    <Col lg={2} xs={3} md={2}>
                      <div
                        align="left"
                        style={{ marginTop: "-20px", marginLeft: "-15px" }}
                      >
                        {item?.jobId?.status === 1 && (
                          <Link
                            to={`/cook/applied-job-details/${item?.jobId.id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile">View</Button>
                          </Link>
                        )}
                        {item?.jobId?.status === 0 && (
                          <p
                            style={{
                              color: "#000",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#d4d3d0",
                            }}
                            align="center"
                          >
                            Expired
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          <Col lg={8} xs={12} md={12} align="center">
            {ShowSkeleton()}
          </Col>
        )}
      </Row>
      {totaldata / itemsPerPage > 1 && (
        <div className="justify-content-right align-right">
          <ReactPaginate
            key={currentPage}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakLinkClassName={"page-link"}
            pageCount={Math.ceil(totaldata / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={pagesToShowInitially}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            disabledClassName="disabled"
            initialPage={currentPage - 1}
          />
        </div>
      )}
    </>
  );
}

export default AppliedJobs;
