//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Button, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import NavMenu from "../nav_bar/NavMenu";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------
function ClientSignup() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9_ ']{3,}$/.test(name);
  };
  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  useEffect(() => {
    document.title = "CookandChef | Client Signup";
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!fullName) {
      validationErrors.username = "कृपया अपना नाम दर्ज करें";
    }

    if (!email) {
      validationErrors.email = "अपना ईमेल दर्ज करें";
    } else if (!isValidEmail(email)) {
      validationErrors.email = "कृपया एक मान्य ईमेल दर्ज करें";
    }

    if (!mobile) {
      validationErrors.number = "अपना मोबाइल नंबर दर्ज करें";
    }

    if (!password) {
      validationErrors.password = "Please enter your password";
    }

    setErrors(validationErrors);

    // if (Object.keys(validationErrors).length === 0) {
    // }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;

    switch (name) {
      case "username":
        setFullName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Name should contain capital letter, small letter, numbers, space, apostrophe, underscore and minimum 4 characters"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "email":
        setEmail(value);
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "enter a valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;

      case "mobile":
        setMobile(value);
        if (value.trim() === "") {
          validationErrors[name] = "कृपया एक वैध मोबाइल नंबर दर्ज करें";
        } else if (!/^\d{10}$/.test(value.trim())) {
          validationErrors[name] = "मोबाइल नंबर should be exactly 10 digits";
        } else {
          delete validationErrors[name];
        }
        break;
      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" || !isValidPassword(value)
            ? "Password must contain at least one letter and one number, with a minimum length of 8 characters"
            : "";
        if (value.trim() !== "" && isValidPassword(value)) {
          validationErrors[name] = "";
        }
        break;

      case "confirmPassword":
        setconfirmPassword(value);
        validationErrors[name] =
          value.trim() === "" || value === password
            ? ""
            : "Passwords do not match";
        break;
      default:
        break;
    }

    setErrors(validationErrors);
  };

  return (
    <>
      <NavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={5} className="d-none d-lg-block cookLogin"></Col>
          <Col
            className="d-flex justify-content-center align-items-center flex-column"
            lg={5}
            xs={11}
            md={6}
            xl={5}
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              height: "70dvh",
            }}
          >
            <p className="h2 text-center mb-8">Client SignUp</p>

            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className="text-center"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "",
                borderRadius: "0px 15px 15px 0px",
              }}
            >
              <Form.Group className="mb-4">
                <Form.Control
                  className="input1"
                  type="text"
                  name="username"
                  value={fullName}
                  onChange={handleChange}
                  isInvalid={!!errors.username}
                  placeholder="अपना नाम दर्ज करें"
                />
                <Form.Control.Feedback type="invalid" className="text-end p-3">
                  {errors.username}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid" className="text-end p-3">
                  {!errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className="input1"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  placeholder="Enter your email"
                />
                <Form.Control.Feedback type="invalid" className="text-end p-3">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className="input1"
                  type="number"
                  name="mobile"
                  value={mobile}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  isInvalid={!!errors.mobile}
                  placeholder="enter मोबाइल नंबर"
                />
                <Form.Control.Feedback type="invalid" className="text-end p-3">
                  {errors.mobile}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 position-relative">
                <Form.Control
                  className="input1"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  name="password"
                  value={password}
                  isInvalid={!!errors.password}
                  placeholder="Enter password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "30px",
                    top: "10%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="text-end p-3">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 position-relative">
                <Form.Control
                  className="input1 mb-4"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                  placeholder="Retype password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "30px",
                    top: "20%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="p-1">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="text-start mb-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Form.Check type="checkbox" />
                  </div>
                  <div>
                    <p className="text-muted">
                      I agree to the
                      <span className="terms_and_conditions">
                        Terms And The Conditions
                      </span>
                    </p>
                  </div>
                </div>
              </Form.Group>
              <Button className="mb-2 login_btn" type="primary">
                Create account
              </Button>
              <p className="text-muted">
                Already have an account?&nbsp;
                <NavLink to="/employer-login">
                  <span className="forgot_password">Login</span>
                </NavLink>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ClientSignup;
