import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "moment";
import { Link } from "react-router-dom";
import { editEvents, getEvents } from "../../../../store/slices/clientprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EventDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | इवेंट डिटेल्स";
  }, []);

  React.useEffect(() => {
    getEvents();
  }, [id]);

  useEffect(() => {
    dispatch(getEvents(id))
      .unwrap()
      .then((data) => {
        setData(data.user.data[0]);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  });

  const enableJob = (id) => {
    var elem = {
      id: id,
      status: 1,
    };

    dispatch(editEvents(elem))
      .then((data) => {
        setModalMessage("Event enabled successfully!");
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getEvents();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const disableJob = (id) => {
    var elem = {
      id: id,
      status: 2,
    };

    dispatch(editEvents(elem))
      .then((data) => {
        setModalMessage("Event disabled successfully!");
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getEvents();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setsuccessModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/client/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/client/events" className="bcrumb">
          इवेंट्स
        </Link>
        &nbsp;&bull; इवेंट डिटेल्स
      </div>
      <Row className="mt-4">
        <Col lg={7} xs={12} md={7} sm={12} className="card p-3 m-1">
          <h6
            className="text-left"
            style={{ fontWeight: "600", color: "#ff5c09 " }}
          >
            इवेंट डिटेल्स
          </h6>
          <Col lg={11} xs={12}>
            <p className="text-heading">व्यंजन</p>
            <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
              {data?.cuisines?.map((cookplanVal, index) => (
                <Col
                  xs={5}
                  sm={5}
                  md={3}
                  lg={2}
                  xl={2}
                  className="me-2"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    color: "black",
                    padding: "4px 8px",
                    margin: "8px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "14px",
                    marginRight: "4px",
                    border: "solid 1px #ff5c09",
                    width: "auto",
                  }}
                >
                  {cookplanVal}
                </Col>
              ))}
            </Row>
          </Col>

          <Col xs={12} md={12} lg={11}>
            <p className="text-heading">व्यंजन</p>
            {data?.dishes && <p className="text-details">{data?.dishes}</p>}
          </Col>
          <Row>
            <Col lg={6} xs={12}>
              <p className="text-heading">इवेंट प्रकार</p>
              <p className="text-details">{data?.eventType}</p>
            </Col>
            <Col lg={6} xs={12}>
              <p className="text-heading">इवेंट दिनांक</p>

              <p className="text-details">
                {Moment.utc(data?.eventDate).format("DD-MM-YYYY")}
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={6} xs={12}>
              <p className="text-heading">अपेक्षित अतिथि</p>
              <p className="text-details">{data?.expectedGuest}</p>
            </Col>
            <Col lg={6} xs={12}>
              <p className="text-heading">जगह</p>

              <p className="text-details">{data?.location}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={12}>
              <p className="text-heading"> शहर </p>
              <p className="text-details">{data?.city}</p>
            </Col>
            <Col lg={6} xs={12}>
              <p className="text-heading">पिन कोड</p>

              <p className="text-details">{data?.Pincode}</p>
            </Col>
          </Row>
        </Col>
        {data?.status !== 0 && (
          <Col
            lg={4}
            xs={12}
            md={4}
            className="justify-content-center align-items-center card m-1"
          >
            {data?.status === 2 && (
              <button className="cancelBtn" onClick={() => enableJob(id)}>
                Enable Event
              </button>
            )}
            {data?.status === 1 && (
              <button className="cancelBtn" onClick={() => disableJob(id)}>
                Disable Event
              </button>
            )}
            {data?.status !== 0 && (
              <Link
                to={`/hin/client/edit-event/${id}`}
                className="saveBtn mt-3"
                style={{ textAlign: "center", paddingTop: "5px" }}
              >
                Edit Event
              </Link>
            )}
          </Col>
        )}
      </Row>
    </>
  );
}

export default EventDetails;
