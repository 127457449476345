import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";

export const cooklogin = createAsyncThunk(
  "auth/cooklogin",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cooklogin(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const cookregister = createAsyncThunk(
  "auth/cookregister",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookregister(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "auth/changepassword",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.changePassword(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const EmployersignupChangeMobile = createAsyncThunk(
  "auth/employerSignupChange",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.employersignupChangeMobile(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


//cook signup mobile change 

export const CooksignupChangeMobile = createAsyncThunk(
  "auth/cookSignupChange",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cooksignupChangeMobile(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


// contact privacy 
export const contactPrivacy = createAsyncThunk(
  "auth/contactprivacy",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.Contactprivay(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const cookotpConfirmation = createAsyncThunk(
  "auth/cookverifyOtp",
  async ({ cookId, otp }, thunkAPI) => {
    try {
      const data = await AuthService.cookotpVerification({ cookId, otp });
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const employerlogin = createAsyncThunk(
  "auth/employerlogin",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.employerlogin(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const employerregister = createAsyncThunk(
  "auth/employerregister",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.employerregister(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async (cookId, thunkAPI) => {
    try {
      const data = await AuthService.resendotp(cookId);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//employer resend otp
export const EmplyeresendOtp = createAsyncThunk(
  "auth/employerresendOtp",
  async (employerId, thunkAPI) => {
    try {
      const data = await AuthService.employeResendotp(employerId);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const forgotpassword = createAsyncThunk(
  "auth/forgotpass",
  async (data1, thunkAPI) => {
    try {
      const data = await AuthService.forgotPassword(data1);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//update password
export const updatepassword = createAsyncThunk(
  "auth/updatepassword",
  async (data1, thunkAPI) => {
    try {
      const data = await AuthService.updatePassword(data1);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//employer verify email
export const VerifyEmail = createAsyncThunk(
  "auth/verifyemail",
  async (thunkAPI) => {
    try {
      const data = await AuthService.verifyEmail();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//client  verify email
export const ClientVerifyEmail = createAsyncThunk(
  "auth/clientverifyemail",
  async (thunkAPI) => {
    try {
      const data = await AuthService.clientVerifyEmail();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//cook  verify email
export const CookVerifyEmail = createAsyncThunk(
  "auth/cookverifyemail",
  async (thunkAPI) => {
    try {
      const data = await AuthService.cookVerifyEmail();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// employer Change email
export const EmployerChangeEmail = createAsyncThunk(
  "auth/employerchangemail",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.employerChangeEmail(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// employer Change email
export const ClientChangeEmail = createAsyncThunk(
  "auth/clientchangemail",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientChangeEmail(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// cook Change email
export const CookChangeEmail = createAsyncThunk(
  "auth/cookchangemail",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookChangeEmail(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for client change mobile
export const ClientChangeMobile = createAsyncThunk(
  "auth/clientchangemobile",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientChangeMobile(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for cook change mobile
export const CookChangeMobile = createAsyncThunk(
  "auth/cookchangemobile",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookChangeMobile(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for client verify mobile otp number
export const ClientVerifyMobileOtp = createAsyncThunk(
  "auth/clientverifyotpmobile",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientVerifyMobileOtp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for client verify mobile otp number
export const CookVerifyMobileOtp = createAsyncThunk(
  "auth/cookverifyotpmobile",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookVerifyMobileOtp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for client change watsapp
export const ClientChangeWhatsapp = createAsyncThunk(
  "auth/clientchangewhatsapp",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientChangeWatsapp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for cook change whatsapp
export const CookChangeWhatsapp = createAsyncThunk(
  "auth/cookchangewhatsapp",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookChangeWatsapp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


//for cook change whatsapp
export const CookCheckWhatsapp = createAsyncThunk(
  "auth/cookcheckwhatsapp",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookCheckWatsapp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message)
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const ClientCheckWhatsapp = createAsyncThunk(
  "auth/clientcheckwhatsapp",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientCheckWatsapp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message)
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for client verify otp number
export const ClientVerifyWhatsapOtp = createAsyncThunk(
  "auth/clientverifyotpwhatsap",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.clientVerifyWatsappOtp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//for cook verify otp number
export const CookVerifyWhatsapOtp = createAsyncThunk(
  "auth/cookverifyotpwhatsap",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.cookVerifyWatsappOtp(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



//for cook verify otp number
export const getwebsiteCooksList = createAsyncThunk(
  "auth/getwebsiteCooksList",
  async (item, thunkAPI) => {
    try {
      const data = await AuthService.getWebsiteCookslist(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const employerotpConfirmation = createAsyncThunk(
  "auth/employerverifyOtp",
  async ({ employerId, otp }, thunkAPI) => {
    try {
      const data = await AuthService.employerotpVerification({
        employerId,
        otp,
      });
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const LOGOUT_ACTION = "[Logout action] logout action";

export function logoutAction() {
  return {
    type: LOGOUT_ACTION,
  };
}

export const logout = createAsyncThunk("auth/logout", async () => {
  AuthService.logout();
});

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [cooklogin.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [cooklogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [cooklogin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [cookregister.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [cookregister.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [cookregister.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [cookotpConfirmation.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [cookotpConfirmation.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [cookotpConfirmation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
