import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import Contact from "./Contact";

const HelpSupportQs = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
  });

  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };
  return (
    <div>
      <div className="p-3">
        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          aria-expanded={openCollapse.open1}
          className="mt-1 help"
        >
          How can I get a chef for my restaurant or hotel?
        </p>
        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              Sign up and post a job as per your needs, chefs will apply to jobs
              and directly contact you. You can also explore the chef profiles
              and contact them directly before your competition hires them.
              <br />
              If you face difficulties contact support team.
            </p>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          How can I get a cook for my house?
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              Sign up, update your profile and post a requirement, cooks can
              send interest or directly contact you. You can also explore the
              cook profiles and contact them directly as per your requirement.
              <br />
              If you face difficulties contact support team.
            </p>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          How can I get a job?
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <p style={{ fontSize: "12px" }}>
              Sign up, update your profile. Keep it 100%, check CookandChef
              regularly, apply or contact relevant jobs.
              <br /> If you face difficulties contact support team.
            </p>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          I need a cook
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Link
              to="/employer-registration"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need House Cook
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are looking for a full time or part time household cook in
              your area for your domestic help.
            </p>
            <Link
              to="/employer-registration"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need Party Cook
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are looking for a cook or chef for small events or house
              parties.
            </p>
            <Link
              to="/employer-registration"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need Chef
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are looking for a full time or part time Chef for your
              restaurant, hotel, café or cloud kitchen.
            </p>
            <Link
              to="/employer-registration"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need Catering
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are looking for Caterings for bigger events.
            </p>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open5")}
          aria-controls="example-fade-text4"
          aria-expanded={openCollapse.open5}
          className="mt-3 help"
        >
          I need a job
        </p>
        <Collapse in={openCollapse.open5}>
          <div id="example-fade-text4">
            <Link
              to="/cook-signup"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need Chef Jobs
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are a chef and looking for jobs in restaurant, hotels or
              cafes
            </p>
            <Link
              to="/cook-signup"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Need a House Job
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are a cook and looking for part time or full time house
              jobs in your locality or other areas.
            </p>
            <Link
              to="/cook-signup"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Want to work as a Cook for Parties
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are a chef or cook interested to get party calls and earn
              handsome money.
            </p>
            <Link
              to="/catering_signup"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Want to register your catering to get event calls
            </Link>
            <p style={{ fontSize: "12px" }}>
              If you are part of catering and want to get more event calls
            </p>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open6")}
          aria-controls="example-fade-text5"
          aria-expanded={openCollapse.open6}
          className="mt-3 help"
        >
          Other Queries
        </p>
        <Collapse in={openCollapse.open6}>
          <div id="example-fade-text5">
            <p style={{ fontSize: "12px" }}>
              If you have any other queries, please drop a message or contact us
              directly.
            </p>
            <Link
              to="/contact"
              style={{
                display: "block",

                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Submit Query online
            </Link>
            <br />
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default HelpSupportQs;
