import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IoBriefcaseOutline } from "react-icons/io5";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { GrLocation } from "react-icons/gr";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../nav_bar/Footer";
import chef from "../../Assets/Images/cook.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getwebsiteCooksList } from "../../store/slices/auth";
import NavMenu from "../nav_bar/NavMenu";

function WebsiteCooksList() {
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [pagechange, setPageChange] = useState(false);
  const dispatch = useDispatch();
  const { name } = useParams();

  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const [cook, setCook] = useState(name);

  const itemsPerPage = 24;
  const pagesToShowInitially = 3;

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = async ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setPageChange(true);
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    document.title = "CookandChef | Cooklist";
  }, []);

  useEffect(() => {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, [1000]);
    getchefs();
  }, [currentPage]);

  const getchefs = () => {
    const item = {
      cook: cook,
      limit: itemsPerPage,
      page: currentPage,
    };
    dispatch(getwebsiteCooksList(item))
      .then((data) => {
        setData(data?.payload?.user?.data);
        setTotaldata(data?.payload?.user?.totalDataCount);
      })
      .catch(({ message }) => {});
  };

  const setDeepLinkforfindchef = () => {
    if (cook === "chef") {
      localStorage.setItem("deeplinkpage", "find-chef");
    } else if (cook === "housecook") {
      localStorage.setItem("deeplinkpage", "find-house-cook");
    } else if (cook === "partycook") {
      localStorage.setItem("deeplinkpage", "find-party-cook");
    } else if (cook === "catering") {
      localStorage.setItem("deeplinkpage", "find-catering");
    }
  };

  const ShowSkeleton = () => {
    return (
      <>
        <div className="row-sm-1 row-lg-1 card-group mb-4">
          <div className="card">
            <Skeleton height={80} />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavMenu />
      <div className="breadcrumb-containerabout pt-2 pb-2">
        <Row>
          <Col>
            <h2 className="text-center">
              {cook === "chef"
                ? "Chef"
                : cook === "housecook"
                ? "House Cook"
                : cook === "partycook"
                ? "Party Cook"
                : "Catering"}
              List
            </h2>
          </Col>
        </Row>
      </div>

      <div className="p-3 mt-3">
        <div>
          <Row className="d-flex justify-content-center">
            <Col lg={10} md={11} sm={12}>
              <Row>
                {data?.map((cook1) => (
                  <Col
                    style={{
                      textAlign: "center",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    md={4}
                    lg={4}
                    sm={12}
                  >
                    <div
                      className="cookCard"
                      onClick={() => setDeepLinkforfindchef()}
                    >
                      {cook1?.dp ? (
                        <img
                          src={cook1?.dp}
                          style={{
                            height: "90px",
                            width: "90px",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={chef}
                          style={{
                            height: "90px",
                            width: "90px",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                      )}

                      <h5 className="mt-1">{cook1?.fullName}</h5>
                      {cook === "chef" ? (
                        <p>
                          <GrLocation /> {cook1?.currentCityName} &nbsp; &nbsp;
                          <IoBriefcaseOutline /> {cook1?.chefExperience} Years
                        </p>
                      ) : cook === "housecook" ? (
                        <p>
                          <GrLocation /> {cook1?.area}
                        </p>
                      ) : cook === "partycook" ? (
                        <p>
                          <GrLocation /> {cook1?.cityName} &nbsp; &nbsp;
                          <IoBriefcaseOutline /> {cook1?.partyExperience} Years
                        </p>
                      ) : (
                        <p>
                          <GrLocation /> {cook1?.cityName} &nbsp; &nbsp;
                        </p>
                      )}

                      {cook && cook === "chef" && (
                        <Row className="justify-content-center">
                          {cook1?.chefCuisines?.map(
                            (el, index) =>
                              index <= 2 && (
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className="me-2"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    color: "black",
                                    padding: "2px 8px",
                                    margin: "8px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    marginRight: "4px",
                                    border: "solid 1px rgba(255, 92, 9,0.2)",
                                    width: "auto",
                                  }}
                                  key={index} // Add key prop for optimization
                                >
                                  {el}
                                </Col>
                              )
                          )}
                        </Row>
                      )}

                      {cook && cook === "housecook" && (
                        <Row className="justify-content-center">
                          {cook1?.householdCuisines?.map(
                            (el, index) =>
                              index <= 2 && (
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className="me-2"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    color: "black",
                                    padding: "2px 8px",
                                    margin: "8px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    marginRight: "4px",
                                    border: "solid 1px rgba(255, 92, 9,0.2)",
                                    width: "auto",
                                  }}
                                  key={index} // Add key prop for optimization
                                >
                                  {el}
                                </Col>
                              )
                          )}
                        </Row>
                      )}
                      {cook && cook === "partycook" && (
                        <Row className="justify-content-center">
                          {cook1?.partyCuisines?.map(
                            (el, index) =>
                              index <= 2 && (
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className="me-2"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    color: "black",
                                    padding: "2px 8px",
                                    margin: "8px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    marginRight: "4px",
                                    border: "solid 1px rgba(255, 92, 9,0.2)",
                                    width: "auto",
                                  }}
                                  key={index} // Add key prop for optimization
                                >
                                  {el}
                                </Col>
                              )
                          )}
                        </Row>
                      )}
                      {cook && cook === "catering" && (
                        <Row className="justify-content-center">
                          {cook1?.cateringCuisines?.map(
                            (el, index) =>
                              index <= 2 && (
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={3}
                                  lg={2}
                                  xl={2}
                                  className="me-2"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    color: "black",
                                    padding: "2px 8px",
                                    margin: "8px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    marginRight: "4px",
                                    border: "solid 1px rgba(255, 92, 9,0.2)",
                                    width: "auto",
                                  }}
                                  key={index} // Add key prop for optimization
                                >
                                  {el}
                                </Col>
                              )
                          )}
                        </Row>
                      )}

                      <button className="saveBtn mt-2">
                        <Link to="/employer-login" style={{ color: "#fff" }}>
                          View Profile
                        </Link>
                      </button>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WebsiteCooksList;
