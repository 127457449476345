import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangePassword } from "../../../../store/slices/auth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modal2 from "../../../auth_screens/Modal2";
import SuccessModal from "../../../auth_screens/SuccessModal";

function ClientChangepassword() {
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);

  const dispatch = useDispatch();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    document.title = "CookandChef | Change Password";
  }, []);

  const navigate = useNavigate("");
  const [errors, setErrors] = useState({});

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;

    switch (name) {
      case "oldPassword":
        validationErrors[name] =
          value === "" ? "कृपया अपना वर्तमान पासवर्ड दर्ज करें" : "";
        break;
      case "newPassword":
        validationErrors[name] =
          value === "" || !isValidPassword(value)
            ? "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए"
            : "";
        break;
      case "confirmPassword":
        validationErrors[name] =
          value === "" || value === data.newPassword
            ? ""
            : "नया पासवर्ड और कन्फर्म पासवर्ड एक ही होना चाहिए";
        break;
      default:
        break;
    }

    setData({
      ...data,
      [name]: value,
    });

    setErrors(validationErrors);
  };

  async function changePassword(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    const validationErrors = {};

    if (!data.oldPassword) {
      validationErrors.oldPassword = "कृपया अपना वर्तमान पासवर्ड दर्ज करें";
    }
    if (!data.newPassword) {
      validationErrors.newPassword = "कृपया नया पासवर्ड दर्ज करें";
    } else if (!isValidPassword(data.newPassword)) {
      validationErrors.newPassword =
        "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए";
    }
    if (!data.confirmPassword) {
      validationErrors.confirmPassword = "कृपया पुष्टि पासवर्ड दर्ज करें";
    } else if (data.newPassword !== data.confirmPassword) {
      validationErrors.confirmPassword =
        "नया पासवर्ड और कन्फर्म पासवर्ड एक ही होना चाहिए";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const item = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      dispatch(ChangePassword(item))
        .unwrap()
        .then((data) => {
          setModalMessage("पासवर्ड सफलतापूर्वक बदला गया");
          setsuccessModal(true);
          // if (data.status === 1) {
          setTimeout(() => {
            navigate("/hin/client/dashboard");
            setsuccessModal(false);
          }, 3000);

          //   }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Row>
        <Col
          lg={8}
          xl={8}
          md={10}
          xs={12}
          sm={12}
          className="card text-center p-4 ms-3"
        >
          <Form noValidate autoComplete="off" onSubmit={changePassword}>
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4  position-relative text-start">
                <Form.Control
                  name="oldPassword"
                  onChange={handleChange}
                  value={data.oldPassword}
                  isInvalid={!!errors.oldPassword}
                  className="input1"
                  type={showPassword ? "text" : "password"}
                  placeholder="वर्तमान पासवर्ड दर्ज करें"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.oldPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4 position-relative text-start">
                <Form.Control
                  name="newPassword"
                  onChange={handleChange}
                  value={data.newPassword}
                  isInvalid={!!errors.newPassword}
                  className="input1"
                  type={showPassword2 ? "text" : "password"}
                  placeholder="नया पासवर्ड दर्ज करें"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group className="mb-4 position-relative text-start">
                <Form.Control
                  name="confirmPassword"
                  onChange={handleChange}
                  value={data.confirmPassword}
                  isInvalid={!!errors.confirmPassword}
                  className="input1"
                  type={showPassword3 ? "text" : "password"}
                  placeholder="पासवर्ड की पुष्टि कीजिये"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword3(!showPassword3)}
                >
                  {showPassword3 ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={10} xl={10}>
              <Form.Group>
                <Button
                  className="cancelBtn"
                  onClick={() => navigate("/hin/client/dashboard")}
                >
                  रद्द कीजिये
                </Button>
                <Button
                  className="saveBtn ms-2"
                  disabled={isdisabled}
                  type="submit"
                >
                  जमा करना
                </Button>
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
    </>
  );
}

export default ClientChangepassword;
