import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { GetAlljobEmployer } from "../../../store/slices/employerprofile";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import Modal2 from "../../auth_screens/Modal2";
import DataSkeleton from "../../auth_screens/dataskeleton";
import SuccessModal from "../../auth_screens/SuccessModal";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { getemployerProfile } from "../../../store/slices/employerprofile";
import ReactTooltip from 'react-tooltip';


function ManageJobs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [totaldata, setTotaldata] = useState();
  const [data, setData] = useState([]);
  const [pagData, setPageData] = useState();
  const [dataCount, setdataCount] = useState();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [sortVal, setSortVal] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [mydata, setMyData] = useState([]);

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handleApplyFilterAndSort = () => {
    let sortedArray = data && [...data];

    // Apply filter
    if (filterVal === "status-active") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 1);
    } else if (filterVal === "status-disable") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 2);
    } else if (filterVal === "status-expired") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 0);
    }

    // Apply sort
    if (sortVal === "event-new") {
      sortedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortVal === "event-old") {
      sortedArray.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    setFilteredData(sortedArray);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSortVal(value);
    search.set("sortVal", value);
    setSearch(search, { replace: true });
    setCurrentPage(1);
    handleApplyFilterAndSort();
  };

  const handleChangefilter = (e) => {
    const value = e.target.value;
    setFilterVal(value);
    search.set("filterVal", value);
    setSearch(search, { replace: true });
    setCurrentPage(1);
    handleApplyFilterAndSort();
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(true);
    }, 300);
    setisLoading(false);
    handleApplyFilterAndSort();
  }, [filterVal, sortVal]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageData(filteredData.slice(start, end));
  }, [filteredData, currentPage, itemsPerPage]);

  console.log(filteredData);

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data1) => {
        setMyData(data1.user.data);
        setData({
          ...data,
          contactNumber: data1.user.data.mobile,
        });
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    getJobDetails();
  }, [dispatch]);
  const getJobDetails = () => {
    dispatch(GetAlljobEmployer())
      .unwrap()
      .then((data) => {
        setData(data.getalljobemployer.data);
        setdataCount(data.getalljobemployer.dataCount);
        setTotaldata(data.getalljobemployer.dataCount);
        setFilteredData(data?.getalljobemployer?.data);
        setPageData(data.getalljobemployer.data.slice(0, itemsPerPage));
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  useEffect(() => {
    const sortingVal = search.get("sortVal");
    if (sortingVal !== null) {
      setSortVal(sortingVal);
    }
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Manage Jobs";
  }, []);

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="">
            <Link to="/employer/dashboard" className="bcrumb">
              Home
            </Link>
            &nbsp;&bull; Manage Jobs
          </div>
        </div>

        {dataCount === 0 && (
          <Row className="card mt-4 p-5">
            <Col lg={12} xs={12} md={12} align="center">
              <h3>You have not posted any job yet</h3>
            </Col>
          </Row>
        )}
        {dataCount !== 0 && (
          <Row>
            <Form>
              <Row>
                <Col lg={9} sm={12} xs={12} md={12}>
                  <Row className="justify-content-between mb-2">
                    <Col lg={10} sm={5} xs={5} md={6}>
                      <label
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Filter by Status:
                      </label>

                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="experience"
                          value={filterVal}
                          onChange={handleChangefilter}
                          style={{
                            width: "120px",
                            fontSize: "13px",
                          }}
                        >
                          <option value="All">All</option>
                          <option value="status-active">Active</option>
                          <option value="status-expired">Expired</option>
                          <option value="status-disable">Disabled</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={2} sm={7} xs={7}>
                      <label
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-20px",
                        }}
                      >
                        Sort by:
                      </label>

                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="experience"
                          value={sortVal}
                          onChange={handleChange}
                          style={{
                            width: "190px",
                            fontSize: "13px",
                            marginLeft: "-20px",
                          }}
                        >
                          <option value="event-new">Date - Newer</option>
                          <option value="event-old">Date - Older</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <div className="mt-4 col-lg-10 col-md-12 col-sm-11 col-xs-11 card">
              {isLoading ? (
                <div className="table-responsve">
                  <Table responsive hover>
                    <thead>
                      <tr style={{ height: "45px" }}>
                        <th className="text-left ps-4">Designation</th>
                        <th className="text-left">Salary</th>
                        <th className="text-left">Location</th>
                        <th className="text-left">Applications</th>
                        <th className="text-left">Posted date</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagData?.map((jobData) => (
                        <tr key={jobData._id}>
                          <td className="text-left ps-4">
                            <Link
                              to={`/employer/job-details/${jobData._id}`}
                              style={{ color: "#478BFF", fontWeight: "500" }}
                            >
                              {jobData.designation}
                            </Link>
                          </td>
                          <td className="text-left">{jobData.salary}</td>

                          <td className="text-left"> {jobData.location}</td>
                          <td className="text-left">
                            {jobData.status === 1 &&
                            jobData.currentResponsesCount !== 0 ? (
                              <Link
                                to={`/employer/job-application/${jobData._id}`}
                                style={{ color: "black", fontWeight: "500" }}
                              >
                                {jobData?.currentResponsesCount} Applications
                              </Link>
                            ) : (
                              <p
                                style={{
                                  color: "black",
                                  marginTop: "15px",
                                  fontWeight: "500",
                                }}
                              >
                                {jobData?.currentResponsesCount} Applications
                              </p>
                            )}
                          </td>
                          <td className="text-left"> {new Date(jobData.createdAt).toLocaleDateString("en-GB", {day: "numeric",month: "long",year: "numeric"})}</td>
                          <td className="text-left">
                            {jobData.status === 0 && (
                              <span
                                style={{
                                  color: "#A0ABA8",
                                  background: "#EEF1F0",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  padding: "5px 7px",
                                  borderRadius: "5px",
                                  width: "80px",
                                  height: "25px",
                                }}
                              >
                                Expired
                              </span>
                            )}
                            {jobData.status === 1 && (
                              <span
                                style={{
                                  color: "#07A64D",
                                  fontSize: "14px",
                                  padding: "5px 12px",
                                  borderRadius: "5px",
                                  width: "80px",
                                  height: "25px",
                                  background: "#E3FCEE",
                                }}
                              >
                                Active
                              </span>
                            )}
                            {jobData.status === 2 && (
                              <span
                                style={{
                                  color: "#B0A111",
                                  height: "25px",
                                  fontSize: "14px",
                                  padding: "5px 7px",
                                  borderRadius: "5px",
                                  width: "80px",
                                  background: "#F9EE8A",
                                }}
                              >
                                Disabled
                              </span>
                            )}
                          </td>

                          <td className="text-center">
                            <Dropdown
                              style={{
                                position:'absolute',
                                // marginTop: "-50px",
                                background: "none",
                              }}
                            >
                              <Dropdown.Toggle
                                // id="dropdown-basic"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  background: "none",
                                  border: "none",
                                }}
                              >
                                <BiDotsHorizontalRounded
                                  style={{
                                    marginTop: "-20px",
                                    fontSize: "20px",
                                    marginLeft: "-10px",
                                    color: "#000",
                                  }}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                              {jobData.status === 2 && (<>
                                <Dropdown.Item  as={Link} to={`/employer/edit-job/${jobData._id}`}>
                                  <Link
                                  to={`/employer/edit-job/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item  as={Link}  to={`/employer/job-application/${jobData._id}`}>
                                  <Link
                                  to={`/employer/job-application/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Applications
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item  as={Link} to={`/employer/job-details/${jobData._id}`}>
                                  <Link
                                  to={`/employer/job-details/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Enable Job
                                  </Link>
                                </Dropdown.Item>
                                </>
                                )}
                              {jobData.status !== 0 && jobData.status !== 2 && (<>
                                <Dropdown.Item  as={Link} to={`/employer/edit-job/${jobData._id}`}>
                                  <Link
                                  to={`/employer/edit-job/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item  as={Link}  to={`/employer/job-application/${jobData._id}`}>
                                  <Link
                                  to={`/employer/job-application/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Applications
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item  as={Link} to={`/employer/job-details/${jobData._id}`}>
                                  <Link
                                  to={`/employer/job-details/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Disable Job
                                  </Link>
                                </Dropdown.Item>
                                </>
                                )}
                                {mydata?.pointsData?.currentJobPoints === 0 && (
                                  <div>
                                    <Dropdown.Item
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="You don't have enough points to post job"
                                      aria-disabled="true"
                                    >
                                      <span
                                      style={{ color: "#999"  }}
                                      >
                                      Clone Job
                                      </span>
                                    </Dropdown.Item >
                                    <ReactTooltip id="my-tooltip" />
                                  </div>
                                )}
                                {mydata?.pointsData?.currentJobPoints !== 0 && (
                                <Dropdown.Item  as={Link} to={`/employer/post-job/${jobData._id}`}>
                                  <Link
                                  to={`/employer/post-job/${jobData._id}`}
                                  style={{ color: "#000" }}
                                  >
                                  Clone Job
                                  </Link>
                                </Dropdown.Item >
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {filteredData?.length / itemsPerPage > 1 && (
                    <div className="justify-content-right align-right">
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(
                          filteredData?.length / itemsPerPage
                        )}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                        renderLastPage={() => null}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="table-responsive">{DataSkeleton()}</div>
              )}
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default ManageJobs;