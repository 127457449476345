import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import employerReducer from "./slices/employerprofile"
import cookReducer from "./slices/cookprofile"
import configReducer from "./slices/config"
import clientReducer from "./slices/clientprofile"

const rootReducer = combineReducers({
   
     auth: authReducer,
     employerData:employerReducer,
     cookData:cookReducer,
     clientData:clientReducer,
     configData :configReducer
  });



const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    // Add middleware, devtools, etc. if needed
  });



export default store;