import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

const logout =()=>{
localStorage.clear();
localStorage.setItem("logout", Date.now().toString());
localStorage.setItem("role", "cook");
}


class CookService {
  static getProfile() {
    let api = "";
    api = API_PATHS.getProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {

        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
  }

  //getshortlistedjobs
  static getShortListedJobs(item) {
    let api = "";
    api = API_PATHS.shortlistedjobs + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //getshortlistedEvents
  static getShortListedEvents(item) {
    let api = "";
    api =
      API_PATHS.shortlistedEvents + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //getshortlistedEvents
  static ChangeRolecook(item) {
    let api = "";
    api = API_PATHS.changeRole;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          //localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //cokkrating employer
  static CookRatingEmployer(item) {
    let api = "";
    api = API_PATHS.employerRating;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          //localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }
  //getshortlistedHouseJobs
  static getShortListedHouseJobs(item) {
    let api = "";
    api =
      API_PATHS.shortlistedHouseJobs + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static UpdateLogin() {
    let api = "";
    api = API_PATHS.updateLastLogin;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios.put(api, {}, config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static getcookDashbaordData() {
    let api = "";
    api = API_PATHS.getDashboardDataCook;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios
      .get(api, config)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static getAllAppliedJobs(item) {
    let api = "";
    api = API_PATHS.appliedjobs + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static getAllAppliedEvents(item) {
    let api = "";
    api = API_PATHS.appliedevents + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static getAllAppliedHouseJobs(item) {
    let api = "";
    api = API_PATHS.appliedhousejobs + `?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //cook report job service
  static cookreportjobService(item) {
    let api = "";
    api = API_PATHS.cookreportjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getemployerProfile(employerId) {
    let api = '';
    api = API_PATHS.getViewEmployerProfile + `?employerId=${employerId}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
        .get(api, { headers: {"x-access-token" : token}})
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            } 
            return response.data;
        }).catch((Error) => {
    if (Error?.response?.status === 401) {
     
      logout();
     
    }
  });
}

  static employerAplicationSatus(item) {
    let api = "";
    api = API_PATHS.employerapplicationstatus;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getJobDetails(id) {
    let api = "";
    api = API_PATHS.viewjobdetails + "?id=" + id;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static getEventDetails(id) {
    let api = "";
    api = API_PATHS.eventdetails + "?id=" + id;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static cookMobileactivityLog(item) {
    let api = "";
    api = API_PATHS.cookmobieactivity;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static editpartyProfile(item) {
    let api = "";
    api = API_PATHS.editPartyProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  //editcatering
  static editcateringProfile(item) {
    let api = "";
    api = API_PATHS.editcatering;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  static edithouseProfile(item) {
    let api = "";
    api = API_PATHS.editHouseProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static editchefProfile(item) {
    let api = "";
    api = API_PATHS.editChefProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static editbasicProfile(item) {
    let api = "";
    api = API_PATHS.editProfile;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  static getplanHistory() {
    let api = "";
    api = API_PATHS.cookplanHistory;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static findjobs(item) {
    let api = "";
    api = API_PATHS.findjobs;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  static cookreachoutactivities(item) {
    const api = API_PATHS.cookreachoutactivities;
      const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.data?.token;

    if (!token) {
      console.error('No authentication token found.');
      return;
    }
      return axios
      .get(api, {
        params: item, 
        headers: { "x-access-token": token } 
      })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
          return;
        }
        console.error('API request failed:', error);
        throw error; 
      });
  }

  // find house jobs
  static findHousejobs(item) {
    let api = "";
    api = API_PATHS.findhousejobs;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //  house job details
  static Housejobdetails(id) {
    let api = "";
    api = API_PATHS.housecookjobdetails + "?id=" + id;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      })
      .catch((Error) => {
        if (Error?.response?.status === 401) {
          logout();
          return;
        }
      });
  }

  //cook aplly jobs
  static cookApplyjob(item) {
    let api = "";
    api = API_PATHS.cookapplyjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  //cook aplly house cook jobs
  static Applyhousecookjob(item) {
    let api = "";
    api = API_PATHS.applyhousecookjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        return response.data;
      });
  }

  //disable accounts for cook
  static cookDisableAccount() {
    let api = "";
    api = API_PATHS.disablecookaccount;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  //enable accounts for cook
  static cookEnableAccount() {
    let api = "";
    api = API_PATHS.enablecookaccount;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  //delete accounts for client and employer
  static cookDeleteAccount(item) {
    let api = "";
    api = API_PATHS.deletecookaccount;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
      data: item,
    };
    return axios.delete(api,config)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static cancelcookDeleteAccount() {
    let api = "";
    api = API_PATHS.cancelcookdeleterequest;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  //create resume  for cook
  static createResume(item) {
    let api = "";
    api = API_PATHS.generateResume;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  // find house jobs
  static cookCanceljob(item) {
    let api = "";
    api = API_PATHS.cookcancelapplication;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static cookApplyEvent(item) {
    let api = "";
    api = API_PATHS.applyevent;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static getResumeTemplates() {
    let api = "";
    api = API_PATHS.getresumetemplates;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static findeventCook(item) {
    let api = "";
    api = API_PATHS.findeventcook;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  //short list jobs from cook
  static shortlistjobs(item) {
    let api = "";
    api = API_PATHS.shortlistjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  //short list house cook jobs from cook
  static applyHouseCookjobs(item) {
    let api = "";
    api = API_PATHS.Applyhousecookjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static shortlistevent(item) {
    let api = "";
    api = API_PATHS.shortlistEvent;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  static shortlisthousejob(item) {
    let api = "";
    api = API_PATHS.shortlistHousejob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      })
  }

  static updateCookWeavy(item) {
    let api = "";
    api = API_PATHS.updatecookWeavyid;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("weavyId", response?.data?.data?.weavyId);
        }
        return response.data;
      });
  }

  static async getAdvertisement(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    api =
      API_PATHS.getAdvertisements +
      `?limit=${item.limit}&page=${item.page}&usertype=${item.usertype}`;
    const response = await axios.get(api, {
      headers: { "x-access-token": token },
    });
    return response.data; // Extract only the serializable data
  }

  // for removing from shortlists
  static removeshortlistjobss(item) {
    let api = "";
    api = API_PATHS.removeshortlistjob;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
      data: item,
    };
    return axios.delete(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  //   static getCities(city) {
  //     return axios
  //       .get(
  //         `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${city}&key=${GOOGLE_SEARCH_APIKEY}`
  //       )
  //       .then((response) => {
  //         if (response.data) {
  //           // localStorage.setItem("user", JSON.stringify(response.data));
  //         }
  //         return response.data;
  //       });
  //   }

  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default CookService;
