import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getQualification,
  getLanguages,
  getStates,
} from "../../../store/slices/config";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  EditemployerProfile,
  getemployerProfile,
} from "../../../store/slices/employerprofile";
import { Link } from "react-router-dom";
import { API_PATHS } from "../../../utils/constants/api.constants";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ImageCropper from "../../../utils/helpers/ImageCropper/ImageCropper";
import AvatarModel from "../../../utils/helpers/ImageCropper/AvatarModal";

function EditClientProfile() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [fullName, setfullName] = useState("");
  const [languages, setLanguages] = useState([]);
  const [about, setabout] = useState("");
  const [addressline1, setaddressLine1] = useState("");
  const [addressline2, setaddressLine2] = useState("");
  const [gender, setGender] = useState("");
  const [qualification, setQualification] = useState("");
  const [cityName, setcityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");
  const [provinceName, setprovinceName] = useState("");
  const [dob, setDob] = useState("");
  const [dp, setDp] = useState("");
  const [errors, setErrors] = useState({});
  const [education, setEducation] = useState([]);
  const [language, setLanguage] = useState([]);
  const [state, setState] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [citycoord, setcitycoord] = useState([]);
  const [areacoord, setareacoord] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const dobRef = useRef(null);
  const [isdisabled, setisDisabled] = useState(false);
  const fullNameRef = useRef(null);
  const genderRef = useRef(null);
  const languagesRef = useRef(null);
  const adressLineref = useRef(null);
  const pincodeRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Edit Profile";
  }, []);

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setLanguages(data?.user?.data?.languages);
        setcitycoord(data?.user?.data?.cityCoordinates?.coordinates);
        setareacoord(data?.user?.data?.areaCoordinates?.coordinates);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getQualification())
      .unwrap()
      .then((data) => {
        setEducation(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getStates())
      .unwrap()
      .then((data) => {
        setState(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getLanguages())
      .unwrap()
      .then((data) => {
        setLanguage(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  var options = language.map(function (item) {
    return item["languageName"];
  });

  const isValidDOB = (dob) => {
    const today = moment();
    const dobDate = moment(dob);

    // Check if the user is between 18 and 80 years old
    return (
      dobDate.isValid() &&
      dobDate.isBefore(today.subtract(18, "years")) &&
      dobDate.isAfter(today.subtract(80, "years"))
    );
  };
  const isValidName = (fullName) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9 ]{2,}$/.test(fullName);
  };

  const isValidOccupation = (occupation) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9 ]{1,}$/.test(occupation);
  };

  const isValidAddressline1 = (addressline1) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,100}$/.test(addressline1);
  };

  const isValidAddressline2 = (addressline2) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,50}$/.test(addressline2);
  };

  const isValidLandmark = (landmark) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-]{1,50}$/.test(landmark);
  };

  function isValidCity(city) {
    const cityRegex = /^[a-zA-Z\s.'-]{1,50}$/;
    return cityRegex.test(city);
  }

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[1-9][0-9]{5}$/.test(pincode);
  };

  const cityinputRef = useRef(null);
  useEffect(() => {
    if (!isLoading && cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
          setcitycoord([]);
        } else {
          setErrors({
            ...errors,
            cityName: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setcitycoord(coordinates);
          setcityName(place.name);
          setcity(coordinates, place.name);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();

        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
          setcitycoord([]);
        }
      });
    }
  }, [isLoading]);

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      cityName: city,
      cityCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const areainputRef = useRef(null);

  useEffect(() => {
    if (!isLoading && areainputRef.current) {
      const autoCompleteArea = new window.google.maps.places.Autocomplete(
        areainputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );
      autoCompleteArea.addListener("place_changed", () => {
        const place = autoCompleteArea.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            area: "Please select location from the suggestion drop-down",
          });
          setareacoord([]);
        } else if (place.geometry.viewport || place.geometry.location) {
          setErrors({
            ...errors,
            area: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setArea(place.name);
          setarea(place.name, coordinates);
          setareacoord(coordinates);
        }
      });
      areainputRef.current.addEventListener("blur", () => {
        const place = autoCompleteArea.getPlace();
        const inputValue = areainputRef.current.value.trim();

        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setErrors({
            ...errors,
            area: "Please select location from the suggestion drop-down",
          });
          setareacoord([]);
        }
      });
    }
  }, [isLoading]);

  const setarea = (city, coordinates) => {
    setData((prevData) => ({
      ...prevData,
      area: city,
      areaCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    const validationErrors = {};
    if (!data.fullName) {
      validationErrors.fullName = "Please enter your name";
    } else if (!isValidName(data.fullName)) {
      validationErrors.fullName = "Name should have at least 3 characters";
    }

    if (!data.gender) {
      validationErrors.gender = "Please select gender";
    }

    if (!data.addressLine1) {
      validationErrors.addressLine1 = "Please enter your address";
    } else if (!isValidAddressline1(data.addressLine1)) {
      validationErrors.addressLine1 = "Please enter your address";
    }

    if (!data.cityName) {
      validationErrors.cityName = "Please enter your city name";
    } else if (!isValidCity(data.city) || !citycoord?.length) {
      validationErrors.cityName = "Please enter valid city name";
    }

    if (!data.pincode) {
      validationErrors.pincode = "Please enter your pincode";
    } else if (!isValidPincode(data.pincode)) {
      validationErrors.pincode = "Pincode must be  6 digits";
    }
    if (!data.area) {
      validationErrors.area = "Please enter your area";
    } else if (!isValidCity(data.area) || !areacoord?.length) {
      validationErrors.area = "Please enter valid area name";
    }
    if (data.dob && !isValidDOB(data.dob)) {
      validationErrors.dob = "Age must be between 18 and 80 years";
    }
    if (data.landmark && !isValidLandmark(data.landmark)) {
      validationErrors.landmark = "Please enter a valid landmark";
    }
    if (data.occupation && !isValidOccupation(data.occupation)) {
      validationErrors.occupation = "Please enter  occupation";
    }

    const isValid = validateLanguages(languages);
    if (!isValid) {
      validationErrors.languages = "Please enter atleast one language";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.fullName) {
        fullNameRef.current.focus();
      } else if (validationErrors.gender) {
        genderRef.current.focus();
      } else if (validationErrors.languages) {
        languagesRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.addressLine1) {
        adressLineref.current.focus();
      } else if (validationErrors.cityName) {
        cityinputRef.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      } else if (validationErrors.area) {
        areainputRef.current.focus();
      }

      return;
    } else {
      const item = {
        fullName: data.fullName,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        dob: data?.dob?.toString()?.split("T")[0],
        dp: data.dp,
        gender: data.gender,
        occupation: data.occupation,
        languages: languages,
        cityName: data.cityName,
        area: data.area,
        provinceName: data.provinceName,
        pincode: data.pincode || undefined,
        landmark: data.landmark,
        cityCoordinates: citycoord
          ? {
              type: "Point",
              coordinates: citycoord,
            }
          : undefined,
        areaCoordinates: areacoord
          ? {
              type: "Point",
              coordinates: areacoord,
            }
          : undefined,
      };

      dispatch(EditemployerProfile(item))
        .unwrap()
        .then((data) => {
          setModalMessage("Profile updated succesfully");
          setsuccessModal(true);
          setTimeout(() => {
            navigate("/client/profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "fullName":
        setfullName(value);
        setData({
          ...data,
          fullName: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (!value == "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;
      case "cityName":
        setcityName(value);
        setData({
          ...data,
          cityName: value,
        });
        validationErrors[name] = value === "" ? "Please enter city" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "gender":
        setGender(value);
        setData({
          ...data,
          gender: value,
        });
        validationErrors[name] =
          value === "" ? "Please select your gender" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "occupation":
        setData({
          ...data,
          occupation: value,
        });
        validationErrors[name] =
          value !== "" && !isValidOccupation(value)
            ? "Please enter occupation"
            : "";
        if (value !== "" && isValidOccupation(value)) {
          validationErrors[name] = "";
        }
        break;
      case "dob":
        setDob(value);
        setData({
          ...data,
          dob: value,
        });
        validationErrors[name] =
          value === "" || !isValidDOB(value)
            ? "Age must be between 18 and 80 years"
            : "";
        if (value !== "" && isValidDOB(value)) {
          validationErrors[name] = "";
        }
        break;
      case "addressLine1":
        setaddressLine1(value);
        setData({
          ...data,
          addressLine1: value,
        });
        validationErrors[name] =
          value === ""
            ? "Please enter your address"
            : value === "" || !isValidAddressline1(value)
            ? "Please enter a valid address"
            : "";
        if (value !== "" && isValidAddressline1(value)) {
          delete validationErrors[name];
        }
        break;
      case "addressLine2":
        setaddressLine2(value);
        setData({
          ...data,
          addressLine2: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAddressline2(value)
            ? "Please enter a valid address"
            : "";
        if (value !== "" && isValidAddressline2(value)) {
          delete validationErrors[name];
        }
        break;

      case "pincode":
        setPincode(value);
        setData({
          ...data,
          pincode: value,
        });
        validationErrors[name] =
          value === ""
            ? "Please enter your pincode"
            : value === "" || !isValidPincode(value)
            ? "Please enter a valid pincode"
            : "";
        if (value !== "" && isValidPincode(value)) {
          delete validationErrors[name];
        }
        break;

      case "landmark":
        setLandmark(value);
        setData({
          ...data,
          landmark: value,
        });
        validationErrors[name] =
          value !== "" && !isValidLandmark(value)
            ? "Please enter a valid landmark"
            : "";
        if (value !== "" && isValidLandmark(value)) {
          delete validationErrors[name];
        }
        break;
      case "area":
        setArea(value);
        setData({
          ...data,
          area: value,
        });
        validationErrors[name] = value === "" ? "Please enter your area" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "provinceName":
        setprovinceName(value);
        setData({
          ...data,
          provinceName: value,
        });
        validationErrors[name] = value === "" ? "Please enter your state" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }

    setErrors(validationErrors);
  };

  const handleLanguageChange = (selectedOptions) => {
    setLanguages(selectedOptions);
    const isValid = validateLanguages(selectedOptions);

    setErrors((prevErrors) => ({
      ...prevErrors,
      languages: isValid ? "" : "Please select at least one language",
    }));
  };

  const validateLanguages = (selectedOptions) => {
    return selectedOptions?.length > 0;
  };

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFileClient, requestOptions);
    let data2 = await response.json();
    setData({
      ...data,
      dp: data2.data.location,
    });
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const openDatePicker = () => {
    const dateInput = document.getElementById("session-date");
    dateInput.showPicker();
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />

      <AvatarModel
        showModal1={avatarModalOpen}
        image={imageData}
        setShowModal1={setAvatarModalOpen}
        handleSave={handleSave}
        setData={setData}
        data={data}
      />

      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/client/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/client/profile" className="bcrumb">
          Profile
        </Link>
        &nbsp;&bull; Edit Profile
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        {isLoading ? (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            {DataSkeleton()}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center mt-2 card p-3">
            <Col lg={12} xs={12} md={12} className="mb-4">
              <Col lg={12} className=" p-3">
                <Row>
                  <Col lg={3} md={3} sm={12} xs={12} align="center">
                    {data?.dp && (
                      <>
                        <img
                          className="mt-2"
                          src={data.dp}
                          alt="user profile"
                          style={{
                            height: "120px",
                            width: "120px",
                            borderRadius: "20px",
                          }}
                        />
                      </>
                    )}
                    {!data?.dp && (
                      <div
                        className="pt-5 mt-2"
                        style={{
                          height: "120px",
                          width: "120px",
                          borderRadius: "20px",
                          background: "#303030",
                        }}
                      >
                        <p style={{ color: "#fff" }}>Upload Image</p>
                      </div>
                    )}
                  </Col>
                  <Col lg={4} md={4} xs={12} className="mt-5" align="left">
                    <label for="fileInput" className="custom-button">
                      Change Image
                    </label>
                    <Form.Control
                      type="file"
                      name="profiledp"
                      id="fileInput"
                      onChange={(e) => handleChangeImage(e)}
                      className="input1"
                      accept="image/*"
                      placeholder="Please choose profile photo"
                    />
                  </Col>
                </Row>
              </Col>
              <hr />
              <h5 className="text-left heading orange ms-2">General Details</h5>

              <Row className="gx-2 d-flex justify-content-center">
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Name *</label>
                    <Form.Control
                      className="input1"
                      name="fullName"
                      value={data.fullName}
                      onChange={handleChange}
                      type="text"
                      maxlength="100"
                      ref={fullNameRef}
                      isInvalid={!!errors.fullName}
                      placeholder="Enter your name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Gender *</label>
                    <Form.Control
                      as="select"
                      name="gender"
                      className="input1"
                      value={data.gender}
                      onChange={handleChange}
                      isInvalid={!!errors.gender}
                      isValid={errors.gender}
                      ref={genderRef}
                    >
                      <option value="" selected disabled hidden>
                        Select
                      </option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Other</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Date of Birth *</label>
                    <div
                      style={{ position: "relative" }}
                      onClick={openDatePicker}
                    >
                      <Form.Control
                        name="dob"
                        value={data?.dob?.toString().split("T")[0]}
                        onChange={handleChange}
                        isInvalid={!!errors.dob}
                        className="input1"
                        type="date"
                        id="session-date"
                        max={moment().format("YYYY-MM-DD")}
                        placeholder="Enter your date of birth"
                        ref={dobRef}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>

                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Occupation</label>
                    <Form.Control
                      name="occupation"
                      value={data?.occupation}
                      onChange={handleChange}
                      isInvalid={!!errors.occupation}
                      className="input1 pb-3"
                      type="text"
                      placeholder="Enter your occupation"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.occupation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={12} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Languages Known *</label>
                    <div ref={languagesRef}>
                      <Typeahead
                        id="basic-typeahead-multiple"
                        labelKey="name"
                        multiple
                        className="input1"
                        onChange={handleLanguageChange}
                        options={options}
                        placeholder="Languages known"
                        selected={languages}
                      />
                    </div>
                  </Form.Group>
                  <p style={{ color: "#DC3545", fontSize: "14px" }}>
                    {errors.languages}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={12}>
              <h5 className="text-left heading orange ms-2">Contact Details</h5>

              <Row className="gx-2 d-flex justify-content-center">
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Address Line 1 *</label>
                    <Form.Control
                      name="addressLine1"
                      value={data.addressLine1}
                      onChange={handleChange}
                      isInvalid={!!errors.addressLine1}
                      className="input1"
                      type="text"
                      maxlength="100"
                      placeholder="Address line 1"
                      ref={adressLineref}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressLine1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Address Line 2</label>
                    <Form.Control
                      name="addressLine2"
                      value={data.addressLine2}
                      onChange={handleChange}
                      maxlength="50"
                      isInvalid={!!errors.addressLine2}
                      className="input1"
                      type="text"
                      placeholder="Address line 2"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressLine2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">City *</label>
                    <Form.Control
                      name="cityName"
                      value={data.cityName}
                      onChange={handleChange}
                      isInvalid={!!errors.cityName}
                      className="input1"
                      maxlength="50"
                      ref={cityinputRef}
                      placeholder="Enter city name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cityName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Pincode *</label>
                    <Form.Control
                      name="pincode"
                      maxlength="6"
                      value={data.pincode}
                      onChange={handleChange}
                      isInvalid={!!errors.pincode}
                      className="input1"
                      ref={pincodeRef}
                      type="text"
                      placeholder="Enter pincode"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pincode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Landmark</label>
                    <Form.Control
                      name="landmark"
                      value={data.landmark}
                      onChange={handleChange}
                      isInvalid={!!errors.landmark}
                      className="input1"
                      maxlength="50"
                      type="text"
                      placeholder="Enter landmark"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.landmark}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">Area *</label>
                    <Form.Control
                      name="area"
                      value={data.area}
                      onChange={handleChange}
                      isInvalid={!!errors.area}
                      className="input1"
                      maxlength="50"
                      ref={areainputRef}
                      placeholder="Enter area"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.area}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3">
                  <Form.Group className="my-2">
                    <label className="mb-2 label">State </label>

                    <Form.Control
                      as="select"
                      name="provinceName"
                      className="input1"
                      value={data.provinceName}
                      onChange={handleChange}
                      isInvalid={!!errors.provinceName}
                    >
                      <option value="" selected disabled hidden>
                        Select
                      </option>
                      {state.map((edudata, index) => (
                        <option key={index} value={edudata.provinceName}>
                          {edudata.provinceName}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="p-3"></Col>
              </Row>
            </Col>
            <Row className="d-flex justify-content-center mt-4">
              <Col lg={4} xs={8}>
                <div className="d-flex justify-content-around my-2 align-items-center">
                  <Button onClick={() => navigate(-1)} className="cancelBtn">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isdisabled}
                    className="saveBtn ms-2"
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        )}
      </Form>
    </>
  );
}

export default EditClientProfile;
