//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import { useNavigate } from "react-router-dom";
import Footer from "../nav_bar/Footer";
import { useDispatch } from "react-redux";
import { updatepassword } from "../../../store/slices/auth";
import { useCallback } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import login_banner from "../../../Assets/Images/20547283_6310507.jpg";
import SuccessModal from "./SuccessModal";
import Modal2 from "./Modal2";
//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function UpdatePassword() {
  const [timer, setTimer] = useState(30);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  React.useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    document.title = "CookandChef | Update Password";
  }, []);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  const dispatch = useDispatch();
  const [data, setData] = useState({
    mobile: "",
    otp: "",
    newPassword: "",
  });

  const [employerId, setemployerId] = useState("");
  const navigate = useNavigate("");
  const [errors, setErrors] = useState({});

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));

    setemployerId(data?.data?.id);
    setData({
      ...data,
      mobile: data?.data?.mobile,
    });
  }, [employerId]);

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  function isValidOTP(otp) {
    const otpRegex = /^\d{6}$/;
    return otpRegex.test(otp);
  }

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "otp":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        validationErrors[name] =
          value === "" || !isValidOTP(value) ? "Please enter a valid OTP" : "";
        break;
      case "newPassword":
        validationErrors[name] =
          value === "" || !isValidPassword(value)
            ? "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए"
            : "";
        break;
      default:
        break;
    }

    setData({
      ...data,
      [name]: value,
    });

    setErrors(validationErrors);
  };

  async function updatePassword(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!data.otp) {
      validationErrors.otp = "कृपया ओटीपी दर्ज करें";
    } else if (!isValidOTP(data.otp)) {
      validationErrors.otp = "The OTP must have six digits";
    }
    if (!data.newPassword) {
      validationErrors.newPassword = "Please enter password";
    } else if (!isValidPassword(data.newPassword)) {
      validationErrors.newPassword =
        "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      dispatch(updatepassword(data))
        .unwrap()
        .then((data) => {
          setModalMessage("Password changed successfully");

          setsuccessModal(true);
          var forgotlink = localStorage.getItem("forgotlink");
          // if (data.status === 1) {
          setTimeout(() => {
            if (forgotlink) {
              navigate("/hin/employer-login");
            } else {
              navigate("/hin/login");
            }
          }, 3000);
          //   }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-5"
        >
          <Col
            lg={4}
            className="d-none d-lg-block"
            style={{ background: "white" }}
          >
            <img src={login_banner} alt="" style={{ height: "472px" }} />
          </Col>
          <Col
            lg={4}
            md={12}
            xs={12}
            className=" text-center"
            style={{
              backgroundColor: "white",
              height: "472px",
            }}
          >
            <div align="center">
              <h3 className="text-center mt-5 mb-3">Update Password</h3>

              <Form
                noValidate
                autoComplete="off"
                onSubmit={updatePassword}
                className="text-center mt-5 p-3"
                style={{
                  height: "100%",
                  width: "90%",
                  backgroundColor: "",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                <Form.Group className="mb-3 position-relative text-start">
                  <Form.Control
                    name="otp"
                    onChange={handleChange}
                    value={data.otp}
                    isInvalid={!!errors.otp}
                    className="input1"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter OTP"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.otp}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 position-relative text-start">
                  <Form.Control
                    name="newPassword"
                    onChange={handleChange}
                    value={data.newPassword}
                    isInvalid={!!errors.newPassword}
                    className="input1"
                    maxlength={25}
                    type={showPassword ? "text" : "password"}
                    placeholder="नया पासवर्ड दर्ज करें"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "13%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEye
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    )}
                  </span>
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Button
                    className="mb-2 mt-3 login_btn"
                    disabled={isdisabled}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <br /> <br />
      <br /> <br />
      <Footer />
    </>
  );
}

export default UpdatePassword;
