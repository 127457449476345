import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "../nav_bar/Footer";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import { cooklogin } from "../../store/slices/auth";
import banner from "../../Assets/Images/chef-presenting-something-white-background.jpg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modal2 from "./Modal2";
import { Helmet } from "react-helmet";
import Fingerprint2 from "fingerprintjs2";

//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function CookLogin() {
  const [emailOrMobile, setemailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);

  const location = useLocation();
  const [mac, setMac] = useState();
  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);
      setMac(fingerprint);
    });
  }, []);
  const { from } = new URLSearchParams(location.search);

  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

      setMac(fingerprint);
    });
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value, checked } = e.target;

    switch (name) {
      case "emailOrMobile":
        setemailOrMobile(value);
        validationErrors[name] =
          value.trim() === "" ? "Please enter your email or mobile number" : "";
        if (value.trim() !== "") {
          validationErrors[name] = "";
        }
        break;

      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" ? "Please enter your password" : "";
        if (value.trim() !== "") {
          validationErrors[name] = "";
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  async function signIn(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!emailOrMobile.trim()) {
      validationErrors.emailOrMobile =
        "Please enter your email or mobile number";
    }

    if (!password.trim()) {
      validationErrors.password = "Please enter your password";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const loginIP = "0.0.0.0";
      let loginMAC = mac;
      let webAccess = 1;

      let item = {
        emailOrMobile,
        password,
        loginIP,
        loginMAC,
        webAccess,
      };

      dispatch(cooklogin(item))
        .unwrap()
        .then((data) => {
          localStorage.removeItem("role");

          navigate("/cook/dashboard");
        })
        .catch(({ message }) => {
          setModalMessage(message);
          //setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>Login to CookandChef | Sign in</title>
        <meta
          name="description"
          content="Already have an account? Sign in to manage profiles, job updates and post requirements."
        />
        <meta
          name="keywords"
          content="Login CookandChef, find Cook, Cook profiles, Chef profiles, Cater, near me, best cook, CDP, Commis 2, Sous Chef"
        />
      </Helmet>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          className="mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={5} className="d-none d-lg-block">
            <img
              src={banner}
              alt="Jobs for chefs"
              style={{ height: "470px", marginLeft: "5px" }}
            />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center flex-column"
            lg={5}
            xs={11}
            md={12}
            xl={5}
            style={{
              backgroundColor: "white",
              height: "470px",
            }}
          >
            <p className="h2 text-center mt-5">Cook Login</p>
            <p style={{ color: "red" }}>{modalMessage}</p>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={signIn}
              className="text-center mt-3"
              style={{
                height: "100%",
                width: "70%",
              }}
            >
              <Form.Group className="mb-4">
                <Form.Control
                  className="input1"
                  type="text"
                  name="emailOrMobile"
                  value={emailOrMobile}
                  onChange={handleChange}
                  isInvalid={!!errors.emailOrMobile}
                  placeholder="Enter email or mobile number"
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.emailOrMobile}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 position-relative">
                <Form.Control
                  name="password"
                  onChange={handleChange}
                  value={FormData.password}
                  isInvalid={!!errors.password}
                  className="input1"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.password}
                </Form.Control.Feedback>
                <NavLink to="/forgot-password">
                  <p className="text-end forgot_password mt-3">
                    Forgot password?
                  </p>
                </NavLink>
              </Form.Group>
              <Button
                className="mb-2 mt-2 login_btn"
                disabled={isdisabled}
                type="submit"
              >
                Login
              </Button>
              <p className="text-muted mt-2">
                Don't have an account?&nbsp;
                <NavLink to="/cook-signup">
                  <span className="forgot_password">Signup</span>
                </NavLink>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default CookLogin;
