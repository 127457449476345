import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import logo from "../../Assets/Images/nav_logo.png";
import { BsBriefcaseFill } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import { LuChefHat } from "react-icons/lu";
import profile from "../../Assets/Images/profile.png";
import signout from "../../Assets/Images/logout.png";

function NavMenu() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [navBackground, setNavBackground] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    setUser(user);
  }, []);

  const navigate = useNavigate();

  const gotoProfile = () => {
    if (user.data.role === "cook") {
      navigate("/cook/dashboard");
    } else if (user.data.role === "employer") {
      navigate("/employer/dashboard");
    } else if (user.data.role === "client") {
      navigate("/client/dashboard");
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  if (!user?.data?.mobileVerified) {
    return (
      <>
        <div>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="light"
            variant="light"
            sticky="top"
            style={{
              transition: "1s ease",
              color: "black",
              backgroundColor: navBackground ? "white" : "white",
            }}
          >
            <Container>
              <Navbar.Brand to="/">
                <NavLink text-start to="/">
                  <img
                    src={logo}
                    className="me-5"
                    height={60}
                    width={142}
                    alt="app logo"
                  />
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <BiMenuAltLeft style={{ fontSize: "32px" }} />
              </Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <NavLink className="me-2 nav-link-item" text-start to="/">
                    Home
                  </NavLink>

                  <NavLink to="/contact" className="me-4 nav-link-item">
                    Contact
                  </NavLink>
                </Nav>

                <Nav>
                  <NavLink to="/login" className="nav-link-item me-2">
                    <LuChefHat /> Login
                  </NavLink>
                </Nav>
                <Nav>
                  <NavLink
                    to="/employer-login"
                    className="nav-link-item nav_login"
                  >
                    <BsBriefcaseFill /> Employer Login
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    );
  }
  if (user) {
    return (
      <>
        {/* web navbar */}

        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          sticky="top"
          style={{
            transition: "1s ease",
            color: "black",
            backgroundColor: navBackground ? "white" : "white",
          }}
        >
          <Container>
            <Navbar.Brand to="/">
              <img
                src={logo}
                className="me-5"
                height={60}
                width={142}
                alt="app logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <BiMenuAltLeft style={{ fontSize: "32px" }} />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavLink className="me-2 nav-link-item" text-start to="/">
                  Home
                </NavLink>

                {/* <NavLink className="me-4 nav-link-item">Contact</NavLink> */}
              </Nav>

              {/* <Nav className="ml-auto">
                <NavDropdown
                  title="Profile"
                  id="profile-dropdown"
                  onClick={gotoProfile}
                  className="nav-link-item"
                  style={{ background: "#f8f9fa", color: "#333" }}
                >
                  <NavDropdown.Item onClick={gotoProfile}>
                    <img src={profile} style={{ height: "15px" }} alt="" />
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>
                    <img src={signout} style={{ height: "15px" }} alt="" /> Sign
                    Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav> */}

              
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default NavMenu;
