import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import { Link, useNavigate } from "react-router-dom";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  generateResume,
  getcookProfile,
} from "../../../../store/slices/cookprofile";
import { useDispatch } from "react-redux";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { getCuisines } from "../../../../store/slices/config";
import SelectResumeTemplate from "./SelectResumeTemplate";
import AvatarModel from "../../../../utils/helpers/ImageCropper/AvatarModal";

function ResumeBuilder() {
  const [showModal, setShowModal] = useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState();
  const [educationErrors, setEducationErrors] = useState([""]);
  const [cuisineError, setCuisineEError] = useState("");
  const [hobbiesErrors, setHobbiesErrors] = useState([""]);
  const [skillsErrors, setSkillsErrors] = useState([""]);
  const [designationError, setDesignationError] = useState();
  const [aboutError, setAboutError] = useState();
  const [imageError, setImageError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [education, setEducation] = useState([""]);
  const [skills, setSkills] = useState([""]);
  const [hobbies, setHobbies] = useState([""]);
  const [awards, setAwards] = useState([""]);
  const [additional, setAdditional] = useState([""]);
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [profile, setProfile] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [formSelected, setFormSelected] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [imageData, setImageData] = useState("");
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [defaultKey, setDefaaultKey] = useState("0");
  const imagerRef = useRef(null);
  const designationRef = useRef(null);
  const addresRef = useRef(null);
  const AboutRef = useRef(null);
  const educationRefs = useRef([]);
  const cuisineRef = useRef();
  const hobbiesRef = useRef([]);
  const skillsRef = useRef([]);
  const [experience, setExperience] = useState([
    {
      designation: "",
      company: "",
      location: "",
      startDate: "",
      endDate: "",
      responsibilities: [""],
    },
  ]);
  const [experienceErrors, setExperienceErrors] = useState([
    {
      designation: "",
      company: "",
      location: "",
      startDate: "",
      endDate: "",
      responsibilities: [""],
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "CookandChef | Resume Builder";
  }, []);

  const isValidDesignation = (designation) => {
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-/;]{1,100}$/.test(designation);
  };

  const isValidAbout = (about) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\s)[\s\S]{3,}$/.test(about);
  };

  const isValidStartDate = (startDate) => {
    // Validates date in the format YYYY-MM-DD
    return /^\d{4}-\d{2}-\d{2}$/.test(startDate);
  };
  const isValidEndDate = (endDate) => {
    // Validates date in the format YYYY-MM-DD
    return /^\d{4}-\d{2}-\d{2}$/.test(endDate);
  };

  const isValidLocation = (location) => {
    // Validates location with letters, numbers, spaces, and commas
    const cityRegex = /^[a-zA-Z\s. '-]{1,50}$/;
    return cityRegex.test(location);
  };

  const isValidAddress = (address) => {
    return /^(?!\d+$)[a-zA-Z0-9 .,'#-/;]{1,200}$/.test(address);
  };

  const isValidCompany = (company) => {
    // Regular expression pattern to validate company name
    const regex = /^[a-zA-Z0-9\s,'.-]{2,}$/;
    return regex.test(company);
  };

  const isValidskills = (skillsVal) => {
    return /^.{2,100}$/.test(skillsVal);
  };

  const isValidHobbies = (hobbies) => {
    return /^.{2,50}$/.test(hobbies);
  };

  const isValidResponsibilities = (resp) => {
    return /^.{2,200}$/.test(resp);
  };

  const isValidEducationDetails = (education) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9\- ',./;]{1,}$/.test(education);
  };

  const startdate = new Date();
  const enddate = new Date();
  startdate.setDate(startdate.getDate() - 1);
  enddate.setDate(enddate.getDate());

  const enddate1 = enddate.toISOString().split("T")[0];
  const startdate1 = startdate.toISOString().split("T")[0];

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);

  var cuisinoptions = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleCuisineChange = (selectedOptions) => {
    setSelectedCuisines(selectedOptions);
    const isValid = validateCuisines(selectedOptions);
    if (isValid) {
      setCuisineEError("");
    } else {
      setCuisineEError("कृपया कम से कम एक व्यंजन चुनें");
    }
  };

  const validateCuisines = (selectedOptions) => {
    return selectedOptions.length > 0;
  };
  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    setImageError("");

    let response = await fetch(API_PATHS.uploadFileCook, requestOptions);
    let data2 = await response.json();
    setImage(data2?.data?.location);
    setData({
      ...data,
      dp: data2?.data?.location,
    });
  }

  const handleEducation = () => {
    if (education.length >= 5) {
      return;
    } else {
      setEducation([...education, ""]);
      setEducationErrors([...educationErrors, ""]);
    }
  };

  const removeEducation = (index) => {
    const updatedInfo = education.filter((elem, i) => i !== index);
    setEducation(updatedInfo);
    setEducationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(index, 1);
      return updatedErrors;
    });
  };

  const handleEducationChange = (e, index) => {
    const updatedInfo = [...education];
    updatedInfo[index] = e.target.value;
    setEducation(updatedInfo);
    setEducationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] =
        e.target.value.trim() === "" || !isValidEducationDetails(e.target.value)
          ? "Please enter valid education details"
          : "";
      return updatedErrors;
    });
  };

  // hobbies
  const handleHobbies = () => {
    if (hobbies.length >= 5) {
      return;
    } else {
      setHobbies([...hobbies, ""]);
      setHobbiesErrors([...educationErrors, ""]);
    }
  };

  const removeHobbies = (index) => {
    const updatedInfo = hobbies.filter((elem, i) => i !== index);
    setHobbies(updatedInfo);
    setHobbiesErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(index, 1);
      return updatedErrors;
    });
  };

  const handleHobbiesChange = (e, index) => {
    const updatedInfo = [...hobbies];
    updatedInfo[index] = e.target.value;
    setHobbies(updatedInfo);
    setHobbiesErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] =
        e.target.value.trim() === "" || !isValidHobbies(e.target.value)
          ? "Please enter valid hobby"
          : "";
      return updatedErrors;
    });
  };

  //awards

  const handleAwards = () => {
    if (awards.length >= 5) {
      return;
    } else {
      setAwards([...awards, ""]);
    }
  };

  const removeAwards = (index) => {
    const updatedInfo = awards.filter((elem, i) => i !== index);
    setAwards(updatedInfo);
  };

  const handleAwardsChange = (e, index) => {
    const updatedInfo = [...awards];
    updatedInfo[index] = e.target.value;
    setAwards(updatedInfo);
  };

  //skills

  const handleSkills = () => {
    if (skills.length >= 5) {
      return;
    } else {
      setSkills([...skills, ""]);
      setHobbiesErrors([...educationErrors, ""]);
    }
  };

  const removeSkills = (index) => {
    const updatedInfo = skills.filter((elem, i) => i !== index);
    setSkills(updatedInfo);
    setSkillsErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(index, 1);
      return updatedErrors;
    });
  };

  const handleSkillsChange = (e, index) => {
    const updatedInfo = [...skills];
    updatedInfo[index] = e.target.value;
    setSkills(updatedInfo);
    setSkillsErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] =
        e.target.value.trim() === "" || !isValidskills(e.target.value)
          ? "Please enter valid skills"
          : "";
      return updatedErrors;
    });
  };

  //additional info

  const handleAdditional = () => {
    if (additional.length >= 5) {
      return;
    } else {
      setAdditional([...additional, ""]);
    }
  };

  const removeAdditional = (index) => {
    const updatedInfo = additional.filter((elem, i) => i !== index);
    setAdditional(updatedInfo);
  };

  const handleAdditionalChange = (e, index) => {
    const updatedInfo = [...additional];
    updatedInfo[index] = e.target.value;
    setAdditional(updatedInfo);
  };

  const handleAddExperience = () => {
    if (experience.length >= 5) {
      return;
    } else {
      setExperience([
        ...experience,
        {
          company: "",
          designation: "",
          startDate: "",
          endDate: "",
          location: "",
          responsibilities: [""],
        },
      ]);
      setExperienceErrors([
        ...experienceErrors,
        {
          company: "",
          designation: "",
          startDate: "",
          endDate: "",
          location: "",
          responsibilities: [""],
        },
      ]);
    }
  };

  const handleRemoveExperience = (index) => {
    const updatedInfo = experience.filter((elem, i) => i !== index);
    setExperience(updatedInfo);

    setExperienceErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors.splice(index, 1);
      return updatedErrors;
    });
  };

  const handleExperienceChange = (e, index, field) => {
    const updatedExperience = [...experience];
    updatedExperience[index][field] = e.target.value;

    let startDate, endDate;

    // Parse start and end dates
    if (field === "startDate") {
      startDate = new Date(e.target.value);
    } else if (field === "endDate") {
      endDate = new Date(e.target.value);
    }

    setExperience(updatedExperience);

    setExperienceErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      if (field === "designation") {
        updatedErrors[index][field] =
          e.target.value.trim() === "" ? "कृपया एक वैध पदनाम दर्ज करें" : "";
      } else if (field === "company") {
        updatedErrors[index][field] =
          e.target.value.trim() === "" || !isValidCompany(e.target.value)
            ? "कृपया एक वैध कंपनी का नाम दर्ज करें"
            : "";
      } else if (field === "startDate") {
        updatedErrors[index][field] =
          e.target.value.trim() === "" || !isValidStartDate(e.target.value)
            ? "Please enter a valid start date"
            : "";

        // Additional check for startDate and endDate relationship
        if (
          isValidEndDate(updatedExperience[index].endDate) &&
          endDate < startDate
        ) {
          updatedErrors[index]["endDate"] =
            "End date should be after start date";
        }
      } else if (field === "endDate") {
        updatedErrors[index][field] =
          e.target.value.trim() === "" || !isValidEndDate(e.target.value)
            ? "Please enter a valid end date"
            : "";

        // Additional check for startDate and endDate relationship
        if (
          isValidStartDate(updatedExperience[index].startDate) &&
          endDate < startDate
        ) {
          updatedErrors[index]["startDate"] =
            "Start date should be before end date";
        }
      } else if (field === "location") {
        updatedErrors[index][field] =
          e.target.value.trim() === "" ? "Please enter a valid location" : "";
      }
      return updatedErrors;
    });
  };

  const handleAddResponsibility = (expIndex) => {
    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      if (updatedExperience[expIndex].responsibilities.length >= 5) {
        return updatedExperience;
      } else {
        // Update only the responsibilities array of the specific experience object
        updatedExperience[expIndex].responsibilities.push("");
        setExperience(updatedExperience);
      }
    });
  };

  const handleRemoveResponsibility = (index, expIndex) => {
    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      if (index >= 0 && index < updatedExperience.length) {
        const updatedResponsibilities = updatedExperience[
          index
        ].responsibilities.filter((elem, j) => j !== expIndex);
        updatedExperience[index] = {
          ...updatedExperience[index],
          responsibilities: updatedResponsibilities,
        };
      }
      return updatedExperience;
    });

    setExperienceErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index].responsibilities.splice(expIndex, 1);
      return updatedErrors;
    });
  };

  const handleResponsibilityChange = (e, expIndex, respIndex) => {
    setExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[expIndex] = {
        ...updatedExperience[expIndex],
        responsibilities: updatedExperience[expIndex].responsibilities.map(
          (item, i) => (i === respIndex ? e.target.value : item)
        ),
      };
      return updatedExperience;
    });

    setExperienceErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[expIndex].responsibilities[respIndex] =
        e.target.value.trim() === "" || !isValidResponsibilities(e.target.value)
          ? `Please enter your responsibility`
          : "";
      return updatedErrors;
    });
  };

  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
    if (e.target.value === "" || !isValidDesignation(e.target.value)) {
      setDesignationError("Please enter valid designation");
    } else {
      setDesignationError("");
    }
  };

  const handleProfileChange = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
    setProfile(e.target.value);
    if (e.target.value === "" || !isValidAbout(e.target.value)) {
      setAboutError("Please write about yourself");
    } else {
      setAboutError("");
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    if (e.target.value === "" || !isValidAddress(e.target.value)) {
      setAddressError("कृपया अपना पता दर्ज करें");
    } else {
      setAddressError("");
    }
  };

  const validateSkills = (skillsArray) =>
    skillsArray.map((skill) => skill.trim() !== "" && isValidskills(skill));
  const validateHobbies = (hobbiesArray) =>
    hobbiesArray.map((hobby) => hobby.trim() !== "" && isValidHobbies(hobby));

  const validateEducation = (educationArray) =>
    educationArray.map(
      (edu) => edu.trim() !== "" && isValidEducationDetails(edu)
    );
  const setupEducationRefs = (index) => (ref) => {
    return (educationRefs.current[index] = ref);
  };
  const setupHobbiesRefs = (index) => (ref) => {
    return (hobbiesRef.current[index] = ref);
  };
  const setupSkillsRefs = (index) => (ref) => {
    return (skillsRef.current[index] = ref);
  };
  const submit = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    if (!profile) {
      setAboutError("Please write about yourself");
      AboutRef.current.focus();
    } else if (!isValidAbout(designation)) {
      setDesignationError("Please enter yourself");
      AboutRef.current.focus();
    }

    if (!address) {
      setAddressError("कृपया अपना पता दर्ज करें");
      addresRef.current.focus();
    } else if (!isValidAddress(address)) {
      setDesignationError("Please enter valid address");
      addresRef.current.focus();
    }
    if (!designation) {
      setDesignationError("कृपया पदनाम दर्ज करें");
      designationRef.current.focus();
    } else if (!isValidDesignation(designation)) {
      setDesignationError("Please enter valid designation");
      designationRef.current.focus();
    }
    if (templateId !== "658ff1bb38320821a421fefd" && !image) {
      setImageError("कृपया चयन कीजिए image to upload");
      imagerRef.current.focus();
    }

    if (!selectedCuisines?.length) {
      setCuisineEError("कृपया कम से कम एक व्यंजन चुनें");
      cuisineRef.current.focus();
    }

    const educationValid = validateEducation(education);
    const skillsValid = validateSkills(skills);
    const hobbiesValid = validateHobbies(hobbies);

    setEducationErrors(
      education.map((_, index) =>
        educationValid[index] ? "" : "Please enter education details"
      )
    );
    if (educationErrors.some((error) => !!error)) {
      const errorIndex = educationErrors.findIndex((error) => !!error);
      educationRefs.current[errorIndex]?.focus(); // Accessing ref correctly
      return;
    }

    setSkillsErrors(
      skills.map((_, index) =>
        skillsValid[index] ? "" : "Please enter at least one skill"
      )
    );

    setHobbiesErrors(
      hobbies.map((_, index) =>
        hobbiesValid[index] ? "" : "Please enter at least one hobby"
      )
    );
    if (hobbiesErrors.some((error) => !!error)) {
      const errorIndex = hobbiesErrors.findIndex((error) => !!error);
      hobbiesRef.current[errorIndex]?.focus(); // Accessing ref correctly
      return;
    }
    if (skillsErrors.some((error) => !!error)) {
      const errorIndex = skillsErrors.findIndex((error) => !!error);
      skillsRef.current[errorIndex]?.focus(); // Accessing ref correctly
      return;
    }

    const experienceErrors = experience.map((exp, index) => {
      const errors = {};
      if (exp.designation.trim() === "") {
        errors.designation = "कृपया एक वैध पदनाम दर्ज करें";
      }
      if (exp.company.trim() === "" || !isValidCompany(exp.company)) {
        errors.company = "कृपया एक वैध कंपनी का नाम दर्ज करें";
      }
      if (exp.location.trim() === "") {
        errors.location = "Please enter a valid location";
      }
      if (exp.startDate.trim() === "" || !isValidStartDate(exp.startDate)) {
        errors.startDate = "Please enter a valid start date";
      }
      if (exp.endDate.trim() === "" || !isValidEndDate(exp.endDate)) {
        errors.endDate = "Please enter a valid end date";
      }
      const startDate = new Date(exp.startDate);
      const endDate = new Date(exp.endDate);
      // Check if end date is before start date
      if (endDate < startDate) {
        errors.endDate = "End date should be after start date";
      }

      errors.responsibilities = exp.responsibilities.map((responsibility) =>
        responsibility.trim() === "" || !isValidResponsibilities(responsibility)
          ? "Please enter a valid responsibility"
          : ""
      );

      return errors;
    });

    setExperienceErrors(experienceErrors);

    if (
      !selectedCuisines.length ||
      educationErrors.some((error) => !!error) ||
      skillsErrors.some((error) => !!error) ||
      hobbiesErrors.some((error) => !!error) ||
      experienceErrors.some((error) =>
        Object.values(error).some((value) =>
          Array.isArray(value)
            ? value.some((responsibility) => !!responsibility)
            : !!value
        )
      ) ||
      designationError ||
      addressError ||
      aboutError ||
      cuisineError ||
      imageError ||
      (templateId !== "658ff1bb38320821a421fefd" && !image) ||
      !designation ||
      !address ||
      !profile
    ) {
      return;
    }

    const updatedExperience = experience.map((exp) => {
      const { startDate, endDate } = exp;
      if (startDate && endDate) {
        const [startMonth, startYear] = startDate.split("-");
        const [endMonth, endYear] = endDate.split("-");
        return {
          ...exp,
          startDate: `${startYear}-${startMonth}`,
          endDate: `${endYear}-${endMonth}`,
        };
      }
      return exp;
    });

    const item = {
      name: data.fullName,
      email: data.email,
      mobile: data.mobile,
      address: address,
      currentDesignation: designation,
      skills: skills,
      profileImage:
        templateId === "658ff1bb38320821a421fefd" ? undefined : image,
      cuisines: selectedCuisines,
      hobbies: hobbies,
      profile: profile,
      education: education,
      workExperience: updatedExperience,
      templateId: templateId,
    };
    if (additional.some((value) => value.trim() !== "")) {
      item.additionalInfo = additional;
    }

    // Add 'awards' only if 'awards' has at least one value
    if (awards.some((value) => value.trim() !== "")) {
      item.awards = awards;
    }

    dispatch(generateResume(item))
      .then((data) => {
        if (data?.payload?.user?.data?.resume) {
          setsuccessModal(true);
          setModalMessage(
            "Resume generated successfully and uploaded to your profile!"
          );
          setTimeout(() => {
            navigate("/hin/cook/cook-profile");
          }, 1000);
        } else {
          setShowModal(true);
          setModalMessage(data.payload.message);
        }
      })
      .catch(({ message }) => {
        setShowModal(true);
        setModalMessage(message);
      });
  };

  const cancel = () => {
    navigate(-1);
  };
  const getDefaultActiveKey = () => {
    const educationErrorIndex = educationErrors?.some((error) => !!error)
      ? "0"
      : null;
    const cuisineErrorIndex = cuisineError ? "1" : null;
    const hobbiesErrorIndex = hobbiesErrors?.some((error) => !!error)
      ? "2"
      : null;
    const skillsErrorIndex = skillsErrors?.some((error) => !!error)
      ? "4"
      : null;
    const hasErrors = experienceErrors.some((error) =>
      Object.values(error).some((value) => {
        if (Array.isArray(value)) {
          return value[0] !== ""; // Check if the array is not empty
        } else {
          return value.trim() !== ""; // Check if the value is not an empty string after trimming whitespace
        }
      })
    );
    const experienceErrorIndex = hasErrors ? "6" : null;

    // Priority: Experience > Additional Info > Skills > Awards > Hobbies > Cuisines > Education
    return (
      educationErrorIndex ||
      cuisineErrorIndex ||
      hobbiesErrorIndex ||
      skillsErrorIndex ||
      experienceErrorIndex
    );
  };

  useEffect(() => {
    const defaultkey = getDefaultActiveKey();
    if (defaultkey) {
      setDefaaultKey(String(defaultkey));
    }
  }, [
    educationErrors,
    cuisineError,
    hobbiesErrors,
    skillsErrors,
    experienceErrors,
  ]);

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <AvatarModel
        showModal1={avatarModalOpen}
        image={imageData}
        setShowModal1={setAvatarModalOpen}
        handleSave={handleSave}
        setData={setData}
        data={data}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard">Home</Link> &bull; Resume Builder
        </div>

        {data?.resumeBuilder === 0 && (
          <Row className="card mt-4 p-5 mb-3">
            <Col lg={12} xs={12} md={12} align="center">
              <h3>You don't have enough points to create a Resume</h3>
              <br />
              <button className="saveBtn" style={{ width: "150px" }}>
                <Link to="/hin/cook/plans" style={{ color: "white" }}>
                  Purchase Plan
                </Link>
              </button>
            </Col>
          </Row>
        )}

        {data?.resumeBuilder === 1 && formSelected === false && (
          <SelectResumeTemplate
            formSelected={formSelected}
            setFormSelected={setFormSelected}
            setTemplateId={setTemplateId}
          />
        )}

        {data?.resumeBuilder === 1 && formSelected === true && (
          <Form autoComplete="off" noValidate>
            <Row className="d-flex justify-content-center mt-4 card p-3">
              <Col lg={12} xs={12} md={12} className="mb-4">
                <Col lg={12}>
                  <h5 className="text-left heading orange ms-2">
                    सामान्य विवरण
                  </h5>

                  <div className="d-flex w-50 justify-content-between">
                    <div style={{ marginLeft: "30px" }}>
                      <p>Name</p>
                      <p>Email</p>
                      <p>Mobile</p>
                    </div>

                    <div>
                      <p>{data.fullName}</p>
                      <p>{data.email}</p>
                      <p>{data.mobile}</p>
                    </div>
                  </div>
                </Col>

                {templateId === "658ff1bb38320821a421fefd" ? (
                  ""
                ) : (
                  <Row className="d-flex justify-content-center mt-1 card p-3">
                    <Col lg={12} xs={12} md={12} className="mb-1">
                      <Col lg={12} className="">
                        <Row>
                          <Col
                            lg={4}
                            md={4}
                            xs={4}
                            className="mt-1"
                            align="left"
                          >
                            <label for="fileInput" className="custom-button">
                              Add Image
                            </label>
                            <Form.Control
                              type="file"
                              name="profiledp"
                              id="fileInput"
                              accept="image/*"
                              ref={imagerRef}
                              onChange={(e) => handleChangeImage(e)}
                              className="input1"
                              placeholder="कृपया प्रोफ़ाइल फ़ोटो चुनें"
                            />
                          </Col>
                        </Row>
                        <p
                          style={{
                            color: "rgb(220,53,69)",
                            marginTop: "10px",
                            fontSize: "14px",
                          }}
                        >
                          {imageError}
                        </p>
                      </Col>
                    </Col>
                  </Row>
                )}
                <Col lg={12}>
                  <Row>
                    <Col lg={10} md={10} sm={12}>
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          Current Designation *
                        </label>
                        <Form.Control
                          name="designation"
                          className="input1"
                          value={designation}
                          ref={designationRef}
                          onChange={handleDesignationChange}
                          type="text"
                          maxlength="25"
                          isInvalid={!!designationError}
                          placeholder="Current designation"
                        />
                        <Form.Control.Feedback type="invalid">
                          {designationError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={10} md={10} sm={12}>
                      <Form.Group className="my-2">
                        <label className="mb-2 label">Address * </label>
                        <Form.Control
                          name="address"
                          className="input1"
                          value={address}
                          ref={addresRef}
                          onChange={handleAddressChange}
                          type="text"
                          maxlength="200"
                          isInvalid={!!addressError}
                          placeholder="Enter your address"
                        />
                        <Form.Control.Feedback type="invalid">
                          {addressError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={10} md={10} sm={12}>
                      <Form.Group className="my-2 mt-3">
                        <label className="mb-2 label">About *</label>
                        <Form.Control
                          name="profile"
                          className="input1"
                          value={profile}
                          required
                          onChange={handleProfileChange}
                          as="textarea"
                          style={{ resize: "none" }}
                          rows={1}
                          ref={AboutRef}
                          isInvalid={!!aboutError}
                          placeholder="अपने बारे में दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {aboutError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <div className="mt-2 col-lg-10">
                  <Accordion defaultActiveKey={"0"}>
                    <div className="mt-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Educational Details</Accordion.Header>
                        <Accordion.Body>
                          <div className="d-flex justify-content-end">
                            <div className="m-1" onClick={handleEducation}>
                              <IoAddCircleOutline
                                size={20}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>

                          {education.map((edu, index) => (
                            <Row>
                              <Col lg={10} xs={10} md={10} className="p-3 ">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Educational details *
                                  </label>

                                  <Form.Control
                                    className="input1"
                                    name="education"
                                    value={edu}
                                    onChange={(e) =>
                                      handleEducationChange(e, index)
                                    }
                                    type="text"
                                    maxlength="200"
                                    isInvalid={!!educationErrors[index]}
                                    ref={setupEducationRefs(index)}
                                    placeholder={`Education details`}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {educationErrors[index]}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={2} xs={2} md={2} className="p-3">
                                <div className="d-flex justify-content-end">
                                  {index > 0 && (
                                    <div
                                      className="mt-4"
                                      onClick={() => removeEducation(index)}
                                    >
                                      <MdDelete
                                        style={{ cursor: "pointer" }}
                                        size={20}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Cuisines</Accordion.Header>
                      <Accordion.Body>
                        <Col lg={12} sm={12}>
                          <Form.Group className="my-1">
                            <label className="mb-2 label">Cuisines *</label>
                            <Typeahead
                              id="basic-typeahead-multiple"
                              labelKey="cuisineName"
                              multiple
                              className="input1"
                              onChange={handleCuisineChange}
                              options={cuisinoptions}
                              placeholder="ज्ञात व्यंजन"
                              ref={cuisineRef}
                              selected={selectedCuisines}
                            />
                            <p style={{ color: "#DC3545", fontSize: "14px" }}>
                              {cuisineError}
                            </p>
                          </Form.Group>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Hobbies</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-end">
                          <div className="m-1" onClick={handleHobbies}>
                            <IoAddCircleOutline
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        {hobbies.map((hobies, index) => (
                          <Row>
                            <Col lg={10} className="p-3">
                              <Form.Group className="my-1">
                                <label className="mb-2 label">Hobbies *</label>
                                <Form.Control
                                  type="text"
                                  name="hobbies"
                                  className="input1"
                                  value={hobies}
                                  maxLength={50}
                                  placeholder={`Hobbies `}
                                  ref={setupHobbiesRefs(index)}
                                  onChange={(e) =>
                                    handleHobbiesChange(e, index)
                                  }
                                  isInvalid={!!hobbiesErrors[index]}
                                  isValid={errors?.hobbies}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {hobbiesErrors[index]}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={2} className="p-3">
                              <div className="d-flex justify-content-end">
                                {index > 0 && (
                                  <div
                                    className="mt-4"
                                    onClick={() => removeHobbies(index)}
                                  >
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Awards</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-end">
                          <div className="m-1" onClick={handleAwards}>
                            <IoAddCircleOutline
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        {awards.map((award, index) => (
                          <Row>
                            <Col lg={10} className="p-3">
                              <Form.Group className="my-1">
                                <label className="mb-2 label">Awards</label>
                                <Form.Control
                                  type="text"
                                  name="awards"
                                  className="input1"
                                  value={award}
                                  placeholder={`Awards`}
                                  onChange={(e) => handleAwardsChange(e, index)}
                                  isInvalid={!!errors?.awards}
                                  isValid={errors?.awards}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors?.awards}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={2} className="p-3 ">
                              <div className="d-flex justify-content-end">
                                {index > 0 && (
                                  <div
                                    className="mt-4"
                                    onClick={() => removeAwards(index)}
                                  >
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Skills</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-end">
                          <div className="m-1" onClick={handleSkills}>
                            <IoAddCircleOutline
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        {skills.map((skill, index) => (
                          <Row>
                            <Col lg={10} className="p-3">
                              <Form.Group className="my-1">
                                <label className="mb-2 label">Skills *</label>
                                <Form.Control
                                  type="text"
                                  name="skills"
                                  className="input1"
                                  value={skill}
                                  onChange={(e) => handleSkillsChange(e, index)}
                                  isInvalid={!!skillsErrors[index]}
                                  ref={setupSkillsRefs(index)}
                                  placeholder={`Skills`}
                                  isValid={errors?.skills}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {skillsErrors[index]}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={2} className="p-3">
                              <div className="d-flex justify-content-end">
                                {index > 0 && (
                                  <div
                                    className="mt-4"
                                    onClick={() => removeSkills(index)}
                                  >
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Additional Info </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-end">
                          <div className="m-1" onClick={handleAdditional}>
                            <IoAddCircleOutline
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        {additional.map((addi, index) => (
                          <Row>
                            <Col lg={10} className="p-3 ">
                              <Form.Group className="my-1">
                                <label className="mb-2 label">
                                  Additional Info
                                </label>
                                <Form.Control
                                  type="text"
                                  name="additional"
                                  className="input1"
                                  value={addi}
                                  onChange={(e) =>
                                    handleAdditionalChange(e, index)
                                  }
                                  isInvalid={!!errors?.additional}
                                  isValid={errors?.additional}
                                  placeholder={`Additional info `}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors?.additional}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={2} className="p-3 ">
                              <div className="d-flex justify-content-end">
                                {index > 0 && (
                                  <div
                                    className="mt-4"
                                    onClick={() => removeAdditional(index)}
                                  >
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Work Experience </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-end">
                          <div className="m-1" onClick={handleAddExperience}>
                            <IoAddCircleOutline
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        {experience?.map((exp, index) => (
                          <div>
                            <Row>
                              {index > 0 && (
                                <div className="d-flex justify-content-between">
                                  <label className="mb-2 label">
                                    Work Experience *
                                  </label>
                                  <div
                                    className="mt-4"
                                    onClick={() =>
                                      handleRemoveExperience(index)
                                    }
                                  >
                                    <MdDelete
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  </div>
                                </div>
                              )}

                              <Col lg={6} className="p-3">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Company *
                                  </label>
                                  <Form.Control
                                    className="input1"
                                    name="company"
                                    value={exp?.company}
                                    onChange={(e) =>
                                      handleExperienceChange(
                                        e,
                                        index,
                                        "company"
                                      )
                                    }
                                    type="text"
                                    maxlength="100"
                                    isInvalid={
                                      !!experienceErrors[index]?.company
                                    }
                                    placeholder="अपना नाम दर्ज करें"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {experienceErrors[index]?.company}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={6} className="p-3">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Designation *
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="designation"
                                    className="input1"
                                    value={exp?.designation}
                                    onChange={(e) =>
                                      handleExperienceChange(
                                        e,
                                        index,
                                        "designation"
                                      )
                                    }
                                    isInvalid={
                                      !!experienceErrors[index]?.designation
                                    }
                                    isValid={errors?.designation}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {experienceErrors[index]?.designation}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={3} className="p-3">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Start Date *
                                  </label>
                                  <Form.Control
                                    name="startDate"
                                    value={exp?.startDate}
                                    max={startdate1}
                                    onChange={(e) =>
                                      handleExperienceChange(
                                        e,
                                        index,
                                        "startDate"
                                      )
                                    }
                                    isInvalid={
                                      !!experienceErrors[index]?.startDate
                                    }
                                    className="input1"
                                    type="date"
                                    id="session-date"
                                    placeholder="Enter start date"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {experienceErrors[index]?.startDate}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={3} className="p-3">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    End Date *
                                  </label>
                                  <Form.Control
                                    name="endDate"
                                    value={exp?.endDate}
                                    max={enddate1}
                                    onChange={(e) =>
                                      handleExperienceChange(
                                        e,
                                        index,
                                        "endDate"
                                      )
                                    }
                                    isInvalid={
                                      !!experienceErrors[index]?.endDate
                                    }
                                    className="input1"
                                    type="date"
                                    id="session-date"
                                    placeholder="Enter end date"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {experienceErrors[index]?.endDate}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col lg={6} className="p-3">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Location *
                                  </label>
                                  <Form.Control
                                    name="location"
                                    value={exp?.location}
                                    onChange={(e) =>
                                      handleExperienceChange(
                                        e,
                                        index,
                                        "location"
                                      )
                                    }
                                    className="input1"
                                    isInvalid={
                                      !!experienceErrors[index]?.location
                                    }
                                    type="text"
                                    placeholder="Enter city"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {experienceErrors[index]?.location}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <div className="d-flex justify-content-end">
                                <Link
                                  className="m-1"
                                  onClick={() => handleAddResponsibility(index)}
                                >
                                  <IoAddCircleOutline
                                    size={20}
                                    style={{ cursor: "pointer", color: "#000" }}
                                  />
                                </Link>
                              </div>

                              {exp?.responsibilities?.map((resp, respIndex) => (
                                <Row>
                                  <Col lg={10} className="p-3 ">
                                    <div>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Responsibilities *
                                        </label>
                                        <Form.Control
                                          name={`responsibility-${index}-${respIndex}`}
                                          value={resp}
                                          className="input1"
                                          isInvalid={
                                            !!experienceErrors[index]
                                              ?.responsibilities[respIndex]
                                          }
                                          as="textarea"
                                          onChange={(e) =>
                                            handleResponsibilityChange(
                                              e,
                                              index,
                                              respIndex
                                            )
                                          }
                                          maxlength={200}
                                          placeholder={`Responsibilities`}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            experienceErrors[index]
                                              ?.responsibilities[respIndex]
                                          }
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col lg={2} className="p-3 ">
                                    <div className="d-flex justify-content-end">
                                      {respIndex > 0 && (
                                        <div
                                          className="mt-4"
                                          onClick={() =>
                                            handleRemoveResponsibility(
                                              index,
                                              respIndex
                                            )
                                          }
                                        >
                                          <MdDelete
                                            style={{ cursor: "pointer" }}
                                            size={20}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              ))}

                              <hr style={{ color: "blue", height: "5px" }} />
                            </Row>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="d-flex  justify-content-center mt-4">
                    <Button
                      type="cancel"
                      className="cancelBtn"
                      onClick={cancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isdisabled}
                      type="submit"
                      className="saveBtn ms-2"
                      onClick={submit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </>
  );
}

export default ResumeBuilder;
