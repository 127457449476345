import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal, Button, Card, Form } from "react-bootstrap";

import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetResumeTemplates } from "../../../../store/slices/cookprofile";
import ResumeTemplate from "./ViewResumeTemplate";
function SelectResumeTemplate({
  formSelected,
  setFormSelected,
  setTemplateId,
}) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Resume Templates";
  }, []);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState();
  const [resumeElem, setResumeElem] = useState();

  useEffect(() => {
    dispatch(GetResumeTemplates())
      .unwrap()
      .then((data) => {
        setData(data?.data);
      })
      .catch(({ message }) => {});
  }, []);

  const openResumeModal = (elem) => {
    setShowModal(true);
    setImage(elem?.templateUrl);
    setResumeElem(elem);
  };

  return (
    <>
      <ResumeTemplate
        showModal={showModal}
        image={image}
        setShowModal={setShowModal}
        data={resumeElem}
        formSelected={formSelected}
        setFormSelected={setFormSelected}
        setTemplateId={setTemplateId}
      />
      <Col lg={12} className="card">
        <Row className="pt-2">
          <Col lg={10} xs={10} md={10} className="p-3 mx-auto">
            <h4 className="text-center mb-5">Select resume template</h4>
            <Row className="mt-3 mb-5 p-1">
              {data?.data?.map((elem) => (
                <Col lg={4} xs={6} md={4} align="center">
                  <img
                    src={elem?.templateUrl}
                    onClick={() => openResumeModal(elem)}
                    alt=""
                    style={{
                      height: "200px",
                      cursor: "pointer",
                      border: "solid 1px",
                    }}
                  />
                  <br />

                  {/* <Link  onClick={() => openResumeModal(elem)}>
                    <u className="mt-5">View Template</u>
                  </Link> */}
                  <br />
                  <br />
                  <button
                    className="saveBtn"
                    style={{ width: "150px" }}
                    onClick={() => openResumeModal(elem)}
                  >
                    Preview
                  </button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default SelectResumeTemplate;
