import React from "react";
import { Modal } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
function IsViewedModalEmployer({
  showViewModal,
  modalViewMessage,
  setShowViewModal,
}) {
  // ... existing code
  const navigate = useNavigate();
  const handleCloseModal = () => setShowViewModal(false);
  const buyPlan = () => {
    navigate("/employer/plans");
  };

  return (
    <>
      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showViewModal}
        onHide={handleCloseModal}
      >
        <Modal.Body align="left">
          <h6>{modalViewMessage}</h6>
        </Modal.Body>

        <div className="d-flex justify-content-center mt-4">
          <button type="button" className="modalsaveBtn" onClick={buyPlan}>
            Buy Plan
          </button>
          <button
            type="button"
            className="modalcancelBtn ms-2"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default IsViewedModalEmployer;
