import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";
import oops from "../../Assets/Images/wrong.png";
const FailEmail = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "CookandChef  | Faqs";
  }, []);

  return (
    <>
      <Container align="center">
        <div className="mt-5">
          <div className="help-lef-inner mt-3">
            <br /> <br /> <br /> <br />
            <img src={oops} alt="" style={{ height: "50px", width: "50px" }} />
            <br />
            <h4 style={{ color: "red" }}>Error!</h4>
            <h5 className="mt-4">Email verification failed!</h5>
          </div>
        </div>
      </Container>
    </>
  );
};

export default FailEmail;
