import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Container} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import verified from "../../../Assets/Images/checked.png";
import empimg from "../../../Assets/Images/restaurant.png";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import { CookMobileactivityLog, getemployerProfile } from "../../../store/slices/cookprofile";
import { FaRegClock } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { API_PATHS } from "../../../utils/constants/api.constants";
import InitApp1 from "../../../utils/helpers/CreateOthersWeavy";
import { createChatRoom } from "./CreateChatRoom";
import ReviewModal from "../../../utils/helpers/ReviewModal";
import ReportChefModal from "./ReportModal";
import ContactPivacyModal from "../../../utils/helpers/ContactPrivacyModal";
import EmployerRatingModal from "../../../utils/helpers/EmployerRatingModal";
import Modal2 from "../../auth_screens/Modal2";
import { IoIosCall } from "react-icons/io";
import { BsFillChatLeftTextFill, BsWhatsapp } from "react-icons/bs";

function EmployerProfile() {
  const { id } = useParams();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [rateModal, setRateModal] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [website, setWebsite] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "CookandChef | Profile";
  }, []);








  React.useEffect(() => {
    getDetails();
  }, [dispatch]);
  //==============


  const getDetails = () => {
    setLoading(true);
    dispatch(getemployerProfile(id))
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setphoneNumber(data?.user?.data?.mobile);
        // setwhatsappNumber(data?.user?.data?.whatsappNumber);
        setIsRespTrue(true);
              setTimeout(() => {
                setLoading(false);
              }, 500);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const handleInteracction = async (value) => {
    const item = {
      cookId: id,
      employerId:data?._id,
      activity: value,
    };
    try {
      const data1 = await dispatch(CookMobileactivityLog(item)).unwrap();

      if (value === "mobileinteraction") {
        window.location.href = `tel:${phoneNumber}`;
      } else if (value === "whatsappinteraction") {
        window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
      } else if (value === "chatinteraction") {
        let member2;
        let res;
        const weavyid = parseInt(localStorage.getItem("weavyId"));
        const member1 = parseInt(user?.data?.weavyId) || weavyid;
        member2 = parseInt(data?.weavyId);

        const chatroomName = data?.fullName;
        if (!data?.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.id,
              name: data?.fullName,
            }),
          };
          const rees = await InitApp1(requestOptions, data, "employer");
          member2 = parseInt(localStorage.getItem("weavyId2"));
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        } else {
          member2 = parseInt(data?.weavyId);
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        }

        if (res?.id) {
          navigate("/employer/message", {
            state: { res: res },
          });
        }
      }
    } catch ({ message }) {
      setModalMessage(message);
      setShowModal(true);
    }
  };

  //==============


  const toggleMobileView = () => {
    if (data?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else {
      const item = {
        cookId: id,
        employerId:data?._id,
        activity: "viewedmobilenumber",
      };

      if (showFullMobile === false) {
        dispatch(CookMobileactivityLog(item))
          .unwrap()
          .then((data) => {
            setShowFullMobile(!showFullMobile);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
          });
      }
    }
  };

  const startsWithHttp =
    website && website.startsWith && website.startsWith("http://");
  const startsWithHttps =
    website && website.startsWith && website.startsWith("https://");

  return (
    <>
      <ReviewModal
        showModal={reviewModal}
        data={data}
        setShowModal={setShowReviewModal}
      />
      {/* <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={id}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
      /> */}
      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <EmployerRatingModal
        rateModal={rateModal}
        id={id}
        setRateModal={setRateModal}
        getDetails={getDetails}
        data={data}
        role="Cook"
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <Container>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link className="bcrumb">
            Home
          </Link>
          &nbsp;&bull; Profile
        </div>
        {loading ? (
          <Row>{ShowprofileSkeleton()}</Row>
        ) : (
          <Row className="mt-4">
            <Col lg={12} className="card">
              <Row className="pt-2">
                <Col lg={2} xs={8} md={3}>
                  {data?.jobSeeking === 1 && (
                    <p
                      className
                      style={{
                        background: "#ff5c09",
                        color: "#fff",
                        padding: "0px 0px 0px 5px",
                        margin: "-10px 0px 0px -15px",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                    >
                      Looking for Job
                    </p>
                  )}
                </Col>
                <Col lg={10} xs={4} md={9} />
                <Col lg={2} xs={8} md={3}>
                  {data?.dp ? (
                    <img
                      style={{
                        height: "90px",
                        width: "90px",
                        borderRadius: "15px",
                      }}
                      className="mt-2"
                      src={data?.dp}
                      alt="user profile"
                    />
                  ) : (
                    <img
                      style={{
                        height: "90px",
                        width: "90px",
                        borderRadius: "15px",
                      }}
                      className="mt-2"
                      src={empimg}
                      alt="user profile"
                    />
                  )}
  
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#8F8686",
                      marginTop: "5px",
                    }}
                  >
                    <FaRegClock style={{ marginTop: "-2px" }} />
                    &nbsp;
                    {Moment.utc(data?.lastLoginDateTime)
                      .local("Asia/Kolkata")
                      .startOf("seconds")
                      .fromNow()}
                  </p>
                </Col>
  
                <Col
                  xs={3}
                  className="d-sm-block d-md-none d-lg-none"
                  align="right"
                >
                  <div className="d-flex justify-content-end align-items-right flex-column">
                    {data?.isShortlisted === true && <p>Saved</p>}
                  </div>
                </Col>
                <Col lg={7} xs={12} xl={8} md={6}>
                  <h6 className="mt-2">{data?.fullName}</h6>
                  <p>
                    <GrLocation style={{ marginBottom: "2px" }} />
                    &nbsp;
                    {data?.currentCityName}
                    <br />
                    <HiOutlineDevicePhoneMobile style={{ marginBottom: "2px" }} />
                    &nbsp;+91-
                    {showFullMobile ? (
                      <span>{data?.mobile}</span>
                    ) : (
                      <span>
                        {data?.mobile?.toString()?.substring(0, 3)}xxxxxxx
                      </span>
                    )}
                    <span onClick={toggleMobileView}>
                      {showFullMobile ? (
                        <p></p>
                      ) : (
                        <Link
                          style={{
                            cursor: "pointer",
                            marginTop: "-25px",
  
                            color: "blue",
                          }}
                          className="ms-2"
                        >
                          View
                        </Link>
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  lg={2}
                  xl={1}
                  md={2}
                  className="d-none d-md-block d-lg-block"
                  align="right"
                >
                  <div className="d-flex justify-content-end align-items-right flex-column">
                    {data?.isShortlisted === true && (
                      <p style={{ marginRight: "-25px" }}>Saved</p>
                    )}
                  </div>
                </Col>
                <Row
                  align="right"
                  className="justify-content-right align-items-right"
                >
                  <Col lg={8} xl={9} xs={12} md={8} />
                  <Col
                    xl={3}
                    lg={4}
                    xs={12}
                    md={4}
                    className="contacticonCard justify-content-end"
                    align="right"
                  >
                  </Col>
                  <Row
                align="right"
                className="justify-content-right align-items-right"
              >
                <Col lg={8} xl={9} xs={12} md={8} />
                <Col
                  xl={3}
                  lg={4}
                  xs={12}
                  md={4}
                  className="contacticonCard justify-content-end"
                  align="right"
                >
                  <Row>
                    {data?.smsContact === 1 && (
                      <Col
                        lg={4}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInteracction("mobileinteraction")}
                      >
                        <IoIosCall
                          style={{ color: "#1808B1", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                    {data?.whatsappContact === 1 && data?.whatsappNumber && (
                      <Col
                        lg={4}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInteracction("whatsappinteraction")
                        }
                      >
                        <BsWhatsapp
                          style={{ color: "#059F14", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                    {data?.chatContact === 1 && (
                      <Col
                        lg={4}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInteracction("chatinteraction")}
                      >
                        <BsFillChatLeftTextFill
                          style={{ color: "#F87F43", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
                </Row>
              </Row>
            </Col>
        </Row>
        )}
        {loading ? (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            {DataSkeleton()}
          </Row>
        ) : (
          <Row className="mt-4 d-flex justify-content-center card p-3">
            <Col lg={12} xs={12} md={12} className="mb-4">
              <h5 className="text-left heading orange">General Details</h5>
              <Row className="gx-2">
                <Col lg={6} md={6} sm={12}>
                  <p className="text-heading">Property Type</p>
                  {data?.propertyType === 1 && (
                    <p className="text-details">Hotel</p>
                  )}
                  {data?.propertyType === 2 && (
                    <p className="text-details">Restaurant</p>
                  )}
                  {data?.propertyType === 3 && (
                    <p className="text-details">Cafe</p>
                  )}
                  {data?.propertyType === 4 && (
                    <p className="text-details">Cloud Kitchen</p>
                  )}
                  {data?.propertyType === 5 && (
                    <p className="text-details">Other</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">Contact Person Name</p>
                  {data?.contactPerson && (
                    <p className="text-details">{data?.contactPerson}</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">Website</p>
                  {data?.website && (startsWithHttp || startsWithHttps) && (
                    <Link to={data?.website} target="_blank">
                      <p className="text-details">{data?.website}</p>
                    </Link>
                  )}
                  {data?.website && !(startsWithHttp || startsWithHttps) && (
                    <Link to={`http://${data?.website}`} target="_blank">
                      <p className="text-details">{data?.website}</p>
                    </Link>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">No. of Total Employee</p>
                  {data?.employeesCount && (
                    <p className="text-details">{data?.employeesCount}</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">Year of Establishment</p>
                  {data?.establishmentYear && (
                    <p className="text-details">{data?.establishmentYear}</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">FSSAI</p>
                  {data?.fssai && <p className="text-details">{data?.fssai}</p>}
                </Col>
              </Row>

              {/* <h5 className="text-left heading mt-3 orange">Contact Details</h5>
              <Row className="gx-2">
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">
                    Mobile Number
                  </p>
                  <p className="text-details">{data?.mobile}</p>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <div className="d-flex">
                    <p className="text-heading">
                      Email 
                    </p>
                  </div>
                  <p className="text-details">
                    {data?.email}
                    {data?.emailVerified === 1 && (
                      <img
                        src={verified}
                        style={{ height: "15px", width: "15px" }}
                        alt=""
                      />
                    )}
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">
                    WhatsApp Number
                  </p>
                  <p className="text-details">{data?.whatsappNumber}</p>
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">Contact Person Number</p>
                  {data?.contactPersonMobile && (
                    <p className="text-details">{data?.contactPersonMobile}</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">Address</p>
                  {data?.addressLine1 && (
                    <p className="text-details">{data?.addressLine1}</p>
                  )}
                </Col>
                <Col lg={6} md={6} sm={12} className="mt-2">
                  <p className="text-heading">City</p>
                  {data?.cityName && (
                    <p className="text-details">{data?.cityName}</p>
                  )}
                </Col>
              </Row> */}
            </Col>
          </Row>
        )}
      </Container>
      <br />
      <br />
      <br />
    </>
  );
}

export default EmployerProfile;
