import React, { useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Container, Card, Form } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { getPaymentsKey } from "../../../../store/slices/config";
import { EmployerInitiatePayment } from "../../../../store/slices/employerplan";
import Modal2 from "../../auth_screens/Modal2";
import { RotatingLines } from "react-loader-spinner";
import success from "../../../../Assets/Images/check.png";

function EmployerCheckout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const location = useLocation();
  const [discount, setDiscount] = useState(0);
  const [adress, setAdress] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [adressError, setAdressError] = useState();
  const [supportAssist, setSupportAssist] = useState(0);
  const [key, setKey] = useState();
  const [suportAssistancPrice, setSupportAssistancePrice] = useState(0);
  const [planData, setPlanData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const planData1 = location.state?.planData || null;
  const [isloading, setIsLoading] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;

  const handleChange = (e) => {
    setAdress(e.target.value);
  };

  useEffect(() => {
    setPlanData(planData1);
    setTotalPrice(planData1.price);
  }, []);

  useEffect(() => {
    getkey();
    if (planData?.plantype === 1) {
      setSupportAssist(0);
    } else if (planData?.plantype === 2) {
      setSupportAssist(1);
    }
  }, []);

  const getkey = () => {
    dispatch(getPaymentsKey())
      .unwrap()
      .then((data) => {
        setKey(data.user.data.key);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  useEffect(() => {
    document.title = "CookandChef | Checkout";
  }, []);

  useEffect(() => {
    // Dynamically load Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const item = {
      employerPlanId: planData?.id,
      discount: discount,
      supportAssistance: supportAssist,
    };
    try {
      const response = await dispatch(EmployerInitiatePayment(item));
      const data = response.payload.user.data;
      const options = {
        key,
        amount: totalPrice,
        currency: "INR",
        name: planData?.clientPlanName,
        description: "employer plan",
        image: "",
        order_id: data.transactionNo,
        adress: adress,
        handler: async function (response) {
          const data22 = {
            transactionNo: data.transactionNo,
            transactionPaymentNo: response.razorpay_payment_id,
            transactionSignature: response.razorpay_signature,
          };
          setIsLoading(true);
          const result = await axios.put(
            "/hin/employer/employerplan/payment/confirm",
            data22,
            { headers: { "x-access-token": token } }
          );
          setModalMessage(result.data.message);
          setsuccessModal(true);
          setIsLoading(false);
          //   setTimeout(() => {
          //  navigate("/hin/employer/dashboard")
          //   }, [500]);
        },
        prefill: {
          name: user.data.fullName,
          email: user.data.email,
          contact: user.data.mobile,
          adress: adress,
        },
        theme: {
          color: "#121212",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handleCloseModal = () => {
    navigate("/hin/employer/dashboard");
  };

  const handleJobModal = () => {
    navigate("/hin/employer/post-job");
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked == true) {
      setSupportAssistancePrice(planData?.assistancePrice);
      setSupportAssist(1);
      setTotalPrice(planData.price + planData.assistancePrice);
    } else {
      setSupportAssist(0);
      setSupportAssistancePrice(0);
      setTotalPrice(planData.price);
    }
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      {isloading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RotatingLines
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="4"
            wrapperStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.175)",
              padding: "200px",
            }}
          />
        </div>
      )}

      <Modal
        style={{ left: "5%" }}
        show={successModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body align="center">
          <img src={success} alt="" style={{ height: "40px", width: "40px" }} />
          <br />
          <h4 style={{ color: "green" }}>Success!</h4>
          <h5 className="mt-2">{modalMessage}</h5>
          <br></br>
          <Button
            style={{ width: "100px" }}
            className="modalcancelBtn"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className="modalsaveBtn ms-2"
            style={{ width: "100px" }}
            onClick={handleJobModal}
          >
            Post Job
          </Button>
        </Modal.Body>
      </Modal>

      <div className="mb-5">
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/employer/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull;&nbsp;
          <Link to="/hin/employer/plans" className="bcrumb">
            प्लान
          </Link>
          &nbsp;&bull; Checkout
        </div>

        <div>
          <Row className="justify-content-left">
            <Col lg={5} md={5} className="m-2 p-3 card">
              <h6 className="p-2">Price Details</h6>

              {/* <div>
                <Form.Group>
                  <Button className="cancelBtn" style={{ width: "100%" }}>
                    Apply Coupon
                  </Button>
                </Form.Group>
              </div> */}
              <div className="align-center  m-1">
                {planData1?.plantype === 1 &&
                planData1.supportAssistance === 1 ? (
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="checkbox2"
                      checked={planData1.supportAssistance === 1}
                      label="Support Assistance (Recommended)"
                      onChange={handleCheckboxChange}
                      disabled={planData1.supportAssistance === 1}
                    />
                  </div>
                ) : planData1?.plantype === 1 &&
                  planData1.supportAssistance === 0 ? (
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="checkbox2"
                      label="Support Assistance (Recommended)"
                      onChange={handleCheckboxChange}
                    />
                  </div>
                ) : (
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id="checkbox1"
                      onChange={handleCheckboxChange}
                      checked={planData1.plantype === 2}
                      label="Support Assistance (Included)"
                      disabled={planData1.plantype === 2}
                    />
                  </div>
                )}
                <div className="d-flex justify-content-between ">
                  <div>
                    <p>Plan Price</p>
                    <p>Support Assistance</p>
                    <p>Voucher Discount</p>
                  </div>
                  <div>
                    <p>₹ {planData?.price}</p>
                    <p> ₹ {suportAssistancPrice}</p>
                    <p>₹ {discount} </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between ">
                  <p>Amount Payable</p>
                  <p>₹ {totalPrice}</p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} className="card m-2 p-3">
              <h6 className="p-2">Plan Details</h6>

              <div className="d-flex justify-content-between p-2">
                <div>
                  <p>Plan Name</p>
                  <p>Price</p>
                  <p>Validity</p>
                </div>
                <div>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    {planData?.employerPlanName}
                  </p>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    ₹ {planData?.price}
                  </p>
                  <p style={{ color: "rgba(255, 92, 9, 1)" }}>
                    {planData?.validityInDays} Days
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 p-1  justify-content-left">
            <Col lg={5} md={6} className="card p-3 m-1">
              <h6>Billing Details</h6>
              <Form.Group className="my-2">
                <Form.Control
                  className="input1"
                  name="adress"
                  onChange={handleChange}
                  type="text"
                  maxlength="100"
                  placeholder="Billing Address"
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={5} className=" p-3 m-1">
              <div className="d-flex justify-content-center mt-3 p-4">
                <Button
                  disabled={isdisabled}
                  className="saveBtn"
                  onClick={handlePayment}
                >
                  Pay Now
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EmployerCheckout;
