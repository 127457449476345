import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";
import success from "../../Assets/Images/check.png";
const SuccessEmail = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "CookandChef  | Faqs";
  }, []);

  return (
    <>
      <Container align="center">
        <div className="mt-5">
          <div className="help-lef-inner mt-3">
            <br /> <br /> <br /> <br />
            <img
              src={success}
              alt=""
              style={{ height: "50px", width: "50px" }}
            />
            <br />
            <h4 style={{ color: "green" }}>Success!</h4>
            <h5 className="mt-4">Email verified successfully!</h5>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SuccessEmail;
