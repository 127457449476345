import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import CookPlanService from "../../services/cookplan.service";
import ClientPlanService from "../../services/clientplan.service";

export const getcookplans = createAsyncThunk(
    "plan/client/getcookplan",
    async ( thunkAPI) => {
        try {
            const data = await CookPlanService.getcookPlans();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const cookinitiatePayments = createAsyncThunk(
    "plan/cook/initiatepaymens",
    async ( item,thunkAPI) => {
        try {
            const data = await CookPlanService.cookinitiatepayments(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const getcookPlans = createAsyncThunk(
    "plan/cook/getcookplan",
    async ( thunkAPI) => {
        try {
            const data = await CookPlanService.getCookPlans();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const cookPlanSlice = createSlice({
    name: "cookplan",
    initialState,
    extraReducers: {
        [getcookplans.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getcookplans.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getcookplans.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
       

        // [logout.fulfilled]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = cookPlanSlice;
export default reducer;