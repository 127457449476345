import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import ClientService from "../../services/client.service";


//get profile
export const getclientProfile = createAsyncThunk(
    "client/getprofile",
    async ( thunkAPI) => {
        try {
            const data = await ClientService.getProfile();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//edit profile
export const editClientProfile = createAsyncThunk(
    "cook/editclientprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await ClientService.editclientProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const GetEventRequestDetails = createAsyncThunk(
    "employer/getapplicationdetails",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getEventRequestDetails(id);
            return { eventapplicationdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetRequirementRequestDetails = createAsyncThunk(
    "employer/getapplicationdetails",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getRequirementRequestDetails(id);
            return { eventapplicationdetails: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




export const getclientRequirement = createAsyncThunk(
    "client/getrequirement",
    async ( thunkAPI) => {
        try {
            const data = await ClientService.getRequirement();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//edit client client requirement
export const editclientRequirement = createAsyncThunk(
    "client/editrequirement",
    async ( item,thunkAPI) => {
        try {
            const data = await ClientService.editRequirement(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editpartyProfile = createAsyncThunk(
    "client/editpartyprofile",
    async ( item,thunkAPI) => {
        try {
            const data = await ClientService.editpartyProfile(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//posting event from client
export const postEvents = createAsyncThunk(
    "client/postevents",
    async ( item,thunkAPI) => {
        try {
            const data = await ClientService.postEvents(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//edit event for client
export const editEvents = createAsyncThunk(
    "client/editevents",
    async ( item,thunkAPI) => {
        try {
            const data = await ClientService.editEvents(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getEvents = createAsyncThunk(
    "client/getevents",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getEvents(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getAllEvents = createAsyncThunk(
    "client/getevents",
    async (thunkAPI) => {
        try {
            const data = await ClientService.getAllEvents();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const FindhouseCook = createAsyncThunk(
    "client/findhousecook",
    async (item,thunkAPI) => {
        try {
            const data = await ClientService.findHouseCook(item);
            return { housecookData: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const FindPartyCook = createAsyncThunk(
    "client/findpartycook",
    async (item,thunkAPI) => {
        try {
            const data = await ClientService.findPartycook(item);
            return { partycookData: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const Findcatering = createAsyncThunk(
    "client/findcatering",
    async (item,thunkAPI) => {
        try {
            const data = await ClientService.findCatering(item);
            return { cateringData: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetCateringDetails = createAsyncThunk(
    "employer/getcateringdetails",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getCateringDetails(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetHouseCookDetails = createAsyncThunk(
    "employer/getcateringdetails",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getHouseDetails(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const GetPartyCookDetails = createAsyncThunk(
    "employer/getcateringdetails",
    async (id, thunkAPI) => {
        try {
            const data = await ClientService.getPartyCookDetails(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



//disable account for client and employer
export const DisableAccount = createAsyncThunk(
    "client/disableaccount",
    async (thunkAPI) => {
        try {
            const data = await ClientService.disableAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//enable account for client and employer
export const DeleteAccount = createAsyncThunk(
    "client/deleteaccount",
    async (item,thunkAPI) => {
        try {
            const data = await ClientService.deleteAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const cancelDelete = createAsyncThunk(
    "client/deleteaccount",
    async (thunkAPI) => {
        try {
            const data = await ClientService.canceldeleteAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//enable account
export const EnableAccount = createAsyncThunk(
    "client/enableaccount",
    async (thunkAPI) => {
        try {
            const data = await ClientService.enableAccount();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getclientPlanHistory = createAsyncThunk(
    "client/getplanhistory",
    async ( thunkAPI) => {
        try {
            const data = await ClientService.getplanHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


const initialState = {
    loading: false,
    error: "",
    housecookData :[],
    partycookData:[],
    cateringData:[],
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const clientSlice = createSlice({
    name: "client",
    initialState,
    extraReducers: {
        [getclientProfile.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getclientProfile.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getclientProfile.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [FindhouseCook.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.housecookData = action.payload;
        },
        [FindPartyCook.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.partycookData = action.payload;
        },
        [Findcatering.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.cateringData = action.payload;
        },
    },
});

const { reducer } = clientSlice;
export default reducer;