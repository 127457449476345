import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

function CateringProfile() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        Home &bull; कैटरिंग प्रोफ़ाइल
      </div>
      <Row className="m-1 d-flex justify-content-center card p-3">
        <Row>
          <Col xs={12} md={12} lg={12} align="right">
            <Link
              to="/hin/cook/edit-catering-profile"
              className="forgot_password"
            >
              <FaEdit /> Edit
            </Link>
          </Col>

          <Col lg={12} xs={12} className="ps-5">
            <p className="text-heading">ज्ञात व्यंजन</p>
            <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
              <Col
                xs={5}
                sm={5}
                md={3}
                lg={2}
                xl={2}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  color: "black",
                  padding: "4px 8px",
                  margin: "8px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontSize: "14px",
                  marginRight: "4px",
                  border: "solid 1px #ff5c09",
                }}
              >
                North Indian
              </Col>
              <Col
                xs={5}
                md={3}
                sm={5}
                lg={2}
                xl={2}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  color: "black",
                  padding: "4px 8px",
                  margin: "8px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontSize: "14px",
                  marginRight: "4px",
                  border: "solid 1px #ff5c09",
                }}
              >
                Chinese
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6} className="ps-5 mt-3">
            <p className="text-heading">Food Type</p>
            <p className="text-details">pure veg</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="ps-5 mt-3">
            <p className="text-heading">Min. Plates</p>
            <p className="text-details">100</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="ps-5 mt-3">
            <p className="text-heading">FSSAI</p>
            <p className="text-details">50</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="ps-5 mt-3">
            <p className="text-heading">Website</p>
            <p className="text-details">www.cnf.in</p>
          </Col>
          <Col xs={12} md={6} lg={6} className="ps-5 mt-3">
            <p className="text-heading">Team Size</p>
            <p className="text-details">50</p>
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default CateringProfile;
