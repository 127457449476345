import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsBriefcase } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import {
  EmployerApplicationStatus,
  getemployerjobApplications,
} from "../../../../store/slices/employerprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import "../employer.css";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import chef from "../../../../Assets/Images/cook.png";
import newtag from "../../../../Assets/Images/new.png";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function JobApplications() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const [successModal, setsuccessModal] = useState(false);
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
    jobId: id,
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getchefs();
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Job Applications";
  }, []);

  const getchefs = () => {
    dispatch(getemployerjobApplications(item))
      .unwrap()
      .then((data) => {
        setData(data?.jobapplication?.data);
        setTotaldata(data?.jobapplication?.totalDataCount);
      })
      .catch(({ message }) => {});
  };

  const SetApplicationStatus = (a, b) => {
    const item = {
      id: a._id,
      applicationStatus: b,
    };
    dispatch(EmployerApplicationStatus(item))
      .unwrap()
      .then((data) => {
        setModalMessage(data?.applicationstatus?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getchefs();
      })
      .catch(({ message }) => {});
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/employer/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/employer/manage-jobs" className="bcrumb">
          नौकरी
        </Link>
        &nbsp;&bull; ऍप्लिकेशन्स
      </div>

      {totaldata === 0 && (
        <Row className="card mt-4 p-5">
          <Col lg={12} xs={12} md={12} align="center">
            <h3>No applications to show!</h3>
          </Col>
        </Row>
      )}
      {totaldata !== 0 && (
        <div>
          <Row className="mt-4 justify-items-center align-items-center">
            <Row>
              <Col lg={9} sm={12} xs={12} md={12}>
                {data?.map((item, index) => (
                  <Row
                    className="d-flex justify-items-center align-items-center mb-2"
                    key={index}
                    style={{
                      backgroundColor: "white",

                      borderRadius: "15px",
                      padding: "8px",
                    }}
                  >
                    <Col
                      lg={2}
                      md={2}
                      xs={3}
                      className="justify-content-center"
                    >
                      {item?.isViewed === false && (
                        <img
                          src={newtag}
                          alt=""
                          style={{ marginTop: "-20px", marginLeft: "-28px" }}
                        />
                      )}
                      {item?.cooksData?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={item.cooksData.dp}
                          alt="user profile"
                        />
                      )}
                      {!item?.cooksData?.dp && (
                        <img
                          className="align-self-center"
                          style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "80px",
                          }}
                          src={chef}
                          alt="user profile"
                        />
                      )}
                    </Col>
                    <Col lg={9} xs={7} md={8} className="ps-3">
                      <h6
                        style={{
                          fontWeight: "600",
                          marginBottom: "0.2rem",
                        }}
                        className="ms-3"
                      >
                        {item?.cooksData?.fullName}
                      </h6>

                      {item?.cooksData?.chefCuisines?.length >= 3 ? (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.cooksData?.chefCuisines
                            ?.join(", ")
                            .substring(0, 20)}
                          ...
                        </p>
                      ) : (
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.cooksData?.chefCuisines?.join(", ")}
                        </p>
                      )}

                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <GrLocation style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cooksData?.currentCityName}
                      </p>
                      <p
                        style={{
                          marginBottom: "0.25rem",
                          fontSize: "14px",
                        }}
                        className="ms-3"
                      >
                        <BsBriefcase style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cooksData?.chefExperience} Years
                        <MdCurrencyRupee className="ms-3" />
                        {item?.cooksData?.expectedSalary}
                      </p>
                    </Col>
                    <Col lg={1} xs={2} md={2} align="right">
                      <Dropdown
                        style={{
                          marginTop: "-50px",
                          background: "none",
                        }}
                      >
                        <Dropdown.Toggle
                          // id="dropdown-basic"
                          style={{
                            width: "25px",
                            height: "25px",
                            background: "none",
                            border: "none",
                          }}
                        >
                          <BiDotsVerticalRounded
                            style={{
                              marginTop: "-20px",
                              fontSize: "20px",
                              marginLeft: "-10px",
                              color: "#000",
                            }}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              SetApplicationStatus(item, "shortlisted")
                            }
                          >
                            Shortlisted
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => SetApplicationStatus(item, "hired")}
                          >
                            Hired
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              SetApplicationStatus(item, "on Hold")
                            }
                          >
                            On Hold
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              SetApplicationStatus(item, "rejected")
                            }
                          >
                            Rejected
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col lg={3} xs={6} md={3}>
                      <p style={{ fontSize: "11px", color: "#8F8686" }}>
                        Applied on&nbsp;
                        {Moment.utc(item.appliedAt).format("DD-MM-YYYY")}
                      </p>
                    </Col>
                    <Col lg={7} xs={3} md={7}>
                      <div align="right">
                        {item?.applicationStatus === "applied" && (
                          <p
                            style={{
                              color: "#929402",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#FEFFD3",
                            }}
                            align="center"
                          >
                            Applied
                          </p>
                        )}
                        {item?.applicationStatus === "on Hold" && (
                          <p
                            style={{
                              color: "#000000",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#FFAF03",
                            }}
                            align="center"
                          >
                            On Hold
                          </p>
                        )}
                        {item?.applicationStatus === "shortlisted" && (
                          <p
                            style={{
                              color: "#0700F5",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "rgb(209 235 250)",
                            }}
                            align="center"
                          >
                            Shortlisted
                          </p>
                        )}
                        {item?.applicationStatus === "rejected" && (
                          <p
                            style={{
                              color: "#FF0606",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#FFDDD5",
                            }}
                            align="center"
                          >
                            Rejected
                          </p>
                        )}
                        {item?.applicationStatus === "hired" && (
                          <p
                            style={{
                              color: "#028D36",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "70px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#e7f8c6",
                            }}
                            align="center"
                          >
                            Hired
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={2} xs={3} md={2}>
                      <div
                        align="left"
                        style={{ marginTop: "-20px", marginLeft: "-15px" }}
                      >
                        <Link
                          to={`/hin/employer/application-details/${item._id}`}
                          style={{ color: "#000" }}
                        >
                          <Button className="view_profile">इसे देखें</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Row>
        </div>
      )}
      {totaldata / itemsPerPage > 1 && (
        <div className="justify-content-right align-right">
          <ReactPaginate
            key={currentPage}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakLinkClassName={"page-link"}
            pageCount={Math.ceil(totaldata / itemsPerPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={pagesToShowInitially}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            disabledClassName="disabled"
            initialPage={currentPage - 1}
          />
        </div>
      )}
    </>
  );
}

export default JobApplications;
