import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "./Helpchat.css";
import Contact from "./Contact";

const EmployerSupport = () => {
  const [openCollapse, setOpenCollapse] = useState({
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
  });
  const handleCollapseToggle = (collapseKey) => {
    setOpenCollapse((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === collapseKey ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };

  return (
    <div className="ps-2">
      <div>
        <p style={{ fontWeight: "500", color: "blue" }}>
          नमस्कार, हम यहां आपकी मदद के लिए हैं
        </p>
        <p style={{ color: "#ff5c09" }}>सुझाव :</p>
        <ul style={{ fontSize: "12px", lineHeight: "20px" }}>
          <li>
            जॉब पोस्ट करें, सभी जानकारी सटीक रहें: विस्तृत और सटीक विवरण के साथ
            जॉब पोस्ट करें जिसमें पदनाम, जिम्मेदारियां, योग्यताएं, वेतन सीमा आदि
            शामिल हों।
          </li>
          <li>
            आवेदनों की नियमित जांच करें, अन्यथा प्रतियोगी बेहतर उम्मीदवारों को
            नियुक्त कर लेंगे: आवेदनों की नियमित जांच करें और जल्द से जल्द योग्य
            व्यक्तियों से संपर्क करें। जितना ज्यादा देर करेंगे, आपके
            प्रतिस्पर्धी बेहतर उम्मीदवारों को आकर्षित कर लेंगे।
          </li>
          <li>
            आवेदनों पर अनुवर्ती करें, शेफ से सीधे संपर्क करें, इससे पहले कि वे
            किसी और के पास चले जाएँ: आवेदन जमा करने के बाद उम्मीदवारों से संपर्क
            करें और उनकी रुचि का पता लगाएं। यदि कोई उम्मीदवार आपको प्रभावित करता
            है, तो जल्द से जल्द उनसे संपर्क करें और उन्हें अन्य अवसरों पर विचार
            करने से पहले अपनी कंपनी के बारे में बताएं।
          </li>
          <li>
            अपनी प्रोफ़ाइल को 100% अपडेट करें: अपनी प्रोफ़ाइल को अपडेट करें ताकि
            यह आपके हाल के अनुभव और कौशल को सटीक रूप से दर्शाए। सुनिश्चित करें
            कि आपकी संपर्क जानकारी सही है।
          </li>
        </ul>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open1")}
          aria-controls="example-fade-text"
          aria-expanded={openCollapse.open1}
          className="help"
        >
          जॉब्स
        </p>

        <Collapse in={openCollapse.open1}>
          <div id="example-fade-text">
            <p style={{ fontSize: "12px" }}>
              आप यहां नौकरी पोस्ट कर सकते हैं और संबंधित शेफ से जवाब प्राप्त कर
              सकते हैं, जिससे आपकी हायरिंग और तेज हो जाएगी।
            </p>
            <Link
              to="/hin/employer/post-job"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              जॉब पोस्ट करें
            </Link>
            <Link
              to="/hin/employer/manage-jobs"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              नौकरी प्रबंधित करें
            </Link>
            <Link
              to="/hin/employer/applications"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              आवेदन
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open2")}
          aria-controls="example-fade-text1"
          aria-expanded={openCollapse.open2}
          className="mt-3 help"
        >
          शेफ
        </p>
        <Collapse in={openCollapse.open2}>
          <div id="example-fade-text1">
            <p style={{ fontSize: "12px" }}>
              <p style={{ fontSize: "12px", fontWeight: "500" }}>
                शेफ कैसे खोजें?
              </p>
              यदि आपकी योजना सक्रिय है, तो आप अपनी जरूरतों के आधार पर शेफ ढूंढ
              सकते हैं।
            </p>
            <Link
              to="/hin/employer/find-chef"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              शेफ खोजें
            </Link>
            <Link
              to="/hin/employer/shortlisted-chefs"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              चुनी हुई प्रोफाइल
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open3")}
          aria-controls="example-fade-text2"
          aria-expanded={openCollapse.open3}
          className="mt-3 help"
        >
          योजनाएं
        </p>
        <Collapse in={openCollapse.open3}>
          <div id="example-fade-text2">
            <p style={{ fontSize: "12px" }}>
              सक्रिय योजना बनाए रखें: प्रासंगिक रसोइयों से अधिक प्रतिक्रियाएँ
              प्राप्त करने के लिए अपनी योजना सक्रिय रखें। अधिक रसोइया प्रोफाइल
              देखने और हमारी टीम से सहायता प्राप्त करने के लिए आपको एक योजना
              खरीदनी होगी:
            </p>
            <Link
              to="/hin/employer/plans"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              योजना खरीदें
            </Link>
            <Link
              to="/hin/employer/plan-history"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              योजना इतिहास
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open4")}
          aria-controls="example-fade-text3"
          aria-expanded={openCollapse.open4}
          className="mt-3 help"
        >
          संपर्क सेटिंग्स
        </p>
        <Collapse in={openCollapse.open4}>
          <div id="example-fade-text3">
            <Link
              to="/hin/employer/profile"
              style={{
                display: "block",

                fontSize: "14px",
              }}
            >
              व्हाट्सऐप जोड़ें
            </Link>
            <Link
              to="/hin/employer/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              मोबाइल नंबर बदलें
            </Link>
            <Link
              to="/hin/employer/profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              ईमेल बदलें
            </Link>
            <Link
              to="/hin/employer/settings"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              संपर्क गोपनीयता
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open5")}
          aria-controls="example-fade-text4"
          aria-expanded={openCollapse.open5}
          className="mt-3 help"
        >
          प्रोफाइल
        </p>
        <Collapse in={openCollapse.open5}>
          <div id="example-fade-text4">
            <p style={{ fontSize: "12px" }}>
              अपने पेशा की प्रोफ़ाइल को अपडेट करें ताकि उम्मीदवारों को आपके पेशा
              के बारे में अधिक जानकारी मिल सके और यह आपकी ब्रांडिंग में भी आपकी
              मदद करेगा।
            </p>
            <Link
              to="/hin/employer/edit-profile"
              style={{
                display: "block",
                marginTop: "8px",
                fontSize: "14px",
              }}
            >
              अपडेट प्रोफाइल
            </Link>
          </div>
        </Collapse>

        <p
          style={{ fontSize: "14px", fontWeight: "500" }}
          onClick={() => handleCollapseToggle("open6")}
          aria-controls="example-fade-text5"
          aria-expanded={openCollapse.open6}
          className="mt-3 help"
        >
          हमसे संपर्क करें
        </p>
        <Collapse in={openCollapse.open6}>
          <div id="example-fade-text4">
            <Contact />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default EmployerSupport;
