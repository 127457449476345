import axios from "axios";
import {
  API_PATHS,
} from "../utils/constants/api.constants";

class CookPlanService {
  static getcookPlans() {
    let api = "";
    api = API_PATHS.getcookplan;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


//initiate payments service
  static cookinitiatepayments(item) {
    let api = "";
    api = API_PATHS.cookinitiatepayments;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .post(api,item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getCookPlans() {
    let api = "";
    api = API_PATHS.getcookPlans;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
      .get(api, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  
  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default CookPlanService;
