import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { contactPrivacy } from "../../../../store/slices/auth";
import { getclientProfile } from "../../../../store/slices/clientprofile";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function ClientContactPrivacy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [smsContact, setsmsContact] = useState();
  const [whatsappContact, setwhatsappContact] = useState();
  const [chatContact, setchatContact] = useState();
  const [showModal, setShowModal] = useState(false);
  const [successShowModal, setsuccessShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [notificationStatus, setnotificationStatus] = useState();
  const [isdisabled, setisDisabled] = useState(false);
  const [data, setData] = useState({
    smsContact: 0,
    whatsappContact: 0,
    notificationStatus: 0,
    chatContact: 0,
  });
  React.useEffect(() => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        setData(data.user.data);
        setsmsContact(data.user.data.smsContact);
        setwhatsappContact(data.user.data.whatsappContact);
        setchatContact(data.user.data.chatContact);
        setnotificationStatus(data.user.data.notificationStatus);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Contact Privacy";
  }, []);

  const handleToggle = (value) => {
    if (value === "smsContact") {
      setData({
        ...data,
        smsContact: data.smsContact === 1 ? 0 : 1,
      });
    }
    if (value === "whatsappContact") {
      setData({
        ...data,
        whatsappContact: data.whatsappContact === 1 ? 0 : 1,
      });
    }
    if (value === "notificationStatus") {
      setData({
        ...data,
        notificationStatus: data.notificationStatus === 1 ? 0 : 1,
      });
    }
    if (value === "chatContact") {
      setData({
        ...data,
        chatContact: data.chatContact === 1 ? 0 : 1,
      });
    }
  };
  const update = () => {
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (
      data.smsContact === 0 &&
      data.chatContact === 0 &&
      data.whatsappContact === 0
    ) {
      setModalMessage("कृपया कम से कम एक विकल्प चयन कीजिए");
      setShowModal(true);
    } else {
      dispatch(contactPrivacy(data))
        .unwrap()
        .then((data) => {
          setModalMessage(data?.user?.message);
          setsuccessShowModal(true);
          setTimeout(() => {
            navigate("/hin/client/settings");
            setsuccessShowModal(false);
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successShowModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessShowModal}
      />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="card text-center p-3">
            <div className="d-flex justify-content-between p-2">
              <div className="text-start">
                <b className="mb-1">कांटेक्ट नंबर</b>
                <p className="mb-1" style={{ textWrap: "wrap" }}>
                  कैंडिडेट के लिए कांटेक्ट नंबर छुपाएं या प्रदर्शित करें
                </p>
              </div>
              <div className="mt-2">
                <Form>
                  <Form.Check
                    type="switch"
                    id="contact-number"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={data?.smsContact === 1}
                    onChange={() => handleToggle("smsContact")}
                  />
                </Form>
              </div>
            </div>
            <hr className="mt-0"></hr>

            <div className="d-flex justify-content-between p-2">
              <div className="text-start">
                <b className="mb-1">व्हाट्सएप नंबर</b>
                <p className="mb-1" style={{ textWrap: "wrap" }}>
                  कैंडिडेट के लिए व्हाट्सएप नंबर प्रदर्शित करें या छुपाएं
                </p>
              </div>
              <div className="mt-2">
                <Form>
                  <Form.Check
                    type="switch"
                    id="whatsapp-number"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={data?.whatsappContact === 1}
                    onChange={() => handleToggle("whatsappContact")}
                  />
                </Form>
              </div>
            </div>
            <hr className="mt-0"></hr>
            <div className="d-flex justify-content-between p-2">
              <div className="text-start">
                <b className="mb-1">चैट विकल्प</b>
                <p className="mb-1" style={{ textWrap: "wrap" }}>
                  कैंडिडेट के लिए चैट सुविधा प्रदर्शित करें या छुपाएं
                </p>
              </div>
              <div className="mt-2">
                <Form>
                  <Form.Check
                    type="switch"
                    id="chat-contact"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={data?.chatContact === 1}
                    onChange={() => handleToggle("chatContact")}
                  />
                </Form>
              </div>
            </div>
            <hr className="mt-0"></hr>
            <div className="d-flex justify-content-between p-2">
              <div className="text-start">
                <b className="mb-1">सूचनाएं</b>
                <p className="mb-1" style={{ textWrap: "wrap" }}>
                  नोटिफिकेशन सक्षम या अक्षम करें
                </p>
              </div>
              <div className="mt-2">
                <Form>
                  <Form.Check
                    type="switch"
                    id="notification-status"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={data?.notificationStatus === 1}
                    onChange={() => handleToggle("notificationStatus")}
                  />
                </Form>
              </div>
            </div>
            <hr className="mt-1"></hr>
            <div className="mb-3 mt-3">
              <Button
                onClick={update}
                disabled={isdisabled}
                className="saveBtn"
              >
                अपडेट
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ClientContactPrivacy;
