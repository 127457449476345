import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import CookSideMenu from "./CookSideMenu";
import CookDashHome from "./pages/CookDashHome";
import CookProfile from "./CookProfile";
import EditCookProfile from "./pages/EditCookProfile";
import DashFooter from "../nav_bar/dashboardfooter";
import EditParty from "./pages/EditParty";
import EditChef from "./pages/EditChef";
import EditHouse from "./pages/EditHouse";
import EditCatering from "./pages/EditCatering";
import CateringProfile from "./CateringProfile";
import CookNavBar from "./CookNavBar";
import Profile from "./Profile";
import CookPlans from "./pages/cookplan";
import CookCheckout from "./pages/cookCheckout";
import FindJobs from "./pages/findjobs";
import AppliedJobs from "./pages/appliedjobs";
import FindEvents from "./pages/FindEvents";
import CookPlanHistory from "./pages/cookplanhistory";
import JobDetails from "./pages/jobdetails";
import CookChangepassword from "./pages/CookChangePassword";
import CookContactPrivacy from "./pages/CookContactPrivacy";
import EventDetails from "./pages/eventdetails";
import AppliedEvents from "./pages/appliedevents";
import FindHouseJob from "./pages/FindHousejobs";
import CookSettings from "./pages/CookSettings";
import HouseJobDetails from "./pages/houseJobDetails";
import AppliedHousejobs from "./pages/appliedhousejobs";
import ResumeBuilder from "./pages/resumeBuilder";

import ChatSupport from "./pages/ChatSupport";
import ChatBot from "../../utils/helpers/ChatBot/HelpChatSupport";
import ShortListedJobs from "./pages/ShortListedJobs";
import ShortListedEvents from "./pages/ShortListedEvents";
import ShortListedHouseJobs from "./pages/ShortListedHouseJobs";
import EditCateringProfile from "./pages/edit-catering-profile";
import SavedJobDetails from "./pages/savedjobdetails";
import AppliedJobDetails from "./pages/appliedjobdetails";
import AppliedEventDetails from "./pages/appliedeventdetails";
import SavedEventDetails from "./pages/savedeventdetails";
import AppliedHouseJobDetails from "./pages/appliedhousejobdetails";
import SavedHouseJobDetails from "./pages/savedhousejobdetails";
import EmployerErrorPage from "../employer/404";
import CookResponse from "./pages/cookResponse";
import EmployerProfile from "./pages/EmployerProfile";

function CookDashboard() {
  return (
    <>
      <CookNavBar />

      <Row className="d-flex justify-content-center p-2">
        <Col lg={2} md={3} xl={2} xxl={2} className="d-none d-lg-block dashbar">
          <CookSideMenu />
        </Col>
        <Col xs={11} md={9} lg={7} className="mx-3 pb-5">
          <Routes>
            <Route path="/dashboard" element={<CookDashHome />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/editcateringprofile"
              element={<EditCateringProfile />}
            />
            <Route path="/edit-cook-profile" element={<EditCookProfile />} />
            <Route path="/edit-party-profile" element={<EditParty />} />
            <Route path="/edit-chef-profile" element={<EditChef />} />
            <Route path="/edit-house-profile" element={<EditHouse />} />
            <Route path="/edit-catering-profile" element={<EditCatering />} />
            <Route path="/short-listed-jobs" element={<ShortListedJobs />} />
            <Route
              path="/short-listed-events"
              element={<ShortListedEvents />}
            />
            <Route
              path="/short-listed-housejobs"
              element={<ShortListedHouseJobs />}
            />
            <Route path="/cook-profile" element={<CookProfile />} />
            <Route path="/employer-profile/:id" element={<EmployerProfile />} />
            <Route path="/catering-profile" element={<CateringProfile />} />
            <Route path="/checkout/:id" element={<CookCheckout />} />
            <Route path="/plans" element={<CookPlans />} />
            <Route path="/jobs" element={<FindJobs />} />
            <Route path="/response" element={<CookResponse />} />
            <Route path="/housejobs" element={<FindHouseJob />} />
            <Route path="/plan-history" element={<CookPlanHistory />} />
            <Route path="/applied-jobs" element={<AppliedJobs />} />
            <Route path="/applied-house-jobs" element={<AppliedHousejobs />} />
            <Route path="/applied-events" element={<AppliedEvents />} />
            <Route path="/find-events" element={<FindEvents />} />
            <Route path="/change-password" element={<CookChangepassword />} />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route
              path="/saved-job-details/:id"
              element={<SavedJobDetails />}
            />
            <Route
              path="/applied-job-details/:id"
              element={<AppliedJobDetails />}
            />
            <Route path="/event-details/:id" element={<EventDetails />} />
            <Route
              path="/saved-event-details/:id"
              element={<SavedEventDetails />}
            />
            <Route
              path="/applied-event-details/:id"
              element={<AppliedEventDetails />}
            />

            <Route path="/resume-builder" element={<ResumeBuilder />} />
            <Route path="/message" element={<ChatSupport />} />
            <Route
              path="/house-job-details/:id"
              element={<HouseJobDetails />}
            />
            <Route
              path="/applied-house-job-details/:id"
              element={<AppliedHouseJobDetails />}
            />
            <Route
              path="/saved-house-job-details/:id"
              element={<SavedHouseJobDetails />}
            />
            <Route path="/contact-privacy" element={<CookContactPrivacy />} />
            <Route path="/settings" element={<CookSettings />} />
            <Route path="*" element={<EmployerErrorPage />} />
          </Routes>
        </Col>
      </Row>
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: 30,
          right: 30,
        }}
      >
        <ChatBot />
      </div>
      <DashFooter />
    </>
  );
}

export default CookDashboard;
