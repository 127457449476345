import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getclientRequirement,
  editclientRequirement,
} from "../../../../store/slices/clientprofile";
import Modal2 from "../../auth_screens/Modal2";
import DataSkeleton from "../../auth_screens/dataskeleton";
import SuccessModal from "../../auth_screens/SuccessModal";
function ClientRequirement() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [cuisines, setcuisines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [successModal, setsuccessModal] = useState(false);
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Requirement";
  }, []);

  React.useEffect(() => {
    getRequirementDetails();
  }, [dispatch]);
  const getRequirementDetails = () => {
    dispatch(getclientRequirement())
      .unwrap()
      .then((data) => {
        setData(data?.user?.data[0]);
        setcuisines(data?.user?.data[0]?.cuisines);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const enableJob = (id) => {
    const elem = {
      status: 1,
      cuisines: data.cuisines,
      jobType: parseInt(data.jobType),
      minimumPayment: data.minimumPayment,
      urgency: data.urgency || undefined,
      breakfast: parseInt(data.breakfast) || 0,
      lunch: parseInt(data.lunch) || 0,
      dinner: parseInt(data.dinner) || 0,
      vesselWash: parseInt(data.vesselWash),
      preferredGender: parseInt(data.preferredGender) || undefined,
    };
    dispatch(editclientRequirement(elem))
      .then((data) => {
        setModalMessage("Requirement enabled successfully!");
        setsuccessModal(true);
        getRequirementDetails();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const disableJob = (id) => {
    const elem = {
      status: 2,
      cuisines: data.cuisines,
      jobType: parseInt(data.jobType),
      minimumPayment: data.minimumPayment,
      urgency: data.urgency || undefined,
      breakfast: parseInt(data.breakfast) || 0,
      lunch: parseInt(data.lunch) || 0,
      dinner: parseInt(data.dinner) || 0,
      vesselWash: parseInt(data.vesselWash),
      preferredGender: parseInt(data.preferredGender) || undefined,
    };

    dispatch(editclientRequirement(elem))
      .then((data) => {
        setModalMessage("Requirement disabled successfully!");
        setsuccessModal(true);
        getRequirementDetails();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/client/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull; रेक्विरेमेंट विवरण
      </div>
      {isLoading ? (
        <Row className="m-1 justify-content-center card">{DataSkeleton()}</Row>
      ) : (
        <Row className="m-1 justify-content-center card">
          <div className="card-header" style={{ background: "#fa7734" }}>
            <Row>
              <Col xs={6} md={6} lg={6}>
                <h5 style={{ color: "#fff" }}>रेक्विरेमेंट विवरण</h5>
              </Col>
              <Col xs={6} md={6} lg={6} align="right">
                {data?.status !== 2 && (
                  <Link
                    to="/hin/client/edit-requirement"
                    style={{ color: "#fff" }}
                  >
                    <FaEdit /> एडिट कीजिये
                  </Link>
                )}
              </Col>
            </Row>
          </div>
          <Col lg={12} xs={12} md={12} className="mb-2 ps-4 mt-3">
            <Row className="gx-2">
              <Col lg={11} xs={10} md={11} align="right" className="mt-4">
                {data?.status === 1 && (
                  <span
                    style={{
                      color: "#07A64D",
                      fontSize: "14px",
                      padding: "5px 12px",
                      borderRadius: "5px",
                      width: "80px",
                      background: "#E3FCEE",
                      marginRight: "-20px",
                    }}
                  >
                    Active
                  </span>
                )}
                {data?.status === 2 && (
                  <span
                    style={{
                      color: "#B0A111",
                      marginRight: "-20px",
                      fontSize: "14px",
                      padding: "5px 7px",
                      borderRadius: "5px",
                      width: "80px",
                      background: "#F9EE8A",
                    }}
                  >
                    Disabled
                  </span>
                )}
              </Col>
              <Col lg={1} xs={2} md={1} align="right">
                {(data?.status === 1 || data?.status === 2) && (
                  <Dropdown
                    style={{
                      marginTop: "-40px",
                      background: "none",
                    }}
                  >
                    <Dropdown.Toggle
                      // id="dropdown-basic"
                      style={{
                        width: "25px",
                        height: "25px",
                        background: "none",
                        border: "none",
                      }}
                    >
                      <BiDotsVerticalRounded
                        style={{
                          marginTop: "60px",
                          fontSize: "20px",
                          marginLeft: "-20px",
                          color: "#000",
                        }}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {data?.status === 1 && (
                        <Dropdown.Item onClick={() => disableJob()}>
                          Pause Requirement
                        </Dropdown.Item>
                      )}
                      {data?.status === 2 && (
                        <Dropdown.Item onClick={() => enableJob()}>
                          Enable Requirement
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Col>
              <Col lg={5} sm={12} md={5} className="ms-3">
                <p className="text-heading">जॉब का प्रकार</p>
                {data?.jobType === 1 && (
                  <p className="text-details">Part Time</p>
                )}
                {data?.jobType === 2 && (
                  <p className="text-details">Full Time</p>
                )}
                {data?.jobType === 3 && <p className="text-details">Any</p>}
              </Col>
              <Col lg={5} sm={12} md={5} className="ms-3">
                <p className="text-heading">पसंदीदा लिंग</p>
                {data?.preferredGender === 1 && (
                  <p className="text-details">पुरुष</p>
                )}
                {data?.preferredGender === 2 && (
                  <p className="text-details">महिला</p>
                )}
                {data?.preferredGender === 3 && (
                  <p className="text-details">Any</p>
                )}
              </Col>

              <Col lg={5} sm={12} className="mt-2 p-1 ms-3">
                <p className="text-heading">व्यंजने</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {cuisines?.map((partyplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {partyplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={5} sm={12} md={5} className="mt-2 ms-3">
                <p className="text-heading">भोजन का प्रकार</p>
                <Row
                  style={{
                    marginTop: "-15px",
                    marginLeft: "0px",
                  }}
                >
                  {data?.breakfast === 1 && (
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={3}
                      xl={3}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      Breakfast
                    </Col>
                  )}
                  {data?.lunch === 1 && (
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={3}
                      xl={3}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      Lunch
                    </Col>
                  )}
                  {data?.dinner === 1 && (
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={3}
                      xl={3}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      Dinner
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            <Row className="gx-2">
              <Col lg={5} md={5} sm={12} className="mt-2 ms-3 ">
                <p className="text-heading">कम से कम पेमेंट</p>
                {data?.minimumPayment && (
                  <p className="text-details">{data?.minimumPayment}</p>
                )}
              </Col>
              <Col lg={5} sm={12} className="mt-2 ms-3">
                <p className="text-heading">तात्कालिकता</p>
                {data?.urgency && (
                  <p className="text-details">{data?.urgency}</p>
                )}
              </Col>

              <Col lg={5} sm={12} className="mt-2 ms-3">
                <p className="text-heading">बर्तन धोना</p>

                {data?.vesselWash === 1 && <p className="text-details">Yes</p>}
                {data?.vesselWash === 0 && <p className="text-details">No</p>}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ClientRequirement;
