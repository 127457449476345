import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getSharedProfiles } from "../../../store/slices/employerprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { MdCurrencyRupee } from "react-icons/md";

import { Link } from "react-router-dom";
import chef from "../../../Assets/Images/cook.png";
import newtag from "../../../Assets/Images/new.png";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import Skeleton from "react-loading-skeleton";

function GetSharedProfile() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const [alldata, setAlldata] = useState([]);
  const [alldataCount, setAlldatacount] = useState();
  const [sortVal, setSortVal] = useState();
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 6;
  const pagesToShowInitially = 2;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  useEffect(() => {
    getchefs(item);
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Shared Profiles";
  }, []);

  let pageq = parseInt(search.get("page"));

  const getchefs = (item) => {
    setisLoading(false);
    dispatch(getSharedProfiles(item))
      .unwrap()
      .then((data) => {
        setAlldata(data?.sharedprofiles?.data);
        setTotaldata(data?.sharedprofiles?.totalDataCount);
        setData(data?.sharedprofiles?.data);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setTimeout(() => {
          setisLoading(true);
        }, 1000);
      });
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      limit: itemsPerPage,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setsuccessModal={setsuccessModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/employer/dashboard" className="bcrumb">
            Home
          </Link>
          &nbsp;&bull; Shared by Asst
        </div>

        <Row className="mt-4 ps-3">
          {totaldata === 0 ? (
            <Row className="">
              <Col lg={10} xs={12} md={12} align="center" className="card p-5">
                <b>No shared profiles to be shown.</b>
                <br />
                <p align="left" style={{ color: "grey" }}>
                  Please make sure:
                  <ul>
                    <li>
                      If you have an active plan with support assistance
                      feature.
                    </li>
                    <li>Your job has been posted and it is active.</li>

                    <li>Your job benefits are competitive enough.</li>
                  </ul>
                  If all of the above are checked, please be patient for 3-5
                  working days, profiles are coming.
                  <br /> For any queries, contact support team
                </p>
              </Col>
            </Row>
          ) : isLoading ? (
            <Col lg={9} sm={12} xs={12} md={12}>
              <Row>
                {data?.map((item, index) => {
                  return (
                    <Row
                      className="d-flex justify-items-center align-items-center mb-2"
                      key={index}
                      style={{
                        backgroundColor: "white",
                        // height: "20dvh",
                        // width: "100%",
                        borderRadius: "15px",
                        padding: "8px",
                      }}
                    >
                      <Col lg={2} xs={3} className="justify-content-center">
                        {item?.cookId?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={item?.cookId?.dp}
                            alt="user profile"
                          />
                        )}
                        {!item?.cookId?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "80px",
                            }}
                            src={chef}
                            alt="user profile"
                          />
                        )}
                      </Col>
                      <Col lg={9} xs={7}>
                        <h6
                          style={{
                            fontWeight: "600",
                            marginBottom: "0.2rem",
                          }}
                          className="ms-3"
                        >
                          {item?.cookId?.fullName}
                        </h6>

                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <GrLocation style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.cookId?.cityName}
                        </p>
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                            color: "#ff5c09",
                          }}
                          className="ms-3"
                        >
                          <span>{item?.jobId?.designation}</span>
                        </p>
                      </Col>
                      <Col xs={2} md={1} lg={1} align="right"></Col>
                      <Col lg={3} xs={6} md={3}></Col>
                      <Col lg={7} xs={3} md={7}>
                        <div align="right"></div>
                      </Col>
                      <Col lg={2} xs={3} md={2}>
                        <div
                          align="left"
                          style={{ marginTop: "-20px", marginLeft: "-15px" }}
                        >
                          <Link
                            to={`/employer/shared-profile-details/${item?.cookId?.id}`}
                            style={{ color: "#000" }}
                            target="_blank"
                          >
                            <Button className="view_profile">View</Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
        </Row>
        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default GetSharedProfile;
