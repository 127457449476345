import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import login_banner from "../../../Assets/Images/employerlogin.png";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "../nav_bar/Footer";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import { employerlogin } from "../../../store/slices/auth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modal2 from "./Modal2";
import Fingerprint2 from "fingerprintjs2";

function EmployerLogin() {
  const [emailOrMobile, setemailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);

  const [mac, setMac] = useState();

  useEffect(() => {
    document.title = "CookandChef | Employer Login";
  }, []);

  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);
      setMac(fingerprint);
    });
  }, []);
  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;

    switch (name) {
      case "emailOrMobile":
        setemailOrMobile(value);
        validationErrors[name] =
          value.trim() === "" ? "अपना ईमेल दर्ज करें or मोबाइल नंबर" : "";
        if (value.trim() !== "") {
          validationErrors[name] = "";
        }
        break;

      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" ? "Please enter your password" : "";
        if (value.trim() !== "") {
          validationErrors[name] = "";
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  async function signIn(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!emailOrMobile.trim()) {
      validationErrors.emailOrMobile = "अपना ईमेल दर्ज करें or मोबाइल नंबर";
    }

    if (!password.trim()) {
      validationErrors.password = "Please enter your password";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const loginIP = "0.0.0.0";
    let loginMAC = mac;
    let webAccess = 1;

    let item = {
      emailOrMobile,
      password,
      loginIP,
      loginMAC,
      webAccess,
    };

    dispatch(employerlogin(item))
      .unwrap()
      .then((data) => {
        localStorage.removeItem("role");
        if (data.user.data.role === "employer") {
          const deeplinkpage = localStorage.getItem("deeplinkpage");
          if (deeplinkpage === "find-chef") {
            navigate("/employer/find-chef");
            return;
          } else {
            const applicationsurl = localStorage.getItem("applicationUrl");
            // If from is present and it's an application details page, navigate to it
            if (applicationsurl?.includes("/employer/application-details")) {
              const id = applicationsurl.split("/").pop();

              navigate(`/employer/application-details/${id}`);
            } else {
              navigate("/employer/dashboard");
            }
          }
        } else if (data.user.data.role === "client") {
          const deeplinkpage = localStorage.getItem("deeplinkpage");
          if (deeplinkpage === "find-house-cook") {
            navigate("/client/find-house-cook");
            return;
          } else if (deeplinkpage === "find-party-cook") {
            navigate("/client/find-party-cook");
            return;
          } else if (deeplinkpage === "find-catering") {
            navigate("/client/find-catering");
            return;
          } else {
            const applicationsurl = localStorage.getItem("applicationUrl");
            // If from is present and it's an application details page, navigate to it
            if (
              applicationsurl?.includes("/client/requirement-request-details")
            ) {
              const id = applicationsurl.split("/").pop();
              navigate(`/client/requirement-request-details/${id}`);
            } else if (
              applicationsurl?.includes("/client/event-request-details")
            ) {
              const id = applicationsurl.split("/").pop();
              navigate(`/client/event-request-details/${id}`);
            } else {
              navigate("/client/dashboard");
            }
          }
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        //setShowModal(true);
      });
  }

  const saveforgotlink = () => {
    localStorage.setItem("forgotlink", "employerforgot");
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          className="mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            lg={5}
            md={5}
            xl={5}
            className="d-none d-lg-block"
            style={{ background: "#fff" }}
            align="right"
          >
            <img src={login_banner} alt="" style={{ height: "470px" }} />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center flex-column"
            lg={5}
            xs={11}
            md={12}
            xl={5}
            style={{
              backgroundColor: "white",
              height: "470px",
            }}
          >
            <p className="h2 text-center mt-5">Employer Login</p>
            <p style={{ color: "red" }}>{modalMessage}</p>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={signIn}
              className="text-center mt-3"
              style={{
                height: "100%",
                width: "70%",
              }}
            >
              <Form.Group className="mb-4">
                <Form.Control
                  className="input1"
                  type="text"
                  name="emailOrMobile"
                  value={emailOrMobile}
                  onChange={handleChange}
                  isInvalid={!!errors.emailOrMobile}
                  placeholder="Enter email or मोबाइल नंबर"
                />
                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.emailOrMobile}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4 position-relative">
                <Form.Control
                  name="password"
                  onChange={handleChange}
                  value={password}
                  isInvalid={!!errors.password}
                  className="input1"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                />

                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "13%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  )}
                </span>

                <Form.Control.Feedback type="invalid" className="text-start">
                  {errors.password}
                </Form.Control.Feedback>
                <NavLink to="/forgot-password" onCLick={saveforgotlink}>
                  <p className="text-end forgot_password mt-2">
                    Forgot password?
                  </p>
                </NavLink>
              </Form.Group>
              <Button
                className="mb-2 mt-2 login_btn"
                disabled={isdisabled}
                type="submit"
              >
                Login
              </Button>
              <p className="text-muted mt-2">
                Don't have an account?&nbsp;
                <NavLink to="/employer-registration">
                  <span className="forgot_password">Signup</span>
                </NavLink>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default EmployerLogin;
