import React, { useEffect, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal2 from "../../auth_screens/Modal2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import SuccessModal from "../../auth_screens/SuccessModal";
import { ChangeRoleCook } from "../../../store/slices/cookprofile";

function CookChangeRole() {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [chef, setCook] = useState(false);
  const [houseCook, setHouseCook] = useState(false);
  const [partyCook, setPartyCook] = useState(false);
  const [showModal1, setshowModal1] = useState(false);
  const data = useSelector((state) => state?.cookData?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [errors, setErrors] = useState("");
  const [changeError, setChangeError] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [notoneSelect, setNotselect] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [rolesFrom, setRolesFrom] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });
  const [rolesTo, setRolesTo] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });

  const rolecomeFrom = [];
  const rolegoneTo = [];

  useEffect(() => {
    if (data?.data?.cookType === 2) {
      setCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
    }
    if (data?.data?.partyCook === 1) {
      setPartyCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
    }

    if (data?.data?.cookType === 1) {
      setHouseCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
    }
  }, []);

  const handleToggleCook = () => {
    setNotselect("");
    setCook((prevCook) => {
      const newCook = !prevCook;
      setRolesTo({
        ...rolesTo,
        chef: newCook ? 1 : 0,
      });
      if (newCook && houseCook) {
        setErrors("Both House Cook and Chef can't be selected");
      } else {
        setErrors("");
      }
      return newCook;
    });

    setChangeError("");
  };

  const handleToggleHouseCook = () => {
    setNotselect("");
    setHouseCook((prevHouseCook) => {
      const newHouseCook = !prevHouseCook;
      setRolesTo({
        ...rolesTo,
        houseCook: newHouseCook ? 1 : 0,
      });
      if (chef && newHouseCook) {
        setErrors("Both House Cook and Chef can't be selected");
      } else {
        setErrors("");
      }
      return newHouseCook;
    });

    setChangeError("");
  };

  const handleTogglePartyCook = () => {
    setNotselect("");
    const newPartyCook = !partyCook;
    setPartyCook(!partyCook);
    setRolesTo({
      ...rolesTo,
      partyCook: newPartyCook ? 1 : 0,
    });
    setChangeError("");
  };

  useEffect(() => {
    document.title = "CookandChef | Change Role";
  }, []);

  useEffect(() => {
    // Check if current values are different from the initial values
    const isCookChanged = rolesFrom.chef !== rolesTo.chef;
    const isHouseCookChanged = rolesFrom.houseCook !== rolesTo.houseCook;
    const isPartyCookChanged = rolesFrom.partyCook !== rolesTo.partyCook;

    // Set isChange to true if any of the values is different
    setIsChange(isCookChanged || isHouseCookChanged || isPartyCookChanged);
  }, [rolesFrom, rolesTo]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (!chef && !houseCook && !partyCook) {
      setNotselect("Please select atleast one role");
    }
    if (!errors && isChange && (chef || houseCook || partyCook)) {
      setshowModal1(true);
    } else if (!errors && !isChange && !notoneSelect) {
      setChangeError("Please change roles to submit");
    }
    Object.keys(rolesFrom).forEach((role) => {
      if (rolesFrom[role] === 1) {
        if (role === "chef") {
          rolecomeFrom.push("Chef");
        }
        if (role === "houseCook") {
          rolecomeFrom.push("House Cook");
        }
        if (role === "partyCook") {
          rolecomeFrom.push("Party Cook");
        }
      }
    });

    // Map rolesTo values and push those that are 1 into rolegoneTo
    Object.keys(rolesTo).forEach((role) => {
      if (rolesTo[role] === 1) {
        if (role === "chef") {
          rolegoneTo.push("Chef");
        }
        if (role === "houseCook") {
          rolegoneTo.push("House Cook");
        }
        if (role === "partyCook") {
          rolegoneTo.push("Party Cook");
        }
      }
    });
    setModalMessage(
      "Your Cook type will be changed from " +
        rolecomeFrom.join(" and ") +
        " to " +
        rolegoneTo.join(", ")
    );
  };

  const handleCloseModal = () => {
    setshowModal1(false);
    setShowPassword(false);
    setPasswordError("");
    setPassword("");
    setPasswordOpen(false);
  };

  const openPassword = () => {
    setPasswordOpen(true);
  };

  const ChangeRolefunc = () => {
    if (!password) {
      setPasswordError("Please enter your password");
    } else if (passwordError) {
    } else {
      const item = {
        memberType: 1,
        cookType: chef ? 2 : houseCook ? 1 : 0,
        partyCook: partyCook ? 1 : 0,
        password: password,
      };
      dispatch(ChangeRoleCook(item))
        .unwrap()
        .then((data) => {
          handleCloseModal();
          setsuccessModal(true);
          setModalMessage(data?.user?.message);
          setTimeout(() => {
            logout();
          }, 1000);
        })
        .catch(({ message }) => {
          if (message === "Invalid password. Try again!") {
            setPasswordError(message);
          }
        });
    }
  };

  const logout = () => {
    localStorage.clear();
    localStorage.setItem("logout", Date.now().toString());
    localStorage.setItem("role", "cook");
    navigate("/login");
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setPassword(value);
    if (value === "") {
      setPasswordError("Please enter your password");
    } else {
      setPasswordError("");
    }
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={showModal1}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title
            align="center"
            style={{ fontSize: "20px" }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <h6>{modalMessage}</h6>
          <button className="modalcancelBtn m-2" onClick={handleCloseModal}>
            Cancel
          </button>
          <Button className="modalsaveBtn m-2" onClick={openPassword}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={passwordOpen}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title align="center" style={{ fontSize: "20px" }}>
            Please enter your password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <Form.Group className="mb-3 position-relative text-start">
            <label>Password *</label>
            <Form.Control
              name="Password"
              onChange={handleChange}
              value={password}
              isInvalid={passwordError}
              className="input1"
              maxlength={25}
              type={showPassword ? "text" : "password"}
              placeholder="Enter  password"
            />
            <span
              style={{
                position: "absolute",
                right: "0px",
                top: passwordError ? "40%" : "60%", // Conditionally set the top value
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEye style={{ fontSize: "18px", cursor: "pointer" }} />
              ) : (
                <AiOutlineEyeInvisible
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
              )}
            </span>
            <Form.Control.Feedback type="invalid" className="text-start">
              {passwordError}
            </Form.Control.Feedback>
          </Form.Group>

          <button className="modalcancelBtn m-2" onClick={handleCloseModal}>
            Cancel
          </button>
          <Button className="modalsaveBtn m-2" onClick={ChangeRolefunc}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
      <Row>
        <Col
          lg={8}
          xl={8}
          md={10}
          xs={12}
          sm={12}
          className="card text-center p-3 ms-3"
        >
          <Form
            noValidate
            autoComplete="off"
            className="text-center mt-3 p-2"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "",
              borderRadius: "0px 15px 15px 0px",
            }}
          >
            <Col lg={10} xl={10}>
              <div className="d-flex justify-content-between p-1">
                <div className="text-start">
                  <p className="mb-1" style={{ textWrap: "wrap" }}>
                    Chef
                  </p>
                </div>
                <div className="mt-1">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="notification-status"
                      style={{ height: "4rem", width: "4rem" }}
                      checked={chef === true}
                      onChange={() => handleToggleCook()}
                    />
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={10} xl={10}>
              <div className="d-flex justify-content-between p-1">
                <div className="text-start">
                  <p className="mb-1" style={{ textWrap: "wrap" }}>
                    House Cook
                  </p>
                </div>

                <Form>
                  <Form.Check
                    type="switch"
                    id="notification-status"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={houseCook === true}
                    onChange={() => handleToggleHouseCook()}
                  />
                </Form>
              </div>
            </Col>
            <Col lg={10} xl={10}>
              <div className="d-flex justify-content-between p-1">
                <div className="text-start">
                  <p className="mb-1" style={{ textWrap: "wrap" }}>
                    Party Cook
                  </p>
                </div>

                <Form>
                  <Form.Check
                    type="switch"
                    id="notification-status"
                    style={{ height: "4rem", width: "4rem" }}
                    checked={partyCook === true}
                    onChange={() => handleTogglePartyCook()}
                  />
                </Form>
              </div>
            </Col>

            <p style={{ color: "#dc3545" }} align="left">
              {errors || changeError || notoneSelect}
            </p>

            <Col lg={10} xl={10}>
              <Form.Group>
                <Button
                  type="cancel"
                  className="cancelBtn"
                  onClick={() => navigate("/cook/dashboard")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="saveBtn ms-2"
                  disabled={isdisabled}
                  onClick={HandleSubmit}
                >
                  Submit
                </Button>
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
    </>
  );
}

export default CookChangeRole;
