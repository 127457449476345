import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useEffect } from "react";
import { getfaq } from "../../store/slices/config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import NavMenu from "../nav_bar/NavMenu";
import Footer from "../nav_bar/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
const FAQs = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getfaq())
      .unwrap()
      .then((data) => {
        setData(data.faq.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Know About Cook and Chef Our Mission and Vision </title>
        <meta
          name="description"
          content="At Cook and Chef, we help both cooks and clients, here they get a great platform for both cook and clients to fulfill their needs. Cook and Chef provides an online platform where anyone can search a cook as per their choice. Both client and cook can search each other. Cook and Chef provides all types of cooks e.g. Household Cook, Party Cook, Chef and caterings near you or any location. You can search a cook nearby you by signing in and updating your profile. You can search cooks by any city e.g. Cooks in Bangalore, Cooks in Delhi etc. Or you can search by specifying area also e.g. Cooks in Electronic City, Cooks in Banaswadi, Cooks near Ecospace Tech Park, Cooks in Shantiniketan etc. You can also search by food type like North Indian Cook near me, South Indian Cooks in Bangalore, Odia Cook in Marathahalli, Bengali Cooks in Kadugodi etc. You can search by Cooks near me, Part Time Cook etc."
        />
      </Helmet>
      <NavMenu />
      <div className="breadcrumb-containerabout pt-2 pb-2">
        <Row>
          <Col>
            <h2 className="text-center">Frequently Asked Questions (FAQs)</h2>
          </Col>
        </Row>
      </div>
      <Container>
        <div className="mt-5">
          <Row className="align-content-center justify-content-center">
            <Col xs={11} md={11} lg={10} xl={9}>
              <Accordion defaultActiveKey="0">
                {data.map((faq, index) => (
                  <div className="mt-3">
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body>
                        <p style={{ textWrap: "wrap" }}>{faq.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
      <br />
      <Footer />
    </>
  );
};

export default FAQs;
