import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaBookmark } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { BsCheck, BsX } from "react-icons/bs";
import restaurant from "../../../../Assets/Images/restaurant.png";
import { BsBookmark, BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import {
  CookApplyJob,
  CookMobileactivityLog,
  GetJobDetails,
  cookCancelJob,
  getcookProfile,
  removeshortlistjob,
  shortlistjob,
} from "../../../../store/slices/cookprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ReportChefModal from "./ReportModal";
import { createChatRoom } from "./CreateChatRoom";
import InitApp1 from "../../../../utils/helpers/CreateOthersWeavy";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import ContactPivacyModal from "../../../../utils/helpers/ContactPrivacyModal";
import { StarRating1 } from "../../../../utils/helpers/EmployerRatingModal";
import EmployerRatingModal from "../../../../utils/helpers/EmployerRatingModal";
import ReviewModal from "../../../../utils/helpers/ReviewModal";

function SavedJobDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [cookplan, setCookPlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [show1, setShow1] = useState(false);
  const [reportJob, setReportJob] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setphoneNumber] = useState();
  const [rateModal, setRateModal] = useState(false);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [employerId, setEmployerId] = useState();
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    document.title = "CookandChef | जॉब विवरण";
  }, []);

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setProfileData(data);
        setCuisines(data?.user.data?.chefCuisines);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const getDetails = () => {
    dispatch(GetJobDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.jobdetails.data);
        setphoneNumber(data?.jobdetails?.data?.jobData?.contactNumber);
        setEmployerId(data?.jobdetails?.data?.jobData?.employerId?.id);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const handleCloseModal = () => {
    setCookPlan(false);
    setShow1(false);
  };

  const handleContinue = () => {
    setCookPlan(false);
    const item = {
      id: id,
    };
    dispatch(CookApplyJob(item))
      .then((data) => {
        if (data?.error?.message === "Rejected") {
          setModalMessage(data?.payload?.message);
          setShowModal(true);

          setTimeout(() => {
            setShowModal(false);
          }, 1000);
          getDetails();
        } else {
          setModalMessage("Applied successfully!");
          setsuccessModal(true);
          getDetails();
          setTimeout(() => {
            setsuccessModal(false);
          }, 1000);
          getDetails();
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      });
  };

  const toggleMobileView = () => {
    const item = {
      jobId: id,
      activity: "viewedmobilenumber",
    };
    if (profileData?.user.data?.chefProfileStatus === 0) {
      setShow1(true);
    } else if (data?.jobData?.employerId?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else {
      if (showFullMobile == false) {
        dispatch(CookMobileactivityLog(item))
          .unwrap()
          .then((data) => {
            setShowFullMobile(!showFullMobile);
          })
          .catch(({ message }) => {
            setModalMessage(message);
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
            }, 3000);
          });
      }
    }
  };

  const handleInteracction = async (value) => {
    const item = {
      jobId: id,
      activity: value,
    };
    if (profileData?.user.data?.chefProfileStatus === 0) {
      setShow1(true);
    } else {
      try {
        const data1 = await dispatch(CookMobileactivityLog(item)).unwrap();
        if (value === "mobileinteraction") {
          window.location.href = `tel:${phoneNumber}`;
        } else if (value === "whatsappinteraction") {
          window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        } else if (value === "chatinteraction") {
          let member2;
          let res;
          const weavyid = parseInt(localStorage.getItem("weavyId"));
          const member1 = parseInt(user?.data?.weavyId) || weavyid;

          const chatroomName = data?.jobData?.employerId?.fullName;
          if (!data?.jobData?.employerId?.weavyId) {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer  ${API_PATHS.weavyKey}`,
              },
              body: JSON.stringify({
                uid: data?.jobData?.employerId?.id,
                name: data?.jobData?.employerId?.fullName,
              }),
            };
            const rees = await InitApp1(
              requestOptions,
              data?.jobData?.employerId,
              "employer"
            );
            member2 = parseInt(localStorage.getItem("weavyId2"));
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          } else {
            member2 = parseInt(data?.jobData?.employerId?.weavyId);
            const memberIds = [member1, member2];
            res = await createChatRoom(chatroomName, memberIds);
          }

          if (res?.id) {
            navigate("/hin/cook/message", {
              state: { res: res },
            });
          }
        }
      } catch ({ message }) {
        setModalMessage(message);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      }
    }
  };

  const removeshortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      jobId: id,
    };
    if (profileData?.user.data?.chefProfileStatus === 0) {
      return;
    } else {
      dispatch(removeshortlistjob(elem))
        .then((data) => {
          if (data?.payload?.message) {
            setModalMessage(data?.payload?.message);
            setsuccessModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
          }
          getDetails();
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    }
  };

  const shortList = (id) => {
    setIsRespTrue(false);
    var elem = {
      id: id,
    };
    if (profileData?.user.data?.chefProfileStatus === 0) {
    } else {
      dispatch(shortlistjob(elem))
        .then((data) => {
          if (data?.payload?.message) {
            setModalMessage(data?.payload?.message);
            setsuccessModal(true);
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
          }
          getDetails();
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    }
  };

  const CancelApplication = () => {
    const item = {
      id: data?.applicationData?.id,
    };
    if (profileData?.user.data?.chefProfileStatus === 0) {
      setShow1(true);
    } else {
      dispatch(cookCancelJob(item))
        .then((data) => {
          if (data?.error?.message === "Rejected") {
            setModalMessage(data?.payload?.message);
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
            }, 3000);
          } else if (data?.payload?.user?.message) {
            setModalMessage(data?.payload?.user?.message);
            setsuccessModal(true);
            getDetails();
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
          }
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
        });
    }
  };

  const ApplyJob = () => {
    if (profileData?.user?.data?.chefProfileStatus === 0) {
      setShow1(true);
    } else {
      const isMatched = data?.jobData?.cuisines?.some((cookplanVal) =>
        cuisines?.includes(cookplanVal)
      );

      if (isMatched === false) {
        setCookPlan(true);
      } else {
        handleContinue();
      }
    }
  };

  const gotoEdit = () => {
    navigate("/hin/cook/edit-chef-profile");
  };

  useEffect(() => {
    // Check if data exists and has ratingsList
    if (data && data?.jobData && data?.jobData?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.jobData?.ratingsList.some(
        (rating) => rating.comment
      );

      // Set reviews based on whether any rating has a comment
      setReviews(hasComment);
    }
  }, [data]);

  return (
    <>
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={id}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
        type="Job"
      />
      <ReviewModal
        showModal={reviewModal}
        data={data.jobData}
        setShowModal={setShowReviewModal}
        role="cook"
      />
      <EmployerRatingModal
        rateModal={rateModal}
        id={employerId}
        setRateModal={setRateModal}
        getDetails={getDetails}
        data={data}
      />
      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <Modal
        show={cookplan}
        style={{ top: "20%", left: "5%" }}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <p>
            Your profile's cuisine doesn't match with the requirement.
            <br /> <b>Would you still want to apply?</b>
          </p>
          <br />
          <Button className="modalsaveBtn" onClick={handleContinue}>
            Confirm
          </Button>
          <Button className="modalcancelBtn ms-2" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={show1}
        style={{ top: "20%", left: "5%" }}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <p>
            Your शेफ प्रोफ़ाइल is empty.
            <br /> <b>Please update your profile to apply</b>
          </p>

          <Button className="modalsaveBtn" onClick={gotoEdit}>
            Update
          </Button>
          <Button className="modalcancelBtn ms-2" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/cook/short-listed-jobs" className="bcrumb">
          नौकरियाँ बचाई गईं
        </Link>
        &nbsp;&bull; जॉब विवरण
      </div>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={3}>
                {data?.jobData?.employerId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={data?.jobData?.employerId?.dp}
                    alt="user profile"
                  />
                )}
                {!data?.jobData?.employerId?.dp && (
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "15px",
                    }}
                    src={restaurant}
                    alt="user profile"
                  />
                )}
              </Col>
              <Col
                xs={3}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && <p>Saved</p>}
                </div>
              </Col>
              <Col
                xs={1}
                className="d-sm-block d-md-none d-lg-none"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={7} xl={7} xs={12} md={6}>
                <h6 className="mt-2" style={{ textWrap: "wrap" }}>
                  {data?.jobData?.employerId?.fullName}
                </h6>
                <p style={{ textWrap: "wrap" }}>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.jobData?.location}, {data?.jobData?.pincode}
                  <br />
                  <AiOutlineMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.jobData?.contactNumber}</span>
                  ) : (
                    <span>
                      {data?.jobData?.contactNumber
                        ?.toString()
                        ?.substring(0, 3)}
                      xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-24px",
                          // marginLeft: "150px",
                          color: "blue",
                          // width: "40px",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col
                lg={2}
                xl={2}
                md={2}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-end align-items-right flex-column">
                  {data?.shortlistData && (
                    <p style={{ marginRight: "-25px" }}>Saved</p>
                  )}
                </div>
              </Col>
              <Col
                lg={1}
                md={1}
                className="d-none d-md-block d-lg-block"
                align="right"
              >
                <div className="d-flex justify-content-between align-items-center flex-column">
                  {data.shortlistData && isRespTrue && (
                    <div
                      onClick={() => removeshortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <FaBookmark />
                    </div>
                  )}

                  {data.shortlistData && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <FaBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue && (
                    <div
                      onClick={() => shortList(id)}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      <BsBookmark />
                    </div>
                  )}
                  {data.shortlistData === null && isRespTrue === false && (
                    <div style={{ color: "#000", cursor: "pointer" }}>
                      <BsBookmark />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={5} xl={7} xs={12} md={5} />
              <Col lg={7} xl={5} xs={12} md={7} className="jobCard">
                <Row>
                  {data?.applicationData ? (
                    <Col lg={6} md={6} xs={6}>
                      <Link
                        onClick={CancelApplication}
                        style={{ lineHeight: "40px" }}
                      >
                        <u>आवेदन रद्द करें</u>
                      </Link>
                    </Col>
                  ) : (
                    <Col lg={6} md={6} xs={6}>
                      <button onClick={ApplyJob} className="jobsaveBtn mt-2">
                        आवेदन कीजिये
                      </button>
                    </Col>
                  )}
                  {data?.jobData?.employerId?.smsContact === 1 && (
                    <Col
                      lg={2}
                      xs={2}
                      md={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.jobData?.employerId?.whatsappContact === 1 && (
                    <Col
                      lg={2}
                      xs={2}
                      md={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("whatsappinteraction")}
                    >
                      <BsWhatsapp
                        style={{ color: "#059F14", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.jobData?.employerId?.chatContact === 1 && (
                    <Col
                      lg={2}
                      md={2}
                      xs={2}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Row className="mt-4">
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col
            lg={6}
            xs={12}
            md={6}
            sm={12}
            className="card m-1 detailCard p-3"
          >
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              नौकरी विवरण
            </h6>
            <p className="text-heading">पद का नाम</p>
            <p className="text-details">{data?.jobData?.designation}</p>
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              &nbsp;
            </h6>
            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading">सैलरी</p>
                <p className="text-details">{data?.jobData?.salary}</p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">अनुभव</p>

                <p className="text-details">
                  {data?.jobData?.experience} Years
                </p>
              </Col>
            </Row>
          </Col>
        )}

        {isLoading ? (
          <Col lg={6} className="card m-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col lg={6} xs={12} md={6} className="card ms-2 detailCard p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              रेक्विरेमेंट विवरण
            </h6>
            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading">पदों की संख्या </p>
                <p className="text-details">{data?.jobData?.openings}</p>
              </Col>
              <Col lg={6} xs={6}>
                <p className="text-heading">अत्यावश्यकता</p>
                <p className="text-details">{data?.jobData?.urgency}</p>
              </Col>
            </Row>
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              जॉब विवरण
            </h6>
            <Row>
              <Col lg={6} xs={6}>
                <p className="text-heading"> खाना </p>
                <p className="text-details">
                  {data?.jobData?.food === 1 ? (
                    <span>
                      <BsCheck
                        style={{
                          color: "green",
                          fontSize: "14px",
                        }}
                      />
                      Yes
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      <BsX />
                      No
                    </span>
                  )}
                </p>
              </Col>

              <Col lg={6} xs={6}>
                <p className="text-heading"> आवास </p>
                <p className="text-details">
                  {data?.jobData?.accommodation === 1 ? (
                    <span>
                      <BsCheck
                        style={{
                          color: "green",
                          fontSize: "14px",
                        }}
                      />
                      Yes
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      <BsX />
                      No
                    </span>
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {isLoading ? (
        <Row className="mt-3 p-2 card mb-3">{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-3 justify-content-center p-2 card mb-3">
          <Col lg={11} xs={12} md={11} sm={12}>
            <h6
              className="text-left mt-1"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              नौकरी का विवरण
            </h6>
          </Col>

          <Col xs={12} md={12} lg={12} className="mt-3">
            {data?.jobData?.description && (
              <p className="text-details">{data?.jobData?.description}</p>
            )}
          </Col>
        </Row>
      )}
      {isLoading ? (
        <Row className="mt-3 p-2 card mb-3">{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-3 justify-content-center p-2 card mb-3">
          <Col lg={11} xs={12} md={11} sm={12}>
            <h6
              className="text-left mt-1"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              कौशल एवं शिक्षा
            </h6>
          </Col>
          <Col lg={11} xs={12}>
            <p className="text-heading">व्यंजन</p>
            <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
              {data?.jobData?.cuisines?.map((cookplanVal, index) => (
                <Col
                  xs={5}
                  sm={5}
                  md={3}
                  lg={2}
                  xl={2}
                  className="me-2"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    color: "black",
                    padding: "4px 8px",
                    margin: "8px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "14px",
                    marginRight: "4px",
                    border: "solid 1px #ff5c09",
                    width: "auto",
                  }}
                >
                  {cookplanVal}
                </Col>
              ))}
            </Row>
          </Col>

          <Col xs={12} md={12} lg={11}>
            <p className="text-heading">व्यंजन</p>
            {data?.jobData?.dishes && (
              <p className="text-details">{data?.jobData?.dishes}</p>
            )}
          </Col>
          <Col xs={12} md={12} lg={11}>
            <p className="text-heading">योग्यता</p>
            <p className="text-details">{data?.jobData?.qualification}</p>
          </Col>
        </Row>
      )}
      <br />
      <br />
      <Row>
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col
            style={{ borderRadius: "15px", padding: "14px" }}
            className="card"
          >
            {data?.jobData?.ratingsData?.behaviour?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.facilities?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.salary?.ratingAvg === 0 &&
            data?.jobData?.ratingsData?.workculture?.ratingAvg === 0 ? (
              <div>
                <div className="text-start">
                  रेटिंग देने वाले प्रथम व्यक्ति बनें
                </div>
                <Link
                  className="text-start"
                  style={{
                    color: "#ff5c09",
                    textDecoration: "underline",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setRateModal(true)}
                >
                  <u>Rate this employer</u>
                </Link>
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <div style={{ marginTop: "10px" }}>Work culture :</div>
                    <div style={{ marginTop: "10px" }}>Salary & increment:</div>
                    <div style={{ marginTop: "10px" }}>Facilities :</div>
                    <div style={{ marginTop: "10px" }}>Behaviour :</div>
                  </div>
                  <div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.behaviour?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.facilities?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.salary?.ratingAvg
                        )}
                      />
                    </div>
                    <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                      <StarRating1
                        value={Math.ceil(
                          data?.jobData?.ratingsData?.workculture?.ratingAvg
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Link
                    className="text-start mt-3"
                    style={{
                      color: "#ff5c09",
                      textDecoration: "underline",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => setRateModal(true)}
                  >
                    <u>Rate this employer</u>
                  </Link>

                  {reviews && (
                    <Link
                      className="text-start mt-3 ml-5"
                      style={{
                        color: "#ff5c09",
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "30px",
                      }}
                      onClick={() => setShowReviewModal(true)}
                    >
                      <u>समीक्षा देखें</u>
                    </Link>
                  )}
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
      <br />
      <br />
      <Row>
        <Col style={{ borderRadius: "15px", padding: "14px" }}>
          <Link
            onClick={() => setReportJob(true)}
            className="text-start mt-3"
            style={{
              color: "#8F8686",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <u>Report this job</u>
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default SavedJobDetails;
