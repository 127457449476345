import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineFoodBank } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import {
  clienteventStatus,
  getRequirementRequests,
} from "../../../../store/slices/employerprofile";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import chef from "../../../../Assets/Images/cook.png";
import newtag from "../../../../Assets/Images/new.png";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import Skeleton from "react-loading-skeleton";

function GetRequirementRequest() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const dispatch = useDispatch();
  const [alldata, setAlldata] = useState([]);
  const [sortVal, setSortVal] = useState();
  const [totaldata, setTotaldata] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 6;
  const pagesToShowInitially = 2;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };

  useEffect(() => {
    getchefs();
  }, [currentPage]);

  useEffect(() => {
    document.title = "CookandChef | Requirement Interests";
  }, []);

  let pageq = parseInt(search.get("page"));

  const getchefs = () => {
    setisLoading(false);
    dispatch(getRequirementRequests(item))
      .unwrap()
      .then((data) => {
        setAlldata(data?.allrequirementrequest?.data);
        setTotaldata(data?.allrequirementrequest?.totalDataCount);
        if (pageq == null) {
          setData(data.allrequirementrequest.data.slice(0, itemsPerPage));
        }
      })
      .catch(({ message }) => {})
      .finally(() => {
        setisLoading(true);
      });
  };

  const SetApplicationStatus = (a, b) => {
    const item = {
      id: a._id,
      applicationStatus: b,
    };
    dispatch(clienteventStatus(item))
      .unwrap()
      .then((data) => {
        setModalMessage(data?.eventstatus?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        getchefs();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const handleApplyFilter = () => {
    let sortedArray = [...alldata];

    if (sortVal === "exp-Low") {
      sortedArray.sort(
        (a, b) => a.cooksData.chefExperience - b.cooksData.chefExperience
      );
    } else if (sortVal === "exp-High") {
      sortedArray.sort(
        (a, b) => b.cooksData.chefExperience - a.cooksData.chefExperience
      );
    } else if (sortVal === "sal-Low") {
      sortedArray.sort(
        (a, b) => a.cooksData.expectedSalary - b.cooksData.expectedSalary
      );
    } else if (sortVal === "sal-High") {
      sortedArray.sort(
        (a, b) => b.cooksData.expectedSalary - a.cooksData.expectedSalary
      );
    } else if (sortVal === "date-Low") {
      sortedArray.sort((a, b) => new Date(a.appliedAt) - new Date(b.appliedAt));
    } else if (sortVal === "date-High") {
      sortedArray.sort((a, b) => new Date(b.appliedAt) - new Date(a.appliedAt));
    }
    setAlldata(sortedArray);
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(true);
    }, [300]);
    setisLoading(false);
    handleApplyFilter();
  }, [sortVal]);

  const handleChange = (e) => {
    let value = e.target.value;
    setCurrentPage(1);
    setSortVal(value);
    search.set("sortVal", e.target.value);
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });

    handleApplyFilter();
  };

  useEffect(() => {
    const sortingVal = search.get("sortVal");
    if (sortingVal !== null) {
      setSortVal(sortingVal);
    }
  }, []);

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setData(alldata.slice(start, end));
  }, [alldata, currentPage]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setsuccessModal={setsuccessModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/client/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull; होउसेकूक आवेदन
        </div>

        <Row className="mt-2 ps-3">
          {totaldata === 0 ? (
            <Row className="">
              <Col lg={10} xs={12} md={12} align="center" className="card p-5">
                <b>कोई आवेदन नहीं दिखानी है.</b>
                <br />
                <p align="left" style={{ color: "grey" }}>
                  कृपया सुनिश्चित करें:
                  <ul>
                    <li>आपकी एक सक्रिय रीक्वारेमेंट है.</li>
                    <li>
                      रीक्वारेमेंट विवरण में आवश्यक जानकारी और कौशल सेट हैं
                    </li>
                  </ul>
                  उपरोक्त सभी के अलावा, अपना प्रोफ़ाइल विवरण अपडेट करें.
                </p>
              </Col>
            </Row>
          ) : isLoading ? (
            <Row>
              <Form>
                <Row>
                  <Col lg={9} sm={12} xs={12} md={12}>
                    <Row className="justify-content-end">
                      <Col lg={2} sm={4} md={3} xs={3} align="right">
                        <label
                          style={{
                            height: "40px",
                            lineHeight: "40px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Sort by:
                        </label>
                      </Col>
                      <Col lg={4} sm={7} md={4} xs={7}>
                        <Form.Group className="mb-1">
                          <Form.Control
                            style={{ width: "190px", fontSize: "14px" }}
                            as="select"
                            name="experience"
                            value={sortVal}
                            onChange={handleChange}
                          >
                            <option value="date-High">
                              Applied Date - Newer
                            </option>
                            <option value="date-Low">
                              Applied Date - Older
                            </option>
                            {/* <option value="sal-Low">Salary - Low to high</option>
                    <option value="sal-High">Salary - High to low</option> */}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Col lg={9} sm={12} xs={12} md={12}>
                <Row>
                  {data?.map((item, index) => (
                    <Row
                      className="d-flex justify-items-center align-items-center mb-2"
                      key={index}
                      style={{
                        backgroundColor: "white",
                        // height: "20dvh",
                        // width: "100%",
                        borderRadius: "15px",
                        padding: "8px",
                      }}
                    >
                      <Col
                        lg={2}
                        xs={3}
                        md={2}
                        className="justify-content-center"
                      >
                        {item?.isViewed === false && (
                          <img
                            src={newtag}
                            alt=""
                            style={{
                              marginTop: "-20px",
                              marginLeft: "-28px",
                            }}
                          />
                        )}
                        {item?.cooksData?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "70px",
                            }}
                            src={item.cooksData.dp}
                            alt="user profile"
                          />
                        )}
                        {!item?.cooksData?.dp && (
                          <img
                            className="align-self-center"
                            style={{
                              borderRadius: "10px",
                              height: "80px",
                              width: "70px",
                            }}
                            src={chef}
                            alt="user profile"
                          />
                        )}
                      </Col>
                      <Col lg={9} xs={8} md={9}>
                        <h6
                          style={{
                            fontWeight: "600",
                            marginBottom: "0.2rem",
                          }}
                          className="ms-3"
                        >
                          {item?.cooksData?.fullName.substring(0, 30)}
                        </h6>

                        {item?.cooksData?.householdCuisines?.length >= 3 ? (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.cooksData?.householdCuisines
                              ?.join(", ")
                              .substring(0, 20)}
                            ...
                          </p>
                        ) : (
                          <p
                            style={{
                              marginBottom: "0.25rem",
                              fontSize: "14px",
                            }}
                            className="ms-3"
                          >
                            <MdOutlineFoodBank
                              style={{ marginBottom: "2px" }}
                            />
                            &nbsp;
                            {item?.cooksData?.householdCuisines
                              ?.join(", ")
                              .substring(0, 20)}
                          </p>
                        )}
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <GrLocation style={{ marginBottom: "2px" }} />
                          &nbsp;
                          {item?.cooksData?.area}
                        </p>
                        <p
                          style={{
                            marginBottom: "0.25rem",
                            fontSize: "14px",
                          }}
                          className="ms-3"
                        >
                          <MdCurrencyRupee style={{ marginBottom: "2px" }} />
                          {item?.cooksData?.payment}
                        </p>
                      </Col>
                      <Col xs={1} md={1} lg={1}>
                        <Dropdown
                          style={{
                            marginTop: "-50px",
                            background: "none",
                          }}
                        >
                          <Dropdown.Toggle
                            // id="dropdown-basic"
                            style={{
                              width: "25px",
                              height: "25px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <BiDotsVerticalRounded
                              style={{
                                marginTop: "-20px",
                                fontSize: "20px",
                                marginLeft: "-10px",
                                color: "#000",
                              }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "shortlisted")
                              }
                            >
                              Shortlisted
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "hired")
                              }
                            >
                              Hired
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "on Hold")
                              }
                            >
                              On Hold
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                SetApplicationStatus(item, "rejected")
                              }
                            >
                              Rejected
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col lg={3} xs={6} md={3}>
                        <p style={{ fontSize: "11px", color: "#8F8686" }}>
                          Applied on&nbsp;
                          {Moment.utc(item.appliedAt).format("DD-MM-YYYY")}
                        </p>
                      </Col>
                      <Col lg={7} xs={3} md={7}>
                        <div align="right">
                          {item?.applicationStatus === "applied" && (
                            <p
                              style={{
                                color: "#929402",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FEFFD3",
                              }}
                              align="center"
                            >
                              Applied
                            </p>
                          )}
                          {item?.applicationStatus === "on Hold" && (
                            <p
                              style={{
                                color: "#000000",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FFAF03",
                              }}
                              align="center"
                            >
                              On Hold
                            </p>
                          )}
                          {item?.applicationStatus === "shortlisted" && (
                            <p
                              style={{
                                color: "#0700F5",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "rgb(209 235 250)",
                              }}
                              align="center"
                            >
                              Shortlisted
                            </p>
                          )}
                          {item?.applicationStatus === "rejected" && (
                            <p
                              style={{
                                color: "#FF0606",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#FFDDD5",
                              }}
                              align="center"
                            >
                              Rejected
                            </p>
                          )}
                          {item?.applicationStatus === "hired" && (
                            <p
                              style={{
                                color: "#028D36",
                                padding: "5px",
                                borderRadius: "10px",
                                width: "70px",
                                fontSize: "12px",
                                backgroundColor: "#e7f8c6",
                              }}
                              align="center"
                            >
                              Hired
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg={2} xs={3} md={2}>
                        <div
                          align="left"
                          style={{ marginTop: "-20px", marginLeft: "-15px" }}
                        >
                          <Link
                            to={`/hin/client/requirement-request-details/${item._id}`}
                            style={{ color: "#000" }}
                          >
                            <Button className="view_profile">इसे देखें</Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Row>
              </Col>
            </Row>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
        </Row>
        {Math.floor(totaldata / itemsPerPage) > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default GetRequirementRequest;
