import React, { useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsCheck, BsX } from "react-icons/bs";
import Switch from "react-switch";
import { useNavigate, Link } from "react-router-dom";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import {
  getemployershortPlans,
  getemployerLongPlans,
} from "../../../store/slices/employerplan";
import { useEffect } from "react";
import Modal2 from "../../auth_screens/Modal2";
import { EffectCards } from "swiper/modules";

function EmployerPlans() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [planType, setPlanType] = useState("short");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData =
          planType === "short"
            ? await dispatch(getemployershortPlans())
            : await dispatch(getemployerLongPlans());

        setData(plansData?.payload?.user?.data);
      } catch (error) {
        setModalMessage(error);
        setShowModal(true);
      }
    };

    fetchPlans();
  }, [dispatch, planType]);

  const handleToggle = () => {
    setPlanType((prevType) => (prevType === "short" ? "long" : "short"));
  };

  const colordata = ["#048A18", "#004EDE", "#D700DE"];

  const getColorForIndex = (index) => {
    const colorIndex = index % colordata.length;
    return colordata[colorIndex];
  };

  const handleSelectPlan = (selectedPlan) => {
    navigate(`/employer/checkout/${selectedPlan.id}`, {
      state: { planData: selectedPlan },
    });
  };
  useEffect(() => {
    document.title = "CookandChef | Plans";
  }, []);

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/employer/dashboard" className="bcrumb">
          Home
        </Link>
        &nbsp;&bull; Plans
      </div>

      <Row className="mb-2 p-3">
        <div className="d-flex justify-content-center align-items-center ">
          <Form>
            {/* <Form.Check
              type="switch"
              id="plan-type-toggle"
              label={planType === "short" ? "   Short Term" : "   Long Term"}
              style={{ fontSize: "25px" }}
              checked={planType === "long"}
              onChange={handleToggle}
            /> */}
            <Switch
              onChange={handleToggle}
              checked={planType === "long"}
              handleDiameter={28}
              height={40}
              width={110}
              offColor="#6982FF"
              onColor="#ff5c09"
              offHandleColor="#ff5c09"
              onHandleColor="#6982FF"
              borderRadius={20}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                    height: "100%",
                    fontSize: 13,
                    color: "#fff",
                    width: "100px",
                    marginTop: "10px",
                    marginLeft: "-12px",
                    paddingRight: 2,
                  }}
                >
                  Short Term
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 13,
                    color: "#fff",
                    width: "100px",
                    marginLeft: "-10px",
                  }}
                >
                  Long Term
                </div>
              }
              uncheckedHandleIcon={<div className="switch-handle"></div>}
              checkedHandleIcon={<div className="switch-handle"></div>}
            />
          </Form>
        </div>
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            <Col
              lg={4}
              md={4}
              xl={4}
              className=" d-none d-lg-flex d-md-none flex-column justify-content-center align-items-center mt-3 "
            >
              <div
                className="planCard"
                style={{
                  background: "#fff",
                  height: "66dvh",
                  borderRadius: "14px",
                  boxShadow: "8px 8px 5px #e2dfff",
                }}
              >
                <p
                  className="my-2 text-center"
                  style={{
                    fontSize: "3dvh",
                    fontWeight: "500",
                    color: `${getColorForIndex(index)}`,
                  }}
                >
                  {item.employerPlanName}
                </p>
                <p
                  className="text-center my-2"
                  style={{ fontSize: "4dvh", fontWeight: "700" }}
                >
                  ₹&nbsp;{item.price}
                </p>
                <p
                  className="text-center  my-2"
                  style={{ fontSize: "20px", color: "#05506B" }}
                >
                  {item.validityInDays}&nbsp;Days
                </p>
                <div
                  className="justify-content-center align-items-center"
                  align="center"
                >
                  <Button
                    className="text-center planBtn mt-3"
                    onClick={() => handleSelectPlan(item)}
                  >
                    Select Plan
                  </Button>
                </div>

                <div className="text-start">
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        borderRadius: "100%",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    {item?.jobPoints}&nbsp;
                    <span className="text-muted">Job Post</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",

                        borderRadius: "100%",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    {item.profileViewPoints}&nbsp;
                    <span className="text-muted">Profile Views</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        padding: "",

                        borderRadius: "100%",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    &nbsp;
                    {item.responsePoints}
                    &nbsp;
                    <span className="text-muted">Candidate Response</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item.supportAssistance === 1 ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                    &nbsp;
                    <span className="text-muted">Support Assistance</span>
                  </p>
                  <p
                    className="text-start mt-3 mb-3 text-muted"
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    {item.refundPolicy ? (
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",

                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    ) : (
                      <BsX
                        style={{
                          color: "red",

                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                    &nbsp;<span className="text-muted">Refund Policy</span>
                  </p>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>

      {/**mobile plan screes */}

      <Swiper
        // slidesPerView={1.2}
        // centeredSlides={true}
        // loop={true}
        // spaceBetween={1}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        effect={"cards"}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCards, Navigation, Pagination]}
        className="mySwiper d-sm-block d-md-block d-lg-none"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Row className="mt-1 justify-content-center">
              <Col
                className="mx-2 d-flex flex-column justify-content-center align-items-center"
                xs={9}
                style={{
                  // border: `2px solid ${colordata[2]}`,
                  background: "white",
                  height: "58dvh",
                  borderRadius: "14px",
                }}
              >
                <div className="text-start">
                  <p
                    className="text-center"
                    style={{
                      fontSize: "3dvh",
                      fontWeight: "500",
                      color: `${getColorForIndex(index)}`,
                    }}
                  >
                    {item.employerPlanName}
                  </p>
                  <p
                    className="text-center my-2"
                    style={{ fontSize: "4dvh", fontWeight: "700" }}
                  >
                    ₹&nbsp;{item.price}
                  </p>

                  <p
                    className="text-center  my-2"
                    style={{ fontSize: "20px", color: "#05506B" }}
                  >
                    {item.validityInDays}&nbsp;Days
                  </p>
                  <div
                    className="justify-content-center align-items-center"
                    align="center"
                  >
                    <Button
                      className="text-center planBtn ms-3"
                      onClick={() => handleSelectPlan(item)}
                    >
                      Select Plan
                    </Button>
                  </div>
                  <div className="text-start">
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",
                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      {item?.jobPoints}&nbsp;
                      <span className="text-muted">Job Post</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      {item.profileViewPoints}&nbsp;
                      <span className="text-muted">Profile Views</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          padding: "",
                          borderRadius: "100%",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      &nbsp;
                      {item.responsePoints}
                      &nbsp;
                      <span className="text-muted">Candidate Response</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item.supportAssistance === 1 ? (
                        <BsCheck
                          style={{
                            color: "green",
                            padding: "",
                            borderRadius: "100%",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",
                            borderRadius: "100%",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                      &nbsp;
                      <span className="text-muted">Support Assistance</span>
                    </p>
                    <p
                      className="text-start mt-3 mb-3 text-muted"
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      {item.refundPolicy ? (
                        <BsCheck
                          style={{
                            color: "green",
                            borderRadius: "100%",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : (
                        <BsX
                          style={{
                            color: "red",
                            borderRadius: "100%",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                      &nbsp;
                      <span className="text-muted">Refund Policy</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default EmployerPlans;
